import React, { Component } from "react";
import { Link } from "react-router-dom";
import userService from "../../services/user.service";
import accessControlService from "../../services/accessControl.service";
import helper from "../../helpers/helpers";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import projectService from "../../services/project.service";
toast.configure();

class ViewUser extends Component {
  constructor(props) {
    super(props); //reference to the parents constructor() function.

    this.handleYes = this.handleYes.bind(this);
    this.handleNo = this.handleNo.bind(this);
    this.showPopUp = this.showPopUp.bind(this);

    ///Component State
    this.state = {
      User: [
        {
          UserID: null,
          FirstName: null,
          MiddleName: null,
          LastName: null,
          UserName: null,
          Email: null,
          IsLockedOut: null,
          RelievingDate: null,
        },
      ],
      showModal: false,
      canAccessEditUser: false,
      canAccessDeleteUser: false,
      loading: false,
      spinnerMessage: "",
    };
  }

  //#region component mount
  componentDidMount() {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    this.canUserAccessPage("Edit User");
    this.canUserAccessPage("Delete User");
    this.fetchUser();
  }
  //#endregion

  //#region Fetching selected User details
  fetchUser() {
    const { state } = this.props.location; // User ID passed from Users List Page
    if (state === 0 || state === null || state === undefined) {
      this.props.history.push("/admin/UserList");
      return;
    }

    this.setState({
      spinnerMessage: "Please wait while loading User Details...",
      loading: true,
    });

    //Service Call
    userService
      .getUser(state, helper.getUser())
      .then((response) => {
        this.displayProfileFile(response.data.PhotoFileName)
        this.setState({
          User: response.data,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region fetching View User page access
  canUserAccessPage(pageName) {
    accessControlService
      .CanUserAccessPage(helper.getUser(), pageName)
      .then((response) => {
        if (pageName === "Edit User") {
          this.setState({
            canAccessEditUser: response.data,
          });
        } else if (pageName === "Delete User") {
          this.setState({
            canAccessDeleteUser: response.data,
          });
        }
      })
      .catch((e) => {
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region modal functions
  //#region show popup
  showPopUp() {
    this.setState({ showModal: true });
  }
  //#endregion

  //#region handle Yes
  handleYes() {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    this.setState({
      spinnerMessage: "Please wait while deleting the User...",
      loading: true,
    });

    userService
      .deleteUser(this.state.User.UserID, helper.getUser())
      .then(() => {
        this.setState({ showModal: false });
        toast.success("User Deleted Successfully");
        this.props.history.push({
          pathname: "/admin/UserList",
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
        this.handleNo();
      });
  }
  //#endregion

  //#region hanle No
  handleNo() {
    this.setState({ showModal: false });
  }
  //#endregion

  // region display profile image
  //#region Downloading Scope File
  displayProfileFile = (profileFileName) => {
    projectService
      .downloadFile(profileFileName, "profilephoto")
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        this.setState((prevState) => ({
          ...prevState,
          profileImg: fileURL
          
        }))   
      })
      .catch((e) => {
        console.log(e)
      });
     
  }
  //#endregion
  // #end region
  //#endregion

  render() {
    const {
      UserID,
      FirstName,
      MiddleName,
      LastName,
      UserName,
      Email,
      DepartmentName,
      ManagerName,
      IsLockedOut
    } = this.state.User;

    const canAccessEditUser = this.state.canAccessEditUser;
    const canAccessDeleteUser = this.state.canAccessDeleteUser;

    return (
      <div>
        <LoadingOverlay
          active={this.state.loading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
          <div className="az-content-breadcrumb">
            <span>Admin</span>
            <span>View User</span>
          </div>
          <h4>
            View User{" "}
            <span className="icon-size">
              <i
                className="far fa-arrow-alt-circle-left text-primary pointer"
                onClick={() => this.props.history.goBack()}
                title="Back to List"
              ></i>
            </span>
          </h4>
          <div>
            <div className="row">
              <div className="col-md-8">
                <div className="row row-sm mg-b-5">
                  <div className="col-md-3">
                    <b>User ID</b>
                  </div>
                  <div className="col-md-8">
                    <p>{UserID}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="row row-sm mg-b-5">
                  <div className="col-md-3">
                    <b>First Name</b>
                  </div>
                  <div className="col-md-8">
                    <p>{FirstName}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="row row-sm">
                  <div className="col-md-3">
                    <b>Middle Name</b>
                  </div>
                  <div className="col-md-8">{MiddleName}</div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-8">
                <div className="row row-sm">
                  <div className="col-md-3">
                    <b>Last Name</b>
                  </div>
                  <div className="col-md-8">{LastName}</div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-8">
                <div className="row row-sm">
                  <div className="col-md-3">
                    <b>Username</b>
                  </div>
                  <div className="col-md-8">{UserName}</div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-8">
                <div className="row row-sm">
                  <div className="col-md-3">
                    <b>Email ID</b>
                  </div>
                  <div className="col-md-8">{Email}</div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-8">
                <div className="row row-sm">
                  <div className="col-md-3">
                    <b>Department</b>
                  </div>
                  <div className="col-md-8">{DepartmentName}</div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-8">
                <div className="row row-sm">
                  <div className="col-md-3">
                    <b>Manager</b>
                  </div>
                  <div className="col-md-8">{ManagerName}</div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-8">
                <div className="row row-sm">
                  <div className="col-md-3">
                    <b>Is Locked Out?</b>
                  </div>
                  <div className="col-md-8">
                    {IsLockedOut === true ? "Yes" : "No"}
                  </div>
                </div>
              </div>
            </div>
            <br />
            {this.state?.User?.RelievingDate &&
            <>
            <div className="row">
              <div className="col-md-8">
                <div className="row row-sm">
                  <div className="col-md-3">
                    <b>Relieving Date</b>
                  </div>
                  <div className="col-md-8">
                    {moment(new Date(this.state?.User?.RelievingDate)).format("DD-MM-YYYY")}
                  </div>
                </div>
              </div>
            </div>
            <br />
            </>}
            {this.state?.User?.PhotoFileName &&
            <>
            <div className="row">
              <div className="col-md-8">
                <div className="row row-sm">
                  <div className="col-md-3">
                    <b>Profile Image</b>
                  </div>
                  <div className="col-md-8">
                    <img src={this.state?.profileImg} alt="" width="150" height="150" />
                  </div>
                </div>
              </div>
            </div>
            <br />
            </>}
            <div className="row row-sm">
              <div className="col-md-0.5"></div>
              {canAccessEditUser && (
                <div className="col-md-2">
                  <Link
                    to={{
                      pathname: "/admin/EditUser",
                      state: UserID, // passing User ID to Edit User Page
                    }}
                    className="mg-t-10 mg-md-t-0 btn  btn-gray-700 btn-block"
                  >
                    Edit
                  </Link>
                </div>
              )}
              <div className="col-md-0.5"></div>
              {canAccessDeleteUser && (
                <div className="col-md-2">
                  <button
                    className="mg-t-10 mg-md-t-0 btn  btn-gray-700 btn-block"
                    onClick={this.showPopUp}
                    hidden={true}
                  >
                    Delete
                  </button>
                </div>
              )}
            </div>
            <Modal
              show={this.state.showModal}
              aria-labelledby="contained-modal-title-vcenter"
              onHide={this.handleNo}
              backdrop="static"
              enforceFocus={false}
            >
              <Modal.Header>
                <Modal.Title>Delete User</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <p>Are you sure to delete this User?</p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={this.handleYes}>
                  Yes
                </Button>
                <Button variant="primary" onClick={this.handleNo}>
                  No
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default ViewUser;
