import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import accessControlService from "../../services/accessControl.service";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import Moment from "moment";
import helper from "../../helpers/helpers";
import tableFunctions from "../../helpers/tableFunctions";
import projectBatchService from "../../services/projectBatch.service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class DeliveredProjectBatchList extends Component {
  constructor(props) {
    super(props);

    this.divScrollRef = React.createRef();

    this.exportDeliveredProjectBatchListToExcel =
      this.exportDeliveredProjectBatchListToExcel.bind(this);
    this.onChangeSortOrder = this.onChangeSortOrder.bind(this);
    this.onChangeColumn = this.onChangeColumn.bind(this);
    this.sortData = this.sortData.bind(this);
    this.displaySortingFields = this.displaySortingFields.bind(this);
    this.displayFilteringField = this.displayFilteringField.bind(this);
    this.onChangefilterValue = this.onChangefilterValue.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.clearSearchField = this.clearSearchField.bind(this);
    this.clearSortFields = this.clearSortFields.bind(this);

    this.state = {
      loading: false,
      spinnerMessage: "",
      deliveredProjectBatches: [],
      canUserDeleteDeliveredProjectBatch: false,
      canAccessViewProjectBatch: false,
      canAccessPostProjectBatchDetails: false,
      scope: "",
      index: 30,
      position: 0,
      columns: [],
      selectedColumn: "",
      selectedSort: "",
      isToShowSortingFields: false,
      isToShowFilteringField: false,
      filteredArray: [],
      filterValue: "",
    };
  }

  //#region component mount
  componentDidMount() {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    this.canUserAccessPage("View Project Batch");
    this.canUserAccessPage("Post Project Batch Details");
    this.fetchDeliveredProjectBatches();
  }
  //#endregion

  //#region fetching View Project page access
  canUserAccessPage(pageName) {
    accessControlService
      .CanUserAccessPage(helper.getUser(), pageName)
      .then((response) => {
        if (pageName === "View Project Batch") {
          this.setState({
            canAccessViewProjectBatch: response.data,
          });
        } else if (pageName === "Post Project Batch Details") {
          this.setState({
            canAccessPostProjectBatchDetails: response.data,
          });
        }
      })
      .catch((e) => {
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region fetching Delivered Project Batches from Web API
  async fetchDeliveredProjectBatches() {
    this.setState({
      loading: true,
      spinnerMessage:
        "Please wait while loading Delivered Project Batches List...",
    });

    await projectBatchService
      .getProjectBatches(this.props.projectID, helper.getUser(), "D")
      .then((response) => {
        if (response.data.length > 0) {
          this.setState({
            canUserDeleteDeliveredProjectBatch:
              response.data[0].canUserDeliverProjectBatch,
          });
        }

        let formattedArray = response.data.map((obj) => ({
          ...obj,
          ReceivedDate: Moment(obj.ReceivedDate).format("DD-MMM-yyyy"),
          DeliveredDate: Moment(obj.DeliveredDate).format("DD-MMM-yyyy"),
        }));

        this.setState({
          deliveredProjectBatches: formattedArray,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Export Project Batch List to Excel
  exportDeliveredProjectBatchListToExcel(projectID) {
    this.setState({
      spinnerMessage:
        "Please wait while exporting Delivered Project Batch List to excel...",
      loading: true,
    });

    let fileName = "Delivered Project Batch List.xlsx";

    projectBatchService
      .exportProjectBatchListToExcel(projectID, "D")
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);
        fileLink.click();

        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Delete Delivered Project Batch
  DeleteDeliveredProjectBatch(customerCode, projectBatch, batchNo) {
    this.setState({
      loading: true,
      spinnerMessage:
        "Please wait while deleting the Delivered Project Batch...",
    });

    projectBatchService
      .deleteDeliveredProjectBatch(
        customerCode,
        projectBatch,
        batchNo,
        helper.getUser()
      )
      .then((response) => {
        this.fetchDeliveredProjectBatches();
        toast.success("Project Batch Delivered Details deleted successfully");

        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Scroll to Top
  scrollToTop = () => {
    this.divScrollRef.current.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  //#endregion

  //#region Sort Functions
  //#region Display Sorting Fields
  displaySortingFields() {
    let columns = Object.keys(this.state.deliveredProjectBatches[0]);

    let excludedColumns = [
      "ProjectID",
      "ProjectBatchID",
      "InputCountType",
      "ReceivedFormat",
      "OutputFormat",
      "PlannedStartDate",
      "PlannedDeliveryDate",
      "Remarks",
      "CustomerInputFileName",
      "canUserDeliverProjectBatch",
      "IsPostProjectBatchDetailsExist",
      "UserID",
    ];

    columns = columns.filter((item) => !excludedColumns.includes(item));

    this.setState((previousState) => ({
      isToShowSortingFields: !previousState.isToShowSortingFields,
      selectedColumn: "",
      selectedSort: "",
      columns: columns,
      filterValue: "",
      isToShowFilteringField: false,
    }));
  }
  //#endregion

  //#region Selecting the sort column
  onChangeColumn(e) {
    this.setState({
      selectedColumn: e.target.value,
      selectedSort: "",
    });
  }
  //#endregion

  //#region On Change Sort
  onChangeSortOrder(e) {
    this.setState(
      {
        selectedSort: e.target.value,
      },
      () => this.sortData()
    );
  }
  //#endregion

  //#region Sort Data based on column and order
  sortData() {
    let sortedArray = [];
    let column =
      this.state.selectedColumn !== "" ? this.state.selectedColumn : "SlNo";
    const selectedSort =
      this.state.selectedSort !== "" ? this.state.selectedSort : "ascending";
    let numberColumns = ["SlNo", "InputCount", "DeliveredCount"];
    let dateColumns = ["ReceivedDate", "DeliveredDate"];

    sortedArray = tableFunctions.sortData(
      this.state.deliveredProjectBatches,
      column,
      selectedSort,
      numberColumns,
      dateColumns
    );

    this.setState({ deliveredProjectBatches: sortedArray });
  }
  //#endregion

  //#region  Clear Sort
  clearSortFields() {
    this.setState(
      {
        selectedColumn: "",
        selectedSort: "",
      },
      () => this.sortData()
    );
  }
  //#endregion
  //#endregion

  //#region Filter Functions
  //#region Display Filtering Field
  displayFilteringField() {
    this.setState((previousState) => ({
      isToShowFilteringField: !previousState.isToShowFilteringField,
      filterValue: "",
      isToShowSortingFields: false,
    }));
  }
  //#endregion

  //#region on change filter value
  onChangefilterValue(e) {
    this.setState({ filterValue: e.target.value }, () =>
      this.getFilteredRows()
    );
  }
  //#endregion

  //#region get filtered rows
  getFilteredRows() {
    const filteredArray = tableFunctions.filterArray(
      this.state.deliveredProjectBatches,
      this.state.filterValue
    );

    this.setState({ filteredArray: filteredArray });
  }
  //#endregion

  //#region Clear Search
  clearSearchField() {
    this.setState({
      filterValue: "",
    });
  }
  //#endregion
  //#endregion

  //#region Handle Scroll
  handleScroll(e) {
    var currentHeight = e.currentTarget.scrollTop;
    var maxScrollPosition =
      e.currentTarget.scrollHeight - e.currentTarget.clientHeight;

    this.setState({ position: currentHeight });

    if ((currentHeight / maxScrollPosition) * 100 > 90) {
      let curIndex = this.state.index + 20;
      this.setState({ index: curIndex });
    }
  }
  //#endregion

  render() {
    const canAccessViewProjectBatch = this.state.canAccessViewProjectBatch;
    const data = this.state.deliveredProjectBatches.slice(0, this.state.index);
    const filteredData = this.state.filteredArray.slice(0, this.state.index);

    //#region Project Batches List Columns
    const projectBatchesListColumns = [
      {
        dataField: "SlNo",
        text: "Sl No.",
        align: "center",
        style: {
          width: "5%",
        },
      },
      {
        dataField: "ProjectID",
        text: "Project ID",
        align: "center",
        hidden: true,
      },
      {
        dataField: "CustomerCode",
        text: "Cus Code",
        align: "center",
        style: {
          width: "7%",
        },
      },
      {
        dataField: "ProjectCode",
        text: "Proj Code",
        align: "center",
        style: {
          width: "7%",
        },
      },
      {
        dataField: "BatchNo",
        text: "Batch No.",
        align: "center",
        classes: canAccessViewProjectBatch ? "demo-key-row1" : "",
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            canAccessViewProjectBatch &&
              this.props.history.push({
                pathname: "/Projects/ViewProjectBatch",
                state: {
                  ProjectBatchID: row.ProjectBatchID,
                  activeTab: 2,
                }, // passing Project Batch ID to View Project Batch Page
              });
          },
        },
        style: {
          width: "7%",
        },
      },
      {
        dataField: "Scope",
        text: "Scope",
        style: {
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          width: "15%",
        },
        title: true,
      },
      {
        dataField: "ReceivedDate",
        text: "Received Date",
        align: "center",
        style: {
          width: "10%",
        },
      },
      {
        dataField: "DeliveredDate",
        text: "Delivered Date",
        align: "center",
        style: {
          width: "9%",
        },
      },
      {
        dataField: "InputCount",
        text: "Input Count",
        align: "center",
        style: {
          width: "9%",
        },
      },
      {
        dataField: "DeliveredCount",
        text: "Delivered Count",
        align: "center",
        style: {
          width: "11%",
        },
      },
      {
        dataField: "Status",
        text: "Status",
        align: "center",
        style: {
          width: "10%",
        },
      },
      {
        dataField: "IsPostProjectBatchDetailsExist",
        text: "Is Post Project Batch Details Exist",
        align: "center",
        hidden: true,
      },
      {
        dataField: "delete",
        text: "Delete",
        hidden: this.state.canUserDeleteDeliveredProjectBatch ? false : true,
        align: "center",
        formatter: (cell, row, rowIndex, extraData) => (
          <div>
            {!row.IsPostProjectBatchDetailsExist && (
              <i
                className="fas fa-trash-alt pointer"
                title="Delete Delivered Project Batch Details"
                onClick={() => {
                  if (
                    window.confirm(
                      "Are you sure to delete, this Delivered Project Batch Details?\n" +
                        "Customer Code: " +
                        row.CustomerCode +
                        " " +
                        "Project Code: " +
                        row.ProjectCode +
                        " " +
                        "Batch No.: " +
                        row.BatchNo
                    )
                  ) {
                    this.DeleteDeliveredProjectBatch(
                      row.CustomerCode,
                      row.ProjectCode,
                      row.BatchNo
                    );
                  }
                }}
              ></i>
            )}
          </div>
        ),
        style: {
          width: "10%",
        },
      },
      {
        dataField: "PostProjectBatchEntry",
        text: "Post Project Batch Entry",
        hidden: this.state.canAccessPostProjectBatchDetails ? false : true,
        align: "center",
        formatter: (cell, row, rowIndex, extraData) => (
          <div>
            <i
              className="fas fa-file-alt"
              title="Enter Post Project Batch Details"
              onClick={() => {
                this.props.history.push({
                  pathname: "/Projects/PostProjectBatchDetails",
                  state: {
                    ProjectID: row.ProjectID,
                    CustomerCode: row.CustomerCode,
                    ProjectCode: row.ProjectCode,
                    BatchNo: row.BatchNo,
                    Scope: row.Scope,
                    InputCount: row.InputCount,
                    DeliveredDate: row.DeliveredDate,
                    DeliveredCount: row.DeliveredCount,
                  },
                });
              }}
            ></i>
          </div>
        ),
        style: {
          width: "15%",
        },
      },
    ];
    //#endregion

    return (
      <div>
        <LoadingOverlay
          active={this.state.loading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
          <div className="az-content-body pd-lg-l-40 d-flex flex-column">
            <ToolkitProvider
              keyField="BatchNo"
              data={this.state.filterValue === "" ? data : filteredData}
              columns={projectBatchesListColumns}
            >
              {(props) => (
                <div>
                  <div className="row mg-b-10" style={{ marginRight: "15px" }}>
                    <div
                      className="col-md-10 mg-t-5"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <div className="row">
                        {this.state.isToShowSortingFields && (
                          <>
                            <div className="col-md-4">
                              <div className="row">
                                <div className="col-md-3 mg-t-7">
                                  <label htmlFor="sortColumn">Column:</label>
                                </div>
                                <div className="col-lg">
                                  <select
                                    className="form-control mg-l-5"
                                    value={this.state.selectedColumn}
                                    onChange={this.onChangeColumn}
                                  >
                                    <option value="">--Select--</option>
                                    {this.state.columns.map((col) => (
                                      <option key={col}>{col}</option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="row">
                                <div className="col-md-3 mg-t-7">
                                  <label htmlFor="sortOrder">Order:</label>
                                </div>
                                <div className="col-lg">
                                  <select
                                    className="form-control mg-l-5"
                                    value={this.state.selectedSort}
                                    onChange={this.onChangeSortOrder}
                                  >
                                    <option value="">--Select--</option>
                                    <option value="ascending">Ascending</option>
                                    <option value="descending">
                                      Descending
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div>
                                <span
                                  className="btn btn-primary pd-b-5"
                                  onClick={this.clearSortFields}
                                  title="Clear Sort Fields"
                                >
                                  <i className="far fa-window-close"></i>
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                        {this.state.isToShowFilteringField && (
                          <>
                            <div className="col-md-12">
                              <div
                                className="row"
                                style={{ flexWrap: "nowrap" }}
                              >
                                <div className="col-md-1 mg-t-7">
                                  <label htmlFor="search">Search:</label>
                                </div>
                                <div className="col-lg pd-r-10">
                                  <input
                                    type="text"
                                    className="form-control mg-l-5"
                                    maxLength="20"
                                    value={this.state.filterValue}
                                    onChange={this.onChangefilterValue}
                                  />
                                </div>
                                <div>
                                  <span
                                    className="btn btn-primary pd-b-5"
                                    onClick={this.clearSearchField}
                                  >
                                    <i
                                      className="far fa-window-close"
                                      title="Clear Filter"
                                    ></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    {this.state.deliveredProjectBatches.length > 0 && (
                      <div
                        className="col-md-2"
                        style={{ textAlign: "end", marginTop: "10px" }}
                      >
                        <i
                          className="fas fa-exchange-alt fa-rotate-90 pointer"
                          title={
                            this.state.isToShowSortingFields
                              ? "Hide Sort"
                              : "Show Sort"
                          }
                          onClick={this.displaySortingFields}
                        ></i>
                        {!this.state.isToShowFilteringField ? (
                          <i
                            className="fas fa-filter pointer mg-l-10"
                            onClick={this.displayFilteringField}
                            title="Show Filter"
                          ></i>
                        ) : (
                          <i
                            className="fas fa-funnel-dollar pointer mg-l-10"
                            onClick={this.displayFilteringField}
                            title="Hide Filter"
                          ></i>
                        )}
                        <i
                          className="fas fa-file-excel mg-l-10 pointer"
                          style={{ color: "green" }}
                          onClick={() =>
                            this.exportDeliveredProjectBatchListToExcel(
                              this.props.projectID
                            )
                          }
                          title="Export to Excel"
                        ></i>
                      </div>
                    )}
                  </div>
                  <div
                    className="borderTable"
                    style={{ overflowX: "auto", width: "98%" }}
                  >
                    <div>
                      <table className="delivered-project-batch-table-header-height">
                        <thead>
                          <tr>
                            <td
                              className="custom-table-header"
                              style={{
                                width:
                                  (this.state.filteredArray.length < 12 &&
                                    this.state.filterValue !== "") ||
                                  this.state.deliveredProjectBatches.length < 12
                                    ? "3.9%"
                                    : "3.9%",
                              }}
                            >
                              Sl No
                            </td>
                            <td
                              className="custom-table-header"
                              style={{
                                width:
                                  (this.state.filteredArray.length < 12 &&
                                    this.state.filterValue !== "") ||
                                  this.state.deliveredProjectBatches.length < 12
                                    ? "5.5%"
                                    : "5.5%",
                              }}
                            >
                              Cus Code
                            </td>
                            <td
                              className="custom-table-header"
                              style={{
                                width:
                                  (this.state.filteredArray.length < 12 &&
                                    this.state.filterValue !== "") ||
                                  this.state.deliveredProjectBatches.length < 12
                                    ? "5.5%"
                                    : "5.45%",
                              }}
                            >
                              Proj Code
                            </td>
                            <td
                              className="custom-table-header"
                              style={{
                                width:
                                  (this.state.filteredArray.length < 12 &&
                                    this.state.filterValue !== "") ||
                                  this.state.deliveredProjectBatches.length < 12
                                    ? "5.45%"
                                    : "5.5%",
                              }}
                            >
                              Batch No.
                            </td>
                            <td
                              className="custom-table-header"
                              style={{
                                width:
                                  (this.state.filteredArray.length < 12 &&
                                    this.state.filterValue !== "") ||
                                  this.state.deliveredProjectBatches.length < 12
                                    ? "11.8%"
                                    : "11.75%",
                              }}
                            >
                              Scope
                            </td>
                            <td
                              className="custom-table-header"
                              style={{
                                width:
                                  (this.state.filteredArray.length < 12 &&
                                    this.state.filterValue !== "") ||
                                  this.state.deliveredProjectBatches.length < 12
                                    ? "7.85%"
                                    : "7.8%",
                              }}
                            >
                              Received Date
                            </td>
                            <td
                              className="custom-table-header"
                              style={{
                                width:
                                  (this.state.filteredArray.length < 12 &&
                                    this.state.filterValue !== "") ||
                                  this.state.deliveredProjectBatches.length < 12
                                    ? "7.05%"
                                    : "7.05%",
                              }}
                            >
                              Delivered Date
                            </td>
                            <td
                              className="custom-table-header"
                              style={{
                                width:
                                  (this.state.filteredArray.length < 12 &&
                                    this.state.filterValue !== "") ||
                                  this.state.deliveredProjectBatches.length < 12
                                    ? "7.05%"
                                    : "7.05%",
                              }}
                            >
                              Input Count
                            </td>
                            <td
                              className="custom-table-header"
                              style={{
                                width:
                                  (this.state.filteredArray.length < 12 &&
                                    this.state.filterValue !== "") ||
                                  this.state.deliveredProjectBatches.length < 12
                                    ? "8.65%"
                                    : "8.6%",
                              }}
                            >
                              Delivered Count
                            </td>
                            <td
                              className="custom-table-header"
                              style={{
                                width:
                                  (this.state.filteredArray.length < 12 &&
                                    this.state.filterValue !== "") ||
                                  this.state.deliveredProjectBatches.length < 12
                                    ? "7.85%"
                                    : "7.8%",
                              }}
                            >
                              Status
                            </td>
                            {this.state.canUserDeleteDeliveredProjectBatch ? (
                              <td
                                className="custom-table-header"
                                style={{
                                  width:
                                    (this.state.filteredArray.length < 12 &&
                                      this.state.filterValue !== "") ||
                                    this.state.deliveredProjectBatches.length <
                                      12
                                      ? "7.85%"
                                      : "7.85%",
                                }}
                              >
                                Delete
                              </td>
                            ) : null}
                            {this.state.canAccessPostProjectBatchDetails && (
                              <td
                                className="custom-table-header"
                                style={{
                                  width:
                                    (this.state.filteredArray.length < 12 &&
                                      this.state.filterValue !== "") ||
                                    this.state.deliveredProjectBatches.length <
                                      12
                                      ? "11.75%"
                                      : "11.75%",
                                }}
                              >
                                Post Project Batch Entry
                              </td>
                            )}
                          </tr>
                        </thead>
                      </table>
                    </div>
                    <div
                      style={
                        (this.state.filteredArray.length > 12 &&
                          this.state.filterValue !== "") ||
                        (this.state.deliveredProjectBatches.length > 12 &&
                          this.state.filterValue === "")
                          ? {
                              overflowY: "scroll",
                              borderBottom: "1px solid #cdd4e0",
                            }
                          : {}
                      }
                      ref={this.divScrollRef}
                      className="delivered-project-batch-table-height"
                      onScroll={this.handleScroll}
                    >
                      <BootstrapTable
                        bootstrap4
                        {...props.baseProps}
                        striped
                        hover
                        condensed
                        headerClasses="header-class"
                      />
                    </div>
                  </div>
                </div>
              )}
            </ToolkitProvider>
            {this.state.position > 600 && this.state.filterValue === "" && (
              <div style={{ textAlign: "end" }}>
                <button className="scroll-top" onClick={this.scrollToTop}>
                  <div className="arrow up"></div>
                </button>
              </div>
            )}
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default DeliveredProjectBatchList;
