import React, { Component } from "react";
import { Link } from "react-router-dom";
import helper from "../../helpers/helpers";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import DeliveredProjectBatchList from "./DeliveredProjectBatchList";
import OnGoingProjectBatchList from "./OnGoingProjectBatchList";
import accessControlService from "../../services/accessControl.service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class ProjectBatchList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      projectID: null,
      customerCode: null,
      projectCode: null,
      scope: null,
      canAccessCreateProjectBatch: false,
      activeTab: null,
      activeTabFromPreviousPage: null,
      defaultActiveKey: "",
    };
  }

  //Page Load
  componentDidMount() {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    this.canUserAccessPage("Create Project Batch");
  }

  //#region fetching Project page access
  async canUserAccessPage(pageName) {
    await accessControlService
      .CanUserAccessPage(helper.getUser(), pageName)
      .then((response) => {
        this.setState(
          {
            canAccessCreateProjectBatch: response.data,
          },
          () => this.fetchProjectDetails()
        );
      })
      .catch((e) => {
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Fetching Project Details
  fetchProjectDetails() {
    const { state } = this.props.location; // Project Details passed from Project List Page Or Create Project Batch Page
    if (state === 0 || state === null || state === undefined) {
      this.props.history.push("/Projects");
      return;
    }

    let defaultActiveKey = "";
    if (state.activeTab === 2) {
      defaultActiveKey = "delivered";
      this.setState({ canAccessCreateProjectBatch: false });
    } else defaultActiveKey = "onGoing";
    this.setState({
      projectID: state.ProjectID,
      customerCode: state.CustomerCode,
      projectCode: state.ProjectCode,
      scope: state.Scope,
      activeTab: state.activeTab,
      activeTabFromPreviousPage: state.activeTab,
      defaultActiveKey: defaultActiveKey,
    });
  }
  //#endregion

  //#region Change Between On Going and Delivered Tab
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }
  //#endregion

  render() {
    const props = this.props;

    const canAccessCreateProjectBatch = this.state.canAccessCreateProjectBatch;
    const defaultActiveKey = this.state.defaultActiveKey;

    return this.state.projectID === null ? (
      "Loading..."
    ) : (
      <div>
        <div className="az-content-breadcrumb mg-l-50">
          <span>Project Batch</span>
          <span>List</span>
        </div>
        <div className="mg-l-50">
          <h4>
            Project Batches List{" "}
            <span className="icon-size">
              {" "}
              <Link
                to={{
                  pathname: "/Projects",
                  state: {
                    activeTab: this.state.activeTabFromPreviousPage,
                  },
                }}
              >
                <i
                  className="far fa-arrow-alt-circle-left"
                  title="Back to Project List"
                ></i>
              </Link>{" "}
              {canAccessCreateProjectBatch && (
                <Link
                  to={{
                    pathname: "/Projects/CreateProjectBatch",
                    state: {
                      ProjectID: this.state.projectID,
                      CustomerCode: this.state.customerCode,
                      ProjectCode: this.state.projectCode,
                      Scope: this.state.scope,
                    }, // passing Project details to Create Project Batch Page
                  }}
                  title="Add Project Batch"
                >
                  <i className="fa fa-plus"></i>
                </Link>
              )}
            </span>
          </h4>
        </div>
        <Tab.Container defaultActiveKey={defaultActiveKey}>
          <Nav variant="pills" className="mg-l-50 mg-b-20">
            <Nav.Item>
              <Nav.Link
                eventKey="onGoing"
                style={{ border: "1px solid #5E41FC" }}
                onClick={() => {
                  this.toggle(1);
                }}
              >
                On Going
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="delivered"
                style={{ border: "1px solid #5E41FC" }}
                onClick={() => {
                  this.toggle(2);
                }}
              >
                Delivered
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="onGoing">
              {this.state.activeTab === 1 ? (
                <OnGoingProjectBatchList
                  projectID={this.state.projectID}
                  {...props}
                />
              ) : null}
            </Tab.Pane>
            <Tab.Pane eventKey="delivered">
              {this.state.activeTab === 2 ? (
                <DeliveredProjectBatchList
                  projectID={this.state.projectID}
                  {...props}
                />
              ) : null}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    );
  }
}

export default ProjectBatchList;
