import React, { Component } from "react";
import DashboardDetails from "./Dashboard/dashboardDetails";
import ActiveResources from "./Dashboard/activeResources";
import ActiveTasks from "./Dashboard/activeTasks";
import Duration from "./Dashboard/duration";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import helper from "../helpers/helpers";
import "./dashboard.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class Dashboard extends Component {
  constructor(props) {
    super(props); //reference to the parent constructor

    this.state = {};
  }

  //#region component mount
  componentDidMount() {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }
  }
  //#endregion

  render() {
    return (
      <div style={{ height: "93%" }}>
        <Router>
          <Switch>
            <Route path="/Dashboard" exact>
              <DashboardDetails {...this.props} />
            </Route>
            <Route path="/Dashboard/Duration" component={Duration}></Route>
            <Route
              path="/Dashboard/ActiveResources"
              component={ActiveResources}
            ></Route>
            <Route
              path="/Dashboard/ActiveTasks"
              component={ActiveTasks}
            ></Route>
          </Switch>
        </Router>
      </div>
    );
  }
}

export default Dashboard;
