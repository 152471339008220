import React, { Component } from "react";
import { connect } from "react-redux";
import { readDeliveredProjectsList } from "../../actions/projects";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import helper from "../../helpers/helpers";
import tableFunctions from "../../helpers/tableFunctions";
import projectService from "../../services/project.service";
import accessControlService from "../../services/accessControl.service";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import Moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Row, Col } from "react-bootstrap";
toast.configure();

class DeliveredProjectList extends Component {
  constructor(props) {
    super(props);

    this.divScrollRef = React.createRef();

    this.exportDeliveredProjectListToExcel =
      this.exportDeliveredProjectListToExcel.bind(this);
    this.onChangeSortOrder = this.onChangeSortOrder.bind(this);
    this.onChangeColumn = this.onChangeColumn.bind(this);
    this.sortData = this.sortData.bind(this);
    this.displaySortingFields = this.displaySortingFields.bind(this);
    this.displayFilteringField = this.displayFilteringField.bind(this);
    this.onChangefilterValue = this.onChangefilterValue.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.clearSearchField = this.clearSearchField.bind(this);
    this.clearSortFields = this.clearSortFields.bind(this);

    this.state = {
      loading: false,
      spinnerMessage: "",
      modalLoading: false,
      canUserRevertProjectStatus: false,
      showChangeProjectStatusModal: false,
      customerCode: "",
      projectCode: "",
      changeStatusTo: [],
      selectedChangeStatusTo: "",
      canAccessViewProject: false,
      deliveredTasks: [],
      resources: [],
      projectsDetailsExpanded: [],
      expandedProjectID: "",
      deliveredTaskExpanded: [],
      index: 30,
      position: 0,
      columns: [],
      selectedColumn: "",
      selectedSort: "",
      isToShowSortingFields: false,
      isToShowFilteringField: true,
      filteredArray: [],
      filterValue: "",
      deliveredProjects: [],
    };
  }

  //#region component mount
  async componentDidMount() {
    this.setState({
      loading: true,
      spinnerMessage: "Please wait while loading Delivered Projects List...",
    });

    this.canUserAccessPage("View Project");

    var data = {
      CustomerCode: this.props.customerCode,
      ProjectCode: this.props.projectCode,
      ProjectType: this.props.projectType,
      FromDate: this.props.fromDate,
      ToDate: this.props.toDate,
      UserID: helper.getUser(),
    };

    await this.props.readDeliveredProjectsList(data);

    if (this.props.projects.length > 0) {
      let formattedArray = this.props.projects.map((obj) => ({
        ...obj,
        ReceivedDate: Moment(obj.ReceivedDate).format("DD-MMM-yyyy"),
        PlannedDeliveryDate: obj.PlannedDeliveryDate
          ? Moment(obj.PlannedDeliveryDate).format("DD-MMM-yyyy")
          : "",
        DeliveredOn: obj.DeliveredOn
          ? Moment(obj.DeliveredOn).format("DD-MMM-yyyy")
          : "",
      }));

      this.setState({
        canUserRevertProjectStatus:
          this.props.projects[0].canUserChangeProjectStatus,
        deliveredProjects: formattedArray,
      });
    }

    this.setState({
      loading: false,
    });
  }
  //#endregion

  //#region fetching View Project page access
  canUserAccessPage(pageName) {
    accessControlService
      .CanUserAccessPage(helper.getUser(), pageName)
      .then((response) => {
        if (pageName === "View Project") {
          this.setState({
            canAccessViewProject: response.data,
          });
        }
      })
      .catch((e) => {
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Export Project List to Excel
  exportDeliveredProjectListToExcel() {
    this.setState({
      spinnerMessage:
        "Please wait while exporting delivered project list to excel...",
      loading: true,
    });

    let fileName = "Delivered Projects List.xlsx";

    var data = {
      CustomerCode: this.props.customerCode,
      ProjectCode: this.props.projectCode,
      ProjectType: this.props.projectType,
      FromDate: this.props.fromDate,
      ToDate: this.props.toDate,
      UserID: helper.getUser(),
    };

    projectService
      .exportDeliveredProjectsListToExcel(data)
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);
        fileLink.click();

        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Revert Project Status
  revertProjectStatus(customerCode, projectBatch) {
    this.setState({
      loading: true,
      spinnerMessage: "Please wait while reverting the Project Status...",
    });

    projectService
      .revertProjectStatus(customerCode, projectBatch, helper.getUser())
      .then(() => {
        this.props.readDeliveredProjectsList(helper.getUser());
        toast.success("Project Status reverted successfully");

        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Fetch Activities of Selected Customer Code and Project Code
  fetchProjectsActivities = (row, isExpand, rowIndex, e) => {
    if (!isExpand) {
      this.setState(() => ({
        projectsDetailsExpanded: [],
        loading: false,
      }));
      return;
    }

    this.setState({
      spinnerMessage: "Please wait while fetching Project Task Details...",
      loading: true,
    });

    projectService
      .fetchProjectActivityDetils(row.ProjectID)
      .then((response) => {
        this.setState({
          deliveredTaskExpanded: [],
          deliveredTasks: response.data,
          projectsDetailsExpanded: [row.ProjectID],
          expandedProjectID: row.ProjectID,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  };
  //#endregion

  //#region Fetch Resources of Selected Customer Code, Project Code and Task
  fetchResources = (row, isExpand, rowIndex, e) => {
    if (!isExpand) {
      this.setState(() => ({
        deliveredTaskExpanded: [],
        loading: false,
      }));
      return;
    }

    this.setState({
      spinnerMessage:
        "Please wait while fetching Resources of selected Task...",
      loading: true,
    });

    projectService
      .readProjectActivityResourcesWithHoursWorked(
        this.state.expandedProjectID,
        row.ProjectActivityID
      )
      .then((response) => {
        this.setState({
          resources: response.data,
          deliveredTaskExpanded: [row.SlNo],
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  };
  //#endregion

  //#region Scroll to Top
  scrollToTop = () => {
    this.divScrollRef.current.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  //#endregion

  //#region Sort Functions
  //#region Display Sorting Fields
  displaySortingFields() {
    let columns = Object.keys(this.state.deliveredProjects[0]);

    let excludedColumns = [
      "ProjectID",
      "LocationCode",
      "TypeOfInput",
      "InputCountType",
      "ReceivedFormat",
      "OutputFormat",
      "PlannedStartDate",
      "DeliveryMode",
      "DeliveryPlanFileName",
      "IsResourceBased",
      "Remarks",
      "CustomerInputFileName",
      "ScopeFileName",
      "Guideline",
      "GuidelineFileName",
      "Checklist",
      "ChecklistFileName",
      "EmailDate",
      "EmailDescription",
      "canUserChangeProjectStatus",
      "HoldOnDate",
      "HoldOnReason",
      "Activities",
      "UserID",
    ];

    columns = columns.filter((item) => !excludedColumns.includes(item));

    this.setState((previousState) => ({
      isToShowSortingFields: !previousState.isToShowSortingFields,
      selectedColumn: "",
      selectedSort: "",
      columns: columns,
      filterValue: "",
      isToShowFilteringField: false,
    }));
  }
  //#endregion

  //#region Selecting the sort column
  onChangeColumn(e) {
    this.setState({
      selectedColumn: e.target.value,
      selectedSort: "",
    });
  }
  //#endregion

  //#region On Change Sort
  onChangeSortOrder(e) {
    this.setState(
      {
        selectedSort: e.target.value,
      },
      () => this.sortData()
    );
  }
  //#endregion

  //#region Sort Data based on column and order
  sortData() {
    let sortedArray = [];
    let column =
      this.state.selectedColumn !== "" ? this.state.selectedColumn : "SlNo";
    const selectedSort =
      this.state.selectedSort !== "" ? this.state.selectedSort : "ascending";
    let numberColumns = ["SlNo", "InputCount", "NoOfBatches", "DeliveredCount"];
    let dateColumns = ["ReceivedDate", "PlannedDeliveryDate", "DeliveredOn"];

    sortedArray = tableFunctions.sortData(
      this.state.deliveredProjects,
      column,
      selectedSort,
      numberColumns,
      dateColumns
    );

    this.setState({ deliveredProjects: sortedArray });
  }
  //#endregion

  //#region  Clear Sort
  clearSortFields() {
    this.setState(
      {
        selectedColumn: "",
        selectedSort: "",
      },
      () => this.sortData()
    );
  }
  //#endregion
  //#endregion

  //#region Filter Functions
  //#region Display Filtering Field
  displayFilteringField() {
    this.setState((previousState) => ({
      isToShowFilteringField: !previousState.isToShowFilteringField,
      filterValue: "",
      isToShowSortingFields: false,
    }));
  }
  //#endregion

  //#region on change filter value
  onChangefilterValue(e) {
    this.setState({ filterValue: e.target.value }, () =>
      this.getFilteredRows()
    );
  }
  //#endregion

  //#region get filtered rows
  getFilteredRows() {
    const filteredArray = tableFunctions.filterArray(
      this.state.deliveredProjects,
      this.state.filterValue
    );

    this.setState({ filteredArray: filteredArray });
  }
  //#endregion

  //#region Clear Search
  clearSearchField() {
    this.setState({
      filterValue: "",
    });
  }
  //#endregion
  //#endregion

  //#region Handle Scroll
  handleScroll(e) {
    var currentHeight = e.currentTarget.scrollTop;
    var maxScrollPosition =
      e.currentTarget.scrollHeight - e.currentTarget.clientHeight;

    this.setState({ position: currentHeight });

    if ((currentHeight / maxScrollPosition) * 100 > 90) {
      let curIndex = this.state.index + 20;
      this.setState({ index: curIndex });
    }
  }
  //#endregion

  render() {
    const canAccessViewProject = this.state.canAccessViewProject;

    const data = this.state.deliveredProjects.slice(0, this.state.index);
    const filteredData = this.state.filteredArray.slice(0, this.state.index);

    const projectListColumns = [
      {
        dataField: "SlNo",
        text: "Sl No.",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "ProjectID",
        text: "Project ID",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
        hidden: true,
      },
      {
        dataField: "CustomerCode",
        text: "Cus Code",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "ProjectCode",
        text: "Proj Code",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
        classes: canAccessViewProject ? "demo-key-row1" : "",
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            canAccessViewProject &&
              this.props.history.push({
                pathname: "/Projects/ViewProject",
                state: { ProjectID: row.ProjectID, activeTab: 2 }, // passing Project ID to View Project Page
              });
          },
        },
      },
      {
        dataField: "Scope",
        text: "Description",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "left",
        align: "left",
        title: true,
        formatter: (cell, row, rowIndex, extraData) => (
          <div>
            {row.Scope}{" "}
            <i
              className="fas fa-sort-down pointer mg-l-5"
              title="Click to View Tasks of selected Project"
            ></i>
          </div>
        ),
      },
      {
        dataField: "ReceivedDate",
        text: "Received Date",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "PlannedDeliveryDate",
        text: "Planned Del Date",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "NoOfBatches",
        text: "Batches",
        classes: (cell, row, rowIndex, colIndex) => {
          if (row.NoOfBatches > 0) return "demo-key-row1";
          return "";
        },
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            row.NoOfBatches > 0 &&
              this.props.history.push({
                pathname: "/Projects/ProjectBatchList",
                state: {
                  ProjectID: row.ProjectID,
                  CustomerCode: row.CustomerCode,
                  ProjectCode: row.ProjectCode,
                  Scope: row.Scope,
                  activeTab: 2,
                }, // passing Project details to Project Batch List Page
              });
          },
        },
      },
      {
        dataField: "ProjectType",
        text: "Project Type",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "InputCount",
        text: "Input Count",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "Status",
        text: "Status",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "RevertStatus",
        text: "Revert Status",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
        hidden: this.state.canUserRevertProjectStatus ? false : true,
        formatter: (cell, row, rowIndex, extraData) => (
          <div>
            <i
              className="fas fa-chevron-circle-left pointer"
              title="Revert Status"
              onClick={() => {
                if (
                  window.confirm(
                    "Are you sure, to revert the status of this Project?\n" +
                      "Customer Code: " +
                      row.CustomerCode +
                      " " +
                      "Project Code: " +
                      row.ProjectCode
                  )
                ) {
                  this.revertProjectStatus(row.CustomerCode, row.ProjectCode);
                }
              }}
            ></i>
          </div>
        ),
      },
      {
        dataField: "DeliveredCount",
        text: "Delivered Count",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "DeliveredOn",
        text: "Delivered Date",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
      },
    ];

    const deliveredTasksColumns = [
      {
        dataField: "SlNo",
        text: "Sl No.",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
        sort: true,
      },
      {
        dataField: "ProjectActivityID",
        text: "Project Activity ID",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
        sort: true,
        hidden: true,
      },
      {
        dataField: "ProjectActivity",
        text: "Activity / Task",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
        sort: true,
      },
      {
        dataField: "StartDate",
        text: "Start Date",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
        sort: true,
        formatter: (cell, row, rowIndex, extraData) =>
          `${Moment(row.StartDate).format("DD-MMM-yyyy")}`,
      },
      {
        dataField: "EndDate",
        text: "End Date",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
        sort: true,
        formatter: (cell, row, rowIndex, extraData) =>
          `${Moment(row.EndDate).format("DD-MMM-yyyy")}`,
      },
      {
        dataField: "ProductionCompletedCount",
        text: "Prod. Completed Count",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
        sort: true,
      },
      {
        dataField: "QCCompletedCount",
        text: "QC Completed Count",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
        sort: true,
      },
      {
        dataField: "HoursWorked",
        text: "No. of Hours Worked",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
        sort: true,
      },
    ];

    const expandDeliveredProjectsRow = {
      onlyOneExpanding: true,
      parentClassName: "rowBackgroundColor",
      onExpand: this.fetchProjectsActivities,
      expanded: this.state.projectsDetailsExpanded,
      expandHeaderColumnRenderer: (isAnyExpands) => (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        ></div>
      ),
      renderer: (row) => (
        <>
          <p>
            <b>Tasks</b>
          </p>
          <div className="table-responsive" style={{ width: "80%" }}>
            <BootstrapTable
              keyField="SlNo"
              data={this.state.deliveredTasks}
              columns={deliveredTasksColumns}
              expandRow={expandTaskRow}
              noDataIndication="Production details not found"
            />
          </div>
        </>
      ),
    };

    const resourcesColumns = [
      {
        dataField: "SlNo",
        text: "Sl No.",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
        sort: true,
      },
      {
        dataField: "UserID",
        text: "Resource Code",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
        sort: true,
      },
      {
        dataField: "Username",
        text: "Resource Name",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        sort: true,
      },
      {
        dataField: "StartDate",
        text: "Start Date",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        sort: true,
        headerAlign: "center",
        align: "center",
        formatter: (cell, row, rowIndex, extraData) =>
          `${Moment(row.StartDate).format("DD-MMM-yyyy")}`,
      },
      {
        dataField: "EndDate",
        text: "End Date",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
        sort: true,
        formatter: (cell, row, rowIndex, extraData) =>
          `${Moment(row.EndDate).format("DD-MMM-yyyy")}`,
      },
      {
        dataField: "ProductionCompletedCount",
        text: "Prod. Completed Count",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
        sort: true,
      },
      {
        dataField: "QCCompletedCount",
        text: "QC Completed Count",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
        sort: true,
      },
      {
        dataField: "HoursWorked",
        text: "No. of Hours Worked",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
        sort: true,
      },
    ];

    const expandTaskRow = {
      onlyOneExpanding: true,
      parentClassName: "rowBackgroundColor",
      onExpand: this.fetchResources,
      expanded: this.state.deliveredTaskExpanded,
      showExpandColumn: true,
      expandHeaderColumnRenderer: (isAnyExpands) => (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        ></div>
      ),
      renderer: (row) => (
        <>
          <p>
            <b>Resources / Project Team</b>
          </p>
          <div>
            <BootstrapTable
              keyField="SlNo"
              data={this.state.resources}
              columns={resourcesColumns}
            />
          </div>
        </>
      ),
    };

    return (
      <LoadingOverlay
          active={this.state.loading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
        <div className="az-content-body pd-lg-l-40 d-flex flex-column">
          <ToolkitProvider
            keyField="ProjectID"
            data={this.state.filterValue === "" ? data : filteredData}
            columns={projectListColumns}
          >
            {(props) => (
              <div className="mg-t-5">
                <Row
                  className="mg-b-10"
                  style={{ marginRight: "15px", whiteSpace: "nowrap" }}
                >
                  <Col md={10} style={{ whiteSpace: "nowrap" }}>
                    {this.state.isToShowSortingFields && (
                      <Row>
                        <Col md={4}>
                          <Row>
                            <Col md={3} className="mg-t-7">
                              <label htmlFor="sortColumn">Column:</label>
                            </Col>
                            <Col md={9}>
                              <select
                                className="form-control mg-l-5"
                                value={this.state.selectedColumn}
                                onChange={this.onChangeColumn}
                              >
                                <option value="">--Select--</option>
                                {this.state.columns.map((col) => (
                                  <option key={col}>{col}</option>
                                ))}
                              </select>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={4}>
                          <Row>
                            <Col md={3} className="mg-t-7">
                              <label htmlFor="sortOrder">Order:</label>
                            </Col>
                            <Col md={9}>
                              <select
                                className="form-control mg-l-5"
                                value={this.state.selectedSort}
                                onChange={this.onChangeSortOrder}
                              >
                                <option value="">--Select--</option>
                                <option value="ascending">Ascending</option>
                                <option value="descending">Descending</option>
                              </select>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={2}>
                          <i
                            className="far fa-window-close btn btn-primary pd-b-5"
                            onClick={this.clearSortFields}
                            title="Clear Filter"
                          ></i>
                        </Col>
                      </Row>
                    )}
                    {this.state.isToShowFilteringField && (
                      <Row>
                        <Col md={1} className="mg-t-7">
                          <label htmlFor="search">Search:</label>
                        </Col>
                        <Col md={10} className="pd-r-10">
                          <input
                            type="text"
                            className="form-control mg-l-10"
                            maxLength="20"
                            value={this.state.filterValue}
                            onChange={this.onChangefilterValue}
                          />
                        </Col>
                        <Col md={1}>
                          <i
                            className="far fa-window-close btn btn-primary pd-b-5"
                            onClick={this.clearSearchField}
                            title="Clear Filter"
                          ></i>
                        </Col>
                      </Row>
                    )}
                  </Col>
                  {this.state.deliveredProjects.length > 0 && (
                    <Col md={2} style={{ textAlign: "end", marginTop: "10px" }}>
                      <i
                        className="fas fa-exchange-alt fa-rotate-90 pointer"
                        title={
                          this.state.isToShowSortingFields
                            ? "Hide Sort"
                            : "Show Sort"
                        }
                        onClick={this.displaySortingFields}
                      ></i>
                      {!this.state.isToShowFilteringField ? (
                        <i
                          className="fas fa-filter pointer mg-l-10"
                          onClick={this.displayFilteringField}
                          title="Show Filter"
                        ></i>
                      ) : (
                        <i
                          className="fas fa-funnel-dollar pointer mg-l-10"
                          onClick={this.displayFilteringField}
                          title="Hide Filter"
                        ></i>
                      )}
                      <i
                        className="fas fa-file-excel mg-l-10 pointer"
                        style={{ color: "green" }}
                        onClick={this.exportDeliveredProjectListToExcel}
                        title="Export to Excel"
                      ></i>
                    </Col>
                  )}
                </Row>
                <div
                  className="borderTable"
                  style={{ overflowX: "auto", width: "98%" }}
                >
                  <div
                    style={{
                      overflowY: "scroll",
                      borderBottom: "1px solid #cdd4e0",
                    }}
                    ref={this.divScrollRef}
                    className="delivered-project-table-height"
                    onScroll={this.handleScroll}
                  >
                    <BootstrapTable
                      bootstrap4
                      {...props.baseProps}
                      expandRow={expandDeliveredProjectsRow}
                    />
                  </div>
                </div>
              </div>
            )}
          </ToolkitProvider>
          {this.state.position > 600 && this.state.filterValue === "" && (
            <div className="up-arrow">
              <i
                className="fa fa-angle-up pro-arrow"
                onClick={this.scrollToTop}
              ></i>
            </div>
          )}
        </div>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    projects: state.projects,
  };
};

export default connect(mapStateToProps, { readDeliveredProjectsList })(
  DeliveredProjectList
);
