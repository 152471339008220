import React, { Component } from "react";
import projectService from "../../services/project.service";
import customerService from "../../services/customer.service";
import inputOutputFormatService from "../../services/inputOutputFormat.service";
import projectActivityService from "../../services/projectActivity.service";
import helper from "../../helpers/helpers";
import { Button, Modal } from "react-bootstrap";
import ModernDatepicker from "react-modern-datepicker";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UnspscService from "../../services/Unspsc.service";
import userService from "../../services/user.service";
import mroDictionaryService from "../../services/mroDictionary.service";
toast.configure();

class CreateProject extends Component {
  constructor(props) {
    super(props); //reference to the parents constructor() function.

    this.handleYes = this.handleYes.bind(this);
    this.handleNo = this.handleNo.bind(this);
    this.onChangeCustomer = this.onChangeCustomer.bind(this);
    this.fetchCustomers = this.fetchCustomers.bind(this);
    this.fetchProjectActivities = this.fetchProjectActivities.bind(this);
    this.onChangeProjectType = this.onChangeProjectType.bind(this);
    this.onChangeTypeOfInput = this.onChangeTypeOfInput.bind(this);
    this.onChangeInputCountType = this.onChangeInputCountType.bind(this);
    this.onChangeReceivedDate = this.onChangeReceivedDate.bind(this);
    this.onChangeReceivedFormat = this.onChangeReceivedFormat.bind(this);
    this.onChangeUNSPSCVersion = this.onChangeUNSPSCVersion.bind(this);
    this.onChangeMroDictionaryVersion = this.onChangeMroDictionaryVersion.bind(this);
    this.onChangeOutputFormat = this.onChangeOutputFormat.bind(this);
    this.onChangeInputCount = this.onChangeInputCount.bind(this);
    this.onChangePlannedStartDate = this.onChangePlannedStartDate.bind(this);
    this.onChangeDeliveryMode = this.onChangeDeliveryMode.bind(this);
    this.onChangepPlannedDeliveryDate =
      this.onChangePlannedDeliveryDate.bind(this);
    this.onChangeIsResourceBased = this.onChangeIsResourceBased.bind(this);
    this.uploadDeliveryPlanFile = this.uploadDeliveryPlanFile.bind(this);
    this.downloadDeliveryPlanFile = this.downloadDeliveryPlanFile.bind(this);
    this.deleteDeliveryPlanFile = this.deleteDeliveryPlanFile.bind(this);

    this.uploadCustomerInputFile = this.uploadCustomerInputFile.bind(this);
    this.downloadCustomerInputFile = this.downloadCustomerInputFile.bind(this);
    this.deleteCustomerInputFile = this.deleteCustomerInputFile.bind(this);
    this.onChangeRemarks = this.onChangeRemarks.bind(this);

    this.onChangeScope = this.onChangeScope.bind(this);
    this.uploadScopeFile = this.uploadScopeFile.bind(this);
    this.downloadScopeFile = this.downloadScopeFile.bind(this);
    this.deleteScopeFile = this.deleteScopeFile.bind(this);

    this.onChangeGuideline = this.onChangeGuideline.bind(this);
    this.uploadGuidelineFile = this.uploadGuidelineFile.bind(this);
    this.downloadGuidelineFile = this.downloadGuidelineFile.bind(this);
    this.deleteGuidelineFile = this.deleteGuidelineFile.bind(this);

    this.onChangeChecklist = this.onChangeChecklist.bind(this);
    this.uploadChecklistFile = this.uploadChecklistFile.bind(this);
    this.downloadChecklistFile = this.downloadChecklistFile.bind(this);
    this.deleteChecklistFile = this.deleteChecklistFile.bind(this);

    this.onChangeEmailDate = this.onChangeEmailDate.bind(this);
    this.onChangeEmailDescription = this.onChangeEmailDescription.bind(this);

    this.onChangeProjectActivity = this.onChangeProjectActivity.bind(this);
    this.onChangeNoOfSKUs = this.onChangeNoOfSKUs.bind(this);
    this.onChangeProductionTarget = this.onChangeProductionTarget.bind(this);
    this.onChangeQCTarget = this.onChangeQCTarget.bind(this);
    this.onChangeQATarget = this.onChangeQATarget.bind(this);

    this.AddToProjectActivityList = this.AddToProjectActivityList.bind(this);
    this.deleteProjectActivityRow = this.deleteProjectActivityRow.bind(this);

    this.reset = this.reset.bind(this);

    this.clearEmailDate = this.clearEmailDate.bind(this);
    this.clearPlannedDeliveryDate = this.clearPlannedDeliveryDate.bind(this);
    this.clearPlannedStartDate = this.clearPlannedStartDate.bind(this);
    this.clearReceivedDate = this.clearReceivedDate.bind(this);

    //Component State
    this.state = {
      loading: false,
      spinnerMessage: "",
      customers: [],
      selectedCustomer: "",
      projectType: "",
      selectedTypeOfInput: "",
      inputCount: 0,
      selectedInputCountType: "",
      receivedDate: "",
      InputOutputFormats: [],
      selectedReceivedFormat: "",
      selectedOutputFormat: "",
      plannedStartDate: "",
      selectedunspcVersion: "",
      selectedDeliveryMode: "",
      plannedDeliveryDate: "",
      deliveryPlanFileName: "",
      DeliveryPlanFileUploadedName: "",
      waitingMessageForPlannedDeliveryFile: false,
      deliveryPlanFileKey: Date.now(),
      isResourceBased: false,
      customerInputFileName: "",
      customerInputFileUploadedName: "",
      messageForCustomerInputFile: false,
      customerInputFileKey: Date.now(),
      remarks: "",
      scope: "",
      scopeFileName: "",
      scopeFileUploadedName: "",
      messageForScopeFile: false,
      scopeFileKey: Date.now(),
      guideline: "",
      guidelineFileName: "",
      guidelineFileUploadedName: "",
      messageForGuidelineFile: false,
      guidelineFileKey: Date.now(),
      checklist: "",
      checklistFileName: "",
      checklistFileUploadedName: "",
      messageForChecklistFile: false,
      checklistFileKey: Date.now(),
      emailDate: "",
      emailDescription: "",
      ProjectActivities: [],
      selectedProjectActivity: "",
      NoOfSKUs: 0,
      productionTarget: 0,
      QCTarget: 0,
      QATarget: 0,
      projectActivityDetails: [],
      formErrors: {},
      projectActivityFormErrors: {},
      unspcVersions: [],
      selectedDepartment: "",
      departmentList: [],
      showModal: false,
      selectedMroDictionaryVersion: "",
      mrodictionaryversionslist: [],
    };

    this.initialState = this.state;
  }

  //Component Mount
  componentDidMount() {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    this.fetchCustomers();
    this.fetchInputOutputFormats();
    this.fetchUnspcVersionData();
    this.mrodictionaryVersionDropDown();
  }

  //#region fetching customers from Web API
  fetchCustomers() {
    this.setState({
      spinnerMessage: "Please wait while loading customers...",
      loading: true,
    });

    customerService
      .getAllCustomers(helper.getUser())
      .then((response) => {
        this.setState({
          customers: response.data,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  // #region Fetch Version Dropdown datas
  fetchUnspcVersionData() {
    this.setState({
      spinnerMessage: "Please wait while fetching the data...!",
      loading: true,
    });
    UnspscService.UNSPSCVersionData()
      .then((resp) => {
        if (resp.data.length === 0) {
          this.setState({
            loading: false,
          });
          return;
        }
        this.setState({
          ...this.state,
          unspcVersions: resp.data,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      });
  }
  // #endregion

  //#region fetching Input Output Formats from Web API
  fetchInputOutputFormats() {
    this.setState({
      spinnerMessage: "Please wait while loading input output formats...",
      loading: true,
    });

    inputOutputFormatService
      .getAllInputOutputFormats(helper.getUser(), true)
      .then((response) => {
        this.setState({
          InputOutputFormats: response.data,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region fetching Project Activities from Web API
  fetchProjectActivities() {
    this.setState({
      spinnerMessage: "Please wait while loading project activities...",
      loading: true,
    });

    projectActivityService
      .getAllActivities(helper.getUser(), true)
      .then((response) => {
        this.setState({
          ProjectActivities: response.data,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Close Modal Pop Up
  handleNo() {
    this.setState({ showModal: false });
  }
  //#endregion

  //#region Display Modal Pop up
  handleYes() {
    this.setState({
      showModal: true,
      selectedProjectActivity: "",
      NoOfSKUs: "",
      productionTarget: "",
      QCTarget: "",
      QATarget: "",
      projectActivityFormErrors: "",
    });
  }
  //#endregion

  //#region Get Selected Customer
  onChangeCustomer(e) {
    this.setState({
      selectedCustomer: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = { ...this.state.formErrors, customerCodeError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Get Selected Project Type
  onChangeProjectType(e) {
    this.setState({
      projectType: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = { ...this.state.formErrors, projectTypeError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Get Selected Type of Input
  onChangeTypeOfInput(e) {
    this.setState({
      selectedTypeOfInput: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = { ...this.state.formErrors, typeOfInputError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Get Input Count value
  onChangeInputCount(e) {
    this.setState({
      inputCount: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = { ...this.state.formErrors, inputCountError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Get Selected Input Count Type
  onChangeInputCountType(e) {
    this.setState({
      selectedInputCountType: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = { ...this.state.formErrors, inputCountTypeError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region  Get Selected Received Date
  onChangeReceivedDate(date) {
    this.setState({
      receivedDate: date,
    });

    if (date !== "" && date !== null) {
      const formErrors = { ...this.state.formErrors, receivedDateError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Get Selected Received Format
  onChangeReceivedFormat(e) {
    this.setState({
      selectedReceivedFormat: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = { ...this.state.formErrors, receivedFormatError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion
  
  // #region Handle change event for the MRO Dictionary Version dropdown
  onChangeMroDictionaryVersion = (e) => {
    this.setState({
      selectedMroDictionaryVersion: e.target.value,
    });

    // Clear any existing errors if a valid selection is made
    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = { ...this.state.formErrors, selectedMroDictionaryVersionError: "" };
      this.setState({ formErrors });
    }
  };

  // Fetch the MRO Dictionary Versions
  mrodictionaryVersionDropDown = () => {
    this.setState({
      spinnerMessage: "Please wait while loading MRO Dictionary Versions...",
      loading: true,
    });

    mroDictionaryService
      .readMRODictionariesList()
      .then((response) => {
        this.setState({ mrodictionaryversionslist: response.data, loading: false });
      })
      .catch((e) => {
        this.setState({ loading: false });
        toast.error(e.response?.data?.Message || "Failed to load data", { autoClose: false });
      });
  };
  //#endregion

  //#region Get Selected Received Format
  onChangeDepartment = (e) => {
    this.setState({
      selectedDepartment: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = { ...this.state.formErrors, selectedDepartmentError: "" };
      this.setState({ formErrors: formErrors });
    }
  }

  departmentDropDown = () => {
    this.setState({
      spinnerMessage: "Please wait while loading Departments...",
      loading: true,
    });

    userService
      .readDepartmentsHcNMro()
      .then((response) => {
        this.setState({ departmentList: response.data, loading: false });
      })
      .catch((e) => {
        this.setState({ loading: false });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Get Selected UNSPSC Format
  onChangeUNSPSCVersion(e) {
    this.setState({
      selectedunspcVersion: e.target.value,
    });
  }
  //#endregion

  //#region Get Selected Output Format
  onChangeOutputFormat(e) {
    this.setState({
      selectedOutputFormat: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = { ...this.state.formErrors, outputFormatError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region  Get Selected Planned Start Date
  onChangePlannedStartDate(date) {
    this.setState({
      plannedStartDate: date,
    });

    if (date !== "" && date !== null) {
      const formErrors = {
        ...this.state.formErrors,
        plannedStartDateError: "",
      };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Get Selected Delivery Mode
  onChangeDeliveryMode(e) {
    this.setState({
      selectedDeliveryMode: e.target.value,
    });

    if (e.target.value === "S") {
      this.setState({
        deliveryPlanFileName: "",
        deliveryPlanFileKey: Date.now(),
        DeliveryPlanFileUploadedName: "",
      });
    } else {
      this.setState({ plannedDeliveryDate: "" });
    }

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = { ...this.state.formErrors, deliveryModeError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region  Get Selected Planned Delivery Date
  onChangePlannedDeliveryDate(date) {
    this.setState({
      plannedDeliveryDate: date,
    });

    if (date !== "" && date !== null) {
      const formErrors = {
        ...this.state.formErrors,
        plannedDeliveryDateError: "",
      };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Uploading Delivery Plan File
  uploadDeliveryPlanFile(e) {
    this.setState({
      waitingMessageForPlannedDeliveryFile: true,
    });
    var files = e.target.files;

    let currentFile = files[0];
    let fileNameUploaded = files[0].name;
    this.setState({
      DeliveryPlanFileUploadedName: fileNameUploaded,
    });

    let formData = new FormData();
    formData.append("File", currentFile);

    this.setState({
      spinnerMessage: "Please wait while uploading delivery plan file...",
      loading: true,
    });

    //Service call
    projectService
      .saveFileupload(formData)
      .then((response) => {
        this.setState({
          waitingMessageForPlannedDeliveryFile: false,
          deliveryPlanFileName: response.data,
          loading: false,
        });
      })
      .catch((error) => {
        toast.error(error.response.data.Message, { autoClose: false });
        this.setState({
          waitingMessageForPlannedDeliveryFile: false,
          deliveryPlanFileName: "",
          loading: false,
        });
      });
  }
  //#endregion

  //#region Downloading Delivery Plan File
  downloadDeliveryPlanFile(e) {
    this.setState({
      spinnerMessage: "Please wait while downloading delivery plan file...",
      loading: true,
    });

    projectService
      .downloadFile(this.state.deliveryPlanFileName, "deliveryPlanFile")
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          this.state.DeliveryPlanFileUploadedName
        );
        document.body.appendChild(fileLink);

        fileLink.click();

        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Deleting Delivery Plan File
  deleteDeliveryPlanFile() {
    this.setState({
      spinnerMessage: "Please wait while deleting delivery plan file...",
      loading: true,
    });

    projectService
      .deleteFile(this.state.deliveryPlanFileName)
      .then((response) => {
        this.setState({
          deliveryPlanFileKey: Date.now(),
          deliveryPlanFileName: "",
          DeliveryPlanFileUploadedName: "",
          loading: false,
        });
      })
      .catch((error) => {
        toast.error(error.response.data.Message, { autoClose: false });
        this.setState({
          deliveryPlanFileName: "",
          loading: false,
        });
      });
  }
  //#endregion

  //#region get Is Resource Based value
  onChangeIsResourceBased(e) {
    this.setState({
      isResourceBased: e.target.checked,
    });
  }
  //#endregion

  //#region Uploading CustomerInput File
  uploadCustomerInputFile(e) {
    this.setState({
      messageForCustomerInputFile: true,
    });
    var files = e.target.files;

    let currentFile = files[0];
    let fileNameUploaded = files[0].name;
    this.setState({
      customerInputFileUploadedName: fileNameUploaded,
    });

    let formData = new FormData();
    formData.append("File", currentFile);

    this.setState({
      spinnerMessage: "Please wait while uploading customer file...",
      loading: true,
    });

    //Service call
    projectService
      .saveFileupload(formData)
      .then((response) => {
        this.setState({
          messageForCustomerInputFile: false,
          customerInputFileName: response.data,
          loading: false,
        });
      })
      .catch((error) => {
        toast.error(error.response.data.Message, { autoClose: false });
        this.setState({
          messageForCustomerInputFile: false,
          customerInputFileName: "",
          loading: false,
        });
      });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = {
        ...this.state.formErrors,
        customerInputFileError: "",
      };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Downloading customer Input File
  downloadCustomerInputFile(e) {
    this.setState({
      spinnerMessage: "Please wait while downloading customer file...",
      loading: true,
    });

    projectService
      .downloadFile(this.state.customerInputFileName, "customerInputFile")
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          this.state.customerInputFileUploadedName
        );
        document.body.appendChild(fileLink);

        fileLink.click();

        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Deleting Customer Input File
  deleteCustomerInputFile() {
    this.setState({
      spinnerMessage: "Please wait while deleting customer file...",
      loading: true,
    });

    projectService
      .deleteFile(this.state.customerInputFileName)
      .then((response) => {
        this.setState({
          customerInputFileKey: Date.now(),
          customerInputFileName: "",
          customerInputFileUploadedName: "",
          loading: false,
        });
      })
      .catch((error) => {
        toast.error(error.response.data.Message, { autoClose: false });
        this.setState({
          customerInputFileName: "",
          loading: false,
        });
      });
  }
  //#endregion

  //#region get Remarks value
  onChangeRemarks(e) {
    this.setState({
      remarks: e.target.value,
    });
  }
  //#endregion

  //#region getScope value
  onChangeScope(e) {
    this.setState({
      scope: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = { ...this.state.formErrors, scopeError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Uploading Scope File
  uploadScopeFile(e) {
    this.setState({
      messageForScopeFile: true,
    });
    var files = e.target.files;

    let currentFile = files[0];
    let fileNameUploaded = files[0].name;
    this.setState({
      scopeFileUploadedName: fileNameUploaded,
    });

    let formData = new FormData();
    formData.append("File", currentFile);

    this.setState({
      spinnerMessage: "Please wait while uploading scope file...",
      loading: true,
    });

    //Service call
    projectService
      .saveFileupload(formData)
      .then((response) => {
        this.setState({
          messageForScopeFile: false,
          scopeFileName: response.data,
          loading: false,
        });
      })
      .catch((error) => {
        toast.error(error.response.data.Message, { autoClose: false });
        this.setState({
          messageForScopeFile: false,
          scopeFileName: "",
          loading: false,
        });
      });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = { ...this.state.formErrors, scopeError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Downloading Scope File
  downloadScopeFile(e) {
    this.setState({
      spinnerMessage: "Please wait while downloading scope file...",
      loading: true,
    });

    projectService
      .downloadFile(this.state.scopeFileName, "scope")
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", this.state.scopeFileUploadedName);
        document.body.appendChild(fileLink);

        fileLink.click();
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Deleting Scope File
  deleteScopeFile() {
    this.setState({
      spinnerMessage: "Please wait while deleting scope file...",
      loading: true,
    });

    projectService
      .deleteFile(this.state.scopeFileName)
      .then((response) => {
        this.setState({
          scopeFileKey: Date.now(),
          scopeFileName: "",
          scopeFileUploadedName: "",
          loading: false,
        });
      })
      .catch((error) => {
        toast.error(error.response.data.Message, { autoClose: false });
        this.setState({
          scopeFileName: "",
          loading: false,
        });
      });
  }
  //#endregion

  //#region get Guideline value
  onChangeGuideline(e) {
    this.setState({
      guideline: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = { ...this.state.formErrors, guidelineError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Uploading Guideline File
  uploadGuidelineFile(e) {
    this.setState({
      messageForGuidelineFile: true,
    });
    var files = e.target.files;

    let currentFile = files[0];
    let fileNameUploaded = files[0].name;
    this.setState({
      guidelineFileUploadedName: fileNameUploaded,
    });

    let formData = new FormData();
    formData.append("File", currentFile);

    this.setState({
      spinnerMessage: "Please wait while uploading guideline file...",
      loading: true,
    });

    //Service call
    projectService
      .saveFileupload(formData)
      .then((response) => {
        this.setState({
          messageForGuidelineFile: false,
          guidelineFileName: response.data,
          loading: false,
        });
      })
      .catch((error) => {
        toast.error(error.response.data.Message, { autoClose: false });
        this.setState({
          messageForGuidelineFile: false,
          guidelineFileName: "",
          loading: false,
        });
      });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = { ...this.state.formErrors, guidelineError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Downloading Guideline File
  downloadGuidelineFile(e) {
    this.setState({
      spinnerMessage: "Please wait while downloading guideline file...",
      loading: true,
    });

    projectService
      .downloadFile(this.state.guidelineFileName, "guidelines")
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", this.state.guidelineFileUploadedName);
        document.body.appendChild(fileLink);

        fileLink.click();

        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });

        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Deleting guideline File
  deleteGuidelineFile() {
    this.setState({
      spinnerMessage: "Please wait while deleting guideline file...",
      loading: true,
    });

    projectService
      .deleteFile(this.state.guidelineFileName)
      .then((response) => {
        this.setState({
          guidelineFileKey: Date.now(),
          guidelineFileName: "",
          guidelineFileUploadedName: "",
          loading: false,
        });
      })
      .catch((error) => {
        toast.error(error.response.data.Message, { autoClose: false });
        this.setState({
          guidelineFileName: "",
          loading: false,
        });
      });
  }
  //#endregion

  //#region get Checklist value
  onChangeChecklist(e) {
    this.setState({
      checklist: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = { ...this.state.formErrors, checklistError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Uploading Checlist File
  uploadChecklistFile(e) {
    this.setState({
      messageForChecklistFile: true,
    });
    var files = e.target.files;

    let currentFile = files[0];
    let fileNameUploaded = files[0].name;
    this.setState({
      checklistFileUploadedName: fileNameUploaded,
    });

    let formData = new FormData();
    formData.append("File", currentFile);

    this.setState({
      spinnerMessage: "Please wait while uploading checklist file...",
      loading: true,
    });

    //Service call
    projectService
      .saveFileupload(formData)
      .then((response) => {
        this.setState({
          messageForChecklistFile: false,
          checklistFileName: response.data,
          loading: false,
        });
      })
      .catch((error) => {
        toast.error(error.response.data.Message, { autoClose: false });
        this.setState({
          messageForChecklistFile: false,
          checklistFileName: "",
          loading: false,
        });
      });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = { ...this.state.formErrors, checklistError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Downloading Checklist File
  downloadChecklistFile(e) {
    this.setState({
      spinnerMessage: "Please wait while downloading checklist file...",
      loading: true,
    });

    projectService
      .downloadFile(this.state.checklistFileName, "checklist")
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", this.state.checklistFileUploadedName);
        document.body.appendChild(fileLink);

        fileLink.click();

        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Deleting Checklist File
  deleteChecklistFile() {
    this.setState({
      spinnerMessage: "Please wait while deleting checklist file...",
      loading: true,
    });

    projectService
      .deleteFile(this.state.checklistFileName)
      .then((response) => {
        this.setState({
          checklistFileKey: Date.now(),
          checklistFileName: "",
          checklistFileUploadedName: "",
          loading: false,
        });
      })
      .catch((error) => {
        toast.error(error.response.data.Message, { autoClose: false });
        this.setState({
          checklistFileName: "",
          loading: false,
        });
      });
  }
  //#endregion

  //#region  Get Selected Received Date
  onChangeEmailDate(date) {
    this.setState({
      emailDate: date,
    });
  }
  //#endregion

  //#region get Email Description value
  onChangeEmailDescription(e) {
    this.setState({
      emailDescription: e.target.value,
    });
  }
  //#endregion

  //#region Get Selected Project Activity
  onChangeProjectActivity(e) {
    this.setState({
      selectedProjectActivity: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = {
        ...this.state.projectActivityFormErrors,
        projectActivityError: "",
        duplicateProjectActivityError: "",
      };
      this.setState({ projectActivityFormErrors: formErrors });
    }
  }
  //#endregion

  //#region Get No. of SKUs
  onChangeNoOfSKUs(e) {
    this.setState({
      NoOfSKUs: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = {
        ...this.state.projectActivityFormErrors,
        NoOfSKUsError: "",
      };
      this.setState({ projectActivityFormErrors: formErrors });
    }
  }
  //#endregion

  //#region Get Production Target value
  onChangeProductionTarget(e) {
    this.setState({
      productionTarget: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = {
        ...this.state.projectActivityFormErrors,
        productionTargetError: "",
      };
      this.setState({ projectActivityFormErrors: formErrors });
    }
  }
  //#endregion

  //#region Get QC Target value
  onChangeQCTarget(e) {
    this.setState({
      QCTarget: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = {
        ...this.state.projectActivityFormErrors,
        QCTargetError: "",
      };
      this.setState({ projectActivityFormErrors: formErrors });
    }
  }
  //#endregion

  //#region Get QA Target value
  onChangeQATarget(e) {
    this.setState({
      QATarget: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = {
        ...this.state.projectActivityFormErrors,
        QATargetError: "",
      };
      this.setState({ projectActivityFormErrors: formErrors });
    }
  }
  //#endregion

  //#region Add to Project Activities List
  AddToProjectActivityList(e) {
    e.preventDefault();
    if (this.handleProjectActivityFormValidation()) {
      let projectActivityDetails = [...this.state.projectActivityDetails];

      projectActivityDetails.push({
        Activity: this.state.selectedProjectActivity,
        NoOfSKUs: parseInt(this.state.NoOfSKUs),
        ProductionTarget: parseInt(this.state.productionTarget),
        QCTarget: parseInt(this.state.QCTarget),
        QATarget: parseInt(this.state.QATarget),
      });

      const formErrors = {
        ...this.state.formErrors,
        projectActivityTargetError: "",
      };

      this.setState({
        projectActivityDetails,
        formErrors: formErrors,
        showModal: false,
        selectedProjectActivity: "",
      });
    }
  }
  //#endregion

  //#region Delete Specific Project Activity Row
  deleteProjectActivityRow = (index) => () => {
    const projectActivityDetails = [...this.state.projectActivityDetails];
    projectActivityDetails.splice(index, 1);
    this.setState({ projectActivityDetails });
  };
  //#endregion

  //#region Rendering Table body data
  renderTableData() {
    if (this.state.projectActivityDetails.length !== 0) {
      return this.state.projectActivityDetails.map((projectActivity, index) => {
        const { Activity, NoOfSKUs, ProductionTarget, QCTarget, QATarget } =
          projectActivity;
        return (
          <tr key={index}>
            <td>{Activity}</td>
            <td align="center">{NoOfSKUs}</td>
            <td align="center">{ProductionTarget}</td>
            <td align="center">{QCTarget}</td>
            <td align="center">{QATarget}</td>
            <td align="center">
              <i
                className="fas fa-trash-alt pointer"
                onClick={this.deleteProjectActivityRow(index)}
              ></i>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td align="center" colSpan={6}>There is no Project Activity</td>
        </tr>
      );
    }
  }
  //#endregion

  //#region Rendering Table Header data
  renderTableHeader() {
    return (
      <tr>
        <th className="bg-white">Project Activity</th>
        <th className="text-center bg-white">No. of SKUs</th>
        <th className="text-center bg-white">Production Target</th>
        <th className="text-center bg-white">QC Target</th>
        <th className="text-center bg-white">QA Target</th>
        <th>Delete</th>
      </tr>
    );
  }
  //#endregion

  //#region Validating the project Activity Target data
  handleProjectActivityFormValidation() {
    const projectActivity = this.state.selectedProjectActivity.trim();
    const inputCount = this.state.inputCount;
    const NoOfSKUs = this.state.NoOfSKUs;
    const productionTarget = this.state.productionTarget;
    const QCTarget = this.state.QCTarget;
    const QATarget = this.state.QATarget;
    let formErrors = {};
    let isValidForm = true;

    //Project Activity
    if (!projectActivity) {
      isValidForm = false;
      formErrors["projectActivityError"] = "Project Activity is required";
    }

    //No. of SKUs
    if (!NoOfSKUs) {
      isValidForm = false;
      formErrors["NoOfSKUsError"] = "No. of SKUs is required";
    }

    if (parseInt(NoOfSKUs) > parseInt(inputCount)) {
      isValidForm = false;
      formErrors["NoOfSKUsError"] =
        "No. of SKUs cannot be more than input count";
    }

    //Production Target
    if (!productionTarget) {
      isValidForm = false;
      formErrors["productionTargetError"] = "Production Target is required";
    }

    //QC Target
    if (!QCTarget) {
      isValidForm = false;
      formErrors["QCTargetError"] = "QC Target is required";
    }

    //QA Target
    if (!QATarget) {
      isValidForm = false;
      formErrors["QATargetError"] = "QA Target is required";
    }

    // Find if the array contains an object by comparing the property value
    if (
      this.state.projectActivityDetails.some(
        (details) => details.Activity === projectActivity
      )
    ) {
      isValidForm = false;
      formErrors["duplicateProjectActivityError"] =
        "Project Activity already exists";
    }

    this.setState({ projectActivityFormErrors: formErrors });
    return isValidForm;
  }
  //#endregion

  //#region Validating the input data
  handleFormValidation() {
    const customerCode = this.state.selectedCustomer.trim();
    const projectType = this.state.projectType.trim();
    const typeOfInput = this.state.selectedTypeOfInput.trim();
    const inputCount = this.state.inputCount;
    const inputCountType = this.state.selectedInputCountType.trim();
    const receivedDate = this.state.receivedDate;
    const receivedFormat = this.state.selectedReceivedFormat.trim();
    const Department = this.state.selectedDepartment.trim();
    const outputFormat = this.state.selectedOutputFormat.trim();
    const plannedStartDate = this.state.plannedStartDate;
    const deliveryMode = this.state.selectedDeliveryMode.trim();
    const plannedDeliveryDate = this.state.plannedDeliveryDate;
    const customerInputFile = this.state.customerInputFileName;
    const scope = this.state.scope.trim();
    const scopeFile = this.state.scopeFileName.trim();
    const guideline = this.state.guideline.trim();
    const guidelineFile = this.state.guidelineFileName.trim();
    const checklist = this.state.checklist.trim();
    const checklistFile = this.state.checklistFileName.trim();
    const projectActivityTarget = this.state.projectActivityDetails;
    let formErrors = {};
    let isValidForm = true;

    //Customer Code
    if (!customerCode) {
      isValidForm = false;
      formErrors["customerCodeError"] = "Customer Code is required";
    }

    //Project Type
    if (!projectType) {
      isValidForm = false;
      formErrors["projectTypeError"] = "Project Type is required";
    }

    //Type of Input
    if (!typeOfInput) {
      isValidForm = false;
      formErrors["typeOfInputError"] = "Type of Input is required";
    }

    //Input Count
    if (!inputCount) {
      isValidForm = false;
      formErrors["inputCountError"] = "Input Count is required";
    }

    //Input Count Type
    if (!inputCountType) {
      isValidForm = false;
      formErrors["inputCountTypeError"] = "Input Count Type is required";
    }

    //Received Date
    if (!receivedDate) {
      isValidForm = false;
      formErrors["receivedDateError"] = "Received Date is required";
    }

    //Received Format
    if (!receivedFormat) {
      isValidForm = false;
      formErrors["receivedFormatError"] = "Received Format is required";
    }

    //Received Format
    if (!Department) {
      isValidForm = false;
      formErrors["selectedDepartmentError"] = "Department is required";
    }

    //Output Format
    if (!outputFormat) {
      isValidForm = false;
      formErrors["outputFormatError"] = "Output Format is required";
    }

    //Planned Start Date
    if (!plannedStartDate) {
      isValidForm = false;
      formErrors["plannedStartDateError"] = "Planned Start Date is required";
    } else if (new Date(plannedStartDate) < new Date(receivedDate)) {
      isValidForm = false;
      formErrors["plannedStartDateError"] =
        "Planned Start Date can not be earlier than Received Date";
    }

    //Delivery Mode
    if (!deliveryMode) {
      isValidForm = false;
      formErrors["deliveryModeError"] = "Delivery Mode is required";
    }

    if (deliveryMode === "S") {
      //Planned Delivery Date
      if (!plannedDeliveryDate) {
        isValidForm = false;
        formErrors["plannedDeliveryDateError"] =
          "Planned Delivery Date is required";
      } else if (new Date(plannedDeliveryDate) < new Date(plannedStartDate)) {
        isValidForm = false;
        formErrors["plannedStartDateError"] =
          "Planned Delivery Date can not be earlier than Planned Start Date";
      }
    }

    //Customer Input File
    if (!customerInputFile) {
      isValidForm = false;
      formErrors["customerInputFileError"] = "Customer Input File is required";
    }

    //Scope
    if (!scope && !scopeFile) {
      isValidForm = false;
      formErrors["scopeError"] = "Either Scope or Scope File is required";
    }

    //Guideline
    if (!guideline && !guidelineFile) {
      isValidForm = false;
      formErrors["guidelineError"] =
        "Either Guideline or Guideline File is required";
    }

    //Checklist
    if (!checklist && !checklistFile) {
      isValidForm = false;
      formErrors["checklistError"] =
        "Either Checklist or Checklist File is required";
    }

    //Project Activity Target
    if (projectActivityTarget.length === 0) {
      isValidForm = false;
      formErrors["projectActivityTargetError"] =
        "At least one Project Activity is required";
    }

    this.setState({ formErrors: formErrors });
    return isValidForm;
  }
  //#endregion

  //#region Save Project
  saveProject = (e) => {
    e.preventDefault();

    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    if (this.handleFormValidation()) {
      this.setState({
        spinnerMessage: "Please wait while Saving Project...",
        loading: true,
      });

      var data = {
        ProjectID: 0,
        CustomerCode: this.state.selectedCustomer,
        ProjectCode: "",
        ProjectType: this.state.projectType,
        LocationCode: "JPN",
        TypeOfInput: this.state.selectedTypeOfInput,
        InputCount: parseInt(this.state.inputCount),
        InputCountType: this.state.selectedInputCountType,
        ReceivedDate: this.state.receivedDate,
        ReceivedFormat: this.state.selectedReceivedFormat,
        OutputFormat: this.state.selectedOutputFormat,
        PlannedStartDate: this.state.plannedStartDate,
        DeliveryMode: this.state.selectedDeliveryMode,
        PlannedDeliveryDate: this.state.plannedDeliveryDate,
        DeliveryPlanFileName: this.state.deliveryPlanFileName,
        IsResourceBased: this.state.isResourceBased,
        Remarks: this.state.remarks,
        CustomerInputFileName: this.state.customerInputFileName,
        Scope: this.state.scope,
        ScopeFileName: this.state.scopeFileName,
        Guideline: this.state.guideline,
        GuidelineFileName: this.state.guidelineFileName,
        Checklist: this.state.checklist,
        ChecklistFileName: this.state.checklistFileName,
        EmailDate: this.state.emailDate,
        EmailDescription: this.state.emailDescription,
        UNSPSCVersion: this.state.selectedunspcVersion,
        MRODictionaryVersion: this.state.selectedMroDictionaryVersion,
        Department: this.state.selectedDepartment,
        NoOfBatches: 0,
        Status: "InProcess",
        Activities: this.state.projectActivityDetails,
        UserID: helper.getUser(),
      };

      console.log(data)

      //Service call
      projectService
        .createProject(data)
        .then((response) => {
          this.setState({
            loading: false,
          });
          toast.success(
            "Project Created Successfully, Customer Code: " +
            this.state.selectedCustomer +
            ", Project Code: " +
            response.data
          );
          this.setState(this.initialState);
          this.props.history.push({
            pathname: "/Projects",
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          toast.error(error.response.data.Message, { autoClose: false });
        });
    }
  };
  //#endregion

  //#region Reset the page
  reset() {
    this.setState(this.initialState);
    this.setState({
      deliveryPlanFileKey: Date.now(),
      customerInputFileKey: Date.now(),
      scopeFileKey: Date.now(),
      guidelineFileKey: Date.now(),
      checklistFileKey: Date.now(),
    });
    this.componentDidMount();
  }
  //#endregion

  //#region Clearing Dates
  clearEmailDate() {
    this.setState({ emailDate: "" });
  }

  clearPlannedDeliveryDate() {
    this.setState({ plannedDeliveryDate: "" });
  }

  clearPlannedStartDate() {
    this.setState({ plannedStartDate: "" });
  }

  clearReceivedDate() {
    this.setState({ receivedDate: "" });
  }
  //#endregion

  render() {
    const deliveryMode = this.state.selectedDeliveryMode;
    const waitingMessageForPlannedDeliveryFile =
      this.state.waitingMessageForPlannedDeliveryFile;
    const messageForCustomerInputFile = this.state.messageForCustomerInputFile;
    const deliveryPlanFileName = this.state.deliveryPlanFileName;
    const customerInputFileName = this.state.customerInputFileName;
    const scopeFileName = this.state.scopeFileName;
    const messageForScopeFile = this.state.messageForScopeFile;
    const guidelineFileName = this.state.guidelineFileName;
    const messageForGuidelineFile = this.state.messageForGuidelineFile;
    const checklistFileName = this.state.checklistFileName;
    const messageForChecklistFile = this.state.messageForChecklistFile;

    //const { customerCodeError } = this.state.formErrors["customerCodeError"];

    let control;
    let DeliveryFileButtons;
    if (deliveryMode === "" || deliveryMode === "S") {
      control = (
        <>
          <label>
            Planned Delivery Date{" "}
            <span className="text-danger asterisk-size">*</span>
          </label>
          <div className="form-field-div flex-grow-1">
            <div className="form-control date-field-width">
              <ModernDatepicker
                date={this.state.plannedDeliveryDate}
                format={"DD-MMM-YYYY"}
                onChange={(date) => this.onChangePlannedDeliveryDate(date)}
                placeholder={"Select a date"}
                className="color"
                minDate={new Date(1900, 1, 1)}
              />
            </div>
            <span
              className="btn btn-secondary"
              onClick={this.clearPlannedDeliveryDate}
            >
              <i
                className="far fa-window-close"
                title="Clear Planned Delivery Date"
              ></i>
            </span>
          </div>
          <div className="error-message">
            {this.state.formErrors["plannedDeliveryDateError"]}
          </div>
        </>
      );
      DeliveryFileButtons = <></>;
    } else {
      control = (
        <>
          <label>Delivery Plan File</label>
          <input
            type="file"
            className="form-control"
            tabIndex="13"
            id="DeliveryPlanFile"
            name="DeliveryPlanFile"
            key={this.state.deliveryPlanFileKey}
            onChange={this.uploadDeliveryPlanFile}
            accept=".xls, .xlsx,.doc,.docx,.pdf"
          />
        </>
      );
      DeliveryFileButtons = (
        <>
          {waitingMessageForPlannedDeliveryFile && <p>Please Wait...</p>}
          {deliveryPlanFileName && (
            <>
              <span
                className="btn btn-secondary"
                onClick={this.downloadDeliveryPlanFile}
              >
                <i className="fas fa-download"></i>
              </span>
              <span
                className="btn btn-secondary mg-l-5"
                onClick={this.deleteDeliveryPlanFile}
              >
                <i className="fas fa-trash-alt"></i>
              </span>
            </>
          )}
        </>
      );
    }

    return (
      <div className="create-project-page" style={{ height: "93%" }}>
        <LoadingOverlay
          active={this.state.loading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
          <div className="cp-main-div">
            <form onSubmit={this.saveProject} className="cp-form">
              <div className="cp-breadcome-header">
                <div className="az-content-breadcrumb mg-t-20">
                  <span>Project</span>
                  <span>Create Project</span>
                </div>
                <h4>
                  Create Project{" "}
                  <span className="icon-size">
                    <i
                      className="far fa-arrow-alt-circle-left text-primary pointer"
                      tabIndex="1"
                      onClick={() => this.props.history.goBack()}
                      title="Back to List"
                    ></i>
                  </span>
                </h4>
              </div>
              <div id="Add_Project" className="cp-container">
                <div className="row row-sm">
                  <div className="col-lg-6">
                    <div className="form-field-div">
                      <label>
                        Customer Code{" "}
                        <span className="text-danger asterisk-size">*</span>
                      </label>
                      <select
                        className="form-control flex-grow-1"
                        tabIndex="2"
                        id="CustomerCode"
                        name="CustomerCode"
                        value={this.state.selectedCustomer}
                        onChange={this.onChangeCustomer}
                        placeholder="--Select--"
                      >
                        <option value="">--Select--</option>
                        {this.state.customers.map((customers) => (
                          <option key={customers.CustomerCode}>
                            {customers.CustomerCode}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="error-message">
                      {this.state.formErrors.customerCodeError}
                    </div>
                  </div>
                  <div className="col-lg-6 mg-t-10 mg-lg-t-0">
                    <div className="form-field-div">
                      <label>
                        Project Type{" "}
                        <span className="text-danger asterisk-size">*</span>
                      </label>
                      <div className="form-field-div flex-grow-1">
                        <input
                          type="radio"
                          value="P"
                          tabIndex="3"
                          id="ProjectType"
                          name="ProjectType"
                          checked={this.state.projectType === "P"}
                          onChange={this.onChangeProjectType}
                        />
                        <label className="checkbox-inline mg-l-10">Pilot</label>
                        <input
                          type="radio"
                          value="R"
                          className="mg-l-20"
                          tabIndex="4"
                          id="ProjectType"
                          name="ProjectType"
                          checked={this.state.projectType === "R"}
                          onChange={this.onChangeProjectType}
                        />
                        <label className="checkbox-inline mg-l-10">
                          Regular
                        </label>
                      </div>
                    </div>
                    <div className="error-message">
                      {this.state.formErrors["projectTypeError"]}
                    </div>
                  </div>
                </div>
                <br />
                <div className="row row-sm">
                  <div className="col-lg-6">
                    <div className="form-field-div">
                      <label>Location</label>
                      <input
                        type="text"
                        className="form-control flex-grow-1"
                        value="JPN"
                        readOnly
                        onChange={() => ""}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 mg-t-10 mg-lg-t-0">
                    <div className="form-field-div">
                      <label>
                        Type of Input{" "}
                        <span className="text-danger asterisk-size">*</span>
                      </label>
                      <select
                        className="form-control flex-grow-1"
                        tabIndex="5"
                        id="TypeOfInput"
                        name="TypeOfInput"
                        value={this.state.selectedTypeOfInput}
                        onChange={this.onChangeTypeOfInput}
                      >
                        <option value="">--Select--</option>
                        <option key="Single" value="S">
                          Single
                        </option>
                        <option key="Recurring" value="R">
                          Recurring
                        </option>
                      </select>
                    </div>
                    <div className="error-message">
                      {this.state.formErrors["typeOfInputError"]}
                    </div>
                  </div>
                  <div className="col-lg mg-t-10 mg-lg-t-0"></div>
                </div>
                <br />
                <div className="row row-sm">
                  <div className="col-lg-6">
                    <div className="form-field-div">
                      <label>
                        Input Count{" "}
                        <span className="text-danger asterisk-size">*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control flex-grow-1"
                        tabIndex="6"
                        id="InputCount"
                        name="InputCount"
                        value={this.state.inputCount}
                        onChange={this.onChangeInputCount}
                        max="9999999"
                        min="1"
                      />
                    </div>
                    <div className="error-message">
                      {this.state.formErrors["inputCountError"]}
                    </div>
                  </div>
                  <div className="col-lg-6 mg-t-10 mg-lg-t-0">
                    <div className="form-field-div">
                      <label>
                        Input Count Type{" "}
                        <span className="text-danger asterisk-size">*</span>
                      </label>
                      <select
                        className="form-control flex-grow-1"
                        tabIndex="7"
                        id="InputCountType"
                        name="InputCountType"
                        value={this.state.selectedInputCountType}
                        onChange={this.onChangeInputCountType}
                      >
                        <option value="">--Select--</option>
                        <option key="Items/Lines" value="I">
                          Items / Lines
                        </option>
                        <option key="Document" value="D">
                          Document
                        </option>
                      </select>
                    </div>
                    <div className="error-message">
                      {this.state.formErrors["inputCountTypeError"]}
                    </div>
                  </div>
                  <div className="col-lg mg-t-10 mg-lg-t-0"></div>
                </div>
                <br />
                <div className="row row-sm">
                  <div className="col-lg-6">
                    <div className="form-field-div">
                      <label>
                        Received Date{" "}
                        <span className="text-danger asterisk-size">*</span>
                      </label>
                      <div className="form-field-div flex-grow-1">
                        <div className="form-control date-field-width">
                          <ModernDatepicker
                            date={this.state.receivedDate}
                            format={"DD-MMM-YYYY"}
                            onChange={(date) => this.onChangeReceivedDate(date)}
                            placeholder={"Select a date"}
                            className="color"
                            minDate={new Date(1900, 1, 1)}
                          />
                        </div>
                        <span
                          className="btn btn-secondary"
                          onClick={this.clearReceivedDate}
                        >
                          <i
                            className="far fa-window-close"
                            title="Clear Received Date"
                          ></i>
                        </span>
                      </div>
                    </div>
                    <div className="error-message">
                      {this.state.formErrors["receivedDateError"]}
                    </div>
                  </div>
                  <div className="col-lg-6 mg-t-10 mg-lg-t-0">
                    <div className="form-field-div">
                      <label>
                        Received Format{" "}
                        <span className="text-danger asterisk-size">*</span>
                      </label>
                      <select
                        className="form-control flex-grow-1"
                        tabIndex="9"
                        id="ReceivedFormat"
                        name="ReceivedFormat"
                        value={this.state.selectedReceivedFormat}
                        onChange={this.onChangeReceivedFormat}
                      >
                        <option value="">--Select--</option>
                        {this.state.InputOutputFormats.map((formats) => (
                          <option key={formats.Format}>{formats.Format}</option>
                        ))}
                      </select>
                    </div>
                    <div className="error-message">
                      {this.state.formErrors["receivedFormatError"]}
                    </div>
                  </div>
                  <div className="col-lg mg-t-10 mg-lg-t-0"></div>
                </div>
                <br />
                <div className="row row-sm">
                  <div className="col-lg-6">
                    <div className="form-field-div">
                      <label>
                        Output Format{" "}
                        <span className="text-danger asterisk-size">*</span>
                      </label>
                      <select
                        className="form-control flex-grow-1"
                        tabIndex="10"
                        id="OutputFormat"
                        name="OutputFormat"
                        value={this.state.selectedOutputFormat}
                        onChange={this.onChangeOutputFormat}
                      >
                        <option value="">--Select--</option>
                        {this.state.InputOutputFormats.map((formats) => (
                          <option key={formats.Format}>{formats.Format}</option>
                        ))}
                      </select>
                    </div>
                    <div className="error-message">
                      {this.state.formErrors["outputFormatError"]}
                    </div>
                  </div>
                  <div className="col-lg-6 mg-t-10 mg-lg-t-0">
                    <div className="form-field-div">
                      <label>
                        Planned Start Date{" "}
                        <span className="text-danger asterisk-size">*</span>
                      </label>
                      <div className="form-field-div">
                        <div className="form-control date-field-width">
                          <ModernDatepicker
                            date={this.state.plannedStartDate}
                            format={"DD-MMM-YYYY"}
                            onChange={(date) =>
                              this.onChangePlannedStartDate(date)
                            }
                            placeholder={"Select a date"}
                            className="color"
                            minDate={new Date(1900, 1, 1)}
                          />
                        </div>
                        <span
                          className="btn btn-secondary"
                          onClick={this.clearPlannedStartDate}
                        >
                          <i
                            className="far fa-window-close"
                            title="Clear Planned Start Date"
                          ></i>
                        </span>
                      </div>
                    </div>
                    <div className="error-message">
                      {this.state.formErrors["plannedStartDateError"]}
                    </div>
                  </div>
                  <div className="col-lg mg-t-10 mg-lg-t-0"></div>
                </div>
                <br />
                <div className="row row-sm">
                  <div className="col-lg-6">
                    <div className="form-field-div">
                      <label>
                        Delivery Mode{" "}
                        <span className="text-danger asterisk-size">*</span>
                      </label>
                      <select
                        className="form-control flex-grow-1"
                        tabIndex="12"
                        id="DeliveryMode"
                        name="DeliveryMode"
                        value={this.state.selectedDeliveryMode}
                        onChange={this.onChangeDeliveryMode}
                      >
                        <option value="">--Select--</option>
                        <option key="Single" value="S">
                          Single
                        </option>
                        <option key="Multiple" value="M">
                          Multiple
                        </option>
                      </select>
                    </div>
                    <div className="error-message">
                      {this.state.formErrors["deliveryModeError"]}
                    </div>
                  </div>
                  <div className="col-lg-6 mg-t-10 mg-lg-t-0">
                    <div className="form-field-div">{control}
                    </div>
                  </div>
                  <div className="col-lg mg-t-10 mg-lg-t-0">
                    <div className="form-field-div">
                      {DeliveryFileButtons}
                    </div>
                  </div>
                </div>
                <br />
                <div className="row row-sm">
                  <div className="col-lg-6">
                    <div className="form-field-div">
                      <label>Is Resource Based?</label>
                      <div className="flex-grow-1" >
                        <label className="checkbox-inline mg-l-10">
                          <input
                            type="checkbox"
                            tabIndex="14"
                            id="IsResourceBased"
                            name="IsResourceBased"
                            value={this.state.isResourceBased}
                            onChange={this.onChangeIsResourceBased}
                            checked={this.state.isResourceBased}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 mg-t-10 mg-lg-t-0">
                    <div className="form-field-div">
                      <label htmlFor="CustomerInputFile">
                        Customer Input File{" "}
                        <span className="text-danger asterisk-size">*</span>
                      </label>
                      <input
                        type="file"
                        className="form-control flex-grow-1"
                        tabIndex="15"
                        id="CustomerInputFile"
                        name="CustomerInputFile"
                        key={this.state.customerInputFileKey}
                        onChange={this.uploadCustomerInputFile}
                        accept=".xls, .xlsx,.doc,.docx,.pdf"
                      />
                    </div>
                    <div className="error-message">
                      {this.state.formErrors["customerInputFileError"]}
                    </div>
                  </div>
                  <div className="col-lg d-flex">
                    {messageForCustomerInputFile && <p>Please Wait...</p>}
                    {customerInputFileName && (
                      <>
                        <span
                          className="btn btn-secondary"
                          onClick={this.downloadCustomerInputFile}
                        >
                          <i className="fas fa-download"></i>
                        </span>
                        <span
                          className="btn btn-secondary mg-l-5"
                          onClick={this.deleteCustomerInputFile}
                        >
                          <i className="fas fa-trash-alt"></i>
                        </span>
                      </>
                    )}
                  </div>
                </div>
                <br />
                <div className="row row-sm">
                  <div className="col-lg-6">
                    <div className="form-field-div">
                      <label>Remarks</label>
                      <textarea
                        className="form-control flex-grow-1"
                        rows="2"
                        tabIndex="16"
                        id="Remarks"
                        name="Remarks"
                        maxLength="500"
                        value={this.state.remarks}
                        onChange={this.onChangeRemarks}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-lg-6 mg-t-10 mg-lg-t-0">
                    <div className="form-field-div">
                      <label>
                        MRO Dictionary Version
                        
                      </label>
                      <select
                        className="form-control flex-grow-1"
                        tabIndex="9"
                        id="ReceivedFormat"
                        name="ReceivedFormat"
                        value={this.state.selectedMroDictionaryVersion || ""}
                        onChange={this.onChangeMroDictionaryVersion}
                      >
                        <option value="">--Select--</option>
                        {this.state.mrodictionaryversionslist.map((dpt) => (
                          <option key={dpt.VersionNameOrNo} value={dpt.VersionNameOrNo}>
                            {dpt.VersionNameOrNo}
                          </option>
                        ))}
                      </select>
                  </div>
                  </div>
                    
                </div>
                <br />
                <div className="row row-sm">
                  <div className="col-lg-6">
                    <div className="form-field-div">
                      <label>Scope</label>
                      <textarea
                        className="form-control flex-grow-1"
                        rows="2"
                        tabIndex="17"
                        id="Scope"
                        maxLength="500"
                        name="Scope"
                        value={this.state.scope}
                        onChange={this.onChangeScope}
                      ></textarea>
                    </div>
                    <div className="error-message">
                      {this.state.formErrors["scopeError"]}
                    </div>
                  </div>
                  <div className="col-lg-5 mg-t-10 mg-lg-t-0">
                    <div className="form-field-div">
                      <label>Scope File</label>
                      <input
                        type="file"
                        className="form-control flex-grow-1"
                        tabIndex="18"
                        id="ScopeFile"
                        name="ScopeFile"
                        key={this.state.scopeFileKey}
                        onChange={this.uploadScopeFile}
                        accept=".xls, .xlsx,.doc,.docx,.pdf"
                      />
                    </div>

                  </div>
                  <div className="col-lg d-flex">
                    {messageForScopeFile && <p>Please Wait...</p>}
                    {scopeFileName && (
                      <>
                        <span
                          className="btn btn-secondary"
                          onClick={this.downloadScopeFile}
                        >
                          <i className="fas fa-download"></i>
                        </span>
                        <span
                          className="btn btn-secondary mg-l-5"
                          onClick={this.deleteScopeFile}
                        >
                          <i className="fas fa-trash-alt"></i>
                        </span>
                      </>
                    )}
                  </div>
                </div>
                <br />
                <div className="row row-sm">
                  <div className="col-lg-6">
                    <div className="form-field-div">
                      <label>Guideline</label>
                      <textarea
                        className="form-control flex-grow-1"
                        rows="2"
                        tabIndex="19"
                        id="Guideline"
                        name="Guideline"
                        maxLength="500"
                        value={this.state.guideline}
                        onChange={this.onChangeGuideline}
                      ></textarea>
                    </div>
                    <div className="error-message">
                      {this.state.formErrors["guidelineError"]}
                    </div>
                  </div>
                  <div className="col-lg-5 mg-t-10 mg-lg-t-0">
                    <div className="form-field-div">
                      <label>Guideline File</label>
                      <input
                        type="file"
                        className="form-control flex-grow-1"
                        tabIndex="20"
                        id="GuidelineFile"
                        name="GuidelineFile"
                        key={this.state.guidelineFileKey}
                        onChange={this.uploadGuidelineFile}
                        accept=".xls, .xlsx,.doc,.docx,.pdf"
                      />
                    </div>
                  </div>
                  <div className="col-lg d-flex">
                    {messageForGuidelineFile && <p>Please Wait...</p>}
                    {guidelineFileName && (
                      <>
                        <span
                          className="btn btn-secondary"
                          onClick={this.downloadGuidelineFile}
                        >
                          <i className="fas fa-download"></i>
                        </span>
                        <span
                          className="btn btn-secondary mg-l-5"
                          onClick={this.deleteGuidelineFile}
                        >
                          <i className="fas fa-trash-alt"></i>
                        </span>
                      </>
                    )}
                  </div>
                </div>
                <br />
                <div className="row row-sm">
                  <div className="col-lg-6">
                    <div className="form-field-div">
                      <label>Checklist</label>
                      <textarea
                        className="form-control flex-grow-1"
                        rows="2"
                        tabIndex="21"
                        id="Checklist"
                        name="Checklist"
                        maxLength="500"
                        value={this.state.checklist}
                        onChange={this.onChangeChecklist}
                      ></textarea>
                    </div>
                    <div className="error-message">
                      {this.state.formErrors["checklistError"]}
                    </div>
                  </div>
                  <div className="col-lg-5 mg-t-10 mg-lg-t-0">
                    <div className="form-field-div">
                      <label>Checklist File</label>
                      <input
                        type="file"
                        className="form-control flex-grow-1"
                        tabIndex="22"
                        id="ChecklistFile"
                        name="ChecklistFile"
                        key={this.state.checklistFileKey}
                        onChange={this.uploadChecklistFile}
                        accept=".xls, .xlsx,.doc,.docx,.pdf"
                      />
                    </div>
                  </div>
                  <div className="col-lg d-flex">
                    {messageForChecklistFile && <p>Please Wait...</p>}
                    {checklistFileName && (
                      <>
                        <span
                          className="btn btn-secondary"
                          onClick={this.downloadChecklistFile}
                        >
                          <i className="fas fa-download"></i>
                        </span>
                        <span
                          className="btn btn-secondary mg-l-5"
                          onClick={this.deleteChecklistFile}
                        >
                          <i className="fas fa-trash-alt"></i>
                        </span>
                      </>
                    )}
                  </div>
                </div>
                <br />
                <br />
                <div className="row row-sm">
                  <div className="col-lg-6">
                    <div className="form-field-div">
                      <label>Email Date</label>
                      <div className="form-field-div flex-grow-1">
                        <div className="form-control date-field-width">
                          <ModernDatepicker
                            date={this.state.emailDate}
                            format={"DD-MMM-YYYY"}
                            onChange={(date) => this.onChangeEmailDate(date)}
                            placeholder={"Select a date"}
                            className="color"
                            minDate={new Date(1900, 1, 1)}
                          />
                        </div>
                        <span
                          className="btn btn-secondary"
                          onClick={this.clearEmailDate}
                        >
                          <i
                            className="far fa-window-close"
                            title="Clear Email Date"
                          ></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mg-t-10 mg-lg-t-0">
                    <div className="form-field-div">
                      <label>Description</label>
                      <textarea
                        className="form-control flex-grow-1"
                        rows="3"
                        tabIndex="24"
                        id="emailDescription"
                        name="emailDescription"
                        maxLength="4000"
                        value={this.state.emailDescription}
                        onChange={this.onChangeEmailDescription}
                      ></textarea>
                    </div>
                  </div>
                  {/* <div className="col-lg mg-t-10 mg-lg-t-0"></div> */}
                </div>
                <br />
                <div className="row row-sm">
                  <div className="col-lg-6">
                  <div className="form-field-div">
                      <label>
                        UNSPSC Version{" "}
                      </label>
                      <select
                        className="form-control flex-grow-1"
                        tabIndex="9"
                        id="ReceivedFormat"
                        name="ReceivedFormat"
                        value={this.state.selectedunspcVersion}
                        onChange={this.onChangeUNSPSCVersion}
                      >
                        <option value="">--Select--</option>
                        {this.state.unspcVersions.map((version) => (
                          <option key={version.Version}>{version.Version}</option>
                        ))}
                      </select>
                    </div>
                    <div className="error-message">
                      {this.state.formErrors["unspscFormatError"]}
                    </div>
                  </div>
                  <div className="col-lg-6 mg-t-10 mg-lg-t-0">
                    <div className="form-field-div">
                      <label>
                        Department{" "}
                        <span className="text-danger asterisk-size">*</span>
                      </label>
                      <select
                        className="form-control flex-grow-1"
                        tabIndex="9"
                        id="ReceivedFormat"
                        name="ReceivedFormat"
                        value={this.state.selectedDepartment}
                        onChange={this.onChangeDepartment}
                        onFocus={this.departmentDropDown}
                      >
                        <option value="">--Select--</option>
                        {this.state.departmentList.map((dpt) => (
                          <option key={dpt.DepartmentID}>{dpt.Name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="error-message">
                      {this.state.formErrors["receivedFormatError"]}
                    </div>
                  </div>
                </div>
                <br />
                <h4 className="project-activity-heading">
                  Project Activity Targets{" "}
                  <span className="icon-size">
                    <i
                      className="fa fa-plus text-primary pointer"
                      onClick={this.handleYes}
                      title="Add New Project Activity"
                      tabIndex="25"
                    ></i>
                  </span>
                </h4>
                <div className="error-message">
                  {this.state.formErrors["projectActivityTargetError"]}
                </div>
                <div className="table-responsive col-md-12 pl-2 pr-2">
                  <table
                    id="projectActivityDetails"
                    className="table table-hover"
                    tabIndex="26"
                  >
                    <thead>{this.renderTableHeader()}</thead>
                    <tbody>{this.renderTableData()}</tbody>
                  </table>
                </div>
                <div className="row row-sm mg-t-30">
                  <div className="col-md-3"></div>
                  <div className="col-md-2 mg-t-10 mg-lg-t-0">
                    <input
                      type="submit"
                      id="Save"
                      className="mg-t-10 mg-md-t-0 btn btn-gray-700 btn-block"
                      tabIndex="27"
                      value="Save"
                    />
                  </div>
                  <div className="col-md-2"></div>
                  <div className="col-md-2  mg-t-10 mg-lg-t-0">
                    <span
                      className="btn btn-gray-700 btn-block"
                      tabIndex="28"
                      onClick={this.reset}
                      id="Reset"
                    >
                      Reset
                    </span>
                  </div>
                </div>
                <div className="mg-b-10"></div>
              </div>
            </form>
            <Modal
              className="cp-add-project-activity-modal"
              show={this.state.showModal}
              aria-labelledby="contained-modal-title-vcenter"
              onHide={this.handleNo}
              backdrop="static"
              enforceFocus={false}
              centered
            >
              <form onSubmit={this.AddToProjectActivityList}>
                <Modal.Header>
                  <Modal.Title>Add New Project Activity</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <div className="row row-sm">
                      <div className="col-lg">
                        <label>
                          Project Activity{" "}
                          <span className="text-danger asterisk-size">*</span>
                        </label>
                        <select
                          className="form-control"
                          tabIndex="2"
                          id="ProjectActivity"
                          name="ProjectActivity"
                          value={this.state.selectedProjectActivity}
                          onChange={this.onChangeProjectActivity}
                          onFocus={this.fetchProjectActivities}
                          placeholder="--Select--"
                        >
                          <option value="">--Select--</option>
                          {this.state.ProjectActivities.map(
                            (projectActivities) => (
                              <option key={projectActivities.Activity}>
                                {projectActivities.Activity}
                              </option>
                            )
                          )}
                        </select>
                        <div className="error-message">
                          {
                            this.state.projectActivityFormErrors[
                            "projectActivityError"
                            ]
                          }
                        </div>
                        <label>
                          No. of SKUs{" "}
                          <span className="text-danger asterisk-size">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          value={this.state.NoOfSKUs}
                          onChange={this.onChangeNoOfSKUs}
                          min="1"
                          max="9999999"
                        />
                        <div className="error-message">
                          {
                            this.state.projectActivityFormErrors[
                            "NoOfSKUsError"
                            ]
                          }
                        </div>
                        <label>
                          Daily Production Target{" "}
                          <span className="text-danger asterisk-size">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          value={this.state.productionTarget}
                          onChange={this.onChangeProductionTarget}
                          min="0"
                          max="9999"
                        />
                        <div className="error-message">
                          {
                            this.state.projectActivityFormErrors[
                            "productionTargetError"
                            ]
                          }
                        </div>
                        <label>
                          Daily QC Target{" "}
                          <span className="text-danger asterisk-size">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          maxLength="4"
                          value={this.state.QCTarget}
                          onChange={this.onChangeQCTarget}
                          min="0"
                          max="9999"
                        />
                        <div className="error-message">
                          {
                            this.state.projectActivityFormErrors[
                            "QCTargetError"
                            ]
                          }
                        </div>
                        <label>
                          Daily QA Target{" "}
                          <span className="text-danger asterisk-size">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          maxLength="4"
                          value={this.state.QATarget}
                          onChange={this.onChangeQATarget}
                          min="0"
                          max="9999"
                        />
                        <div className="error-message">
                          {
                            this.state.projectActivityFormErrors[
                            "QATargetError"
                            ]
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <div className="error-message mg-l-25">
                  {
                    this.state.projectActivityFormErrors[
                    "duplicateProjectActivityError"
                    ]
                  }
                </div>
                <Modal.Footer>
                  <input
                    type="submit"
                    className="btn btn-primary"
                    value="Add To List"
                  />
                  <Button variant="secondary" onClick={this.handleNo}>
                    Cancel
                  </Button>
                </Modal.Footer>
              </form>
            </Modal>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default CreateProject;
