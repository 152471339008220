export const READ_DELIVERED_PROJECTS = "READ_DELIVERED_PROJECTS";
export const READ_ONGOING_PROJECTS = "READ_ONGOING_PROJECTS";

// User Profile Page
export const User_Profile_Data = "User_Profile_Data";
export const User_Role_Data = "User_Role_Data";
// User Profile Page

// Production Unique NM Values
export const Attribute_Unique_Value = "Attribute_Unique_Value";
export const Attribute_rowData = "Attribute_rowData";
// Production Unique NM Values
