import React, { Component } from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import helper from "../../helpers/helpers";
import DeliveredProjectList from "./DeliveredProjectList";
import OnGoingProjectList from "./OnGoingProjectList";
import NotStartedProjectList from "./NotStartedProjectList";
import accessControlService from "../../services/accessControl.service";
import ModernDatepicker from "react-modern-datepicker";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import "./projects.scss";
import Moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import customerService from "../../services/customer.service";
import projectStatusService from "../../services/projectStatus.service";
toast.configure();

class ProjectList extends Component {
  constructor(props) {
    super(props);

    this.moveToCreateProject = this.moveToCreateProject.bind(this);
    this.fetchCustomers = this.fetchCustomers.bind(this);
    this.onChangeCustomerCode = this.onChangeCustomerCode.bind(this);
    this.onChangeProjectCode = this.onChangeProjectCode.bind(this);
    this.onChangeProjectType = this.onChangeProjectType.bind(this);
    this.onChangeFromDate = this.onChangeFromDate.bind(this);
    this.onChangeToDate = this.onChangeToDate.bind(this);

    this.state = {
      canAccessCreateProject: false,
      activeTab: null,
      defaultActiveKey: "",
      activeKey: "",
      loading: false,
      spinnerMessage: "Please wait while loading...",
      customers: [],
      customerCode: "(All)",
      selectedCustomerCode: "(All)",
      projectCodes: [],
      projectCode: "(All)",
      selectedProjectCode: "(All)",
      projectTypes: ["Regular", "Pilot"],
      selectedProjectType: "(All)",
      fromDate: "01-Apr-2011",
      toDate: "",
      formErrors: {},
    };
  }

  //#region component mount
  componentDidMount() {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    this.setActiveTab();
    this.canUserAccessPage("Create Project");
    this.fetchCustomers();

    var toDate = new Date();

    toDate.setDate(toDate.getDate());

    this.setState({
      toDate: Moment(toDate).format("DD-MMM-yyyy"),
    });
  }
  //#endregion

  //#region fetching customers from Web API
  fetchCustomers() {
    this.setState({
      spinnerMessage: "Please wait while loading Customers...",
      loading: true,
    });

    customerService
      .getAllCustomers(helper.getUser())
      .then((response) => {
        this.setState({
          customers: response.data,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Get Selected Customer Code
  onChangeCustomerCode(e) {
    
    // let customerCode = '';
    // if(e.target.value === "(All)"){
    //   customerCode = "All"
    // } else {
    //   customerCode = e.target.value.split("(");
    //   customerCode = customerCode[0].trim();
    // }

    this.setState(
      {
        customerCode: e.target.value,
        selectedCustomerCode: e.target.value,
        selectedProjectCode: "(All)",
        activeTab: 0,
        activeKey: "",
      },
      () => this.fetchProjectCodesOfCustomer(this.state.selectedCustomerCode)
    );
  }
  //#endregion

  //#region Fetch Project Codes of Customer
  fetchProjectCodesOfCustomer(customerCode) {
    if (!customerCode) {
      this.setState({ projectCodes: [], selectedProjectCode: "" });
      return;
    }

    this.setState({
      spinnerMessage: "Please wait while loading Project Codes...",
      loading: true,
    });

    let selectedCustomerCode = customerCode;
    // if(customerCode === "(All)"){
    //   selectedCustomerCode = "All"
    // } else {
    //   selectedCustomerCode = customerCode.split("(");
    //   selectedCustomerCode = selectedCustomerCode[0].trim();
    // }

    projectStatusService
      .readProjectCodesOfCustomer(selectedCustomerCode)
      .then((response) => {
        this.setState({
          projectCodes: response.data,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Get Selected Project Code
  onChangeProjectCode(e) {
    // let projectCode = e.target.value.split("(");
    // projectCode = projectCode[0].trim();

    this.setState({
      projectCode: e.target.value,
      selectedProjectCode: e.target.value,
      activeTab: 0,
      activeKey: "",
    });
  }
  //#endregion

  //#region Get Selected Project Code
  onChangeProjectType(e) {
    this.setState({
      selectedProjectType: e.target.value,
      activeTab: 0,
      activeKey: "",
    });
  }
  //#endregion

  //#region Get Selected From Date
  onChangeFromDate(date) {
    this.setState({
      fromDate: date,
      activeTab: 0,
      activeKey: "",
    });
  }
  //#endregion

  //#region Get Selected To Date
  onChangeToDate(date) {
    this.setState({
      toDate: date,
      activeTab: 0,
      activeKey: "",
    });
  }

  //#endregion

  //#region Set active Tab
  setActiveTab() {
    const { state } = this.props.location;

    if (state) {
      if (state.activeTab === 2) {
        this.setState({
          activeTab: 2,
          defaultActiveKey: "delivered",
          activeKey: "delivered",
        });
      } else {
        this.setState({
          activeTab: 1,
          defaultActiveKey: "onGoing",
          activeKey: "onGoing",
        });
      }
    } else {
      this.setState({
        activeTab: 1,
        defaultActiveKey: "onGoing",
        activeKey: "onGoing",
      });
    }
  }
  //#endregion

  //#region fetching Project page access
  canUserAccessPage(pageName) {
    this.setState({
      spinnerMessage: "Please wait while loading...",
      loading: true,
    });

    accessControlService
      .CanUserAccessPage(helper.getUser(), pageName)
      .then((response) => {
        this.setState({
          canAccessCreateProject: response.data,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Redirect to Create Project Page
  moveToCreateProject() {
    this.props.history.push("/Projects/CreateProject");
  }
  //#endregion

  //#region Change Between On Going and Delivered Tab
  toggle(tab, key) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab, activeKey: key });
    }
  }
  //#endregion

  //#region Clear Search Result
  clearSearchResult() {
    var toDate = new Date();

    toDate.setDate(toDate.getDate());

    this.setState(
      {
        customerCode: "(All)",
        selectedCustomerCode: "(All)",
        projectCode: "(All)",
        selectedProjectCode: "(All)",
        selectedProjectType: "(All)",
        fromDate: "01-Apr-2011",
        toDate: Moment(toDate).format("DD-MMM-yyyy"),
        activeTab: 0,
        activeKey: "",
      },
      () => this.toggle(1, "onGoing")
    );
  }
  //#endregion

  //#region UI
  render() {
    let props = this.props;

    const canAccessCreateProject = this.state.canAccessCreateProject;
    return this.state.activeTab === null ? (
      <LoadingOverlay
          active={true}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
        <p style={{ height: "580px" }}></p>
      </LoadingOverlay>
    ) : (
      <div>
        <LoadingOverlay
          active={this.state.loading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
          <div className="az-content-breadcrumb mg-l-50">
            <span>Project</span>
            <span>List</span>
          </div>
          <h4 className="mg-l-50">
            Projects List{" "}
            {canAccessCreateProject && (
              <span className="icon-size">
                <i
                  className="fa fa-plus text-primary pointer"
                  onClick={this.moveToCreateProject}
                  title="Add New Project"
                ></i>
              </span>
            )}
          </h4>
          <div
            style={{ border: "1px solid #cdd4e0" }}
            className="mg-l-50 mg-r-25 mg-b-10"
          >
            <div className="row row-sm mg-r-15 mg-l-5 mg-t-5">
              <div className="col-lg">
                <div className="row">
                  <div className="col-md-5 mg-t-5">
                    <b>Customer Code</b>
                  </div>
                  <div className="col-md-7">
                    <select
                      className="form-control"
                      tabIndex="1"
                      id="customerCode"
                      name="customerCode"
                      placeholder="--Select--"
                      value={this.state.selectedCustomerCode}
                      onChange={this.onChangeCustomerCode}
                    >
                      <option value="(All)">(All)</option>
                      {this.state.customers.map((customer) => (
                        <option key={customer.CustomerID} value={customer.CustomerCode}>
                          {customer.CustomerCode} ({customer.NoOfProjects})
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <div className="row">
                  <div className="col-md-5 mg-t-5">
                    <b>Project Code</b>
                  </div>
                  <div className="col-md-7">
                    <select
                      className="form-control"
                      id="projectCode"
                      name="projectCode"
                      placeholder="--Select--"
                      value={this.state.selectedProjectCode}
                      onChange={this.onChangeProjectCode}
                    >
                      <option value="(All)">(All)</option>
                      {this.state.projectCodes.map((projectCode) => (
                        <option key={projectCode.ProjectCode} value={projectCode.ProjectCode}>
                          {projectCode.ProjectCode} (
                          {projectCode.ProjectInputCount})
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-lg mg-t-10 mg-lg-t-0">
                {this.state.selectedProjectCode === "(All)" && (
                  <div className="row">
                    <div className="col-md-5 mg-t-5">
                      <b>Project Type</b>
                    </div>
                    <div className="col-md-7">
                      <select
                        className="form-control"
                        tabIndex="2"
                        id="projectType"
                        name="projectType"
                        placeholder="--Select--"
                        value={this.state.selectedProjectType}
                        onChange={this.onChangeProjectType}
                      >
                        <option value="(All)">(All)</option>
                        {this.state.projectTypes.map((type) => (
                          <option key={type}>{type}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="row row-sm mg-r-15 mg-l-5 mg-t-5 mg-b-5">
              <div className="col-lg">
                <div className="row row-sm">
                  <div className="col-md-5">
                    <b>From Date</b>{" "}
                    <span className="text-danger asterisk-size">*</span>
                  </div>
                  <div className="col-md-7">
                    <div className="form-control">
                      <ModernDatepicker
                        date={this.state.fromDate}
                        format={"DD-MMM-YYYY"}
                        onChange={(date) => this.onChangeFromDate(date)}
                        placeholder={"Select a date"}
                        className="color"
                        minDate={new Date(1900, 1, 1)}
                      />
                    </div>
                    <div className="error-message">
                      {this.state.formErrors["fromDateError"]}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <div className="row row-sm">
                  <div className="col-md-5">
                    <b>To Date</b>{" "}
                    <span className="text-danger asterisk-size">*</span>
                  </div>
                  <div className="col-md-7">
                    <div className="form-control">
                      <ModernDatepicker
                        date={this.state.toDate}
                        format={"DD-MMM-YYYY"}
                        onChange={(date) => this.onChangeToDate(date)}
                        placeholder={"Select a date"}
                        className="color"
                        minDate={new Date(1900, 1, 1)}
                      />
                    </div>
                    <div className="error-message">
                      {this.state.formErrors["toDateError"]}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <div className="row">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <button
                      onClick={() => {
                        this.toggle(1, "onGoing");
                      }}
                      className="btn btn-gray-700 btn-block"
                      tabIndex="2"
                    >
                      Submit
                    </button>
                  </div>
                  <div className="col-md-4">
                    <button
                      onClick={() => {
                        this.clearSearchResult();
                      }}
                      className="btn btn-gray-700 btn-block"
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Tab.Container
            defaultActiveKey={this.state.defaultActiveKey}
            activeKey={this.state.activeKey}
          >
            <Nav variant="pills" className="mg-l-50 mg-b-20">
              <Nav.Item>
                <Nav.Link
                  eventKey="onGoing"
                  style={{ border: "1px solid #5E41FC" }}
                  onClick={() => {
                    this.toggle(1, "onGoing");
                  }}
                >
                  On Going
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="delivered"
                  style={{ border: "1px solid #5E41FC" }}
                  onClick={() => {
                    this.toggle(2, "delivered");
                  }}
                >
                  Delivered
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="notStarted"
                  style={{ border: "1px solid #5E41FC" }}
                  onClick={() => {
                    this.toggle(3, "notStarted");
                  }}
                >
                  Not Started
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="onGoing">
                {this.state.activeTab === 1 ? (
                  <OnGoingProjectList
                    {...props}
                    customerCode={this.state.customerCode}
                    projectCode={this.state.projectCode}
                    projectType={this.state.selectedProjectType}
                    fromDate={this.state.fromDate}
                    toDate={this.state.toDate}
                  />
                ) : null}
              </Tab.Pane>
              <Tab.Pane eventKey="delivered">
                {this.state.activeTab === 2 ? (
                  <DeliveredProjectList
                    {...props}
                    customerCode={this.state.customerCode}
                    projectCode={this.state.projectCode}
                    projectType={this.state.selectedProjectType}
                    fromDate={this.state.fromDate}
                    toDate={this.state.toDate}
                  />
                ) : null}
              </Tab.Pane>
              <Tab.Pane eventKey="notStarted">
                {this.state.activeTab === 3 ? (
                  <NotStartedProjectList
                    {...props}
                    customerCode={this.state.customerCode}
                    projectCode={this.state.projectCode}
                    projectType={this.state.selectedProjectType}
                    fromDate={this.state.fromDate}
                    toDate={this.state.toDate}
                  />
                ) : null}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </LoadingOverlay>
      </div>
    );
  }
  //#endregion
}

export default ProjectList;
