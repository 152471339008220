import React, { Component } from "react";
import { HashRouter as Router, Route, Switch, Link } from "react-router-dom";
import RolesList from "./components/Role/RolesList";
import CreateRole from "./components/Role/CreateRole";
import ViewRole from "./components/Role/ViewRole";
import EditRole from "./components/Role/EditRole";
import UserList from "./components/Account/UserList";
import CreateUser from "./components/Account/CreateUser";
import ViewUser from "./components/Account/ViewUser";
import EditUser from "./components/Account/EditUser";
import RoleAccessList from "./components/RoleAccess/RoleAccessList";
import createRoleAccess from "./components/RoleAccess/createRoleAccess";
import EditRoleAccess from "./components/RoleAccess/EditRoleAccess";
import UserRoleList from "./components/UserRoles/UserRoleList";
import createUserRole from "./components/UserRoles/createUserRole";
import EditUserRoles from "./components/UserRoles/EditUserRoles";

import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";

//import icons from react icons
import { FiArrowLeftCircle, FiArrowRightCircle } from "react-icons/fi";
import viewUserRole from "./components/UserRoles/viewUserRole";
import viewRoleAccess from "./components/RoleAccess/viewRoleAccess";

import accessControlService from "../src/services/accessControl.service";
import helper from "../src/helpers/helpers";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import "./components/admin.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class Admin extends Component {
  constructor(props) {
    super(props); //reference to the parent constructor

    this.state = {
      accessControl: [],
      menuCollapse: false,
      style: "margin-not-collpased-admin",
      userPageAccess: false,
      rolePageAccess: false,
      userRolePageAccess: false,
      roleAccessPageAccess: false,
      loading: false,
      spinnerMessage: "",
    };

    this.initialState = this.state;
  }

  //#region component mount
  componentDidMount() {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    this.fetchUserRoleAccess();
  }
  //#endregion

  //#region Fetching Logged In User Access
  fetchUserRoleAccess() {
    this.setState({
      spinnerMessage: "Please wait while loading...",
      loading: true,
    });

    accessControlService
      .ReadUserMenuAccessList(helper.getUser(), "Admin")
      .then((response) => {
        this.setState(
          {
            accessControl: response.data,
          },
          () => {
            let userPageAccess = this.state.accessControl.find(
              (a) => a.PageName === "User List"
            );

            let rolePageAccess = this.state.accessControl.find(
              (a) => a.PageName === "Role List"
            );

            let userRolePageAccess = this.state.accessControl.find(
              (a) => a.PageName === "User Role(s) List"
            );

            let roleAccessPageAccess = this.state.accessControl.find(
              (a) => a.PageName === "Role Access List"
            );

            this.setState({
              userPageAccess: userPageAccess.canAccess,
              rolePageAccess: rolePageAccess.canAccess,
              userRolePageAccess: userRolePageAccess.canAccess,
              roleAccessPageAccess: roleAccessPageAccess.canAccess,
              loading: false,
            });
          }
        );
      })
      .catch((e) => {
        this.setState({ loading: false });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //create a custom function that will change menucollapse state from false to true and true to false
  menuIconClick = () => {
    //condition checking to change state from true to false and vice versa
    if (this.state.menuCollapse) {
      this.setState({
        menuCollapse: false,
        style: "margin-not-collpased-admin",
      });
    } else {
      this.setState({
        menuCollapse: true,
        style: "margin-collpased",
      });
    }
  };

  render() {
    const menuCollapse = this.state.menuCollapse;
    const style = this.state.style;
    const userPageAccess = this.state.userPageAccess;
    const rolePageAccess = this.state.rolePageAccess;
    const userRolePageAccess = this.state.userRolePageAccess;
    const roleAccessPageAccess = this.state.roleAccessPageAccess;

    return (
      <div>
        <LoadingOverlay
          active={this.state.loading}
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
          <div className="az-content">
            <div className="container">
              <div id="header" style={{ zIndex: "0" }}>
                {/* collapsed props to change menu size using menucollapse state */}
                <ProSidebar collapsed={menuCollapse}>
                  <SidebarHeader>
                    <div className="logotext">
                      {/* small and big change using menucollapse state */}
                      <p>{menuCollapse ? "Admin" : "Admin"}</p>
                    </div>
                    <div className="closemenu" onClick={this.menuIconClick}>
                      {/* changing menu collapse icon on click */}
                      {menuCollapse ? (
                        <FiArrowRightCircle />
                      ) : (
                        <FiArrowLeftCircle />
                      )}
                    </div>
                  </SidebarHeader>
                  <hr />
                  <SidebarContent>
                    <Menu iconShape="square">
                      {userPageAccess && (
                        <MenuItem>
                          <i className="fas fa-arrow-circle-right"></i> User
                          <Link to="/admin/UserList"></Link>
                        </MenuItem>
                      )}
                      {rolePageAccess && (
                        <MenuItem>
                          <i className="fas fa-arrow-circle-right"></i> Role
                          <Link to="/admin/Roles"></Link>
                        </MenuItem>
                      )}
                      {userRolePageAccess && (
                        <MenuItem>
                          <i className="fas fa-arrow-circle-right"></i> User
                          Role
                          <Link to="/admin/UserRolesList"></Link>
                        </MenuItem>
                      )}
                      {roleAccessPageAccess && (
                        <MenuItem>
                          <i className="fas fa-arrow-circle-right"></i> Role
                          Access
                          <Link to="/admin/RoleAccessList"></Link>
                        </MenuItem>
                      )}
                    </Menu>
                  </SidebarContent>
                </ProSidebar>
              </div>

              <div className={style} style={{ width: "100%" }}>
                <div className="az-content-body d-flex flex-column">
                  <div>
                    <Router>
                      <Switch>
                        <Route path="/admin" component={UserList} exact />
                        <Route
                          path="/admin/Roles"
                          component={RolesList}
                        ></Route>
                        <Route
                          path="/admin/CreateRole"
                          component={CreateRole}
                        ></Route>
                        <Route
                          path="/admin/ViewRole"
                          component={ViewRole}
                        ></Route>
                        <Route
                          path="/admin/EditRole"
                          component={EditRole}
                        ></Route>
                        <Route
                          path="/admin/UserList"
                          component={UserList}
                        ></Route>
                        <Route
                          path="/admin/CreateUser"
                          component={CreateUser}
                        ></Route>
                        <Route
                          path="/admin/ViewUser"
                          component={ViewUser}
                        ></Route>
                        <Route
                          path="/admin/EditUser"
                          component={EditUser}
                        ></Route>
                        <Route
                          path="/admin/RoleAccessList"
                          component={RoleAccessList}
                        ></Route>
                        <Route
                          path="/admin/RoleAccess"
                          component={createRoleAccess}
                        ></Route>
                        <Route
                          path="/admin/EditRoleAccess"
                          component={EditRoleAccess}
                        ></Route>
                        <Route
                          path="/admin/ViewRoleAccess"
                          component={viewRoleAccess}
                        ></Route>
                        <Route
                          path="/admin/UserRolesList"
                          component={UserRoleList}
                        ></Route>
                        <Route
                          path="/admin/UserRoles"
                          component={createUserRole}
                        ></Route>
                        <Route
                          path="/admin/EditUserRoles"
                          component={EditUserRoles}
                        ></Route>
                        <Route
                          path="/admin/ViewUserRole"
                          component={viewUserRole}
                        ></Route>
                      </Switch>
                    </Router>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default Admin;
