import React, { Component } from "react";
import ProjectList from "./Project/ProjectList";
import CreateProject from "./Project/CreateProject";
import EditProject from "./Project/EditProject";
import ViewProject from "./Project/ViewProject";
import ProjectBatchList from "./ProjectBatch/ProjectBatchList";
import CreateProjectBatch from "./ProjectBatch/CreateProjectBatch";
import ViewProjectBatch from "./ProjectBatch/ViewProjectBatch";
import EditProjectBatch from "./ProjectBatch/EditProjectBatch";
import CompletedProjectList from "./Project/DeliveredProjectList";
import PostProjectBatchDetails from "./PostProjectDetails/postProjectBatchDetails";
import {
  HashRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import DeliveredProjectBatchList from "./ProjectBatch/DeliveredProjectBatchList";
import accessControlService from "../services/accessControl.service";
import helper from "../helpers/helpers";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class Projects extends Component {
  constructor(props) {
    super(props); //reference to the parent constructor

    this.fetchUserRoleAccess = this.fetchUserRoleAccess.bind(this);

    this.state = {
      accessControl: [],
      projectPageAccess: false,
      loading: false,
      spinnerMessage: "Please wait while loading...",
    };

    this.initialState = this.state;
  }

  //#region component mount
  componentDidMount() {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    this.fetchUserRoleAccess();
  }
  //#endregion

  //#region Fetching Logged In User Access
  fetchUserRoleAccess() {
    this.setState({
      spinnerMessage: "Please wait while loading...",
      loading: true,
    });

    accessControlService
      .ReadUserMenuAccessList(helper.getUser(), "Projects")
      .then((response) => {
        this.setState(
          {
            accessControl: response.data,
          },
          () => {
            let projectPageAccess = this.state.accessControl.find(
              (a) => a.PageName === "Project List"
            );

            this.setState({
              projectPageAccess: projectPageAccess.canAccess,
              loading: false,
            });
          }
        );
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  render() {
    const projectPageAccess = this.state.projectPageAccess;

    return (
          <Router>
            <Switch>
              {projectPageAccess && (
                <Route path="/Projects" exact>
                  <ProjectList {...this.props} />
                </Route>
              )}
              <Route
                path="/Projects/CreateProject"
                component={CreateProject}
              ></Route>
              <Route
                path="/Projects/EditProject"
                component={EditProject}
              ></Route>
              <Route path="/Projects/ViewProject">
                <ViewProject {...this.props} />
              </Route>
              <Route
                path="/Projects/ProjectBatchList"
                // component={ProjectBatchList}
              >
                <ProjectBatchList {...this.props} />
              </Route>
              <Route
                path="/Projects/CreateProjectBatch"
                // component={CreateProjectBatch}
              >
                <CreateProjectBatch {...this.props} />
              </Route>
              <Route
                path="/Projects/ViewProjectBatch"
                // component={ViewProjectBatch}
              >
                <ViewProjectBatch {...this.props} />
              </Route>
              <Route
                path="/Projects/EditProjectBatch"
                //component={EditProjectBatch}
              >
                <EditProjectBatch {...this.props} />
              </Route>
              <Route
                path="/Projects/Completed"
                component={CompletedProjectList}
              ></Route>
              <Route
                path="/Projects/DeliveredProjectBatch"
                component={DeliveredProjectBatchList}
              ></Route>
              <Route
                path="/Projects/PostProjectBatchDetails"
                component={PostProjectBatchDetails}
              ></Route>
            </Switch>
          </Router>
    );
  }
}

export default withRouter(Projects);
