import React, { Component } from "react";
import helper from "../../helpers/helpers";
import { Link } from "react-router-dom";
import userRoleService from "../../services/userRole.service";
import tableFunctions from "../../helpers/tableFunctions";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import accessControlService from "../../services/accessControl.service";
import { Row, Col } from "react-bootstrap";
toast.configure();

class viewUserRole extends Component {
  constructor(props) {
    super(props); //reference to the parent constructor

    this.divScrollRef = React.createRef();

    this.onChangeSortOrder = this.onChangeSortOrder.bind(this);
    this.onChangeColumn = this.onChangeColumn.bind(this);
    this.sortData = this.sortData.bind(this);
    this.displaySortingFields = this.displaySortingFields.bind(this);
    this.displayFilteringField = this.displayFilteringField.bind(this);
    this.onChangefilterValue = this.onChangefilterValue.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.clearSearchField = this.clearSearchField.bind(this);
    this.clearSortFields = this.clearSortFields.bind(this);

    this.state = {
      userName: "",
      userRoles: [],
      canAccessEditUserRole: false,
      loading: false,
      spinnerMessage: "",
      index: 20,
      position: 0,
      columns: [],
      selectedColumn: "",
      selectedSort: "",
      isToShowSortingFields: false,
      isToShowFilteringField: true,
      filteredArray: [],
      filterValue: "",
      userRolesArrayForSorting: [],
    };

    this.initialState = this.state;
  }

  //#region component mount
  componentDidMount() {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    this.canUserAccessPage("Create-Edit User Role(s)");
    this.fetchUserRoles();
  }
  //#endregion

  //#region Fetching selected User Role details
  fetchUserRoles() {
    const { state } = this.props.location; // Username passed from User Role List Page
    if (state === 0 || state === null || state === undefined) {
      this.props.history.push("/admin/UserRolesList");
      return;
    }

    this.setState({
      userName: state,
      spinnerMessage: "Please wait while loading User Roles...",
      loading: true,
    });

    userRoleService
      .ReadUserRolesByUserName(state, helper.getUser())
      .then((response) => {
        let formattedArray = response.data.map((obj) => {
          delete obj.UserName;
          return obj;
        });

        this.setState({
          userRoles: formattedArray,
          loading: false,
          userRolesArrayForSorting: response.data,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region fetching User Role page access
  canUserAccessPage(pageName) {
    accessControlService
      .CanUserAccessPage(helper.getUser(), pageName)
      .then((response) => {
        this.setState({
          canAccessEditUserRole: response.data,
        });
      })
      .catch((e) => {
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Scroll to Top
  scrollToTop = () => {
    this.divScrollRef.current.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  //#endregion

  //#region Sort Functions
  //#region Display Sorting Fields
  displaySortingFields() {
    let columns = Object.keys(this.state.userRoles[0]).slice(1);

    this.setState((previousState) => ({
      isToShowSortingFields: !previousState.isToShowSortingFields,
      selectedColumn: "",
      selectedSort: "",
      columns: columns,
      filterValue: "",
      isToShowFilteringField: false,
    }));
  }
  //#endregion

  //#region Selecting the sort column
  onChangeColumn(e) {
    this.setState({
      selectedColumn: e.target.value,
      selectedSort: "",
    });
  }
  //#endregion

  //#region On Change Sort
  onChangeSortOrder(e) {
    this.setState(
      {
        selectedSort: e.target.value,
      },
      () => this.sortData()
    );
  }
  //#endregion

  //#region Sort Data based on column and order
  sortData() {
    let sortedArray = [];
    let column =
      this.state.selectedColumn !== "" ? this.state.selectedColumn : "SlNo";
    const selectedSort =
      this.state.selectedSort !== "" ? this.state.selectedSort : "ascending";
    let numberColumns = ["SlNo", "IsActive"];

    sortedArray = tableFunctions.sortData(
      this.state.userRoles,
      column,
      selectedSort,
      numberColumns,
      []
    );

    sortedArray = sortedArray.map((roles, index) => {
      return {
        ...roles,
        SlNo: index + 1,
      };
    });

    this.setState({ userRoles: sortedArray });
  }
  //#endregion

  //#region  Clear Sort
  clearSortFields() {
    let formattedArray = this.state.userRolesArrayForSorting.map((obj) => {
      delete obj.UserName;
      return obj;
    });
    this.setState({
      selectedColumn: "",
      selectedSort: "",
      userRoles: formattedArray,
    });
  }
  //#endregion
  //#endregion

  //#region Filter Functions
  //#region Display Filtering Field
  displayFilteringField() {
    this.setState((previousState) => ({
      isToShowFilteringField: !previousState.isToShowFilteringField,
      filterValue: "",
      isToShowSortingFields: false,
    }));
  }
  //#endregion

  //#region on change filter value
  onChangefilterValue(e) {
    this.setState({ filterValue: e.target.value }, () =>
      this.getFilteredRows()
    );
  }
  //#endregion

  //#region get filtered rows
  getFilteredRows() {
    let filteredArray = tableFunctions.filterArray(
      this.state.userRoles,
      this.state.filterValue
    );

    filteredArray = filteredArray.map((roles, index) => {
      return {
        ...roles,
        SlNo: index + 1,
      };
    });
    this.setState({ filteredArray: filteredArray });
  }
  //#endregion

  //#region Clear Search
  clearSearchField() {
    this.setState({
      filterValue: "",
    });
  }
  //#endregion
  //#endregion

  //#region Handle Scroll
  handleScroll(e) {
    var currentHeight = e.currentTarget.scrollTop;
    var maxScrollPosition =
      e.currentTarget.scrollHeight - e.currentTarget.clientHeight;

    this.setState({ position: currentHeight });

    if ((currentHeight / maxScrollPosition) * 100 > 90) {
      let curIndex = this.state.index + 20;
      this.setState({ index: curIndex });
    }
  }
  //#endregion

  render() {
    const canAccessEditUserRole = this.state.canAccessEditUserRole;

    const data = this.state.userRoles.slice(0, this.state.index);
    const filteredData = this.state.filteredArray.slice(0, this.state.index);

    const UserRolesColumns = [
      {
        dataField: "SlNo",
        text: "Sl No.",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "UserName",
        text: "User Name",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
        hidden: true,
      },
      {
        dataField: "RoleName",
        text: "Role Name",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "left",
        align: "left",
      },
      {
        dataField: "IsActive",
        text: "Is Active?",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
        formatter: (cell, row, rowIndex, extraData) => (
          <div>
            <input type="checkbox" checked={row.IsActive} readOnly />
          </div>
        ),
      },
    ];

    return (
      <div>
        <LoadingOverlay
          active={this.state.loading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
          <div className="az-content-breadcrumb">
            <span>Users</span>
            <span>User Roles</span>
          </div>
          <h4>
            View User Role(s){" "}
            <span className="icon-size">
              <i
                className="far fa-arrow-alt-circle-left text-primary pointer"
                onClick={() => this.props.history.goBack()}
                title="Back to List"
              ></i>
            </span>
          </h4>
          <div id="Add_form">
            <Row>
              <Col md={2} className="text-nowrap">
                <b>Username</b>{" "}
                <span className="text-danger asterisk-size">*</span>
              </Col>
              <Col md={4}>
                <p style={{ marginTop: "8px" }}>{this.state.userName}</p>
              </Col>
            </Row>
            <ToolkitProvider
              keyField="RoleName"
              data={this.state.filterValue === "" ? data : filteredData}
              columns={UserRolesColumns}
            >
              {(props) => (
                <div>
                  <Row className="mg-b-10" style={{ marginRight: "15px" }}>
                    <Col md={10} style={{ whiteSpace: "nowrap" }}>
                      {this.state.isToShowSortingFields && (
                        <Row>
                          <Col md={4}>
                            <Row>
                              <Col md={3} className="mg-t-7">
                                <label htmlFor="sortColumn">Column:</label>
                              </Col>
                              <Col md={9}>
                                <select
                                  className="form-control mg-l-5"
                                  value={this.state.selectedColumn}
                                  onChange={this.onChangeColumn}
                                >
                                  <option value="">--Select--</option>
                                  {this.state.columns.map((col) => (
                                    <option key={col}>{col}</option>
                                  ))}
                                </select>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={4}>
                            <Row>
                              <Col md={3} className="mg-t-7">
                                <label htmlFor="sortOrder">Order:</label>
                              </Col>
                              <Col md={9}>
                                <select
                                  className="form-control mg-l-5"
                                  value={this.state.selectedSort}
                                  onChange={this.onChangeSortOrder}
                                >
                                  <option value="">--Select--</option>
                                  <option value="ascending">Ascending</option>
                                  <option value="descending">Descending</option>
                                </select>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={4}>
                            <i
                              className="far fa-window-close btn btn-primary pd-b-5"
                              onClick={this.clearSortFields}
                              title="Clear Sort Fields"
                            ></i>
                          </Col>
                        </Row>
                      )}
                      {this.state.isToShowFilteringField && (
                        <Row style={{ flexWrap: "nowrap" }}>
                          <Col md={1} className="mg-t-7">
                            <label htmlFor="search">Search:</label>
                          </Col>
                          <Col md={10} className="col-lg pd-r-10">
                            <input
                              type="text"
                              maxLength="20"
                              className="form-control mg-l-5"
                              value={this.state.filterValue}
                              onChange={this.onChangefilterValue}
                            />
                          </Col>
                          <Col md={1}>
                            <i
                              className="far fa-window-close btn btn-primary pd-b-5"
                              title="Clear Filter"
                              onClick={this.clearSearchField}
                            ></i>
                          </Col>
                        </Row>
                      )}
                    </Col>
                    <Col md={2} style={{ textAlign: "end", marginTop: "10px" }}>
                      <i
                        className="fas fa-exchange-alt fa-rotate-90 pointer"
                        title={
                          this.state.isToShowSortingFields
                            ? "Hide Sort"
                            : "Show Sort"
                        }
                        onClick={this.displaySortingFields}
                      ></i>
                      {!this.state.isToShowFilteringField ? (
                        <i
                          className="fas fa-filter pointer mg-l-10"
                          onClick={this.displayFilteringField}
                          title="Show Filter"
                        ></i>
                      ) : (
                        <i
                          className="fas fa-funnel-dollar pointer mg-l-10"
                          onClick={this.displayFilteringField}
                          title="Hide Filter"
                        ></i>
                      )}
                    </Col>
                  </Row>
                  <div
                    style={
                      (this.state.filteredArray.length > 12 &&
                        this.state.filterValue !== "") ||
                      (this.state.userRoles.length > 12 &&
                        this.state.filterValue === "")
                        ? {
                            height: "340px",
                            overflowY: "scroll",
                            borderBottom: "1px solid #cdd4e0",
                          }
                        : {}
                    }
                    ref={this.divScrollRef}
                    className="scrollable-element"
                    onScroll={this.handleScroll}
                  >
                    <BootstrapTable
                      bootstrap4
                      {...props.baseProps}
                      striped
                      hover
                      condensed
                    />
                    <div className="col-md-10">
                      {((this.state.index <= this.state.userRoles.length &&
                        this.state.filterValue === "") ||
                        this.state.index <=
                          this.state.filteredArray.length) && (
                        <p>loading more rows, please scroll...</p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </ToolkitProvider>
            <div>
              <div className="row row-sm col-md-11 mg-t-10">
                <div className="col-md-6"></div>
                {canAccessEditUserRole && (
                  <div className="col-md-2 mg-t-10 mg-lg-t-0">
                    <Link
                      to={{
                        pathname: "/admin/EditUserRoles",
                        state: this.state.userName,
                      }}
                      className="mg-t-10 mg-md-t-0 btn btn-gray-700 btn-block"
                    >
                      Edit
                    </Link>
                  </div>
                )}
              </div>
              {this.state.position > 600 && this.state.filterValue === "" && (
                <div style={{ textAlign: "end" }}>
                  <button className="scroll-top" onClick={this.scrollToTop}>
                    <div className="arrow up"></div>
                  </button>
                </div>
              )}
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default viewUserRole;
