import React, { Component } from "react";
import { Link } from "react-router-dom";
import ItemStatusService from "../../services/itemStatus.service";
import accessControlService from "../../services/accessControl.service";
import helper from "../../helpers/helpers";
import { Button, Modal } from "react-bootstrap";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class ViewItemStatus extends Component {
  constructor(props) {
    super(props); //reference to the parents constructor() function.

    this.handleYes = this.handleYes.bind(this);
    this.handleNo = this.handleNo.bind(this);
    this.showPopUp = this.showPopUp.bind(this);

    //Component State
    this.state = {
      ItemStatus: [
        {
          ItemStatusID: 0,
          Status: "",
          IsActive: true,
        },
      ],
      showModal: false,
      canAccessEditItemStatus: false,
      //canAccessDeleteItemStatus: false,
      loading: false,
      spinnerMessage: "",
    };

    this.initialState = this.state;
  }

  //#region component mount
  componentDidMount() {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    //this.canUserAccessPage("Edit Item Status");
    this.canUserAccessPage("Delete Item Status");
    this.fetchItemStatus();
  }
  //#endregion

  //#region Fetching selected Item Status details
  fetchItemStatus() {
    const { state } = this.props.location; // Item Status ID passed from Item Status List Page
    if (state === 0 || state === null || state === undefined) {
      this.props.history.push("/Masters/ItemStatusList");
      return;
    }

    this.setState({
      spinnerMessage: "Please wait while loading Item Status...",
      loading: true,
    });

    ItemStatusService.getItemStatus(state, helper.getUser())
      .then((response) => {
        this.setState({
          ItemStatus: response.data,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({ loading: false });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region fetching Customer page access
  canUserAccessPage(pageName) {
    accessControlService
      .CanUserAccessPage(helper.getUser(), pageName)
      .then((response) => {
        if (pageName === "Delete Item Status") {
          this.setState({
            canAccessDeleteItemStatus: response.data,
          });
        }
        // else if (pageName === "Edit Item Status") {
        //   this.setState({
        //     canAccessEditItemStatus: response.data,
        //   });
        // }
      })
      .catch((e) => {
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region modal functions
  //#region show popup
  showPopUp() {
    this.setState({ showModal: true });
  }
  //#endregion

  //#region handle Yes
  handleYes() {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    this.setState({
      spinnerMessage: "Please wait while deleting Item Status...",
      loading: true,
    });

    ItemStatusService.deleteItemStatus(
      this.state.ItemStatus.ItemStatusID,
      helper.getUser()
    )
      .then(() => {
        this.setState({ showModal: false });
        toast.success("Item Status Deleted Successfully");
        this.props.history.push({
          pathname: "/Masters/ItemStatusList",
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
        this.handleNo();
      });
  }
  //#endregion

  //#region hanle No
  handleNo() {
    this.setState({ showModal: false });
  }
  //#endregion
  //#endregion

  //#region UI
  render() {
    const { ItemStatusID, Status, IsActive } = this.state.ItemStatus;

    const canAccessDeleteItemStatus = this.state.canAccessDeleteItemStatus;
    //const canAccessEditItemStatus = this.state.canAccessEditItemStatus;

    return (
      <div className="pro-main-display">
        <LoadingOverlay
          active={this.state.loading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
          <div className="az-content-breadcrumb">
            <span>Master</span>
            <span>Item Status</span>
          </div>
          <h4>
            View Item Status{" "}
            <span className="icon-size">
              <Link to="/Masters/ItemStatusList" title="Back to List">
                <i className="far fa-arrow-alt-circle-left"></i>
              </Link>
            </span>
          </h4>
          <br />
          <div>
            <div className="row">
              <div className="col-md-8">
                <div className="row row-sm mg-b-5">
                  <div className="col-md-3">
                    <b>Item Status ID</b>
                  </div>
                  <div className="col-md-8">
                    <p>{ItemStatusID}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="row row-sm mg-b-5">
                  <div className="col-md-3">
                    <b>Status</b>
                  </div>
                  <div className="col-md-8">
                    <p>{Status}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="row row-sm">
                  <div className="col-md-3">
                    <b>Is Active?</b>
                  </div>
                  <div className="col-md-8">
                    {IsActive === false && <p>No</p>}
                    {IsActive === true && <p>Yes</p>}
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row row-sm">
              {/* <div className="col-md-0.5"></div>
            {canAccessEditItemStatus &&             <div className="col-md-2">
              <Link
                to={{
                  pathname: "/Masters/EditItemStatus",
                  state: ItemStatusID, // passing Item Status ID to Edit Item Status Page
                }}
                className="mg-t-10 mg-md-t-0 btn  btn-gray-700 btn-block"
              >
                Edit
              </Link>
            </div>} */}
              <div className="col-md-0.5"></div>
              {canAccessDeleteItemStatus && (
                <div className="col-md-2">
                  <button
                    className="mg-t-10 mg-md-t-0 btn  btn-gray-700 btn-block"
                    onClick={this.showPopUp}
                  >
                    Delete
                  </button>
                </div>
              )}
            </div>
            <Modal
              show={this.state.showModal}
              aria-labelledby="contained-modal-title-vcenter"
              onHide={this.handleNo}
              backdrop="static"
              enforceFocus={false}
            >
              <Modal.Header>
                <Modal.Title>Delete Item Status</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <p>Are you sure to delete this Item Status?</p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={this.handleYes}>
                  Yes
                </Button>
                <Button variant="primary" onClick={this.handleNo}>
                  No
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
  //#endregion
}

export default ViewItemStatus;
