import React, { Component } from "react";
import { connect } from "react-redux";
import { readOnGoingProjectsList } from "../../actions/projects";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import accessControlService from "../../services/accessControl.service";
import helper from "../../helpers/helpers";
import tableFunctions from "../../helpers/tableFunctions";
import projectService from "../../services/project.service";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import Moment from "moment";
import { Modal } from "react-bootstrap";
import ModernDatepicker from "react-modern-datepicker";
import dayjs from 'dayjs';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class OnGoingProjectList extends Component {
  constructor(props) {
    super(props);

    this.divScrollRef = React.createRef();

    this.exportProjectListToExcel = this.exportProjectListToExcel.bind(this);
    this.showChangeProjectStatusModal =
      this.showChangeProjectStatusModal.bind(this);
    this.onChangeProjectStatus = this.onChangeProjectStatus.bind(this);
    this.onChangeHoldOnReason = this.onChangeHoldOnReason.bind(this);
    this.onChangeDeliveredCount = this.onChangeDeliveredCount.bind(this);
    this.onChangeSortOrder = this.onChangeSortOrder.bind(this);
    this.onChangeColumn = this.onChangeColumn.bind(this);
    this.sortData = this.sortData.bind(this);
    this.displaySortingFields = this.displaySortingFields.bind(this);
    this.displayFilteringField = this.displayFilteringField.bind(this);
    this.onChangefilterValue = this.onChangefilterValue.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.clearSearchField = this.clearSearchField.bind(this);
    this.clearSortFields = this.clearSortFields.bind(this);

    this.state = {
      canAccessViewProject: false,
      canAccessProjectBatchList: false,
      loading: false,
      spinnerMessage: "",
      modalLoading: false,
      canUserChangeProjectStatus: false,
      showChangeProjectStatusModal: false,
      customerCode: "",
      projectCode: "",
      changeStatusTo: [],
      selectedChangeStatusTo: "",
      deliveredDate: "",
      deliveredCount: 0,
      holdOnDate: "",
      holdOnReason: "",
      formErrors: {},
      activeResources: [],
      activeTasks: [],
      projectsDetailsExpanded: [],
      expandedProjectID: "",
      activeTaskExpanded: [],
      index: 30,
      position: 0,
      columns: [],
      selectedColumn: "",
      selectedSort: "",
      isToShowSortingFields: false,
      isToShowFilteringField: true,
      filteredArray: [],
      filterValue: "",
      onGoingProjects: [],
      today: null,
    };
  }

  //#region component mount
  async componentDidMount() {
    this.setState({
      loading: true,
      spinnerMessage: "Please wait while loading On Going Projects List...",
    });

    this.canUserAccessPage("View Project");
    this.canUserAccessPage("Project Batch List");

    if (sessionStorage.getItem("sortField") === null) {
      sessionStorage.setItem("sortField", "ReceivedDate");
      sessionStorage.setItem("sortOrder", "desc");
    }

    var data = {
      CustomerCode: this.props.customerCode,
      ProjectCode: this.props.projectCode,
      ProjectType: this.props.projectType,
      FromDate: this.props.fromDate,
      ToDate: this.props.toDate,
      UserID: helper.getUser(),
    };

    await this.props.readOnGoingProjectsList(data);

    if (this.props.projects.length > 0) {
      let formattedArray = this.props.projects.map((obj) => ({
        ...obj,
        ReceivedDate: Moment(obj.ReceivedDate).format("DD-MMM-yyyy"),
        PlannedDeliveryDate: obj.PlannedDeliveryDate
          ? Moment(obj.PlannedDeliveryDate).format("DD-MMM-yyyy")
          : "",
        HoldOnDate: obj.HoldOnDate
          ? Moment(obj.HoldOnDate).format("DD-MMM-yyyy")
          : "",
      }));

      var today = new Date();
      today.setDate(today.getDate());

      this.setState(
        {
          canUserChangeProjectStatus:
            this.props.projects[0].canUserChangeProjectStatus,
          onGoingProjects: formattedArray,
          today: Moment(today).format("DD-MMM-yyyy"),
        },
        () => this.sortData("D")
      );
    }

    this.setState({
      loading: false,
    });
  }
  //#endregion

  //#region fetching Project page access
  canUserAccessPage(pageName) {
    accessControlService
      .CanUserAccessPage(helper.getUser(), pageName)
      .then((response) => {
        if (pageName === "View Project") {
          this.setState({
            canAccessViewProject: response.data,
          });
        } else if (pageName === "Project Batch List") {
          this.setState({
            canAccessProjectBatchList: response.data,
          });
        }
      })
      .catch((e) => {
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Sort Project List Page
  sortProjectList(field, order) {
    sessionStorage.setItem("sortField", field);
    sessionStorage.setItem("sortOrder", order);
  }
  //#endregion

  //#region Export Project List to Excel
  exportProjectListToExcel() {
    this.setState({
      spinnerMessage: "Please wait while exporting project list to excel...",
      loading: true,
    });

    let fileName = "On-Going Projects List.xlsx";

    var data = {
      CustomerCode: this.props.customerCode,
      ProjectCode: this.props.projectCode,
      ProjectType: this.props.projectType,
      FromDate: this.props.fromDate,
      ToDate: this.props.toDate,
      UserID: helper.getUser(),
    };

    projectService
      .exportOnGoingProjectListToExcel(data)
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);
        fileLink.click();

        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region show Modal to Change Project Status
  showChangeProjectStatusModal(customerCode, projectCode) {
    this.setState(
      {
        customerCode: customerCode,
        projectCode: projectCode,
        showChangeProjectStatusModal: true,
        selectedChangeStatusTo: "",
        deliveredDate: "",
        deliveredCount: 0,
        holdOnDate: "",
        holdOnReason: "",
        formErrors: {},
      },
      () => this.fetchProjectStatusChangeToList()
    );
  }
  //#endregion

  //#region Fetch Project Status Change To List
  fetchProjectStatusChangeToList() {
    this.setState({
      spinnerMessage:
        "Please wait while fetching Project Change Status to List...",
      modalLoading: true,
    });

    projectService
      .fetchChangeProjectStatusToList(
        this.state.customerCode,
        this.state.projectCode
      )
      .then((response) => {
        this.setState({
          changeStatusTo: response.data,
          modalLoading: false,
        });
      })
      .catch((e) => {
        this.setState({
          modalLoading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Get Selected Project Status
  onChangeProjectStatus(e) {
    this.setState({
      selectedChangeStatusTo: e.target.value,
    });

    if (e.target.value === "Delivered") {
      this.setState({
        holdOnDate: null,
        holdOnReason: "",
      });
    } else if (e.target.value === "On Hold") {
      this.setState({
        deliveredDate: null,
        deliveredCount: 0,
      });
    } else if (e.target.value === "In Process") {
      this.setState({
        holdOnDate: null,
        holdOnReason: null,
        deliveredDate: null,
        deliveredCount: 0,
      });
    }

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = {
        ...this.state.formErrors,
        changeStatusToError: "",
      };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Get Selected Delivered Date
  onChangeDeliveredDate = (date) => {
    const formattedDate = dayjs(date, 'DD-MM-YYYY', true);
        if (!formattedDate.isValid()) {
            alert("Please enter the date in DD-MM-YYYY format.");
        }
    this.setState({
      deliveredDate: date,
    });

    if (date !== "" && date !== null) {
      const formErrors = { ...this.state.formErrors, deliveredDateError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Get Entered Delivered Count
  onChangeDeliveredCount(e) {
    this.setState({
      deliveredCount: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = { ...this.state.formErrors, deliveredCountError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Get Selected Delivered Date
  onChangeHoldOnDate(date) {
    this.setState({
      holdOnDate: date,
    });

    if (date !== "" && date !== null) {
      const formErrors = { ...this.state.formErrors, holdOnDateError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Get Entered Delivered Count
  onChangeHoldOnReason(e) {
    this.setState({
      holdOnReason: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = { ...this.state.formErrors, holdOnReasonError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region handle Change Project Status Form Validation
  handleChangeProjectStatusFormValidation() {
    const changeStatusTo = this.state.selectedChangeStatusTo;
    const holdOnDate = this.state.holdOnDate;
    const holdOnReason = this.state.holdOnReason;
    const deliveredCount = this.state.deliveredCount;
    const deliveredDate = this.state.deliveredDate;
    let formErrors = {};
    let isValidForm = true;

    //Change Status to
    if (!changeStatusTo) {
      isValidForm = false;
      formErrors["changeStatusToError"] = "Change Status To is required";
    }

    if (changeStatusTo === "On Hold") {
      //Hold on Date
      if (!holdOnDate) {
        isValidForm = false;
        formErrors["holdOnDateError"] = "Hold on Date is required";
      } else if (new Date(holdOnDate) > new Date()) {
        isValidForm = false;
        formErrors["holdOnDateError"] = "Hold on Date can't be a future date";
      }

      //Hold on Reason
      if (!holdOnReason) {
        isValidForm = false;
        formErrors["holdOnReasonError"] = "Reason to Hold on is required";
      }
    }

    if (changeStatusTo === "Delivered") {
      //Delivered Count
      if (!deliveredCount) {
        isValidForm = false;
        formErrors["deliveredCountError"] = "Delivered Count is required";
      }

      //Delivered Date
      if (!deliveredDate) {
        isValidForm = false;
        formErrors["deliveredDateError"] = "Delivered Date is required";
      } else if (new Date(deliveredDate) > new Date()) {
        isValidForm = false;
        formErrors["deliveredDateError"] =
          "Delivered Date can't be a future date";
      }
    }

    this.setState({ formErrors: formErrors });
    return isValidForm;
  }
  //#endregion

  //#region Change Project Status
  changeProjectStatus = (e) => {
    e.preventDefault();

    if (this.handleChangeProjectStatusFormValidation()) {
      this.setState({
        spinnerMessage: "Please wait while changing the Project Status...",
        modalLoading: true,
      });

      var projectStatusChangedData = {
        CustomerCode: this.state.customerCode,
        ProjectCode: this.state.projectCode,
        ChangeStatusTo: this.state.selectedChangeStatusTo,
        DeliveredDate: this.state.deliveredDate,
        DeliveredCount: this.state.deliveredCount,
        OnHoldReason: this.state.holdOnReason,
        OnHoldDate: this.state.holdOnDate,
        UserID: helper.getUser(),
      };

      //Service call
      projectService
        .ChangeProjectStatus(projectStatusChangedData)
        .then(() => {
          toast.success("Project Status Changed Successfully");

          this.props.readOnGoingProjectsList(helper.getUser());

          this.setState({
            modalLoading: false,
            showChangeProjectStatusModal: false,
          });
        })
        .catch((error) => {
          this.setState({
            modalLoading: false,
          });
          toast.error(error.response.data.Message, { autoClose: false });
        });
    }
  };
  //#endregion

  //#region Fetch Activities of Selected Customer Code and Project Code
  fetchProjectsActivities = (row, isExpand, rowIndex, e) => {
    if (!isExpand) {
      this.setState(() => ({
        projectsDetailsExpanded: [],
        loading: false,
      }));
      return;
    }

    this.setState({
      spinnerMessage: "Please wait while fetching Project Task Details...",
      loading: true,
    });

    projectService
      .fetchProjectActivityDetils(row.ProjectID)
      .then((response) => {
        this.setState({
          activeTaskExpanded: [],
          activeTasks: response.data,
          projectsDetailsExpanded: [row.ProjectID],
          expandedProjectID: row.ProjectID,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  };
  //#endregion

  //#region Fetch Resources of Selected Customer Code, Project Code and Task
  fetchResources = (row, isExpand, rowIndex, e) => {
    if (!isExpand) {
      this.setState(() => ({
        activeTaskExpanded: [],
        loading: false,
      }));
      return;
    }

    this.setState({
      spinnerMessage:
        "Please wait while fetching Resources of selected Task...",
      loading: true,
    });

    projectService
      .readProjectActivityResourcesWithHoursWorked(
        this.state.expandedProjectID,
        row.ProjectActivityID
      )
      .then((response) => {
        this.setState({
          activeResources: response.data,
          activeTaskExpanded: [row.SlNo],
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  };
  //#endregion

  //#region Scroll to Top
  scrollToTop = () => {
    this.divScrollRef.current.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  //#endregion

  //#region Sort Functions
  //#region Display Sorting Fields
  displaySortingFields() {
    let columns = Object.keys(this.state.onGoingProjects[0]);

    let excludedColumns = [
      "ProjectID",
      "LocationCode",
      "TypeOfInput",
      "InputCountType",
      "ReceivedFormat",
      "OutputFormat",
      "PlannedStartDate",
      "DeliveryMode",
      "DeliveryPlanFileName",
      "IsResourceBased",
      "Remarks",
      "CustomerInputFileName",
      "ScopeFileName",
      "Guideline",
      "GuidelineFileName",
      "Checklist",
      "ChecklistFileName",
      "EmailDate",
      "EmailDescription",
      "canUserChangeProjectStatus",
      "DeliveredOn",
      "DeliveredCount",
      "Activities",
      "UserID",
    ];

    columns = columns.filter((item) => !excludedColumns.includes(item));

    this.setState((previousState) => ({
      isToShowSortingFields: !previousState.isToShowSortingFields,
      selectedColumn: "",
      selectedSort: "",
      columns: columns,
      filterValue: "",
      isToShowFilteringField: false,
    }));
  }
  //#endregion

  //#region Selecting the sort column
  onChangeColumn(e) {
    this.setState({
      selectedColumn: e.target.value,
      selectedSort: "",
    });
  }
  //#endregion

  //#region On Change Sort
  onChangeSortOrder(e) {
    this.setState(
      {
        selectedSort: e.target.value,
      },
      () => this.sortData("M")
    );
  }
  //#endregion

  //#region Sort Data based on column and order
  sortData(type) {
    let sortedArray = [];
    let column;
    let selectedSort;
    if (type === "D") {
      column = sessionStorage.getItem("sortField");
      selectedSort = sessionStorage.getItem("sortOrder");
    } else if ((type = "M")) {
      column =
        this.state.selectedColumn !== "" ? this.state.selectedColumn : "SlNo";
      selectedSort =
        this.state.selectedSort !== "" ? this.state.selectedSort : "ascending";
    }
    let numberColumns = [
      "SlNo",
      "InputCount",
      "Batches",
      "NoOfActivities",
      "ProductionAllocatedCount",
      "ProductionCompletedCount",
      "ProductionPendingCount",
      "QCAllocatedCount",
      "QCCompletedCount",
      "QCPendingCount",
      "DeliveredCount",
      "NoOfResources",
      "",
    ];
    let dateColumns = ["ReceivedDate", "PlannedDeliveryDate", "HoldOnDate"];

    sortedArray = tableFunctions.sortData(
      this.state.onGoingProjects,
      column,
      selectedSort,
      numberColumns,
      dateColumns
    );

    this.setState({ onGoingProjects: sortedArray });
    this.sortProjectList(column, selectedSort);
  }
  //#endregion

  //#region  Clear Sort
  clearSortFields() {
    this.setState(
      {
        selectedColumn: "",
        selectedSort: "",
      },
      () => this.sortData("M")
    );
  }
  //#endregion
  //#endregion

  //#region Filter Functions
  //#region Display Filtering Field
  displayFilteringField() {
    this.setState((previousState) => ({
      isToShowFilteringField: !previousState.isToShowFilteringField,
      filterValue: "",
      isToShowSortingFields: false,
    }));
  }
  //#endregion

  //#region on change filter value
  onChangefilterValue(e) {
    this.setState({ filterValue: e.target.value }, () =>
      this.getFilteredRows()
    );
  }
  //#endregion

  //#region get filtered rows
  getFilteredRows() {
    const filteredArray = tableFunctions.filterArray(
      this.state.onGoingProjects,
      this.state.filterValue
    );

    this.setState({ filteredArray: filteredArray });
  }
  //#endregion

  //#region Clear Search
  clearSearchField() {
    this.setState({
      filterValue: "",
    });
  }
  //#endregion
  //#endregion

  //#region Handle Scroll
  handleScroll(e) {
    var currentHeight = e.currentTarget.scrollTop;
    var maxScrollPosition =
      e.currentTarget.scrollHeight - e.currentTarget.clientHeight;

    this.setState({ position: currentHeight });

    if ((currentHeight / maxScrollPosition) * 100 > 90) {
      let curIndex = this.state.index + 20;
      this.setState({ index: curIndex });
    }
  }
  //#endregion

  //#region Flag Header
  flagHeader(column, colIndex) {
    return <i className="far fa-flag"></i>;
  }
  //#endregion

  // region Navigate to project settings page
  showProjectSetingPage = (row) => {
    let locationData =  {
      CustomerCode: row.CustomerCode,
      ProjectCode: row.ProjectCode,
      activeTab: 1,
    }

    sessionStorage.setItem("locationData", JSON.stringify(locationData));
    if(this.state.canAccessProjectBatchList){
      this.props.history.push({
        pathname: "/Allocation/viewProjectSettings",
      });
    }
  }
  // #endregion

  render() {
    const canAccessViewProject = this.state.canAccessViewProject;
    const canAccessProjectBatchList = this.state.canAccessProjectBatchList;

    const data = this.state.onGoingProjects.slice(0, this.state.index);
    data.forEach(items => {
      items.viewAction = "View Project Settings"
    })
    const filteredData = this.state.filteredArray.slice(0, this.state.index);

    const projectListColumns = [
      {
        dataField: "SlNo",
        text: "Sl No",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "ProjectID",
        text: "Project ID",
        align: "center",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        hidden: true,
      },
      {
        dataField: "CustomerCode",
        text: "Cus Code",
        align: "center",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
      },
      {
        dataField: "ProjectCode",
        text: "Proj Code",
        align: "center",
        classes: canAccessViewProject ? "demo-key-row1" : "",
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            canAccessViewProject &&
              this.props.history.push({
                pathname: "/Projects/ViewProject",
                state: {
                  ProjectID: row.ProjectID,
                  activeTab: 1,
                }, // passing Project ID to View Project Page
              });
          },
        },
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
      },
      {
        dataField: "viewAction",
        text: "View Project Settings",
        align: "center",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        classes: "demo-key-row1",
        formatter: (cell, row, rowIndex, extraData) => (
          <div>
            {row.IsProjectSettingsExist && (
              <img
                src="../../../Icons/view-icon.png"
                title="View Project Settings"
                onClick={() => this.showProjectSetingPage(row)}
                alt=""
                className="view-icon"
              />
            )}
          </div>
        ),
        headerAlign: "center",
      },
      {
        dataField: "NoOfBatches",
        text: "Batches",
        align: "center",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        classes: canAccessProjectBatchList ? "demo-key-row1" : "",
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            canAccessProjectBatchList &&
              this.props.history.push({
                pathname: "/Projects/ProjectBatchList",
                state: {
                  ProjectID: row.ProjectID,
                  CustomerCode: row.CustomerCode,
                  ProjectCode: row.ProjectCode,
                  Scope: row.Scope,
                  activeTab: 1,
                }, // passing Project details to Project Batch List Page
              });
          },
        },
      },
      {
        dataField: "NoOfActivities",
        text: "Activities",
        align: "center",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
      },
      {
        dataField: "Scope",
        text: "Description",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        title: true,
        style: {
          width: "250px",
          minWidth: "250px",
        },
        formatter: (cell, row, rowIndex, extraData) => (
          <div>
            {row.Scope}{" "}
            <i
              className="fas fa-sort-down pointer mg-l-5"
              title="Click to View Tasks of selected Project"
            ></i>
          </div>
        ),
      },
      {
        dataField: "ReceivedDate",
        text: "Received On",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "PlannedDeliveryDate",
        text: "Planned Del Date",
        align: "center",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
      },
      {
        dataField: "ProjectType",
        text: "Project Type",
        align: "center",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
      },
      {
        dataField: "InputCount",
        text: "Input Count",
        align: "center",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
      },
      {
        dataField: "ProductionAllocatedCount",
        text: "Prod. Allocated",
        align: "center",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
      },
      {
        dataField: "ProductionCompletedCount",
        text: "Prod. Completed",
        align: "center",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
      },
      {
        dataField: "ProductionPendingCount",
        text: "Prod. Pending",
        align: "center",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
      },
      {
        dataField: "QCAllocatedCount",
        text: "QC Allocated",
        align: "center",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
      },
      {
        dataField: "QCCompletedCount",
        text: "QC Completed",
        align: "center",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
      },
      {
        dataField: "QCPendingCount",
        text: "QC Pending",
        align: "center",
        style: {
          width: "6%",
        },
      },
      {
        dataField: "DeliveredCount",
        text: "Delivered",
        align: "center",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
      },
      {
        dataField: "Status",
        text: "Status",
        align: "center",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        style: {
          width: "150px",
          minWidth: "150px",
        },
        headerAlign: "center",
      },
      {
        dataField: "flag",
        text: "",
        align: "center",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        headerFormatter: this.flagHeader,
        formatter: (cell, row, rowIndex, extraData) => (
          <div>
            {row.Status === "In Process" &&
              new Date(row.PlannedDeliveryDate) <
                new Date(this.state.today) && (
                <i
                  className="fas fa-flag"
                  style={{ color: "red" }}
                  title="Project Delivery Delayed"
                ></i>
              )}
          </div>
        ),
      },
      {
        dataField: "NoOfResources",
        text: "Resources",
        align: "center",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
      },
      {
        dataField: "ChangeProjectStatus",
        text: "Change Proj Status",
        align: "center",
        hidden: this.state.canUserChangeProjectStatus ? false : true,
        formatter: (cell, row, rowIndex, extraData) => (
          <div>
            <i
              className="fas fa-chevron-circle-right pointer"
              title="Change Project Status"
              onClick={() =>
                this.showChangeProjectStatusModal(
                  row.CustomerCode,
                  row.ProjectCode
                )
              }
            ></i>
          </div>
        ),
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
      },
      {
        dataField: "HoldOnDate",
        text: "Hold On Date",
        align: "center",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
      },
      {
        dataField: "HoldOnReason",
        text: "Hold On Reason",
        align: "center",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
      },
    ];

    const activeResourcesColumns = [
      {
        dataField: "SlNo",
        text: "Sl No.",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          fontWeight: 800,
        },
        headerAlign: "center",
        align: "center",
        sort: true,
      },
      {
        dataField: "UserID",
        text: "Resource Code",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          fontWeight: 800,
        },
        headerAlign: "center",
        align: "center",
        sort: true,
      },
      {
        dataField: "Username",
        text: "Resource Name",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          fontWeight: 800,
        },
        sort: true,
      },
      {
        dataField: "StartDate",
        text: "Start Date",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          fontWeight: 800,
        },
        sort: true,
        headerAlign: "center",
        align: "center",
        formatter: (cell, row, rowIndex, extraData) =>
          `${Moment(row.StartDate).format("DD-MMM-yyyy")}`,
      },
      {
        dataField: "EndDate",
        text: "End Date",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          fontWeight: 800,
        },
        headerAlign: "center",
        align: "center",
        sort: true,
        formatter: (cell, row, rowIndex, extraData) =>
          `${Moment(row.EndDate).format("DD-MMM-yyyy")}`,
      },
      {
        dataField: "ProductionCompletedCount",
        text: "Prod. Completed Count",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          fontWeight: 800,
        },
        headerAlign: "center",
        align: "center",
        sort: true,
      },
      {
        dataField: "QCCompletedCount",
        text: "QC Completed Count",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          fontWeight: 800,
        },
        headerAlign: "center",
        align: "center",
        sort: true,
      },
      {
        dataField: "HoursWorked",
        text: "No. of Hours Worked",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          fontWeight: 800,
        },
        headerAlign: "center",
        align: "center",
        sort: true,
      },
    ];

    const expandTaskRow = {
      onlyOneExpanding: true,
      parentClassName: "rowBackgroundColor",
      onExpand: this.fetchResources,
      expanded: this.state.activeTaskExpanded,
      showExpandColumn: true,
      expandHeaderColumnRenderer: (isAnyExpands) => (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        ></div>
      ),
      renderer: (row) => (
        <>
          <p>
            <b>Resources / Project Team</b>
          </p>
          <div>
            <BootstrapTable
              keyField="SlNo"
              data={this.state.activeResources}
              columns={activeResourcesColumns}
            />
          </div>
        </>
      ),
    };

    const expandActiveProjectsRow = {
      onlyOneExpanding: true,
      parentClassName: "rowBackgroundColor",
      onExpand: this.fetchProjectsActivities,
      expanded: this.state.projectsDetailsExpanded,
      expandHeaderColumnRenderer: (isAnyExpands) => (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        ></div>
      ),
      renderer: (row) => (
        <>
          <p>
            <b>Tasks</b>
          </p>
          <div className="table-responsive task-table-width">
            <BootstrapTable
              keyField="SlNo"
              data={this.state.activeTasks}
              columns={activeTasksColumns}
              expandRow={expandTaskRow}
              noDataIndication="Project has not started"
            />
          </div>
        </>
      ),
    };

    const activeTasksColumns = [
      {
        dataField: "SlNo",
        text: "Sl No.",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          fontWeight: 800,
        },
        headerAlign: "center",
        align: "center",
        sort: true,
      },
      {
        dataField: "ProjectActivityID",
        text: "Project Activity ID",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          fontWeight: 800,
        },
        headerAlign: "center",
        align: "center",
        sort: true,
        hidden: true,
      },
      {
        dataField: "ProjectActivity",
        text: "Activity / Task",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          fontWeight: 800,
        },
        sort: true,
      },
      {
        dataField: "StartDate",
        text: "Start Date",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          fontWeight: 800,
        },
        sort: true,
        headerAlign: "center",
        align: "center",
        formatter: (cell, row, rowIndex, extraData) =>
          `${Moment(row.StartDate).format("DD-MMM-yyyy")}`,
      },
      {
        dataField: "EndDate",
        text: "End Date",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          fontWeight: 800,
        },
        headerAlign: "center",
        align: "center",
        sort: true,
        formatter: (cell, row, rowIndex, extraData) =>
          `${Moment(row.EndDate).format("DD-MMM-yyyy")}`,
      },
      {
        dataField: "ProductionCompletedCount",
        text: "Prod. Completed Count",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          fontWeight: 800,
        },
        headerAlign: "center",
        align: "center",
        sort: true,
      },
      {
        dataField: "QCCompletedCount",
        text: "QC Completed Count",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          fontWeight: 800,
        },
        headerAlign: "center",
        align: "center",
        sort: true,
      },
      {
        dataField: "HoursWorked",
        text: "No. of Hours Worked",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          fontWeight: 800,
        },
        headerAlign: "center",
        align: "center",
        sort: true,
      },
    ];

    return (
      <>
        {this.state.onGoingProjects.length > 0 ? (
          <LoadingOverlay
          active={this.state.loading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
            <div className="az-content-body pd-lg-l-40 d-flex flex-column">
              <ToolkitProvider
                keyField="ProjectID"
                data={this.state.filterValue === "" ? data : filteredData}
                columns={projectListColumns}
              >
                {(props) => (
                  <div>
                    <div
                      className="row mg-b-10"
                      style={{ marginRight: "15px" }}
                    >
                      <div
                        className="col-md-10 mg-t-5"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <div className="row">
                          {this.state.isToShowSortingFields && (
                            <>
                              <div className="col-md-4">
                                <div className="row">
                                  <div className="col-md-3 mg-t-7">
                                    <label htmlFor="sortColumn">Column:</label>
                                  </div>
                                  <div className="col-lg">
                                    <select
                                      className="form-control mg-l-5"
                                      value={this.state.selectedColumn}
                                      onChange={this.onChangeColumn}
                                    >
                                      <option value="">--Select--</option>
                                      {this.state.columns.map((col) => (
                                        <option key={col}>{col}</option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="row">
                                  <div className="col-md-3 mg-t-7">
                                    <label htmlFor="sortOrder">Order:</label>
                                  </div>
                                  <div className="col-lg">
                                    <select
                                      className="form-control mg-l-5"
                                      value={this.state.selectedSort}
                                      onChange={this.onChangeSortOrder}
                                    >
                                      <option value="">--Select--</option>
                                      <option value="ascending">
                                        Ascending
                                      </option>
                                      <option value="descending">
                                        Descending
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div>
                                  <span
                                    className="btn btn-primary pd-b-5"
                                    onClick={this.clearSortFields}
                                    title="Clear Sort Fields"
                                  >
                                    <i className="far fa-window-close"></i>
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                          {this.state.isToShowFilteringField && (
                            <>
                              <div className="col-md-12">
                                <div
                                  className="row"
                                  style={{ flexWrap: "nowrap" }}
                                >
                                  <div className="col-md-1 mg-t-7">
                                    <label htmlFor="search">Search:</label>
                                  </div>
                                  <div className="col-lg pd-r-10">
                                    <input
                                      type="text"
                                      className="form-control mg-l-5"
                                      maxLength="20"
                                      value={this.state.filterValue}
                                      onChange={this.onChangefilterValue}
                                    />
                                  </div>
                                  <div>
                                    <span
                                      className="btn btn-primary pd-b-5"
                                      onClick={this.clearSearchField}
                                    >
                                      <i
                                        className="far fa-window-close"
                                        title="Clear Filter"
                                      ></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      {this.state.onGoingProjects.length > 0 && (
                        <div
                          className="col-md-2"
                          style={{ textAlign: "end", marginTop: "10px" }}
                        >
                          <i
                            className="fas fa-exchange-alt fa-rotate-90 pointer"
                            title={
                              this.state.isToShowSortingFields
                                ? "Hide Sort"
                                : "Show Sort"
                            }
                            onClick={this.displaySortingFields}
                          ></i>
                          {!this.state.isToShowFilteringField ? (
                            <i
                              className="fas fa-filter pointer mg-l-10"
                              onClick={this.displayFilteringField}
                              title="Show Filter"
                            ></i>
                          ) : (
                            <i
                              className="fas fa-funnel-dollar pointer mg-l-10"
                              onClick={this.displayFilteringField}
                              title="Hide Filter"
                            ></i>
                          )}
                          <i
                            className="fas fa-file-excel mg-l-10 pointer"
                            style={{ color: "green" }}
                            onClick={this.exportProjectListToExcel}
                            title="Export to Excel"
                          ></i>
                        </div>
                      )}
                    </div>
                    <div
                      className="borderTable"
                      style={{ overflowX: "auto", width: "98%" }}
                    >
                      <div
                        style={{
                          overflowY: "scroll",
                          borderBottom: "1px solid #cdd4e0",
                        }}
                        ref={this.divScrollRef}
                        className="projects-table-height"
                        onScroll={this.handleScroll}
                      >
                        <BootstrapTable
                          bootstrap4
                          {...props.baseProps}
                          expandRow={expandActiveProjectsRow}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </ToolkitProvider>
              {this.state.position > 600 && this.state.filterValue === "" && (
                <div style={{ textAlign: "end" }}>
                  <button className="scroll-top" onClick={this.scrollToTop}>
                    <div className="arrow up"></div>
                  </button>
                </div>
              )}
            </div>
          </LoadingOverlay>
        ) : (
          <LoadingOverlay
          active={this.state.loading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
            <p style={{ height: "350px" }}></p>
          </LoadingOverlay>
        )}
        <Modal
          show={this.state.showChangeProjectStatusModal}
          dialogClassName="modal-width-produpload"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
        >
          <LoadingOverlay
          active={this.state.modalLoading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
            <Modal.Header>
              <Modal.Title>Project Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={this.changeProjectStatus}>
                <div className="row row-sm">
                  <div className="col-md-4 text-nowrap">
                    <label htmlFor="CustomerCode">
                      <b>Customer Code</b>{" "}
                      <span className="text-danger asterisk-size">*</span>
                    </label>
                  </div>
                  <div className="col-md-6 mg-t-7">
                    <p id="CustomerCode" name="CustomerCode">
                      {this.state.customerCode}
                    </p>
                  </div>
                </div>
                <div className="row row-sm">
                  <div className="col-md-4 text-nowrap">
                    <label htmlFor="ProjectCode">
                      <b>Project Code</b>{" "}
                      <span className="text-danger asterisk-size">*</span>
                    </label>
                  </div>
                  <div className="col-md-6 mg-t-7">
                    <p id="ProjectCode" name="ProjectCode">
                      {this.state.projectCode}
                    </p>
                  </div>
                </div>
                <div className="row row-sm">
                  <div className="col-md-4 text-nowrap">
                    <label htmlFor="ChangeStatusTo">
                      <b>Change Status To</b>{" "}
                      <span className="text-danger asterisk-size">*</span>
                    </label>
                  </div>
                  <div className="col-md-6 mg-t-7">
                    <select
                      className="form-control"
                      id="ChangeStatusTo"
                      name="ChangeStatusTo"
                      value={this.state.selectedChangeStatusTo}
                      onChange={this.onChangeProjectStatus}
                      placeholder="--Select--"
                    >
                      <option value="">--Select--</option>
                      {this.state.changeStatusTo.map((status) => (
                        <option key={status}>{status}</option>
                      ))}
                    </select>
                    <div className="error-message">
                      {this.state.formErrors["changeStatusToError"]}
                    </div>
                  </div>
                </div>
                {this.state.selectedChangeStatusTo === "Delivered" && (
                  <>
                    <div className="row row-sm">
                      <div className="col-md-4 text-nowrap">
                        <label htmlFor="DeliveredDate">
                          <b>Delivered Date</b>{" "}
                          <span className="text-danger asterisk-size">*</span>
                        </label>
                      </div>
                      <div className="col-md-6 mg-t-7">
                        <div className="row mg-l-0">
                          <div className="form-control delivered-date-field-width">
                            <ModernDatepicker
                              date={this.state.deliveredDate}
                              format={"DD-MMM-YYYY"}
                              onChange={this.onChangeDeliveredDate}
                              allowEdit
                              placeholder={"Enter/Select Delivered Date in dd-mon-yyyy format"}
                              className="color"
                              minDate={new Date(1900, 1, 1)}
                            />
                          </div>
                          <span
                            className="btn btn-secondary"
                            onClick={() => this.setState({ deliveredDate: "" })}
                          >
                            <i
                              className="far fa-window-close"
                              title="Clear Delivered Date"
                            ></i>
                          </span>
                          <div className="error-message">
                            {this.state.formErrors["deliveredDateError"]}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row row-sm">
                      <div className="col-md-4 text-nowrap">
                        <label htmlFor="DeliveredCount">
                          <b>Delivered Count</b>{" "}
                          <span className="text-danger asterisk-size">*</span>
                        </label>
                      </div>
                      <div className="col-md-6 mg-t-7">
                        <input
                          type="number"
                          className="form-control"
                          id="DeliveredCount"
                          name="DeliveredCount"
                          value={this.state.deliveredCount}
                          onChange={this.onChangeDeliveredCount}
                          max="9999999"
                          min="1"
                        />
                        <div className="error-message">
                          {this.state.formErrors["deliveredCountError"]}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {this.state.selectedChangeStatusTo === "On Hold" && (
                  <>
                    <div className="row row-sm">
                      <div className="col-md-4 text-nowrap">
                        <label htmlFor="HoldOnDate">
                          <b>Hold On Date</b>{" "}
                          <span className="text-danger asterisk-size">*</span>
                        </label>
                      </div>
                      <div className="col-md-6 mg-t-7">
                        <div className="row mg-l-0">
                          <div className="form-control delivered-date-field-width">
                            <ModernDatepicker
                              date={this.state.holdOnDate}
                              format={"DD-MMM-YYYY"}
                              onChange={(date) => this.onChangeHoldOnDate(date)}
                              placeholder={"Select Hold On Date"}
                              className="color"
                              minDate={new Date(1900, 1, 1)}
                            />
                          </div>
                          <span
                            className="btn btn-secondary"
                            onClick={() => this.setState({ holdOnDate: "" })}
                          >
                            <i
                              className="far fa-window-close"
                              title="Clear Hold On Date"
                            ></i>
                          </span>
                          <div className="error-message">
                            {this.state.formErrors["holdOnDateError"]}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row row-sm">
                      <div className="col-md-4 text-nowrap">
                        <label htmlFor="ReasonToHoldOn">
                          <b>Reason to Hold On</b>{" "}
                          <span className="text-danger asterisk-size">*</span>
                        </label>
                      </div>
                      <div className="col-md-6 mg-t-7">
                        <textarea
                          className="form-control"
                          rows="2"
                          maxLength="500"
                          value={this.state.holdOnReason}
                          onChange={this.onChangeHoldOnReason}
                        ></textarea>
                        <div className="error-message">
                          {this.state.formErrors["holdOnReasonError"]}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="row row-sm mg-t-30">
                  <div className="col-md-2 mg-l-25"></div>
                  <div className="col-md-3">
                    <input
                      type="submit"
                      id="Update"
                      className="btn btn-gray-700 btn-block"
                      value="Update"
                    />
                  </div>
                  <div className="col-md-1"></div>
                  <div className="col-md-3 mg-t-10 mg-lg-t-0">
                    <span
                      className="btn btn-gray-700 btn-block"
                      onClick={() =>
                        this.setState({ showChangeProjectStatusModal: false })
                      }
                    >
                      Close
                    </span>
                  </div>
                </div>
              </form>
            </Modal.Body>
          </LoadingOverlay>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    projects: state.projects,
  };
};

export default connect(mapStateToProps, { readOnGoingProjectsList })(
  OnGoingProjectList
);
