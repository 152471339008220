import React, { Component } from "react";
import userService from "../../services/user.service";
import helper from "../../helpers/helpers";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import projectService from "../../services/project.service";
toast.configure();

class CreateUser extends Component {
  constructor(props) {
    super(props); //reference to the parents constructor() function.

    this.onChangeFirstName = this.onChangeFirstName.bind(this);
    this.onChangeMiddleName = this.onChangeMiddleName.bind(this);
    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onChangeUserName = this.onChangeUserName.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeReTypePassword = this.onChangeReTypePassword.bind(this);
    this.onChangeEmailID = this.onChangeEmailID.bind(this);
    this.onChangeDepartment = this.onChangeDepartment.bind(this);
    this.onChangeManager = this.onChangeManager.bind(this);
    this.reset = this.reset.bind(this);

    //Component State
    this.state = {
      userID: 0,
      firstName: "",
      middleName: "",
      lastName: "",
      userName: "",
      password: "",
      reTypePassword: "",
      emailID: "",
      formErrors: {},
      departments: [],
      users: [],
      messageForProfileFile: false,
      profileFileName: false,
      profileFileKey: Date.now(),
      profileFileUploadedName: "",
      selectedDepartment: "",
      selectedManager: "",
      loading: false,
      spinnerMessage: "",
    };

    this.initialState = this.state;
  }

  //#region Component Mount
  componentDidMount() {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    this.fetchDepartments();
    this.fetchUsersList();
  }
  //#endregion

  //#region Fetch Departments
  fetchDepartments() {
    this.setState({
      spinnerMessage: "Please wait while loading Departments...",
      loading: true,
    });

    userService
      .readDepartments()
      .then((response) => {
        this.setState({ departments: response.data, loading: false });
      })
      .catch((e) => {
        this.setState({ loading: false });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region fetching Users List from Web API
  fetchUsersList() {
    this.setState({
      spinnerMessage: "Please wait while fetching Managers...",
      loading: true,
    });

    userService
      .getAllUsers(helper.getUser())
      .then((response) => {
        this.setState({
          users: response.data,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Get First Name Value
  onChangeFirstName(e) {
    this.setState({
      firstName: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = { ...this.state.formErrors, firstNameError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Get Middle Name Value
  onChangeMiddleName(e) {
    this.setState({
      middleName: e.target.value,
    });
  }
  //#endregion

  //#region Get Last Name Value
  onChangeLastName(e) {
    this.setState({
      lastName: e.target.value,
    });
  }
  //#endregion

  //#region Get User Name Value
  onChangeUserName(e) {
    const re = /^[A-Za-z]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({
        userName: e.target.value,
      });
      this.setState({
        userName: e.target.value,
      });

      const formErrors = { ...this.state.formErrors, userNameError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Get password Value
  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = { ...this.state.formErrors, passwordError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Get Re Type Password Value
  onChangeReTypePassword(e) {
    this.setState({
      reTypePassword: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = { ...this.state.formErrors, reTypePasswordError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Get Email Value
  onChangeEmailID(e) {
    this.setState({
      emailID: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = { ...this.state.formErrors, emailIDError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Get Department Value
  onChangeDepartment(e) {
    this.setState({
      selectedDepartment: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = { ...this.state.formErrors, departmentError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Get Manager Value
  onChangeManager(e) {
    this.setState({
      selectedManager: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = { ...this.state.formErrors, managerError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Validating the input data
  handleFormValidation() {
    const firstName = this.state.firstName.trim();
    const userName = this.state.userName.trim();
    const password = this.state.password.trim();
    const reTypePassword = this.state.reTypePassword.trim();
    const emailID = this.state.emailID.trim();
    const department = this.state.selectedDepartment;
    const manager = this.state.selectedManager;
    const re = /\S+@\S+\.\S+/;
    let formErrors = {};
    let isValidForm = true;

    //First Name
    if (!firstName) {
      isValidForm = false;
      formErrors["firstNameError"] = "First Name is required";
    }

    //User Name
    if (!userName) {
      isValidForm = false;
      formErrors["userNameError"] = "User Name is required";
    } else if (userName.length < 3) {
      isValidForm = false;
      formErrors["userNameError"] = "User Name must be at least 3 characters";
    }

    //Password
    if (!password) {
      isValidForm = false;
      formErrors["passwordError"] = "Password is required";
    } else if (password.length < 6) {
      isValidForm = false;
      formErrors["passwordError"] = "Password must be at least 6 characters";
    }

    //Re Type Password
    if (!reTypePassword) {
      isValidForm = false;
      formErrors["reTypePasswordError"] = "Re Type Password is required";
    } else if (password !== reTypePassword) {
      isValidForm = false;
      formErrors["reTypePasswordError"] =
        "Re Type Password doesn't match with password";
    }

    //Emaild ID
    if (!emailID) {
      isValidForm = false;
      formErrors["emailIDError"] = "Email ID is required";
    } else if (!re.test(emailID)) {
      isValidForm = false;
      formErrors["emailIDError"] = "Invalid Email ID";
    }

    //Department
    if (!department) {
      isValidForm = false;
      formErrors["departmentError"] = "Department is required";
    }

    //Manager
    if (!manager) {
      isValidForm = false;
      formErrors["managerError"] = "Manager is required";
    }

    this.setState({ formErrors: formErrors });
    return isValidForm;
  }
  //#endregion

  // #region Upload profilePhoto
  uploadProfilePhoto = (e) => {
    if(e.target.files){
    this.setState({
      messageForProfileFile: true,
    });
    var files = e.target.files;

    let currentFile = files[0];
    let fileNameUploaded = files[0].name;
    this.setState({
      profileFileUploadedName: fileNameUploaded,
    });

    let formData = new FormData();
    formData.append("File", currentFile);

    this.setState({
      spinnerMessage: "Please wait while uploading scope file...",
      loading: true,
    });

    //Service call
    projectService
      .saveFileupload(formData)
      .then((response) => {
        this.setState({
          messageForProfileFile: false,
          profileFileName: response.data,
          loading: false,
        });
      })
      .catch((error) => {
        toast.error(error.response.data.Message, { autoClose: false });
        this.setState({
          messageForProfileFile: false,
          profileFileName: "",
          loading: false,
        });
      });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = { ...this.state.formErrors, scopeError: "" };
      this.setState({ formErrors: formErrors });
    }
    }
  }
  // #endregion Upload profilePhoto

  //#region Downloading Scope File
  downloadScopeFile = (e) => {
    this.setState({
      spinnerMessage: "Please wait while downloading scope file...",
      loading: true,
    });

    projectService
      .downloadFile(this.state.profileFileName, "scope")
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", this.state.profileFileUploadedName);
        document.body.appendChild(fileLink);

        fileLink.click();
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Deleting Scope File
  deleteScopeFile = () => {
    this.setState({
      spinnerMessage: "Please wait while deleting scope file...",
      loading: true,
    });

    projectService
      .deleteFile(this.state.profileFileName)
      .then((response) => {
        this.setState({
          profileFileKey: Date.now(),
          profileFileName: "",
          profileFileUploadedName: "",
          loading: false,
        });
      })
      .catch((error) => {
        toast.error(error.response.data.Message, { autoClose: false });
        this.setState({
          profileFileName: "",
          loading: false,
        });
      });
  }
  //#endregion

  //#region Save User
  saveUser = () => {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    if (this.handleFormValidation()) {
      this.setState({
        spinnerMessage: "Please wait while saving User...",
        loading: true,
      });

      //Bind state data to object
      var data = {
        UserID: this.state.userID,
        FirstName: this.state.firstName.trim(),
        MiddleName: this.state.middleName.trim(),
        LastName: this.state.lastName.trim(),
        UserName: this.state.userName.trim(),
        Password: this.state.password,
        Email: this.state.emailID.trim(),
        DepartmentName: this.state.selectedDepartment.trim(),
        ManagerName: this.state.selectedManager.trim(),
        IsLockedOut: false,
        PhotoFileName: this.state.profileFileName,
        User: helper.getUser(),
      };

      //Service call
      userService
        .createUser(data)
        .then(() => {
          toast.success("User Added Successfully");
          this.setState(this.initialState);
          this.props.history.push({
            pathname: "/admin/UserList",
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          toast.error(error.response.data.Message, { autoClose: false });
        });
    }
  };
  //#endregion

  //#region Reset the page
  reset() {
    this.setState(this.initialState);
  }
  //#endregion

  render() {
    return (
      <div>
        <LoadingOverlay
          active={this.state.loading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
          <div className="az-content-breadcrumb">
            <span>Admin</span>
            <span>Create User</span>
          </div>
          <h4>
            Create User{" "}
            <span className="icon-size">
              <i
                className="far fa-arrow-alt-circle-left text-primary pointer"
                onClick={() => this.props.history.goBack()}
                title="Back to List"
              ></i>
            </span>
          </h4>
          <div id="Add_User">
            <div className="row row-sm">
              <div className="col-lg">
                <label htmlFor="firstName">
                  First Name{" "}
                  <span className="text-danger asterisk-size">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="50"
                  id="firstName"
                  name="firstName"
                  tabIndex="1"
                  value={this.state.firstName}
                  onChange={this.onChangeFirstName}
                />
                <div className="error-message">
                  {this.state.formErrors["firstNameError"]}
                </div>
              </div>
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label htmlFor="middleName" className="mg-t-10">
                  Middle Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="50"
                  id="middleName"
                  name="middleName"
                  tabIndex="2"
                  value={this.state.middleName}
                  onChange={this.onChangeMiddleName}
                />
              </div>
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label htmlFor="lastName" className="mg-t-10">
                  Last Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="50"
                  id="lastName"
                  name="lastName"
                  tabIndex="3"
                  value={this.state.lastName}
                  onChange={this.onChangeLastName}
                />
              </div>
            </div>
            <div className="row row-sm">
              <div className="col-lg">
                <label htmlFor="userName">
                  Username <span className="text-danger asterisk-size">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="50"
                  id="userName"
                  name="userName"
                  tabIndex="4"
                  value={this.state.userName}
                  onChange={this.onChangeUserName}
                />
                <div className="error-message">
                  {this.state.formErrors["userNameError"]}
                </div>
              </div>
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label htmlFor="password">
                  Password <span className="text-danger asterisk-size">*</span>
                </label>
                <input
                  type="password"
                  className="form-control"
                  maxLength="50"
                  id="password"
                  name="password"
                  tabIndex="5"
                  value={this.state.password}
                  onChange={this.onChangePassword}
                />
                <div className="error-message">
                  {this.state.formErrors["passwordError"]}
                </div>
              </div>
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label htmlFor="reTypePassword">
                  Re-type Password{" "}
                  <span className="text-danger asterisk-size">*</span>
                </label>
                <input
                  type="password"
                  className="form-control"
                  maxLength="50"
                  id="reTypePassword"
                  name="reTypePassword"
                  tabIndex="6"
                  value={this.state.reTypePassword}
                  onChange={this.onChangeReTypePassword}
                />
                <div className="error-message">
                  {this.state.formErrors["reTypePasswordError"]}
                </div>
              </div>
            </div>
            <div className="row row-sm">
              <div className="col-lg">
                <label htmlFor="emailID">
                  Email ID <span className="text-danger asterisk-size">*</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  maxLength="50"
                  id="email"
                  name="email"
                  tabIndex="7"
                  value={this.state.emailID}
                  onChange={this.onChangeEmailID}
                />
                <div className="error-message">
                  {this.state.formErrors["emailIDError"]}
                </div>
              </div>
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label htmlFor="department">
                  Department{" "}
                  <span className="text-danger asterisk-size">*</span>
                </label>
                <select
                  className="form-control"
                  tabIndex="1"
                  id="department"
                  name="department"
                  placeholder="--Select--"
                  value={this.state.selectedDepartment}
                  onChange={this.onChangeDepartment}
                >
                  <option value="">--Select--</option>
                  {this.state.departments.map((department) => (
                    <option key={department.DepartmentID}>
                      {department.Name}
                    </option>
                  ))}
                </select>
                <div className="error-message">
                  {this.state.formErrors["departmentError"]}
                </div>
              </div>
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label htmlFor="manager">
                  Manager <span className="text-danger asterisk-size">*</span>
                </label>
                <select
                  className="form-control"
                  tabIndex="1"
                  id="manager"
                  name="manager"
                  placeholder="--Select--"
                  value={this.state.selectedManager}
                  onChange={this.onChangeManager}
                >
                  <option value="">--Select--</option>
                  {this.state.users.map((user) => (
                    <option key={user.UserID}>
                      {user.FirstName +
                        (user.MiddleName ? " " + user.MiddleName : "") +
                        (user.LastName ? " " + user.LastName : "") +
                        " - " +
                        user.UserName}
                    </option>
                  ))}
                </select>
                <div className="error-message">
                  {this.state.formErrors["managerError"]}
                </div>
              </div>
            </div>
            <div className="row row-sm">
              <div className="col-lg-5 mg-t-10 mg-lg-t-0">
                      <label>Choose Profile Image</label>
                      <input
                        type="file"
                        className="form-control flex-grow-1"
                        tabIndex="18"
                        id="ScopeFile"
                        name="ScopeFile"
                        key={this.state.profileFileKey}
                        onChange={this.uploadProfilePhoto}
                        accept="image/*"
                      />

                </div>
                <div className="col-lg d-flex align-items-end">
                    {this.state.messageForProfileFile && <p>Please Wait...</p>}
                    {this.state.profileFileName && (
                      <>
                        <span
                          className="btn btn-secondary"
                          onClick={this.downloadScopeFile}
                          style={{maxHeight: "38px"}}
                        >
                          <i className="fas fa-download"></i>
                        </span>
                        <span
                          className="btn btn-secondary mg-l-5"
                          onClick={this.deleteScopeFile}
                          style={{maxHeight: "38px"}}
                        >
                          <i className="fas fa-trash-alt"></i>
                        </span>
                      </>
                    )}
                  </div>
            </div>
            <br />
            <div className="row row-sm">
              <div className="col-md-3"></div>
              <div className="col-md-2 mg-t-10 mg-lg-t-0">
                <button
                  id="Save"
                  className="mg-t-10 mg-md-t-0 btn btn-gray-700 btn-block"
                  tabIndex="8"
                  onClick={this.saveUser}
                >
                  Save
                </button>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-2 mg-t-10 mg-lg-t-0">
                <button
                  className="btn btn-gray-700 btn-block"
                  tabIndex="9"
                  onClick={this.reset}
                  id="Reset"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default CreateUser;
