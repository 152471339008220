import React, { Component } from "react";
import { Link } from "react-router-dom";
import helpers from "../../helpers/helpers";
import tableFunctions from "../../helpers/tableFunctions";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import dashboardService from "../../services/dashboard.service";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class ActiveTasks extends Component {
  constructor(props) {
    super(props); //reference to the parent constructor

    this.divScrollRef = React.createRef();

    this.displaySortingFields = this.displaySortingFields.bind(this);
    this.displayFilteringField = this.displayFilteringField.bind(this);
    this.onChangefilterValue = this.onChangefilterValue.bind(this);
    this.onChangeColumn = this.onChangeColumn.bind(this);
    this.onChangeSortOrder = this.onChangeSortOrder.bind(this);
    this.sortData = this.sortData.bind(this);
    this.clearSort = this.clearSort.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.exportActiveTasksToExcel = this.exportActiveTasksToExcel.bind(this);

    this.state = {
      activeTasks: [],
      spinnerMessage: "",
      loading: false,
      index: 30,
      position: 0,
      columns: [],
      isToShowSortingFields: false,
      isToShowFilteringField: false,
      selectedColumn: "",
      selectedSort: "",
      filteredArray: [],
      filterValue: "",
    };
  }

  //#region component mount
  componentDidMount() {
    if (!helpers.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    this.fetchActiveTasks();
  }
  //#endregion

  //#region Fetch Active Tasks
  async fetchActiveTasks() {
    this.setState({
      spinnerMessage: "Please wait while fetching Active Tasks...",
      loading: true,
    });

    await dashboardService
      .ReadActiveTasks()
      .then((response) => {
        this.setState({
          activeTasks: response.data,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Export Active Tasks to Excel
  exportActiveTasksToExcel() {
    this.setState({
      spinnerMessage: "Please wait while exporting active tasks to excel...",
      loading: true,
    });

    let fileName = "Active Tasks.xlsx";

    dashboardService
      .exportActiveTasksToExcel()
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);
        fileLink.click();

        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Filter Functions
  //#region Display Filtering Field
  displayFilteringField() {
    this.setState((previousState) => ({
      isToShowFilteringField: !previousState.isToShowFilteringField,
      filterValue: "",
      isToShowSortingFields: false,
    }));
  }
  //#endregion

  //#region on change filter value
  onChangefilterValue(e) {
    this.setState({ filterValue: e.target.value }, () =>
      this.getFilteredRows()
    );
  }
  //#endregion

  //#region get filtered rows
  getFilteredRows() {
    const filteredArray = tableFunctions.filterArray(
      this.state.activeTasks,
      this.state.filterValue
    );

    this.setState({ filteredArray: filteredArray });
  }
  //#endregion

  //#region Clear Search
  clearSearch() {
    this.setState({
      filterValue: "",
    });
  }
  //#endregion
  //#endregion

  //#region  Sort Functions
  //#region Display Sorting Fields
  displaySortingFields() {
    let columns = Object.keys(this.state.activeTasks[0]);

    this.setState((previousState) => ({
      isToShowSortingFields: !previousState.isToShowSortingFields,
      selectedColumn: "",
      selectedSort: "",
      columns: columns,
      filterValue: "",
      isToShowFilteringField: false,
    }));
  }
  //#endregion

  //#region Selecting the sort column
  onChangeColumn(e) {
    this.setState({
      selectedColumn: e.target.value,
      selectedSort: "",
    });
  }
  //#endregion

  //#region On Change Sort
  onChangeSortOrder(e) {
    this.setState(
      {
        selectedSort: e.target.value,
      },
      () => this.sortData()
    );
  }
  //#endregion

  //#region Sort Data based on column and order
  sortData() {
    let sortedArray = [];
    let column =
      this.state.selectedColumn !== "" ? this.state.selectedColumn : "SlNo";
    const selectedSort =
      this.state.selectedSort !== "" ? this.state.selectedSort : "ascending";
    let numberColumns = [
      "SlNo",
      "InputCount",
      "ProductionCompletedCount",
      "QCCompletedCount",
    ];

    sortedArray = tableFunctions.sortData(
      this.state.activeTasks,
      column,
      selectedSort,
      numberColumns,
      []
    );

    this.setState({ activeTasks: sortedArray });
  }
  //#endregion

  //#region  Clear Sort
  clearSort() {
    this.setState(
      {
        selectedColumn: "",
        selectedSort: "",
      },
      () => this.sortData()
    );
  }
  //#endregion
  //#endregion

  //#region Handle Scroll
  handleScroll(e) {
    var currentHeight = e.currentTarget.scrollTop;
    var maxScrollPosition =
      e.currentTarget.scrollHeight - e.currentTarget.clientHeight;

    this.setState({ position: currentHeight });

    if ((currentHeight / maxScrollPosition) * 100 > 90) {
      let curIndex = this.state.index + 20;
      this.setState({ index: curIndex });
    }
  }
  //#endregion

  //#region Scroll to Top
  scrollToTop = () => {
    this.divScrollRef.current.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  //#endregion

  render() {
    const data = this.state.activeTasks.slice(0, this.state.index);
    const filteredData = this.state.filteredArray.slice(0, this.state.index);

    const activeTasksColumns = [
      {
        dataField: "SlNo",
        align: "center",
        style: {
          width: "5%",
        },
      },
      {
        dataField: "CustomerCode",
        align: "center",
        style: {
          width: "7%",
        },
      },
      {
        dataField: "ProjectCode",
        align: "center",
        style: {
          width: "7%",
        },
      },
      {
        dataField: "BatchNo",
        align: "center",
        style: {
          width: "9%",
        },
      },
      {
        dataField: "InputCount",
        align: "center",
        style: {
          width: "10%",
        },
      },
      {
        dataField: "Activity",
        style: {
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          width: "20%",
        },
        title: true,
      },
      {
        dataField: "ProductionCompletedCount",
        align: "center",
        style: {
          width: "13%",
        },
      },
      {
        dataField: "QCCompletedCount",
        align: "center",
        style: {
          width: "13%",
        },
      },
    ];

    return (
      <>
        <LoadingOverlay
          active={this.state.loading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helpers.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
          <div className="az-content-body pd-lg-l-40 d-flex flex-column mg-r-20 mg-t-20">
            <ToolkitProvider
              keyField="SlNo"
              data={this.state.filterValue === "" ? data : filteredData}
              columns={activeTasksColumns}
            >
              {(props) => (
                <div>
                  <div style={{ textAlign: "end", marginBottom: "5px" }}>
                    <div className="row">
                      <div className="col-md-9">
                        <div className="row">
                          <h5 style={{ marginLeft: "15px", marginTop: "7px" }}>
                            <b>Active Tasks</b>
                          </h5>
                          <span className="icon-size mg-l-5">
                            <Link
                              to={{
                                pathname: "/Dashboard",
                              }}
                            >
                              <i
                                className="far fa-arrow-alt-circle-left"
                                title="Back to Dashboard"
                              ></i>
                            </Link>
                          </span>
                          {this.state.isToShowSortingFields && (
                            <>
                              <div className="col-md-4">
                                <div className="row">
                                  <div className="col-md-3 mg-t-7">
                                    <label htmlFor="sortColumn">Column:</label>
                                  </div>
                                  <div className="col-lg">
                                    <select
                                      className="form-control mg-l-5"
                                      value={this.state.selectedColumn}
                                      onChange={this.onChangeColumn}
                                    >
                                      <option value="">--Select--</option>
                                      {this.state.columns.map((col) => (
                                        <option key={col}>{col}</option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="row">
                                  <div className="col-md-3 mg-t-7">
                                    <label htmlFor="sortOrder">Order:</label>
                                  </div>
                                  <div className="col-lg">
                                    <select
                                      className="form-control mg-l-5"
                                      value={this.state.selectedSort}
                                      onChange={this.onChangeSortOrder}
                                    >
                                      <option value="">--Select--</option>
                                      <option value="ascending">
                                        Ascending
                                      </option>
                                      <option value="descending">
                                        Descending
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div>
                                  <span
                                    className="btn btn-primary pd-b-5"
                                    onClick={this.clearSort}
                                    title="Clear Sort Fields"
                                  >
                                    <i className="far fa-window-close"></i>
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                          {this.state.isToShowFilteringField && (
                            <>
                              <div className="col-md-10">
                                <div
                                  className="row"
                                  style={{ flexWrap: "nowrap" }}
                                >
                                  <div className="col-md-1 mg-t-7">
                                    <label htmlFor="search">Search:</label>
                                  </div>
                                  <div className="col-lg pd-r-10">
                                    <input
                                      type="text"
                                      className="form-control mg-l-10"
                                      maxLength="20"
                                      value={this.state.filterValue}
                                      onChange={this.onChangefilterValue}
                                    />
                                  </div>
                                  <div>
                                    <span
                                      className="btn btn-primary pd-b-5"
                                      onClick={this.clearSearch}
                                    >
                                      <i
                                        className="far fa-window-close"
                                        title="Clear Filter"
                                      ></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      {this.state.activeTasks.length > 0 && (
                        <div
                          className="col-md-2 mg-l-80"
                          style={{ marginTop: "10px" }}
                        >
                          <i
                            className="fas fa-exchange-alt fa-rotate-90 pointer"
                            title={
                              this.state.isToShowSortingFields
                                ? "Hide Sort"
                                : "Show Sort"
                            }
                            onClick={this.displaySortingFields}
                          ></i>
                          {!this.state.isToShowFilteringField ? (
                            <i
                              className="fas fa-filter pointer mg-l-10"
                              onClick={this.displayFilteringField}
                              title="Show Filter"
                            ></i>
                          ) : (
                            <i
                              className="fas fa-funnel-dollar pointer mg-l-10"
                              onClick={this.displayFilteringField}
                              title="Hide Filter"
                            ></i>
                          )}
                          <i
                            className="fas fa-file-excel mg-l-10 pointer"
                            style={{ color: "green" }}
                            onClick={this.exportActiveTasksToExcel}
                            title="Export to Excel"
                          ></i>
                        </div>
                      )}
                    </div>
                  </div>
                  <div style={{ width: "98.3%" }}>
                    <div>
                      <table
                        style={{
                          width:
                            (this.state.filteredArray.length < 12 &&
                              this.state.filterValue !== "") ||
                            this.state.activeTasks.length < 12
                              ? "100%"
                              : "98.8%",
                        }}
                      >
                        <thead>
                          <tr>
                            <td
                              className="custom-table-header"
                              style={{
                                width:
                                  (this.state.filteredArray.length < 12 &&
                                    this.state.filterValue !== "") ||
                                  this.state.activeTasks.length < 12
                                    ? "4.7%"
                                    : "4.75%",
                              }}
                            >
                              Sl No
                            </td>
                            <td
                              className="custom-table-header"
                              style={{
                                width:
                                  (this.state.filteredArray.length < 12 &&
                                    this.state.filterValue !== "") ||
                                  this.state.activeTasks.length < 12
                                    ? "6.65%"
                                    : "6.70%",
                              }}
                            >
                              Cus Code
                            </td>
                            <td
                              className="custom-table-header"
                              style={{
                                width:
                                  (this.state.filteredArray.length < 12 &&
                                    this.state.filterValue !== "") ||
                                  this.state.activeTasks.length < 12
                                    ? "6.65%"
                                    : "6.6%",
                              }}
                            >
                              Proj Code
                            </td>
                            <td
                              className="custom-table-header"
                              style={{
                                width:
                                  (this.state.filteredArray.length < 12 &&
                                    this.state.filterValue !== "") ||
                                  this.state.activeTasks.length < 12
                                    ? "8.55%"
                                    : "8.65%",
                              }}
                            >
                              Batch No
                            </td>
                            <td
                              className="custom-table-header"
                              style={{
                                width:
                                  (this.state.filteredArray.length < 12 &&
                                    this.state.filterValue !== "") ||
                                  this.state.activeTasks.length < 12
                                    ? "9.45%"
                                    : "9.5%",
                              }}
                            >
                              Input Count
                            </td>
                            <td
                              className="custom-table-header"
                              style={{
                                width:
                                  (this.state.filteredArray.length < 12 &&
                                    this.state.filterValue !== "") ||
                                  this.state.activeTasks.length < 12
                                    ? "19%"
                                    : "19.05%",
                              }}
                            >
                              Activity / Task
                            </td>
                            <td
                              className="custom-table-header"
                              style={{
                                width:
                                  (this.state.filteredArray.length < 12 &&
                                    this.state.filterValue !== "") ||
                                  this.state.activeTasks.length < 12
                                    ? "12.25%"
                                    : "12.4%",
                              }}
                            >
                              Prod Completed Count
                            </td>
                            <td
                              className="custom-table-header"
                              style={{
                                width:
                                  (this.state.filteredArray.length < 12 &&
                                    this.state.filterValue !== "") ||
                                  this.state.activeTasks.length < 12
                                    ? "12.35%"
                                    : "12.15%",
                              }}
                            >
                              QC Completed Count
                            </td>
                          </tr>
                        </thead>
                      </table>
                    </div>
                    <div
                      style={
                        (this.state.filteredArray.length > 12 &&
                          this.state.filterValue !== "") ||
                        (this.state.activeTasks.length > 12 &&
                          this.state.filterValue === "")
                          ? {
                              overflowY: "scroll",
                              borderBottom: "1px solid #cdd4e0",
                            }
                          : {}
                      }
                      ref={this.divScrollRef}
                      className="active-tasks-table-height"
                      onScroll={this.handleScroll}
                    >
                      <BootstrapTable
                        bootstrap4
                        {...props.baseProps}
                        striped
                        hover
                        condensed
                        headerClasses="header-class"
                      />
                    </div>
                  </div>
                </div>
              )}
            </ToolkitProvider>
            {this.state.position > 600 && this.state.filterValue === "" && (
              <div style={{ textAlign: "end" }}>
                <button className="scroll-top" onClick={this.scrollToTop}>
                  <div className="arrow up"></div>
                </button>
              </div>
            )}
          </div>
        </LoadingOverlay>
      </>
    );
  }
}

export default ActiveTasks;
