import React, { Component } from "react";
import customerFeedbackTypeService from "../../services/customerFeedbackType.service";
import helper from "../../helpers/helpers";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class EditCustomerFeedbackType extends Component {
  constructor(props) {
    super(props); //reference to the parents constructor() function.
    //add super(props) every single time we define a constructor() function inside a class-based component
    this.onChangeFeedbackType = this.onChangeFeedbackType.bind(this);
    this.onChangeIsActive = this.onChangeIsActive.bind(this);
    this.moveToCustomerFeedbackTypesList =
      this.moveToCustomerFeedbackTypesList.bind(this);
    this.saveCustomerFeedbackType = this.saveCustomerFeedbackType.bind(this);
    this.reset = this.reset.bind(this);

    this.state = {
      customerFeedbackTypeID: 0,
      feedbackType: "",
      isActive: true,
      formErrors: {},
      loading: false,
      spinnerMessage: "",
    };
  }

  //#region Component Mount
  componentDidMount() {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    this.fetchCustomerFeedbackType();
  }
  //#endregion

  //#region fetching selected Customer Feedback Type details
  fetchCustomerFeedbackType() {
    const { state } = this.props.location; // Customer Feedback Type ID passed from View Customer Feedback Type Page
    if (state === 0 || state === null || state === undefined) {
      this.props.history.push("/Masters/CustomerFeedbackTypeList");
      return;
    }

    this.setState({
      spinnerMessage: "Please wait while loading Customer Feedback Type...",
      loading: true,
    });

    customerFeedbackTypeService
      .getCustomerFeedbackType(state, helper.getUser())
      .then((response) => {
        this.setState({
          customerFeedbackTypeID: response.data.CustomerFeedbackTypeID,
          feedbackType: response.data.FeedbackType,
          isActive: response.data.IsActive,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({ loading: false });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Redirect to Customer Feedback Type List Page
  moveToCustomerFeedbackTypesList = (e) => {
    this.props.history.push("/Masters/CustomerFeedbackTypeList");
  };
  //#endregion

  //#region Bind control value to state variable
  onChangeFeedbackType(e) {
    this.setState({
      feedbackType: e.target.value,
    });

    if (e.target.value !== "" || e.target.value !== null)
      this.setState({ formErrors: {} });
  }
  //#endregion

  //#region get IsActive value
  onChangeIsActive(e) {
    this.setState({
      isActive: e.target.checked,
    });
  }
  //#endregion

  //#region Reset the page
  reset() {
    this.setState({ formErrors: {} });
    this.fetchCustomerFeedbackType();
  }
  //#endregion

  //#region Save Customer Feedback Type
  saveCustomerFeedbackType = (e) => {
    e.preventDefault(); //cancels the event if it is cancelable,
    //meaning that the default action that belongs to the event will not occur.

    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    if (this.handleFormValidation()) {
      this.setState({
        spinnerMessage: "Please wait while saving Customer Feedback Type...",
        loading: true,
      });

      //Bind state data to object
      var data = {
        CustomerFeedbackTypeID: this.state.customerFeedbackTypeID,
        FeedbackType: this.state.feedbackType.trim(),
        isActive: this.state.isActive,
        UserID: helper.getUser(),
      };

      //Service call
      customerFeedbackTypeService
        .updateCustomerFeedbackType(this.state.customerFeedbackTypeID, data)
        .then((response) => {
          toast.success("Customer Feedback Type Updated Successfully");
          this.setState(this.initialState);
          this.props.history.push({
            pathname: "/Masters/CustomerFeedbackTypeList",
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          toast.error(error.response.data.Message, { autoClose: false });
        });
    }
  };
  //#endregion

  //#region  Validating the input data
  handleFormValidation() {
    const feedbackType = this.state.feedbackType.trim();
    let formErrors = {};
    let isValidForm = true;

    //feedbackType
    if (!feedbackType) {
      isValidForm = false;
      formErrors["feedbackTypeError"] = "Feedback Type is required";
    }

    this.setState({ formErrors: formErrors });
    return isValidForm;
  }
  //#endregion

  render() {
    const { feedbackTypeError } = this.state.formErrors;

    return (
      <div className="pro-main-display">
        <LoadingOverlay
          active={this.state.loading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
          <div className="az-content-breadcrumb">
            <span>Master</span>
            <span>Customer Feedback Types</span>
          </div>
          <h4>
            Edit Customer Feedback Type{" "}
            <span className="icon-size">
              <i
                className="far fa-arrow-alt-circle-left text-primary pointer"
                onClick={this.moveToCustomerFeedbackTypesList}
                title="Back to List"
                tabIndex="1"
              ></i>
            </span>
          </h4>
          <div>
            <div className="row row-sm">
              <div className="col-md-4">
                <label>
                  <b>Customer Feedback Type ID</b>{" "}
                  <span className="text-danger asterisk-size">*</span>
                </label>
              </div>
              <div className="col-md-5 mg-t-7 mg-l-10">
                <p>{this.state.customerFeedbackTypeID}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label>
                  <b>Customer Feedback Type</b>{" "}
                  <span className="text-danger asterisk-size">*</span>
                </label>
              </div>
              <div className="col-md-5">
                <input
                  type="text"
                  className="form-control"
                  tabIndex="2"
                  id="FeedbackType"
                  name="FeedbackType"
                  maxLength="50"
                  value={this.state.feedbackType}
                  onChange={this.onChangeFeedbackType}
                />
                {feedbackTypeError && (
                  <div className="error-message">{feedbackTypeError}</div>
                )}
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-4">
                <label>
                  <b>Is Active?</b>
                </label>
              </div>
              <div className="col-md-5 mg-t-5">
                <input
                  type="checkbox"
                  value={this.state.isActive}
                  onChange={this.onChangeIsActive}
                  checked={this.state.isActive}
                  tabIndex="3"
                />
              </div>
            </div>
            <br />
            <div className="row row-sm">
              <div className="col-md-1"></div>
              <div className="col-md-2">
                <button
                  className="mg-t-10 mg-md-t-0 btn btn-gray-700 btn-block"
                  tabIndex="4"
                  id="Save"
                  onClick={this.saveCustomerFeedbackType}
                >
                  Save
                </button>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-2">
                <button
                  className="mg-t-10 mg-md-t-0 btn btn-gray-700 btn-block"
                  tabIndex="5"
                  id="Reset"
                  onClick={this.reset}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default EditCustomerFeedbackType;
