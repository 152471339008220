import React, { Component } from "react";
import { Link } from "react-router-dom";
import projectBatchService from "../../services/projectBatch.service";
import accessControlService from "../../services/accessControl.service";
import helper from "../../helpers/helpers";
import Moment from "moment";
import { Button, Modal } from "react-bootstrap";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class ViewProjectBatch extends Component {
  constructor(props) {
    super(props);

    this.downloadCustomerInputFile = this.downloadCustomerInputFile.bind(this);

    this.handleYes = this.handleYes.bind(this);
    this.showPopUp = this.showPopUp.bind(this);
    this.handleNo = this.handleNo.bind(this);
    this.showChangeBatchNoModal = this.showChangeBatchNoModal.bind(this);
    this.changeBatchNo = this.changeBatchNo.bind(this);
    this.onChangeBatchNo = this.onChangeBatchNo.bind(this);

    //#region Component State
    this.state = {
      projectID: null,
      projectBatchID: null,
      customerCode: null,
      projectCode: null,
      batchNo: null,
      scope: null,
      inputCount: null,
      inputCountType: null,
      receivedDate: null,
      receivedFormat: null,
      outputFormat: null,
      plannedStartDate: null,
      plannedDeliveryDate: null,
      remarks: null,
      customerInputFileName: null,
      showModal: false,
      canAccessEditProjectBatch: false,
      canAccessDeleteProjectBatch: false,
      canAccessChangeBatchNo: false,
      showChangeBatchNoModal: false,
      changedBatchNo: "",
      deliveredCount: 0,
      deliveredDate: "",
      status: "",
      formErrors: {},
      activeTab: null,
    };
  }

  //#region component mount
  componentDidMount() {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    this.canUserAccessPage("Edit Project Batch");
    this.canUserAccessPage("Delete Project Batch");
    this.canUserAccessPage("Change Project Batch No");
    this.fetchProjectBatchDetails();
  }
  //#endregion

  //#region Fetching selected Project Batch details
  fetchProjectBatchDetails() {
    const { state } = this.props.location; // Project Batch ID passed from Project Batch List Page
    if (state === 0 || state === null || state === undefined) {
      this.props.history.push("/Projects");
      return;
    }

    this.setState({
      spinnerMessage: "Please wait while fetching project batch details...",
      loading: true,
    });

    projectBatchService
      .getProjectBatchDetailsByID(state.ProjectBatchID, helper.getUser())
      .then((response) => {
        this.setState({
          projectID: response.data.ProjectID,
          projectBatchID: response.data.ProjectBatchID,
          customerCode: response.data.CustomerCode,
          projectCode: response.data.ProjectCode,
          batchNo: response.data.BatchNo,
          scope: response.data.Scope,
          inputCount: response.data.InputCount,
          inputCountType: response.data.InputCountType,
          receivedDate: response.data.ReceivedDate,
          receivedFormat: response.data.ReceivedFormat,
          outputFormat: response.data.OutputFormat,
          plannedStartDate: response.data.PlannedStartDate,
          plannedDeliveryDate: response.data.PlannedDeliveryDate,
          remarks: response.data.Remarks,
          customerInputFileName: response.data.CustomerInputFileName,
          deliveredCount: response.data.DeliveredCount,
          deliveredDate: response.data.DeliveredDate,
          status: response.data.Status,
          activeTab: state.activeTab,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({ loading: false });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region fetching Project page access
  canUserAccessPage(pageName) {
    accessControlService
      .CanUserAccessPage(helper.getUser(), pageName)
      .then((response) => {
        if (pageName === "Edit Project Batch") {
          this.setState({
            canAccessEditProjectBatch: response.data,
          });
        } else if (pageName === "Delete Project Batch") {
          this.setState({
            canAccessDeleteProjectBatch: response.data,
          });
        } else if (pageName === "Change Project Batch No") {
          this.setState({
            canAccessChangeBatchNo: response.data,
          });
        }
      })
      .catch((e) => {
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Downloading Customer Input File
  downloadCustomerInputFile() {
    this.setState({
      spinnerMessage: "Please wait while downloading customer input file...",
      loading: true,
    });

    projectBatchService
      .downloadFile(this.state.customerInputFileName, "customerinputfile")
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", this.state.customerInputFileName);
        document.body.appendChild(fileLink);

        fileLink.click();

        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });

        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Display Modal Pop up
  showPopUp() {
    this.setState({ showModal: true });
  }
  //#endregion

  //#region Close modal Pop Up
  handleNo() {
    this.setState({ showModal: false });
  }
  //#endregion

  //#region Delete Project Batch
  handleYes() {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    this.setState({
      spinnerMessage: "Please wait while deleting project batch...",
      loading: true,
    });

    projectBatchService
      .deleteProjectBatch(this.state.projectBatchID, helper.getUser())
      .then(() => {
        this.setState({ showModal: false });
        toast.success("Project Batch Deleted Successfully");
        this.props.history.push({
          pathname: "/Projects/ProjectBatchList",
          state: {
            ProjectID: this.state.projectID,
            CustomerCode: this.state.customerCode,
            ProjectCode: this.state.projectCode,
            Scope: this.state.scope,
            activeTab: 1,
          },
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });

        toast.error(e.response.data.Message, { autoClose: false });
        this.handleNo();
      });
  }
  //#endregion

  //#region Show Change Batch No Modal
  showChangeBatchNoModal() {
    this.setState({
      showChangeBatchNoModal: true,
      changedBatchNo: "",
      formErrors: {},
    });
  }
  //#endregion

  //#region On Change Batch No
  onChangeBatchNo(e) {
    this.setState({
      changedBatchNo: e.target.value,
    });

    if (e.target.value !== "" || e.target.value !== null)
      this.setState({ formErrors: {} });
  }
  //#endregion

  //#region Validating the Changed Batch No
  handleBatchValidation() {
    const changedBatchNo = this.state.changedBatchNo.trim();
    const batchNo = this.state.batchNo.trim();
    let formErrors = {};
    let isValidForm = true;
    const re = /^\d+$/;

    //Batch No
    if (!changedBatchNo) {
      isValidForm = false;
      formErrors["batchNoError"] = "Batch No is required";
    }

    if (changedBatchNo) {
      if (changedBatchNo === batchNo) {
        isValidForm = false;
        formErrors["batchNoError"] =
          "Change To Batch No. cannot be same as existing Batch No.";
      } else if (!re.test(changedBatchNo)) {
        isValidForm = false;
        formErrors["batchNoError"] = "Batch No. should contain only numbers";
      } else if (changedBatchNo.length !== 4) {
        isValidForm = false;
        formErrors["batchNoError"] = "Batch No. must have 4 Digits";
      } else if (changedBatchNo === "0000") {
        isValidForm = false;
        formErrors["batchNoError"] = "Invalid Change To Batch No.";
      }
    }

    this.setState({ formErrors: formErrors });

    return isValidForm;
  }
  //#endregion

  //#region Save Batch No
  changeBatchNo() {
    if (this.handleBatchValidation()) {
      this.setState({
        spinnerMessage: "Please wait while changing Batch No...",
        modalLoading: true,
      });

      projectBatchService
        .changeBatchNo(
          this.state.customerCode,
          this.state.projectCode,
          this.state.batchNo,
          this.state.changedBatchNo,
          helper.getUser()
        )
        .then(() => {
          this.setState({
            modalLoading: false,
            showChangeBatchNoModal: false,
          });
          toast.success("Batch No Channged Successfully");
          this.props.history.push({
            pathname: "/Projects/ProjectBatchList",
            state: {
              ProjectID: this.state.projectID,
              CustomerCode: this.state.customerCode,
              ProjectCode: this.state.projectCode,
              Scope: this.state.scope,
              activeTab: 1,
            },
          });
        })
        .catch((e) => {
          this.setState({
            modalLoading: false,
          });
          toast.error(e.response.data.Message, { autoClose: false });
        });
    }
  }
  //#endregion

  render() {
    const canAccessEditProjectBatch = this.state.canAccessEditProjectBatch;
    const canAccessDeleteProjectBatch = this.state.canAccessDeleteProjectBatch;
    const canAccessChangeBatchNo = this.state.canAccessChangeBatchNo;

    return (
      <div>
        <LoadingOverlay
          active={this.state.loading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
          <div className="container">
            <div className="az-content-breadcrumb mg-t-20">
              <span>Projects</span>
              <span>View Project Batch</span>
            </div>
            <h4>
              View Project Batch{" "}
              <span className="icon-size">
                <i
                  onClick={() => {
                    this.props.history.push({
                      pathname: "/Projects/ProjectBatchList",
                      state: {
                        ProjectID: this.state.projectID,
                        CustomerCode: this.state.customerCode,
                        ProjectCode: this.state.projectCode,
                        Scope: this.state.scope,
                        activeTab: 1,
                      },
                    });
                  }}
                  className="far fa-arrow-alt-circle-left text-primary pointer"
                  tabIndex="1"
                  title="Back to List"
                ></i>
              </span>
            </h4>
            <div className="col-md-8">
              <div className="card mb-3">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-3">
                      <label>Customer Code</label>
                    </div>
                    <div className="col-sm-9 ">
                      <label>{this.state.customerCode}</label>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <label>Project Code </label>
                    </div>
                    <div className="col-sm-9 ">
                      <label>{this.state.projectCode}</label>
                    </div>
                  </div>
                  <hr />
                  <div className="row align-items-center">
                    <div className="col-sm-3">
                      <label>Batch No. </label>
                    </div>
                    <div className="col-sm-4">
                      <label>{this.state.batchNo}</label>
                    </div>
                    <div className="col-sm-4">
                      {canAccessChangeBatchNo &&
                        this.state.deliveredCount === 0 && (
                          <button
                            className="mg-t-10 mg-md-t-0 btn btn-gray-700 btn-block"
                            onClick={this.showChangeBatchNoModal}
                          >
                            Change Batch No
                          </button>
                        )}
                    </div>
                  </div>
                  <hr />
                  <div className="row align-items-center">
                    <div className="col-sm-3">
                      <label>Scope </label>
                    </div>
                    <div className="col-sm-4">
                      <label>{this.state.scope}</label>
                    </div>
                    <div className="col-sm-5 row">
                      {this.state.deliveredCount > 0 && (
                        <>
                          <label className="mg-r-70">Delivered Date</label>
                          <label>
                            {Moment(this.state.deliveredDate).format(
                              "DD-MMM-yyyy"
                            )}
                          </label>
                        </>
                      )}
                    </div>
                  </div>
                  <hr />
                  <div className="row align-items-center">
                    <div className="col-sm-3">
                      <label>Input Count</label>
                    </div>
                    <div className="col-sm-4">
                      <label>{this.state.inputCount}</label>
                    </div>
                    <div className="col-sm-5 row">
                      {this.state.deliveredCount > 0 && (
                        <>
                          <label className="mg-r-70">Delivered Count</label>
                          <label>{this.state.deliveredCount}</label>
                        </>
                      )}
                    </div>
                  </div>
                  <hr />
                  <div className="row align-items-center">
                    <div className="col-sm-3">
                      <label>Input Count Type</label>
                    </div>
                    <div className="col-sm-4">
                      <label>{this.state.inputCountType}</label>
                    </div>
                    <div className="col-sm-5 row">
                      <>
                        <label className="mg-r-120">Status</label>
                        <label>{this.state.status}</label>
                      </>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <label>Received Date</label>
                    </div>
                    <div className="col-sm-9 ">
                      <label>
                        {this.state.receivedDate !== null
                          ? Moment(this.state.receivedDate).format(
                              "DD-MMM-yyyy"
                            )
                          : ""}
                      </label>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <label>Received Format</label>
                    </div>
                    <div className="col-sm-9 ">
                      <label>{this.state.receivedFormat}</label>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <label>Output Format</label>
                    </div>
                    <div className="col-sm-9 ">
                      <label>{this.state.outputFormat}</label>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <label>Planned Start Date</label>
                    </div>
                    <div className="col-sm-9 ">
                      <label>
                        {this.state.plannedStartDate !== null
                          ? Moment(this.state.plannedStartDate).format(
                              "DD-MMM-yyyy"
                            )
                          : ""}
                      </label>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <label>Planned Delivery Date</label>
                    </div>
                    <div className="col-sm-9 ">
                      <label>
                        {this.state.plannedDeliveryDate !== null
                          ? Moment(this.state.plannedDeliveryDate).format(
                              "DD-MMM-yyyy"
                            )
                          : ""}
                      </label>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <label>Remarks</label>
                    </div>
                    <div className="col-sm-9">
                      <label>{this.state.remarks}</label>
                    </div>
                  </div>
                  <hr />
                  <div className="row align-items-center">
                    <div className="col-sm-3">
                      <label>Customer Input File</label>
                    </div>
                    <div className="col-sm-9">
                      <label>
                        <Link to="#/" onClick={this.downloadCustomerInputFile}>
                          {this.state.customerInputFileName}
                        </Link>
                      </label>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-2"></div>
                    {canAccessEditProjectBatch &&
                      this.state.deliveredCount === 0 && (
                        <div className="col-md-3">
                          <Link
                            to={{
                              pathname: "/Projects/EditProjectBatch",
                              state: this.state.projectBatchID, // passing Project Batch ID to Edit Project Batch Page
                            }}
                            className="mg-t-10 mg-md-t-0 btn btn-gray-700 btn-block"
                          >
                            Edit
                          </Link>
                        </div>
                      )}
                    <div className="col-sm-1"></div>
                    {canAccessDeleteProjectBatch &&
                      this.state.deliveredCount === 0 && (
                        <div className="col-md-3">
                          <button
                            className="mg-t-10 mg-md-t-0 btn btn-gray-700 btn-block"
                            onClick={this.showPopUp}
                          >
                            Delete
                          </button>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <Modal
              show={this.state.showModal}
              aria-labelledby="contained-modal-title-vcenter"
              onHide={this.handleNo}
              backdrop="static"
              enforceFocus={false}
            >
              <Modal.Header>
                <Modal.Title>Delete Project Batch</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <p>Are you sure to delete this Project Batch?</p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={this.handleYes}>
                  Yes
                </Button>
                <Button variant="primary" onClick={this.handleNo}>
                  No
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal
              show={this.state.showChangeBatchNoModal}
              onHide={() => this.setState({ showChangeBatchNoModal: false })}
              dialogClassName="modal-width-produpload"
              aria-labelledby="contained-modal-title-vcenter"
              backdrop="static"
              enforceFocus={false}
            >
              <LoadingOverlay
          active={this.state.modalLoading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
                <Modal.Header>
                  <Modal.Title id="changeProjectCodeModal">
                    Change Batch No
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row row-sm">
                    <div className="col-md-6 text-nowrap">
                      <label htmlFor="CustomerCode">
                        <b>Customer Code</b>
                      </label>
                    </div>
                    <div className="col-md-5 mg-t-7 mg-l-2">
                      <p id="CustomerCode" name="CustomerCode">
                        {this.state.customerCode}
                      </p>
                    </div>
                  </div>
                  <div className="row row-sm">
                    <div className="col-md-6 text-nowrap">
                      <label htmlFor="ProjectCode">
                        <b>Project Code</b>
                      </label>
                    </div>
                    <div className="col-md-5 mg-t-7 mg-l-2">
                      <p id="ProjectCode" name="ProjectCode">
                        {this.state.projectCode}
                      </p>
                    </div>
                  </div>
                  <div className="row row-sm">
                    <div className="col-md-6 text-nowrap">
                      <label htmlFor="ProjectCode">
                        <b>Batch No</b>
                      </label>
                    </div>
                    <div className="col-md-5 mg-t-7 mg-l-2">
                      <p id="ProjectCode" name="ProjectCode">
                        {this.state.batchNo}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <b>Change To Batch No.</b>{" "}
                      <span className="text-danger asterisk-size">*</span>
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control"
                        maxLength="4"
                        id="ChangeToBatchNo"
                        name="ChangeToBatchNo"
                        tabIndex="1"
                        value={this.state.changedBatchNo}
                        onChange={this.onChangeBatchNo}
                      />
                      <div className="error-message">
                        {this.state.formErrors["batchNoError"]}
                      </div>
                    </div>
                  </div>
                  <div className="row row-sm mg-t-30">
                    <div className="col-md-3"></div>
                    <div className="col-md-3 mg-t-10 mg-lg-t-0">
                      <span
                        className="mg-t-10 mg-md-t-0 btn btn-gray-700 btn-block"
                        onClick={this.changeBatchNo}
                      >
                        Change
                      </span>
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-3  mg-t-10 mg-lg-t-0">
                      <span
                        className="btn btn-gray-700 btn-block"
                        tabIndex="28"
                        onClick={() =>
                          this.setState({ showChangeBatchNoModal: false })
                        }
                        id="Cancel"
                      >
                        Cancel
                      </span>
                    </div>
                  </div>
                </Modal.Body>
              </LoadingOverlay>
            </Modal>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default ViewProjectBatch;
