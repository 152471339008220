import React, { Component } from "react";
import { Link } from "react-router-dom";
import customerFeedbackTypeService from "../../services/customerFeedbackType.service";
import accessControlService from "../../services/accessControl.service";
import helper from "../../helpers/helpers";
import { Button, Modal } from "react-bootstrap";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class ViewCustomerFeedbackType extends Component {
  constructor(props) {
    super(props); //reference to the parents constructor() function.

    this.handleYes = this.handleYes.bind(this);
    this.handleNo = this.handleNo.bind(this);
    this.showPopUp = this.showPopUp.bind(this);

    this.state = {
      CustomerFeedbackTypes: [
        {
          CustomerFeedbackTypeID: 0,
          FeedbackType: "",
          IsActive: true,
        },
      ],
      showModal: false,
      canAccessEditCustomerFeedbackType: false,
      canAccessDeleteCustomerFeedbackType: false,
      loading: false,
      spinnerMessage: "",
    };

    this.initialState = this.state;
  }

  //#region component mount
  componentDidMount() {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    this.canUserAccessPage("Edit Customer Feedback Type");
    this.canUserAccessPage("Delete Customer Feedback Type");
    this.fetchCustomerFeedbackType();
  }
  //#endregion

  //#region modal functions
  //#region show popup
  showPopUp() {
    this.setState({ showModal: true });
  }
  //#endregion

  //#region handle Yes
  handleYes() {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    this.setState({
      spinnerMessage: "Please wait while deleting Customer Feedback Type...",
      loading: true,
    });

    customerFeedbackTypeService
      .deleteCustomerFeedbackType(
        this.state.CustomerFeedbackTypes.CustomerFeedbackTypeID,
        helper.getUser()
      )
      .then(() => {
        this.setState({ showModal: false });
        toast.success("Customer Feedback Type Deleted Successfully");
        this.props.history.push({
          pathname: "/Masters/CustomerFeedbackTypeList",
        });
      })
      .catch((e) => {
        this.handleNo();
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region handle No
  handleNo() {
    this.setState({ showModal: false });
  }
  //#endregion
  //#endregion

  //#region Fetching selected Customer Feedback Type details
  fetchCustomerFeedbackType() {
    const { state } = this.props.location; // Customer Feedback Type ID passed from Customer Feedback Type List Page
    if (state === 0 || state === null || state === undefined) {
      this.props.history.push("/Masters/CustomerFeedbackTypeList");
      return;
    }

    this.setState({
      spinnerMessage: "Please wait while loading Customer Feedback Type...",
      loading: true,
    });

    customerFeedbackTypeService
      .getCustomerFeedbackType(state, helper.getUser())
      .then((response) => {
        this.setState({
          CustomerFeedbackTypes: response.data,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({ loading: false });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region fetching Customer Feedback Type page access
  canUserAccessPage(pageName) {
    accessControlService
      .CanUserAccessPage(helper.getUser(), pageName)
      .then((response) => {
        if (pageName === "Edit Customer Feedback Type") {
          this.setState({
            canAccessEditCustomerFeedbackType: response.data,
          });
        } else if (pageName === "Delete Customer Feedback Type") {
          this.setState({
            canAccessDeleteCustomerFeedbackType: response.data,
          });
        }
      })
      .catch((e) => {
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region UI
  render() {
    const { CustomerFeedbackTypeID, FeedbackType, IsActive } =
      this.state.CustomerFeedbackTypes;

    const canAccessEditCustomerFeedbackType =
      this.state.canAccessEditCustomerFeedbackType;
    const canAccessDeleteCustomerFeedbackType =
      this.state.canAccessDeleteCustomerFeedbackType;

    return (
      <div className="pro-main-display">
        <LoadingOverlay
          active={this.state.loading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
          <div className="az-content-breadcrumb">
            <span>Master</span>
            <span>Customer Feedback Types</span>
          </div>
          <h4>
            View Customer Feedback Type{" "}
            <span className="icon-size">
              {" "}
              <Link to="/Masters/CustomerFeedbackTypeList" title="Back to List">
                <i className="far fa-arrow-alt-circle-left"></i>
              </Link>
            </span>
          </h4>
          <br />
          <div>
            <div className="row">
              <div className="col-md-8">
                <div className="row row-sm mg-b-5">
                  <div className="col-md-5">
                    <b>Customer Feedback Type ID</b>
                  </div>
                  <div className="col-md-6">
                    <p>{CustomerFeedbackTypeID}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="row row-sm">
                  <div className="col-md-5">
                    <b>Feedback Type</b>
                  </div>
                  <div className="col-md-6">
                    <p>{FeedbackType}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="row row-sm">
                  <div className="col-md-5">
                    <b>Is Active?</b>
                  </div>
                  <div className="col-md-6">
                    {IsActive === false && <p>No</p>}
                    {IsActive === true && <p>Yes</p>}
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row row-sm">
              <div className="col-md-0.5"></div>
              {canAccessEditCustomerFeedbackType && (
                <div className="col-md-2">
                  <Link
                    to={{
                      pathname: "/Masters/EditCustomerFeedbackType",
                      state: CustomerFeedbackTypeID, // passing Customer Feedback Type ID to Edit Customer Feedback Type Page
                    }}
                    className="mg-t-10 mg-md-t-0 btn  btn-gray-700 btn-block"
                  >
                    Edit
                  </Link>
                </div>
              )}
              <div className="col-md-0.5"></div>
              {canAccessDeleteCustomerFeedbackType && (
                <div className="col-md-2">
                  <button
                    className="mg-t-10 mg-md-t-0 btn  btn-gray-700 btn-block"
                    onClick={this.showPopUp}
                  >
                    Delete
                  </button>
                </div>
              )}
            </div>
            <Modal
              show={this.state.showModal}
              aria-labelledby="contained-modal-title-vcenter"
              onHide={this.handleNo}
              backdrop="static"
              enforceFocus={false}
            >
              <Modal.Header>
                <Modal.Title>Delete Customer Feedback Type</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <p>Are you sure to delete this Customer Feedback Type?</p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={this.handleYes}>
                  Yes
                </Button>
                <Button variant="primary" onClick={this.handleNo}>
                  No
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
  //#endregion
}

export default ViewCustomerFeedbackType;
