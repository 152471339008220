import http from "../http-common";

class productionAllocationService {
  //#region Get Allocation pending Customer Codes
  getCustomerCodes(productionUser, status) {
    return http.get(
      `/production/ReadProjectCustomerCodesOfUser/${productionUser}/${status}`
    );
  }
  //#endregion

  //#region Get Project Codes of Selected Customer
  getProjectCodesOfCustomer(customerCode, productionUser, status) {
    return http.get(
      `/production/ReadCustomerProjectCodesOfUser/${customerCode}/${productionUser}/${status}`
    );
  }
  //#endregion

  //#region Get Batch Nos. of Selected Project Code
  getBatchesOfProject(customerCode, projectCode, productionUser, status) {
    return http.get(
      `/production/ReadCustomerProjectBatchNosOfUser/${customerCode}/${projectCode}/${productionUser}/${status}`
    );
  }
  //#endregion

  //#region Get Project Details of Selected Project Code or Batch No.
  getProjectDetails(customerCode, projectCode, batchNo) {
    return http.get(
      `/productionallocation/ReadProjectScope/${customerCode}/${projectCode}/${batchNo}`
    );
  }
  //#endregion

  //#region Get Activity Details of Selected Project Code or Batch No.
  getActivityDetails(customerCode, projectCode, productionUser, batchNo) {
    return http.get(
      `/production/ReadProjectActivitiesOfUser/${customerCode}/${projectCode}/${productionUser}/${batchNo}`
    );
  }
  //#endregion

  //#region Download Production Allocated file
  downloadProductionAllocatedFile(data) {
    return http.post("/production/DownloadProductionAllocationOfUser/", data, {
      responseType: "blob",
    });
  }
  //#endregion

  //#region Upload Production Completed File
  uploadProductionCompletedFile(data) {
    return http.post("/production/ValidateAndUploadProduction/", data);
  }
  //#endregion

  //#region Get Production Uploaded details of Selected Project Code or Batch No.
  getProductionUploadedDetails(
    customerCode,
    projectCode,
    productionUser,
    batchNo
  ) {
    return http.get(
      `/production/ReadExistingProjectUploadsByUser/${customerCode}/${projectCode}/${productionUser}/${batchNo}`
    );
  }
  //#endregion

  //#region Download Allocated file
  downloadUploadedFile(id) {
    return http.get("/production/downloadfile/" + id, {
      responseType: "blob",
    });
  }
  //#endregion

  //#region Delete Production Upload
  deleteProductionUpload(id, userID) {
    return http.patch(`/production/DeleteProductionUpload/${id}/${userID}`);
  }
  //#endregion

  //#region Download Production Error file
  downloadProductionErrorFile(data) {
    return http.post(
      "/production/DownloadProductionAllocationErrorsOfUser/",
      data,
      {
        responseType: "blob",
      }
    );
  }
  //#endregion

  //#region Upload Production Error File
  uploadProductionErrorFile(data) {
    return http.post("/production/ValidateAndUploadProductionErrorSKUs/", data);
  }
  //#endregion

  // #region find duplicates
  findDuplicates(data){
    return http.post("/production/FindDuplicatesOnSelectedColumns", data);
  }
  //  #endregion

  // #region find duplicates
  findCustomerDuplicates(data){
    return http.post("/production/ReadDuplicateSKUsBasedOnSelectedColumnsFromCustomerInputFile", data);
  }
  //  #endregion

   //#region Get Allocation pending Customer Codes
   getqcCustomerCodes() {
    return http.get("/productionallocation");
  }
  //#endregion

   //#region Get Project Codes of Selected Customer
   ReadOnGoingProjectCodesOfCustomer(customerCode) {
    return http.get(
      `/productionallocation/ReadOnGoingProjectCodesOfCustomer/${customerCode}`
    );
  }
  //#endregion

   //#region Get Batch Nos. of Selected Project Code
   ReadOnGoingBatchesOfProject(customerCode, projectCode) {
    return http.get(
      `/productionallocation/ReadOnGoingBatchesOfProject/${customerCode}/${projectCode}`
    );
  }
  //#endregion

   //#region Search 
   ReadAllSKUsPendingForQCFromSelectedProjectOrBatch(
    searchData
  ){
    return http.get(
      `/production/ReadAllSKUsPendingForQCFromSelectedProjectOrBatch/?CustomerCode=${searchData.customerCode}&ProjectCode=${searchData.projectCode}`
    );
  }

  //#endregion


}

export default new productionAllocationService();
