import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import projectService from "../../services/project.service";
import Moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import helper from "../../helpers/helpers";
import tableFunctions from "../../helpers/tableFunctions";
import accessControlService from "../../services/accessControl.service";
import { Row, Col } from "react-bootstrap";
toast.configure();

class NotStartedProjectList extends Component {
  constructor(props) {
    super(props);

    this.divScrollRef = React.createRef();

    this.displaySortingFields = this.displaySortingFields.bind(this);
    this.displayFilteringField = this.displayFilteringField.bind(this);
    this.onChangefilterValue = this.onChangefilterValue.bind(this);
    this.onChangeColumn = this.onChangeColumn.bind(this);
    this.onChangeSortOrder = this.onChangeSortOrder.bind(this);
    this.sortData = this.sortData.bind(this);
    this.clearSort = this.clearSort.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.exportNotStartedProjectListToExcel =
      this.exportNotStartedProjectListToExcel.bind(this);

    this.state = {
      canAccessViewProject: false,
      canAccessProjectBatchList: false,
      loading: false,
      spinnerMessage: "",
      projects: [],
      index: 20,
      position: 0,
      columns: [],
      isToShowSortingFields: false,
      isToShowFilteringField: true,
      selectedColumn: "",
      selectedSort: "",
      filteredArray: [],
      filterValue: "",
    };
  }

  //#region component mount
  componentDidMount() {
    this.canUserAccessPage("View Project");
    this.canUserAccessPage("Project Batch List");

    this.fetchNotStartedProjectList();
  }
  //#endregion

  //#region Fetch Not Started Project List
  async fetchNotStartedProjectList() {
    this.setState({
      spinnerMessage: "Please wait while fetching not started project List...",
      loading: true,
    });

    var data = {
      CustomerCode: this.props.customerCode,
      ProjectCode: this.props.projectCode,
      ProjectType: this.props.projectType,
      FromDate: this.props.fromDate,
      ToDate: this.props.toDate,
      UserID: helper.getUser(),
    };

    await projectService
      .readNotStartedProjectsList(data)
      .then((response) => {
        let formattedArray = response.data.map((obj) => ({
          ...obj,
          ReceivedDate: Moment(obj.ReceivedDate).format("DD-MMM-yyyy"),
          PlannedDeliveryDate:
            obj.PlannedDeliveryDate !== null
              ? Moment(obj.PlannedDeliveryDate).format("DD-MMM-yyyy")
              : "",
        }));

        this.setState({ loading: false, projects: formattedArray });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region fetching Project page access
  canUserAccessPage(pageName) {
    accessControlService
      .CanUserAccessPage(helper.getUser(), pageName)
      .then((response) => {
        if (pageName === "View Project") {
          this.setState({
            canAccessViewProject: response.data,
          });
        } else if (pageName === "Project Batch List") {
          this.setState({
            canAccessProjectBatchList: response.data,
          });
        }
      })
      .catch((e) => {
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Export Not Started Project List to Excel
  exportNotStartedProjectListToExcel() {
    this.setState({
      spinnerMessage:
        "Please wait while exporting not started project list to excel...",
      loading: true,
    });

    let fileName = "Not Started Projects List.xlsx";

    var data = {
      CustomerCode: this.props.customerCode,
      ProjectCode: this.props.projectCode,
      ProjectType: this.props.projectType,
      FromDate: this.props.fromDate,
      ToDate: this.props.toDate,
      UserID: helper.getUser(),
    };

    projectService
      .exportNotStartedProjectListToExcel(data)
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);
        fileLink.click();

        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Filter Functions
  //#region Display Filtering Field
  displayFilteringField() {
    this.setState((previousState) => ({
      isToShowFilteringField: !previousState.isToShowFilteringField,
      filterValue: "",
      isToShowSortingFields: false,
    }));
  }
  //#endregion

  //#region on change filter value
  onChangefilterValue(e) {
    this.setState({ filterValue: e.target.value }, () =>
      this.getFilteredRows()
    );
  }
  //#endregion

  //#region get filtered rows
  getFilteredRows() {
    const filteredArray = tableFunctions.filterArray(
      this.state.projects,
      this.state.filterValue
    );

    this.setState({ filteredArray: filteredArray });
  }
  //#endregion

  //#region Clear Search
  clearSearch() {
    this.setState({
      filterValue: "",
    });
  }
  //#endregion
  //#endregion

  //#region  Sort Functions
  //#region Display Sorting Fields
  displaySortingFields() {
    let columns = Object.keys(this.state.projects[0]);
    let excludedColumns = [
      "ProjectID",
      "LocationCode",
      "TypeOfInput",
      "InputCountType",
      "ReceivedFormat",
      "OutputFormat",
      "PlannedStartDate",
      "DeliveryMode",
      "DeliveryPlanFileName",
      "IsResourceBased",
      "Remarks",
      "CustomerInputFileName",
      "ScopeFileName",
      "Guideline",
      "GuidelineFileName",
      "Checklist",
      "ChecklistFileName",
      "EmailDate",
      "EmailDescription",
      "Status",
      "canUserChangeProjectStatus",
      "HoldOnDate",
      "HoldOnReason",
      "DeliveredOn",
      "DeliveredCount",
      "Activities",
      "UserID",
    ];

    columns = columns.filter((item) => !excludedColumns.includes(item));

    this.setState((previousState) => ({
      isToShowSortingFields: !previousState.isToShowSortingFields,
      selectedColumn: "",
      selectedSort: "",
      columns: columns,
      filterValue: "",
      isToShowFilteringField: false,
    }));
  }
  //#endregion

  //#region Selecting the sort column
  onChangeColumn(e) {
    this.setState({
      selectedColumn: e.target.value,
      selectedSort: "",
    });
  }
  //#endregion

  //#region On Change Sort
  onChangeSortOrder(e) {
    this.setState(
      {
        selectedSort: e.target.value,
      },
      () => this.sortData()
    );
  }
  //#endregion

  //#region Sort Data based on column and order
  sortData() {
    let sortedArray = [];
    let column =
      this.state.selectedColumn !== "" ? this.state.selectedColumn : "SlNo";
    const selectedSort =
      this.state.selectedSort !== "" ? this.state.selectedSort : "ascending";
    let numberColumns = ["SlNo", "NoOfBatches", "InputCount"];
    let dateColumns = ["ReceivedDate", "PlannedDeliveryDate"];

    sortedArray = tableFunctions.sortData(
      this.state.projects,
      column,
      selectedSort,
      numberColumns,
      dateColumns
    );

    this.setState({ projects: sortedArray });
  }
  //#endregion

  // region Navigate to project settings page
  showProjectSetingPage = (row) => {
    let locationData =  {
      CustomerCode: row.CustomerCode,
      ProjectCode: row.ProjectCode,
      activeTab: 1,
    }

    sessionStorage.setItem("locationData", JSON.stringify(locationData));
    if(this.state.canAccessProjectBatchList){
      this.props.history.push({
        pathname: "/Allocation/screenProjectSettings",
      });
    }
  }
  // #endregion

  //#region  Clear Sort
  clearSort() {
    this.setState(
      {
        selectedColumn: "",
        selectedSort: "",
      },
      () => this.sortData()
    );
  }
  //#endregion
  //#endregion

  //#region Handle Scroll
  handleScroll(e) {
    var currentHeight = e.currentTarget.scrollTop;
    var maxScrollPosition =
      e.currentTarget.scrollHeight - e.currentTarget.clientHeight;

    this.setState({ position: currentHeight });

    if ((currentHeight / maxScrollPosition) * 100 > 90) {
      let curIndex = this.state.index + 20;
      this.setState({ index: curIndex });
    }
  }
  //#endregion

  //#region Scroll to Top
  scrollToTop = () => {
    this.divScrollRef.current.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  //#endregion

  render() {
    const data = this.state.projects.slice(0, this.state.index);
    data.forEach(items => {
      items.Action = "Edit Project Setting"
    })

    const filteredData = this.state.filteredArray.slice(0, this.state.index);
    const canAccessViewProject = this.state.canAccessViewProject;
    const canAccessProjectBatchList = this.state.canAccessProjectBatchList;

    const projectListColumns = [
      {
        dataField: "SlNo",
        text: "Sl No",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "ProjectID",
        text: "Proj ID",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
        hidden: true,
      },
      {
        dataField: "CustomerCode",
        text: "Cus Code	",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "ProjectCode",
        text: "Proj Code	",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
        classes: canAccessViewProject ? "demo-key-row1" : "",
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            canAccessViewProject &&
              this.props.history.push({
                pathname: "/Projects/ViewProject",
                state: {
                  ProjectID: row.ProjectID,
                  activeTab: 3,
                }, // passing Project ID to View Project Page
              });
          },
        },
      },
      {
        dataField: "Scope",
        text: "Description",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "left",
        align: "left",
        title: true,
      },
      {
        dataField: "ReceivedDate",
        text: "Received Date",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "PlannedDeliveryDate",
        text: "Planned Del Date",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "NoOfBatches",
        text: "Batches",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
        classes: canAccessProjectBatchList ? "demo-key-row1" : "",
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            canAccessProjectBatchList &&
              this.props.history.push({
                pathname: "/Projects/ProjectBatchList",
                state: {
                  ProjectID: row.ProjectID,
                  CustomerCode: row.CustomerCode,
                  ProjectCode: row.ProjectCode,
                  Scope: row.Scope,
                  activeTab: 1,
                }, // passing Project details to Project Batch List Page
              });
          },
        },
      },
      {
        dataField: "Action",
        text: "Edit Screen Settings",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        formatter: (cell, row, rowIndex, extraData) => (
          <div>
            <i
              className="fas fa-edit pointer"
              title="Edit Screen Settings"
              onClick={() =>
                this.showProjectSetingPage(row)
              }
            ></i>
          </div>
        ),
        headerAlign: "center",
        align: "center",
        classes: canAccessProjectBatchList ? "demo-key-row1" : "",
      },
      {
        dataField: "ProjectType",
        text: "Project Type",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "InputCount",
        text: "Input Count",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
      },
    ];

    return (
      <>
        <LoadingOverlay
          active={this.state.loading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
          <div className="az-content-body pd-lg-l-40 d-flex flex-column">
            <ToolkitProvider
              keyField="ProjectID"
              data={this.state.filterValue === "" ? data : filteredData}
              columns={projectListColumns}
            >
              {(props) => (
                <div className="mg-t-5">
                  <Row
                    className="mg-b-10"
                    style={{ marginRight: "15px", whiteSpace: "nowrap" }}
                  >
                    <Col md={10} style={{ whiteSpace: "nowrap" }}>
                      {this.state.isToShowSortingFields && (
                        <Row>
                          <Col md={4}>
                            <Row>
                              <Col lg={3} className="mg-t-7">
                                <label htmlFor="sortColumn">Column:</label>
                              </Col>
                              <Col lg={9}>
                                <select
                                  className="form-control mg-l-5"
                                  value={this.state.selectedColumn}
                                  onChange={this.onChangeColumn}
                                >
                                  <option value="">--Select--</option>
                                  {this.state.columns.map((col) => (
                                    <option key={col}>{col}</option>
                                  ))}
                                </select>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={4}>
                            <Row>
                              <Col md={3} className="mg-t-7">
                                <label htmlFor="sortOrder">Order:</label>
                              </Col>
                              <Col md={9}>
                                <select
                                  className="form-control mg-l-5"
                                  value={this.state.selectedSort}
                                  onChange={this.onChangeSortOrder}
                                >
                                  <option value="">--Select--</option>
                                  <option value="ascending">Ascending</option>
                                  <option value="descending">Descending</option>
                                </select>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={2}>
                            <i
                              className="far fa-window-close btn btn-primary pd-b-5"
                              onClick={this.clearSort}
                              title="Clear Sort Fields"
                            ></i>
                          </Col>
                        </Row>
                      )}
                      {this.state.isToShowFilteringField && (
                        <Row>
                          <Col md={1} className="mg-t-7">
                            <label htmlFor="search">Search:</label>
                          </Col>
                          <Col md={10} className="pd-r-10">
                            <input
                              type="text"
                              className="form-control mg-l-10"
                              maxLength="20"
                              value={this.state.filterValue}
                              onChange={this.onChangefilterValue}
                            />
                          </Col>
                          <Col md={1}>
                            <i
                              className="far fa-window-close btn btn-primary pd-b-5"
                              onClick={this.clearSearch}
                              title="Clear Filter"
                            ></i>
                          </Col>
                        </Row>
                      )}
                    </Col>
                    {this.state.projects.length > 0 && (
                      <Col
                        md={2}
                        style={{
                          textAlign: "end",
                          marginTop: "10px",
                          marginLeft: "0px",
                        }}
                      >
                        <i
                          className="fas fa-exchange-alt fa-rotate-90 pointer"
                          title={
                            this.state.isToShowSortingFields
                              ? "Hide Sort"
                              : "Show Sort"
                          }
                          onClick={this.displaySortingFields}
                        ></i>
                        {!this.state.isToShowFilteringField ? (
                          <i
                            className="fas fa-filter pointer mg-l-10"
                            onClick={this.displayFilteringField}
                            title="Show Filter"
                          ></i>
                        ) : (
                          <i
                            className="fas fa-funnel-dollar pointer mg-l-10"
                            onClick={this.displayFilteringField}
                            title="Hide Filter"
                          ></i>
                        )}
                        <i
                          className="fas fa-file-excel mg-l-10 pointer"
                          style={{ color: "green" }}
                          onClick={this.exportNotStartedProjectListToExcel}
                          title="Export to Excel"
                        ></i>
                      </Col>
                    )}
                  </Row>
                  <div style={{ width: "98.3%" }}>
                    <div
                      style={{
                        overflowY: "scroll",
                        borderBottom: "1px solid #cdd4e0",
                      }}
                      ref={this.divScrollRef}
                      className="not-started-project-list-table-height"
                      onScroll={this.handleScroll}
                    >
                      <BootstrapTable
                        bootstrap4
                        {...props.baseProps}
                        striped
                        hover
                        condensed
                      />
                    </div>
                  </div>
                </div>
              )}
            </ToolkitProvider>
            {this.state.position > 600 && (
              <div className="up-arrow">
                <i
                  className="fa fa-angle-up pro-arrow"
                  onClick={this.scrollToTop}
                ></i>
              </div>
            )}
          </div>
        </LoadingOverlay>
      </>
    );
  }
}

export default NotStartedProjectList;
