import http from "../http-common";

class QCAllocationService {
  //#region Get QCAllocated Customer Codes of logedin User
  getCustomerCodes(QCUser, status) {
    return http.get(`/QC/ReadProjectCustomerCodesOfUser/${QCUser}/${status}`);
  }
  //#endregion

  //#region Get Project Codes of Selected Customer
  getProjectCodesOfCustomer(customerCode, QCUser, status) {
    return http.get(
      `/QC/ReadCustomerProjectCodesOfUser/${customerCode}/${QCUser}/${status}`
    );
  }
  //#endregion

  //#region Get Batch Nos. of Selected Project Code
  getBatchesOfProject(customerCode, projectCode, QCUser, status) {
    return http.get(
      `/QC/ReadCustomerProjectBatchNosOfUser/${customerCode}/${projectCode}/${QCUser}/${status}`
    );
  }
  //#endregion

  //#region Get Activity Details of Selected Project Code or Batch No.
  getActivityDetails(customerCode, projectCode, QCUser, batchNo) {
    return http.get(
      `/QC/ReadProjectActivitiesOfUser/${customerCode}/${projectCode}/${QCUser}/${batchNo}`
    );
  }
  //#endregion

  //#region Download QC Allocated file
  downloadQCAllocatedFile(data) {
    return http.post("/QC/DownloadQCAllocationOfUser/", data, {
      responseType: "blob",
    });
  }
  //#endregion

  //#region Upload QC Completed File
  uploadQCCompletedFile(data) {
    return http.post("/QC/ValidateAndUploadQC/", data);
  }
  //#endregion

  //#region Get QC Uploaded details of Selected Project Code or Batch No.
  getQCUploadedDetails(customerCode, projectCode, QCUser, batchNo) {
    return http.get(
      `/QC/ReadExistingProjectUploadsByUser/${customerCode}/${projectCode}/${QCUser}/${batchNo}`
    );
  }
  //#endregion

  //#region Download QC Uploaded file
  downloadQCUploadedFile(id) {
    return http.get("/QC/downloadfile/" + id, {
      responseType: "blob",
    });
  }
  //#endregion

  //#region Delete Production Upload
  deleteQCUpload(id, userID) {
    return http.patch(`/QC/DeleteQCUpload/${id}/${userID}`);
  }
  //#endregion

  //#region Download Production Error file
  downloadProductionErrorFile(data) {
    return http.post("/QC/DownloadProductionErrorSKUs/", data, {
      responseType: "blob",
    });
  }
  //#endregion
}

export default new QCAllocationService();
