import React, { useState, useEffect, useRef } from "react";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import { MaterialReactTable } from "material-react-table";
import { Box, IconButton, Tooltip } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { CSVLink } from "react-csv";
import projectSubActivityService from "../../services/projectSubActivity.service";
import accessControlService from "../../services/accessControl.service";
import helper from "../../helpers/helpers";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

export default function ProjectSubActivityList() {
  const history = useHistory();
  const [projectSubActivitiesData, setProjectSubActivities] = useState([]);
  const [canAccessCreateProjectSubActivity, setCanAccessCreateProjectSubActivity] = useState(false);
  const [canAccessViewProjectSubActivity, setCanAccessViewProjectSubActivity] = useState(false);
  const [initStates, setInitStates] = useState({
    spinnerMessage: "",
    loading: false,
  });
  const [projectSubActivityTableColumns, setProjectSubActivityListColumns] = useState([]);
  const csvLink = useRef(null);

  //#region Use effect
  useEffect(() => {
    if (!helper.getUser()) {
      history.push({
        pathname: "/",
      });
      return;
    }
    canUserAccessPage();
    projectSubActivityTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion

  //#region Project Sub Activity Table Data
  const projectSubActivityTable = () => {
    const columns = [
      {
        accessorKey: "SlNo",
        header: "Sl No.",
        muiTableHeadCellProps: { align: "center", style: { width: "10%" } },
        muiTableBodyCellProps: { align: "center" },
      },
      {
        accessorKey: "ProjectSubActivityID",
        header: "Sub Activity ID",
        muiTableHeadCellProps: { align: "center", style: { width: "10%" } },
        muiTableBodyCellProps: { align: "center" },
      },
      {
        accessorKey: "SubActivity",
        header: "Sub Activity",
        muiTableHeadCellProps: { align: "center" },
        muiTableBodyCellProps: { align: "left" },
        Cell: ({ row }) => {
          const { ProjectSubActivityID, SubActivity } = row.original;
          return (
            <span
              style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
              onClick={() => navigateToView(ProjectSubActivityID)}
            >
              {SubActivity}
            </span>
          );
        },
      },
      {
        accessorKey: "IsActive",
        header: "Is Active?",
        muiTableHeadCellProps: { align: "center", style: { width: "10%" } },
        muiTableBodyCellProps: { align: "center" },
      },
    ];
    setProjectSubActivityListColumns(columns);
  };
  //#endregion

  //#region fetching project sub activities from Web API
  const fetchProjectSubActivities = () => {
    setInitStates({
      spinnerMessage: "Please wait while loading Project Sub-Activities List...",
      loading: true,
    });

    projectSubActivityService
      .getAllSubActivities(helper.getUser())
      .then((response) => {
        const formattedArray = response.data.map((obj, index) => ({
          SlNo: index + 1,
          ...obj,
          IsActive: obj.IsActive ? "Yes" : "No",
        }));
        setProjectSubActivities(formattedArray);
      })
      .catch((e) => {
        toast.error(e.response?.data?.Message, { autoClose: false });
      })
      .finally(() => {
        setInitStates({
          spinnerMessage: "",
          loading: false,
        });
      });
  };
  //#endregion

  //#region fetching Project Sub-Activity page access
  const canUserAccessPage = () => {
    accessControlService
      .CanUserAccessPage(helper.getUser(), "Create Project SubActivity")
      .then((response) => setCanAccessCreateProjectSubActivity(response.data))
      .catch(() => toast.error("Error checking access."));

    accessControlService
      .CanUserAccessPage(helper.getUser(), "View Project SubActivity")
      .then((response) => {
        setCanAccessViewProjectSubActivity(response.data);
        fetchProjectSubActivities();
      })
      .catch(() => toast.error("Error checking access."));
  };
  //#endregion

  //#region Navigate to View Project SubActivity Page
  const navigateToView = (ProjectSubActivityID) => {
    history.push({
      pathname: "/Masters/ViewProjectSubActivity",
      state: { ProjectSubActivityID },
    });
  };
  //#endregion

  //#region Export to CSV
  const handleCSVExport = () => {
    csvLink.current.link.click();
  };
  //#endregion

  //#region Transformed Data CSV For Export
  const getTransformedDataForExport = () => {
    return projectSubActivitiesData.map((row) => ({
      "Sl No.": row.SlNo,
      "Sub Activity ID": row.ProjectSubActivityID,
      "Sub Activity": row.SubActivity,
      "Is Active?": row.IsActive,
    }));
  };
  //#endregion

  //#region return
  return (
    <div className="pro-main-display">
      <LoadingOverlay
        active={initStates.loading}
        spinner={
          <div className="spinner-background">
            <BarLoader color="#38D643" width="350px" />
            <p style={{ marginTop: "10px", fontSize: "16px", color: "#333" }}>
              {initStates.spinnerMessage || "Please wait while loading Project Sub-Activities List..."}
            </p>
          </div>
        }
      >
        <div className="az-content-breadcrumb mg-l-10">
          <span>Master</span>
          <span>Sub-Activities</span>
        </div>
        <h4 className="mg-l-10">
          Project Sub-Activities List{" "}
          {canAccessCreateProjectSubActivity && (
            <IconButton onClick={() => history.push("/Masters/AddProjectSubActivity")}>
              <i className="fa fa-plus text-primary pointer" title="Add New Project Sub Activity" />
            </IconButton>
          )}
        </h4>

        {canAccessViewProjectSubActivity && (
          <div className="masters-material-table mg-l-10 projectSubActivityTypeTable">
            <MaterialReactTable
              keyField="ProjectSubActivityID"
              columns={projectSubActivityTableColumns}
              data={projectSubActivitiesData}
              enablePagination={false}
              enableStickyHeader
              renderTopToolbarCustomActions={() => (
                <Box sx={{ display: "flex", gap: "16px" }}>
                  <Tooltip title="Export CSV">
                    <IconButton onClick={handleCSVExport}>
                      <FileDownloadIcon style={{ color: "#5B47FB" }} />
                    </IconButton>
                  </Tooltip>
                  <CSVLink
                    data={getTransformedDataForExport()}
                    filename="ProjectSubActivityData.csv"
                    ref={csvLink}
                  />
                </Box>
              )}
            />
          </div>
        )}
      </LoadingOverlay>
    </div>
  );
  //#endregion
}
