import React, { Component } from "react";
import { Link } from "react-router-dom";
import genericActivityService from "../../services/genericActivity.service";
import accessControlService from "../../services/accessControl.service";
import helper from "../../helpers/helpers";
import { Button, Modal } from "react-bootstrap";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class ViewGenericActivity extends Component {
  constructor(props) {
    super(props); //reference to the parents constructor() function.

    this.handleYes = this.handleYes.bind(this);
    this.handleNo = this.handleNo.bind(this);
    this.showPopUp = this.showPopUp.bind(this);

    this.state = {
      GenericActivities: [
        {
          GenerictActivityID: 0,
          Activity: "",
          IsActive: true,
        },
      ],
      showModal: false,
      canAccessEditGenericActivity: false,
      canAccessDeleteGenericActivity: false,
      loading: false,
      spinnerMessage: "",
    };

    this.initialState = this.state;
  }

  //#region component mount
  componentDidMount() {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    this.canUserAccessPage("Edit Generic Activity");
    this.canUserAccessPage("Delete Generic Activity");
    this.fetchGenericActivity();
  }
  //#endregion

  //#region Fetching selected Generic Activity details
  fetchGenericActivity() {
    const { state } = this.props.location; // Generic Activity ID passed from Generic Activity List Page
    if (state === 0 || state === null || state === undefined) {
      this.props.history.push("/Masters/GenericActivities");
      return;
    }

    this.setState({
      spinnerMessage: "Please wait while loading Generic Activity...",
      loading: true,
    });

    genericActivityService
      .getGenericActivity(state, helper.getUser())
      .then((response) => {
        this.setState({
          GenericActivities: response.data,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({ loading: false });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region fetching Generic Activity page access
  canUserAccessPage(pageName) {
    accessControlService
      .CanUserAccessPage(helper.getUser(), pageName)
      .then((response) => {
        if (pageName === "Edit Generic Activity") {
          this.setState({
            canAccessEditGenericActivity: response.data,
          });
        } else if (pageName === "Delete Generic Activity") {
          this.setState({
            canAccessDeleteGenericActivity: response.data,
          });
        }
      })
      .catch((e) => {
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region modal functions
  //#region show popup
  showPopUp() {
    this.setState({ showModal: true });
  }
  //#endregion

  //#region handle Yes
  handleYes() {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    this.setState({
      spinnerMessage: "Please wait while deleting Generic Activity...",
      loading: true,
    });

    genericActivityService
      .deleteGenericActivity(
        this.state.GenericActivities.GenericActivityID,
        helper.getUser()
      )
      .then(() => {
        this.setState({ showModal: false });
        toast.success("Generic Activity Deleted Successfully");
        this.props.history.push({
          pathname: "/Masters/GenericActivities",
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
        this.handleNo();
      });
  }
  //#endregion

  //#region handle No
  handleNo() {
    this.setState({ showModal: false });
  }
  //#endregion
  //#endregion

  //#region UI
  render() {
    const { GenericActivityID, Activity, IsActive } =
      this.state.GenericActivities;

    const canAccessEditGenericActivity =
      this.state.canAccessEditGenericActivity;
    const canAccessDeleteGenericActivity =
      this.state.canAccessDeleteGenericActivity;

    return (
      <div className="pro-main-display">
        <LoadingOverlay
          active={this.state.loading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
          <div className="az-content-breadcrumb">
            <span>Master</span>
            <span>Generic-Activities</span>
          </div>
          <h4>
            View Generic Activity{" "}
            <span className="icon-size">
              {" "}
              <Link to="/Masters/GenericActivities" title="Back to List">
                <i className="far fa-arrow-alt-circle-left"></i>
              </Link>
            </span>
          </h4>
          <br />
          <div>
            <div className="row">
              <div className="col-md-8">
                <div className="row row-sm mg-b-5">
                  <div className="col-md-3">
                    <b>Generic Activity ID</b>
                  </div>
                  <div className="col-md-6">
                    <p>{GenericActivityID}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="row row-sm">
                  <div className="col-md-3">
                    <b>Activity</b>
                  </div>
                  <div className="col-md-6">
                    <p>{Activity}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="row row-sm">
                  <div className="col-md-3">
                    <b>Is Active?</b>
                  </div>
                  <div className="col-md-6">
                    {IsActive === false && <p>No</p>}
                    {IsActive === true && <p>Yes</p>}
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row row-sm">
              <div className="col-md-0.5"></div>
              {canAccessEditGenericActivity && (
                <div className="col-md-2">
                  <Link
                    to={{
                      pathname: "/Masters/EditGenericActivity",
                      state: GenericActivityID, // passing Generic Activity to Edit Generic Activity Page
                    }}
                    className="mg-t-10 mg-md-t-0 btn  btn-gray-700 btn-block"
                  >
                    Edit
                  </Link>
                </div>
              )}
              <div className="col-md-0.5"></div>
              {canAccessDeleteGenericActivity && (
                <div className="col-md-2">
                  <button
                    className="mg-t-10 mg-md-t-0 btn  btn-gray-700 btn-block"
                    onClick={this.showPopUp}
                  >
                    Delete
                  </button>
                </div>
              )}
            </div>
            <Modal
              show={this.state.showModal}
              aria-labelledby="contained-modal-title-vcenter"
              onHide={this.handleNo}
              backdrop="static"
              enforceFocus={false}
            >
              <Modal.Header>
                <Modal.Title>Delete Generic Activity</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <p>Are you sure to delete this Generic Activity?</p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={this.handleYes}>
                  Yes
                </Button>
                <Button variant="primary" onClick={this.handleNo}>
                  No
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
  //#endregion
}

export default ViewGenericActivity;
