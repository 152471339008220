import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import userRoleService from "../../services/userRole.service";
import tableFunctions from "../../helpers/tableFunctions";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import helper from "../../helpers/helpers";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import accessControlService from "../../services/accessControl.service";
import { Row, Col } from "react-bootstrap";
toast.configure();

class UserRoleList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userRoles: [],
      canAccessCreateUserRole: false,
      canAccessViewUserRole: false,
      loading: false,
      spinnerMessage: "",
      index: 30,
      position: 0,
      columns: [],
      selectedColumn: "",
      selectedSort: "",
      isToShowSortingFields: false,
      isToShowFilteringField: true,
      filteredArray: [],
      filterValue: "",
      userRolesArrayForSorting: [],
    };

    this.divScrollRef = React.createRef();

    this.moveToCreateUserRole = this.moveToCreateUserRole.bind(this);
    this.exportUserRoleListToExcel = this.exportUserRoleListToExcel.bind(this);
    this.onChangeSortOrder = this.onChangeSortOrder.bind(this);
    this.onChangeColumn = this.onChangeColumn.bind(this);
    this.sortData = this.sortData.bind(this);
    this.displaySortingFields = this.displaySortingFields.bind(this);
    this.displayFilteringField = this.displayFilteringField.bind(this);
    this.onChangefilterValue = this.onChangefilterValue.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.clearSortFields = this.clearSortFields.bind(this);
  }

  //#region page load
  componentDidMount() {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    this.canUserAccessPage("Create-Edit User Role(s)");
    this.canUserAccessPage("View User Role(s)");
  }
  //#endregion

  //#region fetching User Role page access
  canUserAccessPage(pageName) {
    accessControlService
      .CanUserAccessPage(helper.getUser(), pageName)
      .then((response) => {
        if (pageName === "Create-Edit User Role(s)") {
          this.setState({
            canAccessCreateUserRole: response.data,
          });
        } else if (pageName === "View User Role(s)") {
          this.setState({
            canAccessViewUserRole: response.data,
          });
        }
        this.fetchUserRole();
      })
      .catch((e) => {
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Redirect to Create User Role Page
  moveToCreateUserRole() {
    this.props.history.push("/admin/UserRoles");
  }
  //#endregion

  //#region fetching User Roles from Web API
  fetchUserRole() {
    this.setState({
      spinnerMessage: "Please wait while loading User Role List...",
      loading: true,
    });

    userRoleService
      .ReadUserRoles(helper.getUser())
      .then((response) => {
        let formattedArray = response.data.map((obj) => ({
          ...obj,
          IsActive: obj.IsActive === true ? "Yes" : "No",
        }));

        this.setState({
          userRoles: formattedArray,
          loading: false,
          userRolesArrayForSorting: response.data,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Export User Role List to Excel
  exportUserRoleListToExcel() {
    this.setState({
      spinnerMessage: "Please wait while exporting user role list to excel...",
      loading: true,
    });

    let fileName = "User Role List.xlsx";

    userRoleService
      .exportUserRoleListToExcel()
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);
        fileLink.click();

        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Scroll to Top
  scrollToTop = () => {
    this.divScrollRef.current.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  //#endregion

  //#region Sort Functions
  //#region Display Sorting Fields
  displaySortingFields() {
    let columns = Object.keys(this.state.userRoles[0]).slice(1);

    this.setState((previousState) => ({
      isToShowSortingFields: !previousState.isToShowSortingFields,
      selectedColumn: "",
      selectedSort: "",
      columns: columns,
      filterValue: "",
      isToShowFilteringField: false,
    }));
  }
  //#endregion

  //#region Selecting the sort column
  onChangeColumn(e) {
    this.setState({
      selectedColumn: e.target.value,
      selectedSort: "",
    });
  }
  //#endregion

  //#region On Change Sort
  onChangeSortOrder(e) {
    this.setState(
      {
        selectedSort: e.target.value,
      },
      () => this.sortData()
    );
  }
  //#endregion

  //#region Sort Data based on column and order
  sortData() {
    let sortedArray = [];
    let column =
      this.state.selectedColumn !== "" ? this.state.selectedColumn : "SlNo";
    const selectedSort =
      this.state.selectedSort !== "" ? this.state.selectedSort : "ascending";
    let numberColumns = ["SlNo"];

    sortedArray = tableFunctions.sortData(
      this.state.userRoles,
      column,
      selectedSort,
      numberColumns,
      []
    );

    sortedArray = sortedArray.map((roles, index) => {
      return {
        ...roles,
        SlNo: index + 1,
      };
    });
    this.setState({ userRoles: sortedArray });
  }
  //#endregion

  //#region  Clear Sort
  clearSortFields() {
    let formattedArray = this.state.userRolesArrayForSorting.map((obj) => ({
      ...obj,
      IsActive: obj.IsActive === true ? "Yes" : "No",
    }));

    this.setState({
      userRoles: formattedArray,
      loading: false,
    });
    this.setState({
      selectedColumn: "",
      selectedSort: "",
      userRoles: formattedArray,
    });
  }
  //#endregion
  //#endregion

  //#region Filter Functions
  //#region Display Filtering Field
  displayFilteringField() {
    this.setState((previousState) => ({
      isToShowFilteringField: !previousState.isToShowFilteringField,
      filterValue: "",
      isToShowSortingFields: false,
    }));
  }
  //#endregion

  //#region on change filter value
  onChangefilterValue(e) {
    this.setState({ filterValue: e.target.value }, () =>
      this.getFilteredRows()
    );
  }
  //#endregion

  //#region get filtered rows
  getFilteredRows() {
    let filteredArray = tableFunctions.filterArray(
      this.state.userRoles,
      this.state.filterValue
    );

    filteredArray = filteredArray.map((userRoles, index) => {
      return {
        ...userRoles,
        SlNo: index + 1,
      };
    });

    this.setState({ filteredArray: filteredArray });
  }
  //#endregion

  //#region Clear Search
  clearSearch() {
    this.setState({
      filterValue: "",
    });
  }
  //#endregion
  //#endregion

  //#region Handle Scroll
  handleScroll(e) {
    var currentHeight = e.currentTarget.scrollTop;
    var maxScrollPosition =
      e.currentTarget.scrollHeight - e.currentTarget.clientHeight;

    this.setState({ position: currentHeight });

    if ((currentHeight / maxScrollPosition) * 100 > 90) {
      let curIndex = this.state.index + 20;
      this.setState({ index: curIndex });
    }
  }
  //#endregion

  render() {
    const canAccessCreateUserRole = this.state.canAccessCreateUserRole;
    const canAccessViewUserRole = this.state.canAccessViewUserRole;

    const data = this.state.userRoles.slice(0, this.state.index);
    const filteredData = this.state.filteredArray.slice(0, this.state.index);

    const UserRolesColumns = [
      {
        dataField: "SlNo",
        text: "Sl No",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "UserName",
        text: "User Name",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
        classes: canAccessViewUserRole ? "demo-key-row1" : "",
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            canAccessViewUserRole &&
              this.props.history.push({
                pathname: "/admin/ViewUserRole",
                state: row.UserName,
              });
          },
        },
      },
      {
        dataField: "RoleName",
        text: "Role Name",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "left",
        align: "left",
      },
      {
        dataField: "IsActive",
        text: "Is Active?",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
      },
    ];

    return (
      <div>
        <LoadingOverlay
          active={this.state.loading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
          <div className="az-content-breadcrumb">
            <span>Users</span>
            <span>User Roles-List</span>
          </div>
          <h4>
            User Role(s) List{" "}
            {canAccessCreateUserRole && (
              <span className="icon-size">
                <i
                  className="fa fa-plus text-primary pointer"
                  onClick={this.moveToCreateUserRole}
                  title="Create User Role"
                ></i>
              </span>
            )}
          </h4>
          <ToolkitProvider
            keyField="SlNo"
            data={this.state.filterValue === "" ? data : filteredData}
            columns={UserRolesColumns}
            search={{ searchFormatted: true }}
          >
            {(props) => (
              <div className="mg-t-10">
                <Row style={{ marginRight: "5px" }}>
                  <Col md={10} style={{ whiteSpace: "nowrap" }}>
                    {this.state.isToShowSortingFields && (
                      <Row>
                        <Col md={4}>
                          <Row>
                            <Col md={3} className="mg-t-7">
                              <label htmlFor="sortColumn">Column:</label>
                            </Col>
                            <Col md={9}>
                              <select
                                className="form-control mg-l-5"
                                value={this.state.selectedColumn}
                                onChange={this.onChangeColumn}
                              >
                                <option value="">--Select--</option>
                                {this.state.columns.map((col) => (
                                  <option key={col}>{col}</option>
                                ))}
                              </select>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={4}>
                          <Row>
                            <Col md={3} className="mg-t-7">
                              <label htmlFor="sortOrder">Order:</label>
                            </Col>
                            <Col md={9}>
                              <select
                                className="form-control mg-l-5"
                                value={this.state.selectedSort}
                                onChange={this.onChangeSortOrder}
                              >
                                <option value="">--Select--</option>
                                <option value="ascending">Ascending</option>
                                <option value="descending">Descending</option>
                              </select>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={2}>
                          <i
                            className="far fa-window-close btn btn-primary pd-b-5"
                            onClick={this.clearSortFields}
                            title="Clear Filter"
                          ></i>
                        </Col>
                      </Row>
                    )}
                    {this.state.isToShowFilteringField && (
                      <Row>
                        <Col md={1} className="col-md-1 mg-t-7">
                          <label htmlFor="search">Search:</label>
                        </Col>
                        <Col md={10} className="col-lg pd-r-10">
                          <input
                            type="text"
                            className="form-control mg-l-10"
                            maxLength="20"
                            value={this.state.filterValue}
                            onChange={this.onChangefilterValue}
                          />
                        </Col>
                        <Col md={1}>
                          <i
                            className="far fa-window-close btn btn-primary pd-b-5"
                            title="Clear Filter"
                            onClick={this.clearSearch}
                          ></i>
                        </Col>
                      </Row>
                    )}
                  </Col>
                  <Col md={2} style={{ textAlign: "end", marginTop: "10px" }}>
                    <i
                      className="fas fa-exchange-alt fa-rotate-90 pointer"
                      title={
                        this.state.isToShowSortingFields
                          ? "Hide Sort"
                          : "Show Sort"
                      }
                      onClick={this.displaySortingFields}
                    ></i>
                    {!this.state.isToShowFilteringField ? (
                      <i
                        className="fas fa-filter pointer mg-l-10"
                        onClick={this.displayFilteringField}
                        title="Show Filter"
                      ></i>
                    ) : (
                      <i
                        className="fas fa-funnel-dollar pointer mg-l-10"
                        onClick={this.displayFilteringField}
                        title="Hide Filter"
                      ></i>
                    )}
                    <i
                      className="fas fa-file-excel mg-l-10 pointer"
                      style={{ color: "green" }}
                      onClick={this.exportUserRoleListToExcel}
                      title="Export to Excel"
                    ></i>
                  </Col>
                </Row>
                <div
                  style={{
                    overflowY: "scroll",
                    borderBottom: "1px solid #cdd4e0",
                  }}
                  ref={this.divScrollRef}
                  className="admin-table-height"
                  onScroll={this.handleScroll}
                >
                  <BootstrapTable
                    bootstrap4
                    {...props.baseProps}
                    striped
                    hover
                    condensed
                  />
                  <div className="col-md-10">
                    {((this.state.index <= this.state.userRoles.length &&
                      this.state.filterValue === "") ||
                      this.state.index <= this.state.filteredArray.length) && (
                      <p>loading more rows, please scroll...</p>
                    )}
                  </div>
                </div>
              </div>
            )}
          </ToolkitProvider>
          {this.state.position > 600 && (
            <div className="up-arrow">
              <i
                className="fa fa-angle-up pro-arrow"
                onClick={this.scrollToTop}
              ></i>
            </div>
          )}
        </LoadingOverlay>
      </div>
    );
  }
}

export default UserRoleList;
