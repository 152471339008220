import React, { Component, createRef } from "react";
import helper from "../../helpers/helpers";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import { Modal, Button, Row, Col, Tab,Nav, } from 'react-bootstrap';
import "react-toastify/dist/ReactToastify.css";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { MaterialReactTable } from 'material-react-table';
import { Box } from '@mui/material';
import grievanceService from "../../services/grievanceService";
import {IconButton,Tooltip} from '@mui/material';
import { FaFileExcel } from 'react-icons/fa';
import moment from 'moment';
import accessControlService from "../../services/accessControl.service";

toast.configure();

class viewsuggestions extends Component {
  
  constructor(props) {
    super(props);
    const fixedDate = this.getTodayDate();
    this.state = {
      activeTab: "open",
      accessControl: [],
      customers: [],
      closedcustomers: [],
      index: 30,
      position: 0,
      columns: [],
      closedcolumns:[],
      modalData: { },
      filterValue: "",  
      myfilterValue: "",
      defaultActiveKey: "open",
      spinnerMessage: "",
      loading: false,
      showModal: false,
      openSuggestions: [],
      closedSuggestions: [],
      isoDate: this.convertDateToISO(fixedDate),
      currentTab: "open",   
      fullScreen: false,
      SuggestionToManagementID: '', 
      UserID: 'dsm',
      subject: '',
      details: '',
      id: '', 
    };
    this.tableContainerRef = createRef();
    this.handleClose = this.handleClose.bind(this);
  }

  // #region Data Loading
  componentDidMount() {
    this.loadSuggestions();
    this.loadSuggestionsid();
    this.loadClosedSuggestions();
  }
  // #endregion
  
  // #region Export Suggestions To Excel
  
  handleExport = () => {
    const { currentTab } = this.state;
    const UserID = "dsm";
    const Status = currentTab === "open" ? "o" : "c";
  
    this.setState({ loading: true });
  
    grievanceService.ExportSuggestionsToExcel(UserID, Status)
      .then((response) => {
       
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.download = `${currentTab === "open" ? "Open" : "Closed"}Suggestions.xlsx`;
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        window.URL.revokeObjectURL(fileURL);
        this.setState({
          loading: false,
          spinnerMessage: "",
          modalLoading: false,
        });
      })
      .catch(error => {
        console.error('Error downloading file:', error);
        this.setState({
          loading: false,
          spinnerMessage: "",
          modalLoading: false,
        });
      });
  };
  
  // #endregion

  // #region Tab Handling
  handleTabChange = (tab) => {
    this.setState({ currentTab: tab });
  };

  toggle = (key) => {
    this.setState({ activeTab: key });
  };
  // #endregion

  // #region Modal Handling
  handleRowClick = (rowData) => {
    this.setState({
      modalData: rowData || {}, 
      showModal: true,
    });     
  };
    
  handleOpenModal = (data) => {
    this.setState({ modalData: data, showModal: true });
  };
  handleClose = () => {
    this.setState({ showModal: false });
  };
  // #endregion
  
  // #region Read Suggestions By Status open suggestions
  loadSuggestions = () => {
    this.setState({ loading: true });
    const { UserID } = this.state; 
    const Status = 'o'; 
    grievanceService.ReadSuggestionsByStatus(UserID, Status)
      .then(response => {
          this.setState({
            openSuggestions: response.data, 
            loading: false,
          });
      })
      .catch(error => {
        toast.error("An error occurred while loading open suggestions.");
        this.setState({ loading: false });
      });
  };
  //#endregion

  // #region Read Suggestions By Status closed suggestions
  loadClosedSuggestions = () => {
    this.setState({ loading: true });
    const { UserID } = this.state; 
    const Status = 'c'; 
    grievanceService.ReadSuggestionsByStatus(UserID, Status)
    .then(response => {
        this.setState({
          closedSuggestions: response.data, 
          loading: false,
        });
    })
    .catch(error => {
      toast.error("An error occurred while loading closed suggestions.");
      this.setState({ loading: false });
    });
  };
  //#endregion

  // #region Read Suggestion By Id
  loadSuggestionsid = () => {
    const { modalData } = this.state;
    const id = modalData.SuggestionToManagementID; 
    const UserID = this.state.UserID; 
    const myStatus = 'o'; 
      if (!id) {
        return;
      }
    this.setState({ loading: true });
    grievanceService.ReadSuggestionById(id, UserID)
    .then(response => {
      const newSuggestion = response.data;
      this.setState({
        modalData: newSuggestion, 
        Status : myStatus,
        loading: false,
      });
    })
    .catch(error => {
      const errorMessage = error.response?.data?.message || "An error occurred while fetching the suggestion.";
      toast.error(errorMessage);
      this.setState({ loading: false });
    });
  };
  //#endregion

  // #region Update Suggestion Status To Closed
  updateSuggestionStatusToClosed = () => {
    accessControlService.CanUserAccessPage(helper.getUser(), 'Update Suggestion')
      .then((response) => {
        if(response.data){
          const { modalData } = this.state;
          const id = modalData.SuggestionToManagementID; 
          const UserID = this.state.UserID; 
          const newStatus = 'c'; 
          if (!id) {
              return;
          }
          this.setState({ loading: true });
          grievanceService.UpdateSuggestionStatusToClosed(id, UserID)
          .then(response => {
            this.setState(prevState => ({
              modalData: {
                ...prevState.modalData,
                Status: newStatus, 
              },
              loading: false,
            }));
            toast.success("Suggestion Closed successfully.");
            this.loadSuggestions(); 
            this.loadClosedSuggestions();
            this.handleClose();
          })
          .catch(error => {
            const errorMessage = error.response?.data?.message || "An error occurred while updating the suggestion status.";
            toast.error(errorMessage);
            this.setState({ loading: false });
          });
        } else{
            toast.error("Access Denied");
        }

      })
      .catch((e) => {
        const errorMessage = e.response?.data?.Message ?? 'An error occurred';
        toast.error(errorMessage, { autoClose: false });
      });
    
  };
  // #endregion
       
  // #region Table Columns
  createColumns() {
    return [          
      {
        accessorKey: "Subject", 
        header: "Subject",
        textAlign: "center",
        muiTableHeadCellProps: {
          align: "left",
          style: {
            maxWidth: '25%', 
            width: '25%', 
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            
          },      
        },
        muiTableBodyCellProps: {
          align: "left",
          style: {
            maxWidth: '25%', 
            width: '25%', 
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },    
        },
        Cell: ({ row }) => (
          <div
            style={{ cursor: 'pointer', color:'#5b47fb', textDecoration:'underline' }} 
            onClick={() => this.handleRowClick(row.original)} 
          >
            {row.original.Subject} 
          </div>
        ),
      },
          
      {
        accessorKey: "Details", 
        header: "Details",
        textAlign: "center",
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "left",
          style: {
            maxWidth: '50%', 
            width: '50%',
            whiteSpace: 'break-spaces', 
              
          },
        },
      },
      {
        accessorKey: "CreatedOn", 
        header: "Date",
          Cell: ({ row }) => (
            <div>
              {moment(row.original.CreatedOn).format('DD-MMM-YYYY')}
            </div>
          ),
          muiTableHeadCellProps: {
            align: "center",
          },
          muiTableBodyCellProps: {
            align: "center",
          },
      },
    ];
  }
  // #endregion
        
  // #region Date Utilities
  getTodayDate() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); 
    const year = today.getFullYear();
    return `${day}.${month}.${year}`;
  }
  convertDateToISO(dateString) {
    const [day, month, year] = dateString.split('.');
    return `${day}-${month}-${year}`;
  }
  
  // #endregion

  render() {
    const { activeTab, showModal, modalData, currentTab, fullScreen, openSuggestions, closedSuggestions, position} = this.state;
    
    return (
      <div>
        <LoadingOverlay active={this.state.loading} className="custom-loader" spinner={
                <div className="spinner-background">
                    <BarLoader
                        css={helper.getcss()}
                        color={"#38D643"}
                        width={"350px"}
                        height={"10px"}
                        speedMultiplier={0.3}
                    />
                    <p style={{ color: "black", marginTop: "5px" }}>
                        {this.state.spinnerMessage}
                    </p>
                </div>
            }
            >
          <div style={{ height: fullScreen ? '100vh' : 'auto' }}>
            <div className="mg-l-35 mg-r-25">
              <Tab.Container defaultActiveKey={activeTab} onSelect={this.handleTabChange}>
                <Row>
                  <Col md={3}>
                    <Nav variant="pills" className="mg-l-0 mg-b-15 mg-t-0">
                      <Nav.Item>
                        <Nav.Link eventKey="open" onClick={() => this.toggle('open')}>
                          Open
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="closed" onClick={() => this.toggle('closed')}>
                          Closed
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                </Row>
                <Tab.Content>
                  <Tab.Pane eventKey="open">
                    <ToolkitProvider keyField="id">
                      {(props) => (
                        <div className="mg-t-0">
                          <div className="masters-material-table">
                            <MaterialReactTable
                              data={openSuggestions}
                              columns={this.createColumns()}
                              enableColumnFilterModes={true}
                              enableColumnOrdering={false}
                              enableRowSelection={false}
                              enablePagination={false}
                              enableStickyHeader={true}
                              renderTopToolbarCustomActions={() => (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    gap: '16px',
                                    padding: '8px',
                                    flexWrap: 'wrap',
                                  }}
                                >
                                  <Tooltip title="Download Excel">
                                    <IconButton onClick={this.handleExport}>
                                      <FaFileExcel style={{ color: 'green', width:'1em', height:'1em' }} />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              )}
                                                                            
                            />
                          </div>
                        </div>
                      )}
                    </ToolkitProvider>

                    <>
                      {position > 600 && (
                        <div className='scroll-top-div' style={{ textAlign: "end" }}>
                          <button className="scroll-top" onClick={this.scrollToTop} title="Go To Top">
                            <div className="arrow up"></div>
                          </button>
                        </div>
                      )}
                    </>

                  </Tab.Pane>

                  <Tab.Pane eventKey="closed">
                    <ToolkitProvider keyField="CustomerID">
                      {(props) => (
                        <div className="mg-t-10">
                          <div className="masters-material-table">
                            <MaterialReactTable
                              data={closedSuggestions} 
                              columns={this.createColumns()}
                              enableColumnFilterModes={true}
                              enableColumnOrdering={false}
                              enableRowSelection={false}
                              enablePagination={false}
                              enableStickyHeader={true}
                              renderTopToolbarCustomActions={() => (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    gap: '16px',
                                    padding: '8px',
                                    flexWrap: 'wrap',
                                  }}
                                >
                                  <Tooltip title="Download Excel">
                                    <IconButton onClick={this.handleExport}>
                                      <FaFileExcel style={{ color: 'green' }} />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              )}
                            />
                          </div>
                        </div>
                      )}
                    </ToolkitProvider>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
                    
          <Modal show={showModal} onHide={this.handleClose} className="edit-gop-modal mymnmdl viewsug">
            <Modal.Header closeButton>
              <Modal.Title>View Suggestion / Change Suggestion Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="mymdldata">
                <div className="row mt-2 mysgtdta">
                  <div className="col-md-1 txt-plce">
                    <b>Subject :</b>
                  </div>
                  <div className="col-md-11">
                    <div className="mydtlscnt">
                      <input type="text" className="form-control mg-l-5 mg-r-15 myfrm" maxLength="20" placeholder="Subject" value={modalData.Subject || ''} readOnly/>
                    </div>
                  </div>
                </div>
                <div className="row mt-2 mb-4 mysgtdta">
                  <div className="col-md-1 txt-plce">
                    <b>Details :</b>
                  </div>
                  <div className="col-md-11">
                    <div className="mydtlscnt">
                      <textarea placeholder="Details" rows="10" cols="210" className="mg-r-15 mytxtareacnt" value={modalData.Details || ''} readOnly/>
                    </div>
                  </div>
                </div>
                <div className="row mt-2 mb-4 mysgtdta">
                  <div className="col-md-1 txt-plce">
                    <b>Date :</b>
                  </div>
                  <div className="col-md-4">
                    <input type="date" className="form-control date-input" value={moment(modalData.CreatedOn).format('YYYY-MM-DD') || ''} readOnly/>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {currentTab === "closed" ? (
                <Button variant="secondary" onClick={this.handleClose} className="vewsubmit-button">
                  <i className="fa fa-close mr-1"></i> Close Window
                </Button>
                ) : (
                <>
                  <Button variant="secondary" onClick={this.handleClose} className="vewsubmit-button">
                    <i className="fa fa-close mr-1"></i> Close Window
                  </Button>
                  <Button variant="primary" type="submit" className="vewsubmit-button" onClick={this.updateSuggestionStatusToClosed}>
                    <i className="fa fa-save"></i> Close Suggestion
                  </Button>
                </>
              )}
            </Modal.Footer>
          </Modal>
        </LoadingOverlay>
            
      </div>
    );
  }
}

export default viewsuggestions;
