import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import ItemStatusService from "../../services/itemStatus.service";
import accessControlService from "../../services/accessControl.service";
import helper from "../../helpers/helpers";
import tableFunctions from "../../helpers/tableFunctions";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import itemStatusService from "../../services/itemStatus.service";
import { Row, Col } from "react-bootstrap";
toast.configure();

class ItemStatusList extends Component {
  constructor(props) {
    super(props); //reference to the parents constructor()

    this.divScrollRef = React.createRef();

    this.moveToAddItemStatus = this.moveToAddItemStatus.bind(this);

    this.displaySortingFields = this.displaySortingFields.bind(this);
    this.displayFilteringField = this.displayFilteringField.bind(this);
    this.onChangefilterValue = this.onChangefilterValue.bind(this);
    this.onChangeColumn = this.onChangeColumn.bind(this);
    this.onChangeSortOrder = this.onChangeSortOrder.bind(this);
    this.sortData = this.sortData.bind(this);
    this.clearSort = this.clearSort.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.exportItemStatusListToExcel =
      this.exportItemStatusListToExcel.bind(this);

    ///Component State
    this.state = {
      itemStatus: [],
      canAccessCreateItemStatus: false,
      canAccessViewItemStatus: false,
      loading: false,
      spinnerMessage: "",
      index: 30,
      position: 0,
      columns: [],
      isToShowSortingFields: false,
      isToShowFilteringField: true,
      selectedColumn: "",
      selectedSort: "",
      filteredArray: [],
      filterValue: "",
      itemStatusFormatListForSorting: [],
    };
  }

  //#region component mount
  componentDidMount() {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    this.canUserAccessPage("Create Item Status");
    this.canUserAccessPage("View Item Status");
  }
  //#endregion

  //#region fetching Item Status List from Web API
  fetchItemStatusList() {
    this.setState({
      spinnerMessage: "Please wait while loading Item Status...",
      loading: true,
    });

    ItemStatusService.getAllItemStatus(helper.getUser())
      .then((response) => {
        let formattedArray = response.data.map((obj) => ({
          ...obj,
          IsActive: obj.IsActive === true ? "Yes" : "No",
        }));

        formattedArray = formattedArray.map((obj) => {
          delete obj.UserID;
          return obj;
        });
        this.setState({
          itemStatus: formattedArray,
          loading: false,
          itemStatusFormatListForSorting: response.data,
        });
      })
      .catch((e) => {
        this.setState({ loading: false });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region fetching Item Status page access
  canUserAccessPage(pageName) {
    accessControlService
      .CanUserAccessPage(helper.getUser(), pageName)
      .then((response) => {
        if (pageName === "Create Item Status") {
          this.setState({
            canAccessCreateItemStatus: response.data,
          });
        } else if (pageName === "View Item Status") {
          this.setState({
            canAccessViewItemStatus: response.data,
          });
        }
        this.fetchItemStatusList();
      })
      .catch((e) => {
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Redirect to Add Item Status Page
  moveToAddItemStatus() {
    this.props.history.push("/Masters/AddItemStatus");
  }
  //#endregion

  //#region Export Item Status List to Excel
  exportItemStatusListToExcel() {
    this.setState({
      spinnerMessage:
        "Please wait while exporting Item Status List to excel...",
      loading: true,
    });

    let fileName = "Item Status List.xlsx";

    itemStatusService
      .exportItemStatusListToExcel()
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);
        fileLink.click();

        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Filter Functions
  //#region Display Filtering Field
  displayFilteringField() {
    this.setState((previousState) => ({
      isToShowFilteringField: !previousState.isToShowFilteringField,
      filterValue: "",
      isToShowSortingFields: false,
    }));
  }
  //#endregion

  //#region on change filter value
  onChangefilterValue(e) {
    this.setState({ filterValue: e.target.value }, () =>
      this.getFilteredRows()
    );
  }
  //#endregion

  //#region get filtered rows
  getFilteredRows() {
    let filteredArray = tableFunctions.filterArray(
      this.state.itemStatus,
      this.state.filterValue
    );

    filteredArray = filteredArray.map((users, index) => {
      return {
        ...users,
        SlNo: index + 1,
      };
    });
    this.setState({ filteredArray: filteredArray });
  }
  //#endregion

  //#region Clear Search
  clearSearch() {
    this.setState({
      filterValue: "",
    });
  }
  //#endregion
  //#endregion

  //#region  Sort Functions
  //#region Display Sorting Fields
  displaySortingFields() {
    let columns = Object.keys(this.state.itemStatus[0]).slice(1);

    this.setState((previousState) => ({
      isToShowSortingFields: !previousState.isToShowSortingFields,
      selectedColumn: "",
      selectedSort: "",
      columns: columns,
      filterValue: "",
      isToShowFilteringField: false,
    }));
  }
  //#endregion

  //#region Selecting the sort column
  onChangeColumn(e) {
    this.setState({
      selectedColumn: e.target.value,
      selectedSort: "",
    });
  }
  //#endregion

  //#region On Change Sort
  onChangeSortOrder(e) {
    this.setState(
      {
        selectedSort: e.target.value,
      },
      () => this.sortData()
    );
  }
  //#endregion

  //#region Sort Data based on column and order
  sortData() {
    let sortedArray = [];
    let column =
      this.state.selectedColumn !== "" ? this.state.selectedColumn : "SlNo";
    const selectedSort =
      this.state.selectedSort !== "" ? this.state.selectedSort : "ascending";
    let numberColumns = ["SlNo", "ItemStatusID"];

    sortedArray = tableFunctions.sortData(
      this.state.itemStatus,
      column,
      selectedSort,
      numberColumns,
      []
    );

    sortedArray = sortedArray.map((users, index) => {
      return {
        ...users,
        SlNo: index + 1,
      };
    });
    this.setState({ itemStatus: sortedArray });
  }
  //#endregion

  //#region  Clear Sort
  clearSort() {
    let formattedArray = this.state.itemStatusFormatListForSorting.map(
      (obj) => ({
        ...obj,
        IsActive: obj.IsActive === true ? "Yes" : "No",
      })
    );

    this.setState({
      itemStatus: formattedArray,
      loading: false,
    });
    this.setState({
      selectedColumn: "",
      selectedSort: "",
      itemStatus: formattedArray,
    });
  }
  //#endregion

  //#region Handle Scroll
  handleScroll(e) {
    var currentHeight = e.currentTarget.scrollTop;
    var maxScrollPosition =
      e.currentTarget.scrollHeight - e.currentTarget.clientHeight;

    this.setState({ position: currentHeight });

    if ((currentHeight / maxScrollPosition) * 100 > 90) {
      let curIndex = this.state.index + 20;
      this.setState({ index: curIndex });
    }
  }
  //#endregion

  //#region Scroll to Top
  scrollToTop = () => {
    this.divScrollRef.current.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  //#endregion

  //#region UI
  render() {
    const data = this.state.itemStatus.slice(0, this.state.index);
    const filteredData = this.state.filteredArray.slice(0, this.state.index);

    const canAccessCreateItemStatus = this.state.canAccessCreateItemStatus;
    const canAccessViewItemStatus = this.state.canAccessViewItemStatus;

    const ItemStatusListColumns = [
      {
        dataField: "SlNo",
        text: "Sl No.",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "ItemStatusID",
        text: "Item Status ID",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "Status",
        text: "Status",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "left",
        align: "left",
        classes: canAccessViewItemStatus ? "demo-key-row1" : "",
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            canAccessViewItemStatus &&
              this.props.history.push({
                pathname: "/Masters/ViewItemStatus",
                state: row.ItemStatusID,
              });
          },
        },
      },
      {
        dataField: "IsActive",
        text: "Is Active?",
        headerAlign: "center",
        align: "center",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
      },
    ];

    return (
      <div className="pro-main-display">
        <LoadingOverlay
          active={this.state.loading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
          <div className="az-content-breadcrumb">
            <span>Master</span>
            <span>Item Status</span>
          </div>
          <h4>
            Item Status List{" "}
            {canAccessCreateItemStatus && (
              <span className="icon-size">
                <i
                  className="fa fa-plus text-primary pointer"
                  onClick={this.moveToAddItemStatus}
                  title="Add New Item Status"
                ></i>
              </span>
            )}
          </h4>
          <ToolkitProvider
            keyField="ItemStatusID"
            data={this.state.filterValue === "" ? data : filteredData}
            columns={ItemStatusListColumns}
            search={{ searchFormatted: true }}
          >
            {(props) => (
              <div className="mg-t-10">
                <Row style={{ marginRight: "5px" }}>
                  <Col md={10} style={{ whiteSpace: "nowrap" }}>
                    {this.state.isToShowSortingFields && (
                      <Row>
                        <Col md={4}>
                          <Row>
                            <Col md={3} className="mg-t-7">
                              <label htmlFor="sortColumn">Column:</label>
                            </Col>
                            <Col md={9}>
                              <select
                                className="form-control mg-l-5"
                                value={this.state.selectedColumn}
                                onChange={this.onChangeColumn}
                              >
                                <option value="">--Select--</option>
                                {this.state.columns.map((col) => (
                                  <option key={col}>{col}</option>
                                ))}
                              </select>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={4}>
                          <Row>
                            <Col md={3} className="mg-t-7">
                              <label htmlFor="sortOrder">Order:</label>
                            </Col>
                            <Col md={9}>
                              <select
                                className="form-control mg-l-5"
                                value={this.state.selectedSort}
                                onChange={this.onChangeSortOrder}
                              >
                                <option value="">--Select--</option>
                                <option value="ascending">Ascending</option>
                                <option value="descending">Descending</option>
                              </select>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={2}>
                          {/*<div>
								<span className="btn btn-primary pd-b-5"
								  onClick={this.clearSort} title="Clear Sort Fields"
								> */}
                          <i
                            className="far fa-window-close btn btn-primary pd-b-5"
                            onClick={this.clearSort}
                            title="Clear Sort Fields"
                          ></i>
                          {/* </span> 
							</div>*/}
                        </Col>
                      </Row>
                    )}
                    {this.state.isToShowFilteringField && (
                      <Col md={12}>
                        <Row style={{ flexWrap: "nowrap" }}>
                          <Col md={1} className="mg-t-7">
                            <label htmlFor="search">Search:</label>
                          </Col>
                          <Col lg className="pd-r-10">
                            <input
                              type="text"
                              className="form-control mg-l-10"
                              maxLength="20"
                              value={this.state.filterValue}
                              onChange={this.onChangefilterValue}
                            />
                          </Col>
                          <div>
                            {/* <span className="btn btn-primary pd-b-5"
                              onClick={this.clearSearch}
                            > */}
                            <i
                              className="far fa-window-close btn btn-primary pd-b-5"
                              onClick={this.clearSearch}
                              title="Clear Filter"
                            ></i>
                            {/* </span> */}
                          </div>
                        </Row>
                      </Col>
                    )}
                  </Col>
                  <Col md={2} style={{ textAlign: "end", marginTop: "10px" }}>
                    <i
                      className="fas fa-exchange-alt fa-rotate-90 pointer"
                      title={
                        this.state.isToShowSortingFields
                          ? "Hide Sort"
                          : "Show Sort"
                      }
                      onClick={this.displaySortingFields}
                    ></i>
                    {!this.state.isToShowFilteringField ? (
                      <i
                        className="fas fa-filter pointer mg-l-10"
                        onClick={this.displayFilteringField}
                        title="Show Filter"
                      ></i>
                    ) : (
                      <i
                        className="fas fa-funnel-dollar pointer mg-l-10"
                        onClick={this.displayFilteringField}
                        title="Hide Filter"
                      ></i>
                    )}
                    <i
                      className="fas fa-file-excel mg-l-10 pointer"
                      style={{ color: "green" }}
                      onClick={this.exportItemStatusListToExcel}
                      title="Export to Excel"
                    ></i>
                  </Col>
                </Row>
                <div
                  style={{
                    overflowY: "scroll",
                    borderBottom: "1px solid #cdd4e0",
                  }}
                  ref={this.divScrollRef}
                  className="masters-table-height"
                  onScroll={this.handleScroll}
                >
                  <BootstrapTable
                    bootstrap4
                    {...props.baseProps}
                    striped
                    hover
                    condensed
                  />
                </div>
              </div>
            )}
          </ToolkitProvider>
          {this.state.position > 600 && (
            <div className="up-arrow">
              <i
                className="fa fa-angle-up pro-arrow"
                title="Scroll to Top"
                onClick={this.scrollToTop}
              ></i>
            </div>
          )}
        </LoadingOverlay>
      </div>
    );
  }
  //#endregion
}

export default ItemStatusList;
