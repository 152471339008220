import React, { Component } from "react";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import tableFunctions from "../../helpers/tableFunctions";
import { getProjectBatches } from "../../actions/projectBatch/projectBatch";
import accessControlService from "../../services/accessControl.service";
import projectBatchService from "../../services/projectBatch.service";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import helper from "../../helpers/helpers";
import Moment from "moment";
import { Modal } from "react-bootstrap";
import ModernDatepicker from "react-modern-datepicker";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class OnGoingProjectBatchList extends Component {
  constructor(props) {
    super(props);

    this.divScrollRef = React.createRef();

    this.exportProjectBatchListToExcel =
      this.exportProjectBatchListToExcel.bind(this);
    this.showDeliverProjectBatchModal =
      this.showDeliverProjectBatchModal.bind(this);
    this.onChangeDeliveredDate = this.onChangeDeliveredDate.bind(this);
    this.onChangeDeliveredCount = this.onChangeDeliveredCount.bind(this);
    this.onChangeSortOrder = this.onChangeSortOrder.bind(this);
    this.onChangeColumn = this.onChangeColumn.bind(this);
    this.sortData = this.sortData.bind(this);
    this.displaySortingFields = this.displaySortingFields.bind(this);
    this.displayFilteringField = this.displayFilteringField.bind(this);
    this.onChangefilterValue = this.onChangefilterValue.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.clearSearchField = this.clearSearchField.bind(this);
    this.clearSortFields = this.clearSortFields.bind(this);

    this.state = {
      canAccessViewProjectBatch: false,
      loading: false,
      spinnerMessage: "",
      canUserDeliverProjectBatch: false,
      showDeliverProjectBatchModal: false,
      modalLoading: false,
      customerCode: "",
      projectCode: "",
      batchNo: "",
      deliveredDate: "",
      deliveredCount: 0,
      formErrors: {},
      projectBatches: [],
      index: 30,
      position: 0,
      columns: [],
      selectedColumn: "",
      selectedSort: "",
      isToShowSortingFields: false,
      isToShowFilteringField: false,
      filteredArray: [],
      filterValue: "",
    };
  }

  //#region component mount
  async componentDidMount() {
    try {
      this.setState({
        loading: true,
        spinnerMessage: "Please wait while loading Project Batches List...",
      });
      this.canUserAccessPage("View Project Batch");
      await this.props.getProjectBatches(
        this.props.projectID,
        helper.getUser(),
        "O"
      );

      if (this.props.projectBatches.length > 0) {
        let formattedArray = this.props.projectBatches.map((obj) => ({
          ...obj,
          ReceivedDate: Moment(obj.ReceivedDate).format("DD-MMM-yyyy"),
          PlannedDeliveryDate: Moment(obj.PlannedDeliveryDate).format(
            "DD-MMM-yyyy"
          ),
        }));

        this.setState({
          canUserDeliverProjectBatch:
            this.props.projectBatches[0].canUserDeliverProjectBatch,
          projectBatches: formattedArray,
        });
      }
      this.setState({
        loading: false,
      });
    } catch (error) {
      toast.error(error, { autoClose: false });
    }
  }
  //#endregion

  //#region fetching Project page access
  canUserAccessPage(pageName) {
    accessControlService
      .CanUserAccessPage(helper.getUser(), pageName)
      .then((response) => {
        this.setState({
          canAccessViewProjectBatch: response.data,
        });
      })
      .catch((e) => {
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Scroll to Top
  scrollToTop = () => {
    this.divScrollRef.current.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  //#endregion

  //#region Sort Functions
  //#region Display Sorting Fields
  displaySortingFields() {
    let columns = Object.keys(this.state.projectBatches[0]);

    let excludedColumns = [
      "ProjectID",
      "ProjectBatchID",
      "InputCountType",
      "ReceivedFormat",
      "OutputFormat",
      "PlannedStartDate",
      "DeliveredDate",
      "Remarks",
      "CustomerInputFileName",
      "DeliveredCount",
      "canUserDeliverProjectBatch",
      "IsPostProjectBatchDetailsExist",
      "UserID",
    ];

    columns = columns.filter((item) => !excludedColumns.includes(item));

    this.setState((previousState) => ({
      isToShowSortingFields: !previousState.isToShowSortingFields,
      selectedColumn: "",
      selectedSort: "",
      columns: columns,
      filterValue: "",
      isToShowFilteringField: false,
    }));
  }
  //#endregion

  //#region Selecting the sort column
  onChangeColumn(e) {
    this.setState({
      selectedColumn: e.target.value,
      selectedSort: "",
    });
  }
  //#endregion

  //#region On Change Sort
  onChangeSortOrder(e) {
    this.setState(
      {
        selectedSort: e.target.value,
      },
      () => this.sortData()
    );
  }
  //#endregion

  //#region Sort Data based on column and order
  sortData() {
    let sortedArray = [];
    let column =
      this.state.selectedColumn !== "" ? this.state.selectedColumn : "SlNo";
    const selectedSort =
      this.state.selectedSort !== "" ? this.state.selectedSort : "ascending";
    let numberColumns = ["SlNo", "InputCount"];
    let dateColumns = ["ReceivedDate", "PlannedDeliveryDate"];

    sortedArray = tableFunctions.sortData(
      this.state.projectBatches,
      column,
      selectedSort,
      numberColumns,
      dateColumns
    );

    this.setState({ projectBatches: sortedArray });
  }
  //#endregion

  //#region  Clear Sort
  clearSortFields() {
    this.setState(
      {
        selectedColumn: "",
        selectedSort: "",
      },
      () => this.sortData()
    );
  }
  //#endregion
  //#endregion

  //#region Filter Functions
  //#region Display Filtering Field
  displayFilteringField() {
    this.setState((previousState) => ({
      isToShowFilteringField: !previousState.isToShowFilteringField,
      filterValue: "",
      isToShowSortingFields: false,
    }));
  }
  //#endregion

  //#region on change filter value
  onChangefilterValue(e) {
    this.setState({ filterValue: e.target.value }, () =>
      this.getFilteredRows()
    );
  }
  //#endregion

  //#region get filtered rows
  getFilteredRows() {
    const filteredArray = tableFunctions.filterArray(
      this.state.projectBatches,
      this.state.filterValue
    );

    this.setState({ filteredArray: filteredArray });
  }
  //#endregion

  //#region Clear Search
  clearSearchField() {
    this.setState({
      filterValue: "",
    });
  }
  //#endregion
  //#endregion

  //#region Handle Scroll
  handleScroll(e) {
    var currentHeight = e.currentTarget.scrollTop;
    var maxScrollPosition =
      e.currentTarget.scrollHeight - e.currentTarget.clientHeight;

    this.setState({ position: currentHeight });

    if ((currentHeight / maxScrollPosition) * 100 > 90) {
      let curIndex = this.state.index + 20;
      this.setState({ index: curIndex });
    }
  }
  //#endregion

  //#region Export Project Batch List to Excel
  exportProjectBatchListToExcel(projectID) {
    this.setState({
      spinnerMessage:
        "Please wait while exporting Project Batch List to excel...",
      loading: true,
    });

    let fileName = "Projects Batch List.xlsx";

    projectBatchService
      .exportProjectBatchListToExcel(projectID, "O")
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);
        fileLink.click();

        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region show Modal to Deliver Project Batch
  showDeliverProjectBatchModal(customerCode, projectCode, batchNo) {
    this.setState({
      customerCode: customerCode,
      projectCode: projectCode,
      batchNo: batchNo,
      showDeliverProjectBatchModal: true,
      deliveredDate: "",
      deliveredCount: 0,
      formErrors: {},
    });
  }
  //#endregion

  //#region Get Selected Delivered Date
  onChangeDeliveredDate(date) {
    this.setState({
      deliveredDate: date,
    });

    if (date !== "" && date !== null) {
      const formErrors = { ...this.state.formErrors, deliveredDateError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Get Entered Delivered Count
  onChangeDeliveredCount(e) {
    this.setState({
      deliveredCount: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = { ...this.state.formErrors, deliveredCountError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Handle Deliver Project Batch Form Validation
  handleDeliverProjectBatchFormValidation() {
    const deliveredCount = this.state.deliveredCount;
    const deliveredDate = this.state.deliveredDate;
    let formErrors = {};
    let isValidForm = true;

    //Delivered Count
    if (!deliveredCount) {
      isValidForm = false;
      formErrors["deliveredCountError"] = "Delivered Count is required";
    }

    //Delivered Date
    if (!deliveredDate) {
      isValidForm = false;
      formErrors["deliveredDateError"] = "Delivered Date is required";
    } else if (new Date(deliveredDate) > new Date()) {
      isValidForm = false;
      formErrors["deliveredDateError"] =
        "Delivered Date can't be a future date";
    }

    this.setState({ formErrors: formErrors });
    return isValidForm;
  }
  //#endregion

  //#region Deliver Project Batch
  deliverProjectBatch = (e) => {
    e.preventDefault();

    if (this.handleDeliverProjectBatchFormValidation()) {
      this.setState({
        spinnerMessage: "Please wait while delivering the Project Batch...",
        modalLoading: true,
      });

      var data = {
        CustomerCode: this.state.customerCode,
        ProjectCode: this.state.projectCode,
        BatchNo: this.state.batchNo,
        DeliveredDate: this.state.deliveredDate,
        DeliveredCount: this.state.deliveredCount,
        UserID: helper.getUser(),
      };

      //Service call
      projectBatchService
        .deliverProjectBatch(data)
        .then((response) => {
          this.setState({
            modalLoading: false,
          });

          this.props.getProjectBatches(
            this.props.projectID,
            helper.getUser(),
            "O"
          );

          toast.success("Project Batch Delivered Details updated Successfully");
          this.setState({ showDeliverProjectBatchModal: false });
        })
        .catch((error) => {
          this.setState({
            modalLoading: false,
          });
          toast.error(error.response.data.Message, { autoClose: false });
        });
    }
  };
  //#endregion

  render() {
    const projectID = this.props.projectID;
    const data = this.state.projectBatches.slice(0, this.state.index);
    const filteredData = this.state.filteredArray.slice(0, this.state.index);

    const canAccessViewProjectBatch = this.state.canAccessViewProjectBatch;

    //#region Project Batches List Columns
    const projectBatchesListColumns = [
      {
        dataField: "SlNo",
        text: "Sl No.",
        align: "center",
        style: {
          width: "6%",
        },
      },
      {
        dataField: "ProjectID",
        text: "Project ID",
        align: "center",
        hidden: true,
      },
      {
        dataField: "CustomerCode",
        text: "Cus Code",
        align: "center",
        style: {
          width: "7%",
        },
      },
      {
        dataField: "ProjectCode",
        text: "Proj Code",
        align: "center",
        style: {
          width: "7%",
        },
      },
      {
        dataField: "BatchNo",
        text: "Batch No.",
        align: "center",
        classes: canAccessViewProjectBatch ? "demo-key-row1" : "",
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            canAccessViewProjectBatch &&
              this.props.history.push({
                pathname: "/Projects/ViewProjectBatch",
                state: {
                  ProjectBatchID: row.ProjectBatchID,
                  activeTab: 1,
                }, // passing Project Batch ID to View Project Batch Page
              });
          },
        },
        style: {
          width: "7%",
        },
      },
      {
        dataField: "Scope",
        text: "Scope",
        style: {
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          width: "16%",
        },
        title: true,
      },
      {
        dataField: "ReceivedDate",
        text: "Received Date",
        align: "center",
        style: {
          width: "9%",
        },
      },
      {
        dataField: "PlannedDeliveryDate",
        text: "Planned Del Date",
        style: {
          width: "10%",
        },
        align: "center",
      },
      {
        dataField: "InputCount",
        text: "Input Count",
        align: "center",
        style: {
          width: "8%",
        },
      },
      {
        dataField: "Status",
        text: "Status",
        align: "center",
        style: {
          width: "7.8%",
        },
      },
      {
        dataField: "canUserDeliverProjectBatch",
        text: "can User Deliver Project Batch",
        align: "center",
        hidden: true,
      },
      {
        dataField: "deliver",
        text: "Deliver Proj Batch",
        align: "center",
        style: {
          width: "9%",
        },
        hidden: this.state.canUserDeliverProjectBatch ? false : true,
        formatter: (cell, row, rowIndex, extraData) => (
          <div>
            <i
              className="fas fa-chevron-circle-right pointer"
              title="Deliver Project Batch"
              onClick={() =>
                this.showDeliverProjectBatchModal(
                  row.CustomerCode,
                  row.ProjectCode,
                  row.BatchNo
                )
              }
            ></i>
          </div>
        ),
      },
    ];
    //#endregion

    return (
      <div>
        <LoadingOverlay
          active={this.state.loading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
          <div className="az-content-body pd-lg-l-40 d-flex flex-column">
            <div className="table-responsive">
              <ToolkitProvider
                keyField="BatchNo"
                data={this.state.filterValue === "" ? data : filteredData}
                columns={projectBatchesListColumns}
              >
                {(props) => (
                  <div>
                    <div
                      className="row mg-b-10"
                      style={{ marginRight: "15px" }}
                    >
                      <div
                        className="col-md-10"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <div className="row">
                          {this.state.isToShowSortingFields && (
                            <>
                              <div className="col-md-4">
                                <div className="row">
                                  <div className="col-md-3 mg-t-7">
                                    <label htmlFor="sortColumn">Column:</label>
                                  </div>
                                  <div className="col-lg">
                                    <select
                                      className="form-control mg-l-5"
                                      value={this.state.selectedColumn}
                                      onChange={this.onChangeColumn}
                                    >
                                      <option value="">--Select--</option>
                                      {this.state.columns.map((col) => (
                                        <option key={col}>{col}</option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="row">
                                  <div className="col-md-3 mg-t-7">
                                    <label htmlFor="sortOrder">Order:</label>
                                  </div>
                                  <div className="col-lg">
                                    <select
                                      className="form-control mg-l-5"
                                      value={this.state.selectedSort}
                                      onChange={this.onChangeSortOrder}
                                    >
                                      <option value="">--Select--</option>
                                      <option value="ascending">
                                        Ascending
                                      </option>
                                      <option value="descending">
                                        Descending
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div>
                                  <span
                                    className="btn btn-primary pd-b-5"
                                    onClick={this.clearSortFields}
                                    title="Clear Sort Fields"
                                  >
                                    <i className="far fa-window-close"></i>
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                          {this.state.isToShowFilteringField && (
                            <>
                              <div className="col-md-12">
                                <div
                                  className="row"
                                  style={{ flexWrap: "nowrap" }}
                                >
                                  <div className="col-md-1 mg-t-7">
                                    <label htmlFor="search">Search:</label>
                                  </div>
                                  <div className="col-lg pd-r-10">
                                    <input
                                      type="text"
                                      className="form-control mg-l-5"
                                      maxLength="20"
                                      value={this.state.filterValue}
                                      onChange={this.onChangefilterValue}
                                    />
                                  </div>
                                  <div>
                                    <span
                                      className="btn btn-primary pd-b-5"
                                      onClick={this.clearSearchField}
                                    >
                                      <i
                                        className="far fa-window-close"
                                        title="Clear Filter"
                                      ></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div
                        className="col-md-2"
                        style={{ textAlign: "end", marginTop: "10px" }}
                      >
                        <i
                          className="fas fa-exchange-alt fa-rotate-90 pointer"
                          title={
                            this.state.isToShowSortingFields
                              ? "Hide Sort"
                              : "Show Sort"
                          }
                          onClick={this.displaySortingFields}
                        ></i>
                        {!this.state.isToShowFilteringField ? (
                          <i
                            className="fas fa-filter pointer mg-l-10"
                            onClick={this.displayFilteringField}
                            title="Show Filter"
                          ></i>
                        ) : (
                          <i
                            className="fas fa-funnel-dollar pointer mg-l-10"
                            onClick={this.displayFilteringField}
                            title="Hide Filter"
                          ></i>
                        )}
                        <i
                          className="fas fa-file-excel mg-l-10 pointer"
                          style={{ color: "green" }}
                          onClick={() =>
                            this.exportProjectBatchListToExcel(projectID)
                          }
                          title="Export to Excel"
                        ></i>
                      </div>
                    </div>
                    <div>
                      <table
                        style={{
                          width:
                            (this.state.filteredArray.length < 12 &&
                              this.state.filterValue !== "") ||
                            this.state.projectBatches.length < 12
                              ? "99.5%"
                              : "99.1%",
                        }}
                      >
                        <thead>
                          <tr>
                            <td
                              className="custom-table-header"
                              style={{
                                width:
                                  (this.state.filteredArray.length < 12 &&
                                    this.state.filterValue !== "") ||
                                  this.state.projectBatches.length < 12
                                    ? "6.9%"
                                    : "6.9%",
                              }}
                            >
                              Sl No
                            </td>
                            <td
                              className="custom-table-header"
                              style={{
                                width:
                                  (this.state.filteredArray.length < 12 &&
                                    this.state.filterValue !== "") ||
                                  this.state.projectBatches.length < 12
                                    ? "8.05%"
                                    : "8.1%",
                              }}
                            >
                              Cus Code
                            </td>
                            <td
                              className="custom-table-header"
                              style={{
                                width:
                                  (this.state.filteredArray.length < 12 &&
                                    this.state.filterValue !== "") ||
                                  this.state.projectBatches.length < 12
                                    ? "8.05%"
                                    : "8%",
                              }}
                            >
                              Proj Code
                            </td>
                            <td
                              className="custom-table-header"
                              style={{
                                width:
                                  (this.state.filteredArray.length < 12 &&
                                    this.state.filterValue !== "") ||
                                  this.state.projectBatches.length < 12
                                    ? "8.1%"
                                    : "8.1%",
                              }}
                            >
                              Batch No.
                            </td>
                            <td
                              className="custom-table-header"
                              style={{
                                width:
                                  (this.state.filteredArray.length < 12 &&
                                    this.state.filterValue !== "") ||
                                  this.state.projectBatches.length < 12
                                    ? "18.4%"
                                    : "18.4%",
                              }}
                            >
                              Scope
                            </td>
                            <td
                              className="custom-table-header"
                              style={{
                                width:
                                  (this.state.filteredArray.length < 12 &&
                                    this.state.filterValue !== "") ||
                                  this.state.projectBatches.length < 12
                                    ? "10.45%"
                                    : "10.45%",
                              }}
                            >
                              Received Date
                            </td>
                            <td
                              className="custom-table-header"
                              style={{
                                width:
                                  (this.state.filteredArray.length < 12 &&
                                    this.state.filterValue !== "") ||
                                  this.state.projectBatches.length < 12
                                    ? "11.5%"
                                    : "11.5%",
                              }}
                            >
                              Planned Del Date
                            </td>
                            <td
                              className="custom-table-header"
                              style={{
                                width:
                                  (this.state.filteredArray.length < 12 &&
                                    this.state.filterValue !== "") ||
                                  this.state.projectBatches.length < 12
                                    ? "9.2%"
                                    : "9.2%",
                              }}
                            >
                              Input Count
                            </td>
                            <td
                              className="custom-table-header"
                              style={{
                                width:
                                  (this.state.filteredArray.length < 12 &&
                                    this.state.filterValue !== "") ||
                                  this.state.projectBatches.length < 12
                                    ? "9%"
                                    : "9%",
                              }}
                            >
                              Status
                            </td>
                            {this.state.canUserDeliverProjectBatch ? (
                              <td
                                className="custom-table-header"
                                style={{
                                  width:
                                    (this.state.filteredArray.length < 12 &&
                                      this.state.filterValue !== "") ||
                                    this.state.projectBatches.length < 12
                                      ? "12%"
                                      : "12%",
                                }}
                              >
                                Deliver Proj Batch
                              </td>
                            ) : null}
                          </tr>
                        </thead>
                      </table>
                    </div>
                    <div
                      style={
                        (this.state.filteredArray.length > 12 &&
                          this.state.filterValue !== "") ||
                        (this.state.projectBatches.length > 12 &&
                          this.state.filterValue === "")
                          ? {
                              overflowY: "scroll",
                              borderBottom: "1px solid #cdd4e0",
                            }
                          : { width: "99.5%" }
                      }
                      ref={this.divScrollRef}
                      className="project-batch-table-height"
                      onScroll={this.handleScroll}
                    >
                      <BootstrapTable
                        bootstrap4
                        {...props.baseProps}
                        striped
                        hover
                        condensed
                        headerClasses="header-class"
                      />
                    </div>
                  </div>
                )}
              </ToolkitProvider>
              {this.state.position > 600 && this.state.filterValue === "" && (
                <div style={{ textAlign: "end" }}>
                  <button className="scroll-top" onClick={this.scrollToTop}>
                    <div className="arrow up"></div>
                  </button>
                </div>
              )}
            </div>
          </div>
        </LoadingOverlay>
        <Modal
          show={this.state.showDeliverProjectBatchModal}
          dialogClassName="modal-width-produpload"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          enforceFocus={false}
        >
          <LoadingOverlay
          active={this.state.modalLoading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
            <Modal.Header>
              <Modal.Title>Deliver Project Batch</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={this.deliverProjectBatch}>
                <div className="row row-sm">
                  <div className="col-md-4 text-nowrap">
                    <label htmlFor="CustomerCode">
                      <b>Customer Code</b>{" "}
                      <span className="text-danger asterisk-size">*</span>
                    </label>
                  </div>
                  <div className="col-md-6 mg-t-7">
                    <p id="CustomerCode" name="CustomerCode">
                      {this.state.customerCode}
                    </p>
                  </div>
                </div>
                <div className="row row-sm">
                  <div className="col-md-4 text-nowrap">
                    <label htmlFor="ProjectCode">
                      <b>Project Code</b>{" "}
                      <span className="text-danger asterisk-size">*</span>
                    </label>
                  </div>
                  <div className="col-md-6 mg-t-7">
                    <p id="ProjectCode" name="ProjectCode">
                      {this.state.projectCode}
                    </p>
                  </div>
                </div>
                <div className="row row-sm">
                  <div className="col-md-4 text-nowrap">
                    <label htmlFor="BatchNo">
                      <b>Batch No.</b>{" "}
                      <span className="text-danger asterisk-size">*</span>
                    </label>
                  </div>
                  <div className="col-md-6 mg-t-7">
                    <p id="BatchNo" name="BatchNo">
                      {this.state.batchNo}
                    </p>
                  </div>
                </div>
                <div className="row row-sm">
                  <div className="col-md-4 text-nowrap">
                    <label htmlFor="DeliveredDate">
                      <b>Delivered Date</b>{" "}
                      <span className="text-danger asterisk-size">*</span>
                    </label>
                  </div>
                  <div className="col-md-6">
                    <div className="row mg-l-0">
                      <div className="form-control delivered-date-field-width">
                        <ModernDatepicker
                          date={this.state.deliveredDate}
                          format={"DD-MMM-YYYY"}
                          onChange={(date) => this.onChangeDeliveredDate(date)}
                          placeholder={"Select Delivered Date"}
                          className="color"
                          minDate={new Date(1900, 1, 1)}
                        />
                      </div>
                      <span
                        className="btn btn-secondary"
                        onClick={() => this.setState({ deliveredDate: "" })}
                      >
                        <i
                          className="far fa-window-close"
                          title="Clear Delivered Date"
                        ></i>
                      </span>
                      <div className="error-message">
                        {this.state.formErrors["deliveredDateError"]}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-sm">
                  <div className="col-md-4 text-nowrap">
                    <label htmlFor="DeliveredCount">
                      <b>Delivered Count</b>{" "}
                      <span className="text-danger asterisk-size">*</span>
                    </label>
                  </div>
                  <div className="col-md-6 mg-t-7">
                    <input
                      type="number"
                      className="form-control"
                      id="DeliveredCount"
                      name="DeliveredCount"
                      value={this.state.deliveredCount}
                      onChange={this.onChangeDeliveredCount}
                      max="9999999"
                      min="1"
                    />
                    <div className="error-message">
                      {this.state.formErrors["deliveredCountError"]}
                    </div>
                  </div>
                </div>
                <div className="row row-sm mg-t-30">
                  <div className="col-md-2 mg-l-25"></div>
                  <div className="col-md-3">
                    <input
                      type="submit"
                      id="Save"
                      className="mg-t-10 mg-md-t-0 btn btn-gray-700 btn-block"
                      value="Update"
                    />
                  </div>
                  <div className="col-md-1"></div>
                  <div className="col-md-3 mg-t-10 mg-lg-t-0">
                    <span
                      className="mg-t-10 mg-md-t-0 btn btn-gray-700 btn-block"
                      onClick={() =>
                        this.setState({ showDeliverProjectBatchModal: false })
                      }
                    >
                      Close
                    </span>
                  </div>
                </div>
              </form>
            </Modal.Body>
          </LoadingOverlay>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    projectBatches: state.projectBatches,
  };
};

export default connect(mapStateToProps, { getProjectBatches })(
  OnGoingProjectBatchList
);
