import React, { Component } from "react";
import genericActivityService from "../../services/genericActivity.service";
import helper from "../../helpers/helpers";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class EditGenericActivity extends Component {
  constructor(props) {
    super(props); //reference to the parents constructor() function.
    //add super(props) every single time we define a constructor() function inside a class-based component
    this.onChangeActivity = this.onChangeActivity.bind(this);
    this.onChangeIsActive = this.onChangeIsActive.bind(this);
    this.moveToGenericActivityList = this.moveToGenericActivityList.bind(this);
    this.saveGenericActivity = this.saveGenericActivity.bind(this);
    this.reset = this.reset.bind(this);

    this.state = {
      genericActivityID: 0,
      activity: "",
      isActive: true,
      formErrors: {},
      loading: false,
      spinnerMessage: "",
    };
  }

  //#region Component Mount
  componentDidMount() {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    this.fetchGenericActivity();
  }
  //#endregion

  //#region Fetching selected Generic Activity details
  fetchGenericActivity() {
    const { state } = this.props.location; // Generic Activity ID passed from View Generic Activity Page
    if (state === 0 || state === null || state === undefined) {
      this.props.history.push("/Masters/GenericActivities");
      return;
    }

    this.setState({
      spinnerMessage: "Please wait while loading Generic Activities...",
      loading: true,
    });

    genericActivityService
      .getGenericActivity(state, helper.getUser())
      .then((response) => {
        this.setState({
          genericActivityID: response.data.GenericActivityID,
          activity: response.data.Activity,
          isActive: response.data.IsActive,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({ loading: false });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region get IsActive value
  onChangeIsActive(e) {
    this.setState({
      isActive: e.target.checked,
    });
  }
  //#endregion

  //#region bind control value to state variable
  onChangeActivity(e) {
    this.setState({
      activity: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null)
      this.setState({ formErrors: {} });
  }
  //#endregion

  //#region Redirect to Project Activity List Page
  moveToGenericActivityList = (e) => {
    this.props.history.push("/Masters/GenericActivities");
  };
  //#endregion

  //#region Reset the page
  reset() {
    this.setState({ formErrors: {} });
    this.fetchGenericActivity();
  }
  //#endregion

  //#region Save Generic Activity
  saveGenericActivity = (e) => {
    e.preventDefault(); //cancels the event if it is cancelable,
    //meaning that the default action that belongs to the event will not occur.

    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    if (this.handleFormValidation()) {
      this.setState({
        spinnerMessage: "Please wait while saving Generic Activity...",
        loading: true,
      });

      //Bind state data to object
      var data = {
        GenericActivityID: this.state.genericActivityID,
        activity: this.state.activity.trim(),
        isActive: this.state.isActive,
        UserID: helper.getUser(),
      };

      //Service call
      genericActivityService
        .updateGenericActivity(this.state.genericActivityID, data)
        .then((response) => {
          toast.success("Activity Updated Successfully");
          this.setState(this.initialState);
          this.props.history.push({
            pathname: "/Masters/GenericActivities",
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          toast.error(error.response.data.Message, { autoClose: false });
        });
    }
  };
  //#endregion

  //#region  Validating the input data
  handleFormValidation() {
    const activity = this.state.activity.trim();
    let formErrors = {};
    let isValidForm = true;

    //activity
    if (!activity) {
      isValidForm = false;
      formErrors["activityError"] = "Activity is required";
    }

    this.setState({ formErrors: formErrors });
    return isValidForm;
  }
  //#endregion

  render() {
    const { activityError } = this.state.formErrors;

    return (
      <div className="pro-main-display">
        <LoadingOverlay
          active={this.state.loading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
          <div className="az-content-breadcrumb">
            <span>Master</span>
            <span>Generic-Activities</span>
          </div>
          <h4>
            Edit Generic-Activity{" "}
            <span className="icon-size">
              <i
                className="far fa-arrow-alt-circle-left text-primary pointer"
                onClick={this.moveToGenericActivityList}
                title="Back to List"
                tabIndex="1"
              ></i>
            </span>
          </h4>
          <div>
            <div className="row row-sm">
              <div className="col-md-3">
                <label>
                  <b>Generic Activity ID</b>{" "}
                  <span className="text-danger asterisk-size">*</span>
                </label>
              </div>
              <div className="col-md-5 mg-t-7 mg-l-10">
                <p> {this.state.genericActivityID}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <label>
                  <b>Activity</b>{" "}
                  <span className="text-danger asterisk-size">*</span>
                </label>
              </div>
              <div className="col-md-5">
                <input
                  type="text"
                  className="form-control"
                  tabIndex="2"
                  id="Activity"
                  name="Activity"
                  maxLength="50"
                  value={this.state.activity}
                  onChange={this.onChangeActivity}
                />
                {activityError && (
                  <div className="error-message">{activityError}</div>
                )}
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-3">
                <label>
                  <b>Is Active?</b>
                </label>
              </div>
              <div className="col-md-5 mg-t-5">
                <input
                  type="checkbox"
                  value={this.state.isActive}
                  onChange={this.onChangeIsActive}
                  checked={this.state.isActive}
                  tabIndex="3"
                />
              </div>
            </div>
            <br />
            <div className="row row-sm">
              <div className="col-md-1"></div>
              <div className="col-md-2">
                <button
                  className="mg-t-10 mg-md-t-0 btn btn-gray-700 btn-block"
                  tabIndex="4"
                  id="Save"
                  onClick={this.saveGenericActivity}
                >
                  Save
                </button>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-2">
                <button
                  className="mg-t-10 mg-md-t-0 btn btn-gray-700 btn-block"
                  tabIndex="5"
                  id="Reset"
                  onClick={this.reset}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default EditGenericActivity;
