import React, { Component } from "react";
import { Link } from "react-router-dom";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import helper from "../../helpers/helpers";
import Moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import postProjectBatchDetailsService from "../../services/postProjectBatchDetails.service";
toast.configure();

class PostProjectBatchDetails extends Component {
  constructor(props) {
    super(props); //reference to the parents constructor() function.

    this.onChangeDuplicateCount = this.onChangeDuplicateCount.bind(this);
    this.onChangeExceptionalCount = this.onChangeExceptionalCount.bind(this);
    this.onChangeNotProcessedCount = this.onChangeNotProcessedCount.bind(this);
    this.onChangeRemarks = this.onChangeRemarks.bind(this);
    this.onChangeQCSamplingPercentage =
      this.onChangeQCSamplingPercentage.bind(this);
    this.onChangeQCErrorRate = this.onChangeQCErrorRate.bind(this);
    this.onChangeQASamplingPercentage =
      this.onChangeQASamplingPercentage.bind(this);
    this.onChangeQAErrorRate = this.onChangeQAErrorRate.bind(this);
    this.onChangeCAPADetails = this.onChangeCAPADetails.bind(this);
    this.reset = this.reset.bind(this);

    //Component State
    this.state = {
      projectID: "",
      customerCode: "",
      projectCode: "",
      batchNo: "",
      scope: "",
      deliveredDate: "",
      inputCount: 0,
      deliveredCount: 0,
      duplicateCount: 0,
      exceptionalCount: 0,
      notProcessedCount: 0,
      remarks: "",
      QCSamplingPercentage: 0,
      QCErrorRate: 0,
      QASamplingPercentage: 0,
      QAErrorRate: 0,
      CAPADetails: "",
      formErrors: {},
      loading: false,
      spinnerMessage: "",
    };

    this.initialState = this.state;
  }

  //#region Component Mount
  componentDidMount() {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    this.fetchPostProjectBatchDetails();
  }
  //#endregion

  //#region Fetching Post Project Batch details
  fetchPostProjectBatchDetails() {
    const { state } = this.props.location; // Project Batch Details passed from Delivered Project Batch List Page
    if (state === 0 || state === null || state === undefined) {
      this.props.history.push("/Projects");
      return;
    }

    this.setState({
      spinnerMessage:
        "Please wait while fetching post project batch details...",
      loading: true,
    });

    postProjectBatchDetailsService
      .readPostProjectBatchDetailsByBatchNo(
        state.CustomerCode,
        state.ProjectCode,
        state.BatchNo,
        helper.getUser()
      )
      .then((response) => {
        this.setState({
          projectID: state.ProjectID,
          customerCode: state.CustomerCode,
          projectCode: state.ProjectCode,
          batchNo: state.BatchNo,
          scope: state.Scope,
          inputCount: state.InputCount,
          deliveredDate: state.DeliveredDate,
          deliveredCount: state.DeliveredCount,
          duplicateCount: response.data.DuplicateCount,
          exceptionalCount: response.data.ExceptionalCount,
          notProcessedCount: response.data.NotProcessedCount,
          QCSamplingPercentage: response.data.QCSamplingPercentage,
          QCErrorRate: response.data.QCErrorRate,
          QASamplingPercentage: response.data.QASamplingPercentage,
          QAErrorRate: response.data.QAErrorRate,
          remarks: response.data.Remarks,
          CAPADetails: response.data.CAPADetails,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({ loading: false });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Get Duplicate Count value
  onChangeDuplicateCount(e) {
    this.setState({
      duplicateCount: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = { ...this.state.formErrors, duplicateCountError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Get Exceptional Count value
  onChangeExceptionalCount(e) {
    this.setState({
      exceptionalCount: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = {
        ...this.state.formErrors,
        exceptionalCountError: "",
      };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Get Not Processed Count value
  onChangeNotProcessedCount(e) {
    this.setState({
      notProcessedCount: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = {
        ...this.state.formErrors,
        notProcessedCountError: "",
      };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Get Remarks value
  onChangeRemarks(e) {
    this.setState({
      remarks: e.target.value,
    });
  }
  //#endregion

  //#region Get QC Sampling Percentage value
  onChangeQCSamplingPercentage(e) {
    this.setState({
      QCSamplingPercentage: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = {
        ...this.state.formErrors,
        QCSamplingPercentageError: "",
      };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Get QC Error Rate value
  onChangeQCErrorRate(e) {
    this.setState({
      QCErrorRate: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = { ...this.state.formErrors, QCErrorRateError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Get QA Sampling Percentage value
  onChangeQASamplingPercentage(e) {
    this.setState({
      QASamplingPercentage: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = {
        ...this.state.formErrors,
        QASamplingPercentageError: "",
      };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Get QA Error Rate value
  onChangeQAErrorRate(e) {
    this.setState({
      QAErrorRate: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = { ...this.state.formErrors, QAErrorRateError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Get CAPA Details value
  onChangeCAPADetails(e) {
    this.setState({
      CAPADetails: e.target.value,
    });
  }
  //#endregion

  //#region Validating the input data
  handlePostProjectBatchFormValidation() {
    const inputCount = this.state.inputCount;
    const duplicateCount = this.state.duplicateCount;
    const exceptionalCount = this.state.exceptionalCount;
    const notProcessedCount = this.state.notProcessedCount;
    const QCSamplingPercentage = this.state.QCSamplingPercentage;
    const QCErrorRate = this.state.QCErrorRate;
    const QASamplingPercentage = this.state.QASamplingPercentage;
    const QAErrorRate = this.state.QAErrorRate;
    let formErrors = {};
    let isValidForm = true;

    //Duplicate Count
    if (
      duplicateCount === undefined ||
      duplicateCount === null ||
      duplicateCount === ""
    ) {
      isValidForm = false;
      formErrors["duplicateCountError"] = "Duplicate Count is required";
    }

    //Exceptional Count
    if (
      exceptionalCount === undefined ||
      exceptionalCount === null ||
      exceptionalCount === ""
    ) {
      isValidForm = false;
      formErrors["exceptionalCountError"] = "Exceptional Count is required";
    }

    //Not Processed Count
    if (
      notProcessedCount === undefined ||
      notProcessedCount === null ||
      notProcessedCount === ""
    ) {
      isValidForm = false;
      formErrors["notProcessedCountError"] = "Not Processed Count is required";
    }

    //Duplicate Count greater than Input Count
    if (duplicateCount > inputCount) {
      isValidForm = false;
      formErrors["duplicateCountError"] =
        "Duplicate Count can't be more than Input Count";
    }

    //Exceptional Count greater than Input Count
    if (exceptionalCount > inputCount) {
      isValidForm = false;
      formErrors["exceptionalCountError"] =
        "Exceptional Count can't be more than Input Count";
    }

    //Not Processed Count greater than Input Count
    if (notProcessedCount > inputCount) {
      isValidForm = false;
      formErrors["notProcessedCountError"] =
        "Not Processed Count can't be more than Input Count";
    }

    //QC Sampling Percentage
    if (
      QCSamplingPercentage === undefined ||
      QCSamplingPercentage === null ||
      QCSamplingPercentage === ""
    ) {
      isValidForm = false;
      formErrors["QCSamplingPercentageError"] =
        "QC Sampling Percentage is required";
    }

    //QC Error Rate
    if (
      QCErrorRate === undefined ||
      QCErrorRate === null ||
      QCErrorRate === ""
    ) {
      isValidForm = false;
      formErrors["QCErrorRateError"] = "QC Error Rate is required";
    }

    //QA Sampling Percentage
    if (
      QASamplingPercentage === undefined ||
      QASamplingPercentage === null ||
      QASamplingPercentage === ""
    ) {
      isValidForm = false;
      formErrors["QASamplingPercentageError"] =
        "QC Sampling Percentage is required";
    }

    //QA Error Rate
    if (
      QAErrorRate === undefined ||
      QAErrorRate === null ||
      QAErrorRate === ""
    ) {
      isValidForm = false;
      formErrors["QAErrorRateError"] = "QA Error Rate is required";
    }

    this.setState({ formErrors: formErrors });
    return isValidForm;
  }
  //#endregion

  //#region Save Post Project Batch Details
  savePostProjectBatchDetails = (e) => {
    e.preventDefault();

    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    if (this.handlePostProjectBatchFormValidation()) {
      this.setState({
        spinnerMessage:
          "Please wait while Saving Post Project Batch Details...",
        loading: true,
      });

      let projectID = this.state.projectID;
      let scope = this.state.scope;

      var data = {
        CustomerCode: this.state.customerCode,
        ProjectCode: this.state.projectCode,
        BatchNo: this.state.batchNo,
        DuplicateCount: this.state.duplicateCount,
        ExceptionalCount: this.state.exceptionalCount,
        NotProcessedCount: this.state.notProcessedCount,
        QCSamplingPercentage: this.state.QCSamplingPercentage,
        QCErrorRate: this.state.QCErrorRate,
        QASamplingPercentage: this.state.QASamplingPercentage,
        QAErrorRate: this.state.QAErrorRate,
        Remarks: this.state.remarks,
        CAPADetails: this.state.CAPADetails,
        UserID: helper.getUser(),
      };

      //Service call
      postProjectBatchDetailsService
        .updatePostProjectBatchDetails(data)
        .then(() => {
          this.setState({
            loading: false,
          });
          toast.success("Post Project Batch Details Updated Successfully");
          this.setState(this.initialState);
          this.props.history.push({
            pathname: "/Projects/ProjectBatchList",
            state: {
              ProjectID: projectID,
              CustomerCode: data.CustomerCode,
              ProjectCode: data.ProjectCode,
              Scope: scope,
              activeTab: 2,
            },
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          toast.error(error.response.data.Message, { autoClose: false });
        });
    }
  };
  //#endregion

  //#region Reset Page
  reset() {
    this.setState(
      {
        customerCode: "",
        projectCode: "",
        batchNo: "",
        scope: "",
        deliveredDate: "",
        inputCount: 0,
        deliveredCount: 0,
        duplicateCount: 0,
        exceptionalCount: 0,
        notProcessedCount: 0,
        remarks: "",
        QCSamplingPercentage: 0,
        QCErrorRate: 0,
        QASamplingPercentage: 0,
        QAErrorRate: 0,
        CAPADetails: "",
        formErrors: {},
      },
      () => this.fetchPostProjectBatchDetails()
    );
  }
  //#endregion

  render() {

    return (
      <>
        <LoadingOverlay
          active={this.state.loading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
          <div>
            <div className="container mg-t-5">
              <h4>
                Post Project Batch Details{" "}
                <span className="icon-size">
                  <Link
                    to={{
                      pathname: "/Projects/ProjectBatchList",
                      state: {
                        ProjectID: this.state.projectID,
                        CustomerCode: this.state.customerCode,
                        ProjectCode: this.state.projectCode,
                        Scope: this.state.scope,
                        activeTab: 2,
                      },
                    }}
                  >
                    <i
                      className="far fa-arrow-alt-circle-left text-primary pointer"
                      tabIndex="1"
                      title="Back to List"
                    ></i>
                  </Link>
                </span>
              </h4>
              <form onSubmit={this.savePostProjectBatchDetails}>
                <div>
                  <div className="row row-sm">
                    <div className="col-lg">
                      <div className="row row-sm mg-t-20">
                        <div className="col-md-5 text-nowrap">
                          <label htmlFor="CustomerCode">
                            <b>Customer Code</b>{" "}
                            <span className="text-danger asterisk-size">*</span>
                          </label>
                        </div>
                        <div className="col-md-6 mg-t-7">
                          <p id="CustomerCode" name="CustomerCode">
                            {this.state.customerCode}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg mg-t-10 mg-lg-t-0">
                      <div className="row row-sm mg-t-20">
                        <div className="col-md-5">
                          <label htmlFor="ProjectCode">
                            <b>Project Code</b>{" "}
                            <span className="text-danger asterisk-size">*</span>
                          </label>
                        </div>
                        <div className="col-md-6 mg-t-7">
                          <p id="ProjectCode" name="ProjectCode">
                            {this.state.projectCode}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg mg-t-10 mg-lg-t-0">
                      <div className="row row-sm mg-t-20">
                        <div className="col-md-5">
                          <label htmlFor="BatchNo">
                            <b>Batch No.</b>{" "}
                            <span className="text-danger asterisk-size">*</span>
                          </label>
                        </div>
                        <div className="col-md-6 mg-t-7">
                          <p id="BatchNo" name="BatchNo">
                            {this.state.batchNo}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row row-sm">
                    <div className="col-lg">
                      <h5>
                        <b>Enter Details of Delivered Project</b>
                      </h5>
                      <div className="row row-sm">
                        <div className="col-md-4 text-nowrap mg-t-7">
                          <label htmlFor="DeliveredDate">Delivered Date</label>
                        </div>
                        <div className="col-md-6 mg-t-7">
                          <p id="DeliveredDate" name="DeliveredDate">
                            {this.state.deliveredDate !== null
                              ? Moment(this.state.deliveredDate).format(
                                  "DD-MMM-yyyy"
                                )
                              : ""}
                          </p>
                        </div>
                      </div>
                      <div className="row row-sm">
                        <div className="col-md-4 text-nowrap">
                          <label htmlFor="InputCount">Input Count</label>
                        </div>
                        <div className="col-md-6">
                          <p id="InputCount" name="InputCount">
                            {this.state.inputCount}
                          </p>
                        </div>
                      </div>
                      <div className="row row-sm">
                        <div className="col-md-4 text-nowrap">
                          <label htmlFor="DeliveredCount">
                            Delivered Count
                          </label>
                        </div>
                        <div className="col-md-6">
                          <p id="DeliveredCount" name="DeliveredCount">
                            {this.state.deliveredCount}
                          </p>
                        </div>
                      </div>
                      <div className="row row-sm">
                        <div className="col-md-4 text-nowrap">
                          <label htmlFor="DuplicateCount">
                            Duplicate Count{" "}
                            <span className="text-danger asterisk-size">*</span>
                          </label>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="number"
                            className="form-control"
                            id="DuplicateCount"
                            name="DuplicateCount"
                            value={this.state.duplicateCount}
                            onChange={this.onChangeDuplicateCount}
                            max="999999"
                            min="0"
                          />
                          <div className="error-message">
                            {this.state.formErrors["duplicateCountError"]}
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm">
                        <div className="col-md-4 text-nowrap">
                          <label htmlFor="ExceptionalCount">
                            Exceptional Count{" "}
                            <span className="text-danger asterisk-size">*</span>
                          </label>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="number"
                            className="form-control"
                            id="ExceptionalCount"
                            name="ExceptionalCount"
                            value={this.state.exceptionalCount}
                            onChange={this.onChangeExceptionalCount}
                            max="999999"
                            min="0"
                          />
                          <div className="error-message">
                            {this.state.formErrors["exceptionalCountError"]}
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm">
                        <div className="col-md-4 text-nowrap">
                          <label htmlFor="NotProcessedCount">
                            Not Processed Count{" "}
                            <span className="text-danger asterisk-size">*</span>
                          </label>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="number"
                            className="form-control"
                            id="NotProcessedCount"
                            name="NotProcessedCount"
                            value={this.state.notProcessedCount}
                            onChange={this.onChangeNotProcessedCount}
                            max="999999"
                            min="0"
                          />
                          <div className="error-message">
                            {this.state.formErrors["notProcessedCountError"]}
                          </div>
                        </div>
                      </div>
                      <div>
                        <label htmlFor="Remarks">Remarks</label>
                        <textarea
                          className="form-control col-md-10"
                          rows="2"
                          id="Remarks"
                          name="Remarks"
                          maxLength="500"
                          value={this.state.remarks}
                          onChange={this.onChangeRemarks}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-lg mg-t-10 mg-lg-t-0">
                      <h5>
                        <b>QC Details</b>
                      </h5>
                      <div className="row row-sm">
                        <div className="col-md-4 text-nowrap">
                          <label htmlFor="QCSampling">
                            QC Sampling(%){" "}
                            <span className="text-danger asterisk-size">*</span>
                          </label>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="number"
                            className="form-control"
                            id="QCSampling"
                            name="QCSampling"
                            value={this.state.QCSamplingPercentage}
                            onChange={this.onChangeQCSamplingPercentage}
                            max="100"
                            min="0"
                            placeholder="Enter from 0-100%"
                          />
                          <div className="error-message">
                            {this.state.formErrors["QCSamplingPercentageError"]}
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm mg-b-20">
                        <div className="col-md-4 text-nowrap">
                          <label htmlFor="QCErrorRate">
                            QC Error Rate(%){" "}
                            <span className="text-danger asterisk-size">*</span>
                          </label>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="number"
                            className="form-control"
                            id="QCErrorRate"
                            name="QCErrorRate"
                            value={this.state.QCErrorRate}
                            onChange={this.onChangeQCErrorRate}
                            max="100"
                            min="0"
                            placeholder="Enter from 0-100%"
                          />
                          <div className="error-message">
                            {this.state.formErrors["QCErrorRateError"]}
                          </div>
                        </div>
                      </div>
                      <h5>
                        <b>QA Details</b>
                      </h5>
                      <div className="row row-sm">
                        <div className="col-md-4 text-nowrap">
                          <label htmlFor="QASampling">
                            QA Sampling(%){" "}
                            <span className="text-danger asterisk-size">*</span>
                          </label>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="number"
                            className="form-control"
                            id="QASampling"
                            name="QASampling"
                            value={this.state.QASamplingPercentage}
                            onChange={this.onChangeQASamplingPercentage}
                            max="100"
                            min="0"
                            placeholder="Enter from 0-100%"
                          />
                          <div className="error-message">
                            {this.state.formErrors["QASamplingPercentageError"]}
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm mg-b-20">
                        <div className="col-md-4 text-nowrap">
                          <label htmlFor="QAErrorRate">
                            QA Error Rate(%){" "}
                            <span className="text-danger asterisk-size">*</span>
                          </label>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="number"
                            className="form-control"
                            id="QAErrorRate"
                            name="QAErrorRate"
                            value={this.state.QAErrorRate}
                            onChange={this.onChangeQAErrorRate}
                            max="100"
                            min="0"
                            placeholder="Enter from 0-100%"
                          />
                          <div className="error-message">
                            {this.state.formErrors["QAErrorRateError"]}
                          </div>
                        </div>
                      </div>
                      <h5>
                        <b>CAPA Details</b>
                      </h5>
                      <textarea
                        className="form-control col-md-10"
                        rows="2"
                        id="CAPADetails"
                        name="CAPADetails"
                        maxLength="500"
                        value={this.state.CAPADetails}
                        onChange={this.onChangeCAPADetails}
                      ></textarea>
                    </div>
                  </div>
                  <br />
                  <div className="row row-sm">
                    <div className="col-md-3"></div>
                    <div className="col-md-2 mg-t-10 mg-lg-t-0">
                      <input
                        type="submit"
                        id="Save"
                        className="mg-t-10 mg-md-t-0 btn btn-gray-700 btn-block"
                        value="Save"
                      />
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-2  mg-t-10 mg-lg-t-0">
                      <span
                        className="btn btn-gray-700 btn-block"
                        onClick={this.reset}
                        id="Reset"
                      >
                        Reset
                      </span>
                    </div>
                  </div>
                  <div className="mg-b-10"></div>
                </div>
              </form>
            </div>
          </div>
        </LoadingOverlay>
      </>
    );
  }
}

export default PostProjectBatchDetails;
