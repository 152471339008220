import React, { Component } from "react";
import helper from "../../helpers/helpers";
import roleAccessService from "../../services/roleAccess.service";
import tableFunctions from "../../helpers/tableFunctions";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Row, Col } from "react-bootstrap";
toast.configure();

class editRoleAccess extends Component {
  constructor(props) {
    super(props); //reference to the parent constructor
    this.divScrollRef = React.createRef();

    this.onChangeSortOrder = this.onChangeSortOrder.bind(this);
    this.onChangeColumn = this.onChangeColumn.bind(this);
    this.sortData = this.sortData.bind(this);
    this.displaySortingFields = this.displaySortingFields.bind(this);
    this.displayFilteringField = this.displayFilteringField.bind(this);
    this.onChangefilterValue = this.onChangefilterValue.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.clearSearchField = this.clearSearchField.bind(this);
    this.clearSortFields = this.clearSortFields.bind(this);
    this.onChangeIsActive = this.onChangeIsActive.bind(this);
    this.reset = this.reset.bind(this);

    this.state = {
      roleAccess: [],
      roleName: "",
      grantAll: false,
      revokeAll: false,
      loading: false,
      spinnerMessage: "",
      index: 30,
      position: 0,
      columns: [],
      selectedColumn: "",
      selectedSort: "",
      isToShowSortingFields: false,
      isToShowFilteringField: true,
      filteredArray: [],
      filterValue: "",
    };

    this.initialState = this.state;
  }

  //#region component mount
  componentDidMount() {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    this.fetchRoleAccess();
  }
  //#endregion

  //#region Fetching selected Role Access details
  fetchRoleAccess() {
    const { state } = this.props.location; // RoleName passed from View Role Access Page
    if (state === 0 || state === null || state === undefined) {
      this.props.history.push("/admin/RoleAccessList");
      return;
    }

    this.setState({
      roleName: state,
      spinnerMessage: "Please wait while loading Role Access...",
      loading: true,
    });

    roleAccessService
      .ReadRoleAccessByRoleName(state, helper.getUser())
      .then((response) => {
        this.setState({
          roleAccess: response.data,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region get IsActive value
  onChangeIsActive(PageName, IsActive) {
    var index = this.state.roleAccess.findIndex((x) => x.PageName === PageName);

    const roleAccess = [...this.state.roleAccess];
    roleAccess[index] = { ...roleAccess[index], IsActive: IsActive };

    this.setState({
      roleAccess: roleAccess,
      grantAll: false,
      revokeAll: false,
    });
  }
  //#endregion

  //#region Save Role Access
  saveRoleAccess = (e) => {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    this.setState({
      spinnerMessage: "Please wait while saving Role Access...",
      loading: true,
    });

    //Bind state data to object
    var data = {
      RoleAccessID: 0,
      RoleAccessList: this.state.roleAccess,
      UserID: helper.getUser(),
    };

    //Service call
    roleAccessService
      .updateRoleAccess(data)
      .then(() => {
        toast.success("Role Access Updated Successfully");
        this.setState(this.initialState);
        this.props.history.push({
          pathname: "/admin/RoleAccessList",
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        toast.error(error.response.data.Message, { autoClose: false });
      });
  };
  //#endregion

  //#region Grant All or Revoke all
  grantAllOrRevokeAll(value) {
    if (value) {
      this.setState({
        grantAll: true,
        revokeAll: false,
      });
    } else {
      this.setState({
        grantAll: false,
        revokeAll: true,
      });
    }

    const tmpRoleAccess = [...this.state.roleAccess];

    const newRoleAccess = tmpRoleAccess.map((obj) => {
      return { ...obj, IsActive: value };
    });

    this.setState({
      roleAccess: newRoleAccess,
    });
  }
  //#endregion

  //#region Reset the page
  reset() {
    this.setState(this.initialState);
    this.componentDidMount();
  }
  //#endregion

  //#region Scroll to Top
  scrollToTop = () => {
    this.divScrollRef.current.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  //#endregion

  //#region Sort Functions
  //#region Display Sorting Fields
  displaySortingFields() {
    let columns = Object.keys(this.state.roleAccess[0]).slice(1);
    let indexOfRoleName = columns.indexOf("RoleName");

    columns.splice(indexOfRoleName, 1);

    this.setState((previousState) => ({
      isToShowSortingFields: !previousState.isToShowSortingFields,
      selectedColumn: "",
      selectedSort: "",
      columns: columns,
      filterValue: "",
      isToShowFilteringField: false,
    }));
  }
  //#endregion

  //#region Selecting the sort column
  onChangeColumn(e) {
    this.setState({
      selectedColumn: e.target.value,
      selectedSort: "",
    });
  }
  //#endregion

  //#region On Change Sort
  onChangeSortOrder(e) {
    this.setState(
      {
        selectedSort: e.target.value,
      },
      () => this.sortData()
    );
  }
  //#endregion

  //#region Sort Data based on column and order
  sortData() {
    let sortedArray = [];
    let column =
      this.state.selectedColumn !== "" ? this.state.selectedColumn : "SlNo";
    const selectedSort =
      this.state.selectedSort !== "" ? this.state.selectedSort : "ascending";
    let numberColumns = ["SlNo", "IsActive"];

    sortedArray = tableFunctions.sortData(
      this.state.roleAccess,
      column,
      selectedSort,
      numberColumns,
      []
    );

    sortedArray = sortedArray.map((roles, index) => {
      return {
        ...roles,
        SlNo: index + 1,
      };
    });
    this.setState({ roleAccess: sortedArray });
  }
  //#endregion

  //#region  Clear Sort
  clearSortFields() {
    this.setState({ selectedColumn: "", selectedSort: "" }, () =>
      this.sortData()
    );
    const { state } = this.props.location;
    roleAccessService
      .ReadRoleAccessByRoleName(state, helper.getUser())
      .then((response) => {
        this.setState({
          roleAccess: response.data,
          loading: false,
        });
      });
  }
  //#endregion
  //#endregion

  //#region Filter Functions
  //#region Display Filtering Field
  displayFilteringField() {
    this.setState((previousState) => ({
      isToShowFilteringField: !previousState.isToShowFilteringField,
      filterValue: "",
      isToShowSortingFields: false,
    }));
  }
  //#endregion

  //#region on change filter value
  onChangefilterValue(e) {
    this.setState({ filterValue: e.target.value }, () =>
      this.getFilteredRows()
    );
  }
  //#endregion

  //#region get filtered rows
  getFilteredRows() {
    let filteredArray = tableFunctions.filterArray(
      this.state.roleAccess,
      this.state.filterValue
    );

    filteredArray = filteredArray.map((userRoles, index) => {
      return {
        ...userRoles,
        SlNo: index + 1,
      };
    });
    this.setState({ filteredArray: filteredArray });
  }
  //#endregion

  //#region Clear Search
  clearSearchField() {
    this.setState({
      filterValue: "",
    });
  }
  //#endregion
  //#endregion

  //#region Handle Scroll
  handleScroll(e) {
    var currentHeight = e.currentTarget.scrollTop;
    var maxScrollPosition =
      e.currentTarget.scrollHeight - e.currentTarget.clientHeight;

    this.setState({ position: currentHeight });

    if ((currentHeight / maxScrollPosition) * 100 > 90) {
      let curIndex = this.state.index + 20;
      this.setState({ index: curIndex });
    }
  }
  //#endregion

  render() {
    const data = this.state.roleAccess.slice(0, this.state.index);
    const filteredData = this.state.filteredArray.slice(0, this.state.index);

    const RoleAccessColumns = [
      {
        dataField: "SlNo",
        text: "Sl No.",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "RoleName",
        text: "Role Name",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
        hidden: true,
      },
      {
        dataField: "PageName",
        text: "Page Name",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "IsActive",
        text: "Is Active ?",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
        formatter: (cell, row, rowIndex, extraData) => (
          <div>
            <input
              type="checkbox"
              value={row.IsActive}
              onChange={() =>
                this.onChangeIsActive(row.PageName, !row.IsActive)
              }
              checked={row.IsActive}
            />
          </div>
        ),
      },
    ];

    return this.state.roleAccess.length > 0 ? (
      <div>
        <LoadingOverlay
          active={this.state.loading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
          <div className="az-content-breadcrumb">
            <span>Role</span>
            <span>Role Access</span>
          </div>
          <h4>
            Edit Role Access{" "}
            <span className="icon-size">
              <i
                className="far fa-arrow-alt-circle-left text-primary pointer"
                onClick={() => this.props.history.goBack()}
                title="Back to List"
              ></i>
            </span>
          </h4>
          <div id="Add_form">
            <Row>
              <Col md={2} className="text-nowrap">
                <b>Rolename</b>{" "}
                <span className="text-danger asterisk-size">*</span>
              </Col>
              <Col md={4}>
                <p style={{ marginTop: "8px" }}>{this.state.roleName}</p>
              </Col>
            </Row>
            <ToolkitProvider
              keyField="SlNo"
              data={this.state.filterValue === "" ? data : filteredData}
              columns={RoleAccessColumns}
              search
            >
              {(props) => (
                <div>
                  <Row className="mg-b-10" style={{ marginRight: "15px" }}>
                    <Col md={9} style={{ whiteSpace: "nowrap" }}>
                      {this.state.isToShowSortingFields && (
                        <Row>
                          <Col md={5}>
                            <Row>
                              <Col md={3} className="mg-t-7">
                                <label htmlFor="sortColumn">Column:</label>
                              </Col>
                              <Col md={9}>
                                <select
                                  className="form-control mg-l-5"
                                  value={this.state.selectedColumn}
                                  onChange={this.onChangeColumn}
                                >
                                  <option value="">--Select--</option>
                                  {this.state.columns.map((col) => (
                                    <option key={col}>{col}</option>
                                  ))}
                                </select>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={5}>
                            <Row>
                              <Col md={3} className="mg-t-7">
                                <label htmlFor="sortOrder">Order:</label>
                              </Col>
                              <Col md={9}>
                                <select
                                  className="form-control mg-l-5"
                                  value={this.state.selectedSort}
                                  onChange={this.onChangeSortOrder}
                                >
                                  <option value="">--Select--</option>
                                  <option value="ascending">Ascending</option>
                                  <option value="descending">Descending</option>
                                </select>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={1}>
                            <i
                              className="far fa-window-close btn btn-primary pd-b-5"
                              onClick={this.clearSortFields}
                              title="Clear Sort Fields"
                            ></i>
                          </Col>
                        </Row>
                      )}
                      {this.state.isToShowFilteringField && (
                        <Row>
                          <Col md={1} className="mg-t-7">
                            <label htmlFor="search">Search:</label>
                          </Col>
                          <Col md={9}>
                            <input
                              type="text"
                              maxLength="20"
                              className="form-control mg-l-5"
                              value={this.state.filterValue}
                              onChange={this.onChangefilterValue}
                            />
                          </Col>
                          <Col md={1}>
                            <i
                              className="far fa-window-close btn btn-primary pd-b-5"
                              onClick={this.clearSearchField}
                              title="Clear Filter"
                            ></i>
                          </Col>
                        </Row>
                      )}
                    </Col>
                    <Col md={3} style={{ textAlign: "end", marginTop: "10px" }}>
                      <Row className="text-nowrap">
                        {this.state.roleAccess.length > 0 && (
                          <div>
                            <input
                              type="checkbox"
                              id="grantAll"
                              value={this.state.grantAll}
                              onChange={() => this.grantAllOrRevokeAll(true)}
                              checked={this.state.grantAll}
                            />
                            <label htmlFor="grantAll" className="mg-l-5">
                              Grant All
                            </label>
                            <input
                              type="checkbox"
                              className="mg-l-20"
                              id="revokeAll"
                              value={this.state.revokeAll}
                              onChange={() => this.grantAllOrRevokeAll(false)}
                              checked={this.state.revokeAll}
                            />
                            <label htmlFor="revokeAll" className="mg-l-5">
                              Revoke All
                            </label>
                          </div>
                        )}
                        <div className="mg-l-5">
                          <i
                            className="fas fa-exchange-alt fa-rotate-90 pointer"
                            title={
                              this.state.isToShowSortingFields
                                ? "Hide Sort"
                                : "Show Sort"
                            }
                            onClick={this.displaySortingFields}
                          ></i>
                          {!this.state.isToShowFilteringField ? (
                            <i
                              className="fas fa-filter pointer mg-l-10"
                              onClick={this.displayFilteringField}
                              title="Show Filter"
                            ></i>
                          ) : (
                            <i
                              className="fas fa-funnel-dollar pointer mg-l-10"
                              onClick={this.displayFilteringField}
                              title="Hide Filter"
                            ></i>
                          )}
                        </div>
                      </Row>
                    </Col>
                  </Row>
                  <div
                    style={
                      (this.state.filteredArray.length > 12 &&
                        this.state.filterValue !== "") ||
                      (this.state.roleAccess.length > 12 &&
                        this.state.filterValue === "")
                        ? {
                            maxHeight : "calc(100vh - 350px)",
                            overflowY: "scroll",
                            borderBottom: "1px solid #cdd4e0",
                          }
                        : {}
                    }
                    ref={this.divScrollRef}
                    className="userlist-table-height"
                    onScroll={this.handleScroll}
                  >
                    <BootstrapTable
                      bootstrap4
                      {...props.baseProps}
                      striped
                      hover
                      condensed
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
            <Row>
              <Col md={11} className="mg-t-10">
                <Row>
                  <Col md={4}></Col>
                  <Col md={2} className="col-md-2 mg-t-10 mg-lg-t-0">
                    <button
                      id="Save"
                      tabIndex="3"
                      onClick={this.saveRoleAccess}
                      className="mg-t-10 mg-md-t-0 btn btn-gray-700 btn-block"
                    >
                      Save
                    </button>
                  </Col>
                  <Col md={1}></Col>
                  <Col md={2} className="mg-t-10 mg-lg-t-0">
                    <button
                      className="btn btn-gray-700 btn-block"
                      tabIndex="4"
                      id="Reset"
                      onClick={this.reset}
                    >
                      Reset
                    </button>
                  </Col>
                </Row>
              </Col>
              {this.state.position > 600 && this.state.filterValue === "" && (
                <div className="up-arrow">
                  <i
                    className="fa fa-angle-up pro-arrow"
                    onClick={this.scrollToTop}
                  ></i>
                </div>
              )}
            </Row>
          </div>
        </LoadingOverlay>
      </div>
    ) : (
      "loading"
    );
  }
}

export default editRoleAccess;
