import React, { Component } from "react";
import helper from "../../helpers/helpers";
import userService from "../../services/user.service";
import userRoleService from "../../services/userRole.service";
import tableFunctions from "../../helpers/tableFunctions";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Row, Col } from "react-bootstrap";
toast.configure();

class createUserRole extends Component {
  constructor(props) {
    super(props); //reference to the parent constructor

    this.divScrollRef = React.createRef();

    this.onChangeSortOrder = this.onChangeSortOrder.bind(this);
    this.onChangeColumn = this.onChangeColumn.bind(this);
    this.sortData = this.sortData.bind(this);
    this.displaySortingFields = this.displaySortingFields.bind(this);
    this.displayFilteringField = this.displayFilteringField.bind(this);
    this.onChangefilterValue = this.onChangefilterValue.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.clearSearchField = this.clearSearchField.bind(this);
    this.clearSortFields = this.clearSortFields.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangeIsActive = this.onChangeIsActive.bind(this);
    this.reset = this.reset.bind(this);

    this.state = {
      userRoles: [],
      users: [],
      formErrors: {},
      username: "",
      grantAll: false,
      revokeAll: false,
      loading: false,
      spinnerMessage: "",
      index: 20,
      position: 0,
      columns: [],
      selectedColumn: "",
      selectedSort: "",
      isToShowSortingFields: false,
      isToShowFilteringField: false,
      filteredArray: [],
      filterValue: "",
    };

    this.initialState = this.state;
  }

  //#region Component Mount
  componentDidMount() {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    this.fetchUsersList();
  }
  //#endregion

  //#region fetching Users from Web API
  fetchUsersList() {
    this.setState({
      spinnerMessage: "Please wait while loading User List...",
      loading: true,
    });

    userService
      .getAllUsers(helper.getUser())
      .then((response) => {
        this.setState({
          users: response.data,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Fetching Rolename based on Username
  onChangeUsername(e) {
    this.setState(
      {
        username: e.target.value,
      },
      () => {
        const username = this.state.username.split("-");

        userRoleService
          .ReadUserRolesByUserName(username[0].trim(), helper.getUser())
          .then((response) => {
            this.setState({
              userRoles: response.data,
              isToShowSortingFields: false,
              isToShowFilteringField: false,
            });
          })
          .catch((e) => {
            toast.error(e.response.data.Message, { autoClose: false });
          });
      }
    );

    if (e.target.value !== "" || e.target.value !== null)
      this.setState({ formErrors: {} });
  }
  //#endregion

  //#region get IsActive value
  onChangeIsActive(RoleName, IsActive) {
    var index = this.state.userRoles.findIndex((x) => x.RoleName === RoleName);

    const userRole = [...this.state.userRoles];
    userRole[index] = { ...userRole[index], IsActive: IsActive };

    this.setState({
      userRoles: userRole,
      grantAll: false,
      revokeAll: false,
    });
  }
  //#endregion

  //#region  Validating the input data
  handleFormValidation() {
    const userName = this.state.username.trim();
    let formErrors = {};
    let isValidForm = true;

    //validating user name
    if (!userName) {
      isValidForm = false;
      formErrors["UsernameError"] = "Username is required";
    }

    this.setState({ formErrors: formErrors });
    return isValidForm;
  }
  //#endregion

  //#region Save User Role
  saveUserRole = (e) => {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    if (this.handleFormValidation()) {
      this.setState({
        spinnerMessage: "Please wait while saving User Role...",
        loading: true,
      });

      //Bind state data to object
      var data = {
        UserRoleID: 0,
        UserRolesList: this.state.userRoles,
        UserID: helper.getUser(),
      };

      //Service call
      userRoleService
        .createUserRole(data)
        .then(() => {
          toast.success("User Role Added Successfully");
          this.setState(this.initialState);
          this.props.history.push({
            pathname: "/admin/UserRolesList",
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          toast.error(error.response.data.Message, { autoClose: false });
        });
    }
  };
  //#endregion

  //#region Reset the page
  reset() {
    this.setState(this.initialState);
    this.componentDidMount();
  }
  //#endregion

  //#region Grant All or Revoke all
  grantAllOrRevokeAll(value) {
    if (value) {
      this.setState({
        grantAll: true,
        revokeAll: false,
      });
    } else {
      this.setState({
        grantAll: false,
        revokeAll: true,
      });
    }

    const tmpUserRoles = [...this.state.userRoles];

    const newUserRoles = tmpUserRoles.map((obj) => {
      return { ...obj, IsActive: value };
    });

    this.setState({
      userRoles: newUserRoles,
    });
  }
  //#endregion

  //#region Scroll to Top
  scrollToTop = () => {
    this.divScrollRef.current.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  //#endregion

  //#region Sort Functions
  //#region Display Sorting Fields
  displaySortingFields() {
    let columns = Object.keys(this.state.userRoles[0]).slice(1);
    let indexOfUserName = columns.indexOf("UserName");

    columns.splice(indexOfUserName, 1);

    this.setState((previousState) => ({
      isToShowSortingFields: !previousState.isToShowSortingFields,
      selectedColumn: "",
      selectedSort: "",
      columns: columns,
      filterValue: "",
      isToShowFilteringField: false,
    }));
  }
  //#endregion

  //#region Selecting the sort column
  onChangeColumn(e) {
    this.setState({
      selectedColumn: e.target.value,
      selectedSort: "",
    });
  }
  //#endregion

  //#region On Change Sort
  onChangeSortOrder(e) {
    this.setState(
      {
        selectedSort: e.target.value,
      },
      () => this.sortData()
    );
  }
  //#endregion

  //#region Sort Data based on column and order
  sortData() {
    let sortedArray = [];
    let column =
      this.state.selectedColumn !== "" ? this.state.selectedColumn : "SlNo";
    const selectedSort =
      this.state.selectedSort !== "" ? this.state.selectedSort : "ascending";
    let numberColumns = ["SlNo", "IsActive"];

    sortedArray = tableFunctions.sortData(
      this.state.userRoles,
      column,
      selectedSort,
      numberColumns,
      []
    );

    sortedArray = sortedArray.map((roles, index) => {
      return {
        ...roles,
        SlNo: index + 1,
      };
    });
    this.setState({ userRoles: sortedArray });
  }
  //#endregion

  //#region  Clear Sort
  clearSortFields() {
    this.setState({ selectedColumn: "", selectedSort: "" }, () =>
      this.sortData()
    );
    let username = this.state.username.split("-");
    userRoleService
      .ReadUserRolesByUserName(username[0].trim(), helper.getUser())
      .then((response) => {
        this.setState({
          userRoles: response.data,
          isToShowSortingFields: true,
        });
      });
  }
  //#endregion
  //#endregion

  //#region Filter Functions
  //#region Display Filtering Field
  displayFilteringField() {
    this.setState((previousState) => ({
      isToShowFilteringField: !previousState.isToShowFilteringField,
      filterValue: "",
      isToShowSortingFields: false,
    }));
  }
  //#endregion

  //#region on change filter value
  onChangefilterValue(e) {
    this.setState({ filterValue: e.target.value }, () =>
      this.getFilteredRows()
    );
  }
  //#endregion

  //#region get filtered rows
  getFilteredRows() {
    let filteredArray = tableFunctions.filterArray(
      this.state.userRoles,
      this.state.filterValue
    );

    filteredArray = filteredArray.map((userRoles, index) => {
      return {
        ...userRoles,
        SlNo: index + 1,
      };
    });
    this.setState({ filteredArray: filteredArray });
  }
  //#endregion

  //#region Clear Search
  clearSearchField() {
    this.setState({
      filterValue: "",
    });
  }
  //#endregion
  //#endregion

  //#region Handle Scroll
  handleScroll(e) {
    var currentHeight = e.currentTarget.scrollTop;
    var maxScrollPosition =
      e.currentTarget.scrollHeight - e.currentTarget.clientHeight;

    this.setState({ position: currentHeight });

    if ((currentHeight / maxScrollPosition) * 100 > 90) {
      let curIndex = this.state.index + 20;
      this.setState({ index: curIndex });
    }
  }
  //#endregion

  render() {
    const data = this.state.userRoles.slice(0, this.state.index);
    const filteredData = this.state.filteredArray.slice(0, this.state.index);
    const userRoleColumns = [
      {
        dataField: "SlNo",
        text: "Sl No.",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "UserName",
        text: "UserName",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
        hidden: true,
      },
      {
        dataField: "RoleName",
        text: "Role Name",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "IsActive",
        text: "Is Active?",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
        formatter: (cell, row, rowIndex, extraData) => (
          <div>
            <input
              type="checkbox"
              value={row.IsActive}
              onChange={() =>
                this.onChangeIsActive(row.RoleName, !row.IsActive)
              }
              checked={row.IsActive}
            />
          </div>
        ),
      },
    ];

    return (
      <div>
        <LoadingOverlay
          active={this.state.loading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
          <div className="az-content-breadcrumb">
            <span>Users</span>
            <span>User Roles</span>
          </div>
          <h4>
            Create User Role(s){" "}
            <span className="icon-size">
              <i
                className="far fa-arrow-alt-circle-left text-primary pointer"
                onClick={() => this.props.history.goBack()}
                title="Back to List"
              ></i>
            </span>
          </h4>
          <div id="Add_form">
            <Row>
              <div className="col-md-2 text-nowrap">
                <b>Username</b>{" "}
                <span className="text-danger asterisk-size">*</span>
              </div>
              <Col md={4}>
                <select
                  className="form-control"
                  tabIndex="1"
                  id="username"
                  name="username"
                  placeholder="--Select--"
                  value={this.state.username}
                  onChange={this.onChangeUsername}
                >
                  <option value="">--Select--</option>
                  {this.state.users.map((user) => (
                    <option key={user.UserID}>
                      {user.UserName +
                        " - " +
                        user.FirstName +
                        " " +
                        user.MiddleName +
                        " " +
                        user.LastName}
                    </option>
                  ))}
                </select>
                <div className="error-message">
                  {this.state.formErrors["UsernameError"]}
                </div>
              </Col>
            </Row>
            <ToolkitProvider
              keyField="SlNo"
              data={this.state.filterValue === "" ? data : filteredData}
              columns={userRoleColumns}
              search
            >
              {(props) => (
                <div>
                  <Row className="mg-b-10" style={{ marginRight: "15px" }}>
                    <Col md={9} style={{ whiteSpace: "nowrap" }}>
                      {this.state.isToShowSortingFields && (
                        <Row>
                          <Col md={5}>
                            <Row>
                              <Col md={3} className="mg-t-7">
                                <label htmlFor="sortColumn">Column:</label>
                              </Col>
                              <Col md={9}>
                                <select
                                  className="form-control mg-l-5"
                                  value={this.state.selectedColumn}
                                  onChange={this.onChangeColumn}
                                >
                                  <option value="">--Select--</option>
                                  {this.state.columns.map((col) => (
                                    <option key={col}>{col}</option>
                                  ))}
                                </select>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={5}>
                            <Row>
                              <Col md={3} className="mg-t-7">
                                <label htmlFor="sortOrder">Order:</label>
                              </Col>
                              <Col md={9}>
                                <select
                                  className="form-control mg-l-5"
                                  value={this.state.selectedSort}
                                  onChange={this.onChangeSortOrder}
                                >
                                  <option value="">--Select--</option>
                                  <option value="ascending">Ascending</option>
                                  <option value="descending">Descending</option>
                                </select>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={1}>
                            <i
                              className="far fa-window-close btn btn-primary pd-b-5"
                              onClick={this.clearSortFields}
                            ></i>
                          </Col>
                        </Row>
                      )}
                      {this.state.isToShowFilteringField && (
                        <Row>
                          <Col md={1} className="mg-t-7">
                            <label htmlFor="search">Search:</label>
                          </Col>
                          <Col md={9}>
                            <input
                              type="text"
                              className="form-control mg-l-5"
                              maxLength="20"
                              value={this.state.filterValue}
                              onChange={this.onChangefilterValue}
                            />
                          </Col>
                          <Col md={1}>
                            <i
                              className="far fa-window-close btn btn-primary pd-b-5"
                              title="Clear Filter"
                              onClick={this.clearSearchField}
                            ></i>
                          </Col>
                        </Row>
                      )}
                    </Col>
                    <Col md={3} style={{ textAlign: "end", marginTop: "10px" }}>
                      {this.state.userRoles.length > 0 && (
                        <Row className="text-nowrap">
                          <div>
                            <input
                              type="checkbox"
                              id="grantAll"
                              value={this.state.grantAll}
                              onChange={() => this.grantAllOrRevokeAll(true)}
                              checked={this.state.grantAll}
                            />
                            <label htmlFor="grantAll" className="mg-l-5">
                              Grant All
                            </label>
                            <input
                              type="checkbox"
                              className="mg-l-20"
                              id="revokeAll"
                              value={this.state.revokeAll}
                              onChange={() => this.grantAllOrRevokeAll(false)}
                              checked={this.state.revokeAll}
                            />
                            <label htmlFor="revokeAll" className="mg-l-5">
                              Revoke All
                            </label>
                          </div>
                          <div className="mg-l-5">
                            <i
                              className="fas fa-exchange-alt fa-rotate-90 pointer"
                              title={
                                this.state.isToShowSortingFields
                                  ? "Hide Sort"
                                  : "Show Sort"
                              }
                              onClick={this.displaySortingFields}
                            ></i>
                            {!this.state.isToShowFilteringField ? (
                              <i
                                className="fas fa-filter pointer mg-l-10"
                                onClick={this.displayFilteringField}
                                title="Show Filter"
                              ></i>
                            ) : (
                              <i
                                className="fas fa-funnel-dollar pointer mg-l-10"
                                onClick={this.displayFilteringField}
                                title="Hide Filter"
                              ></i>
                            )}
                          </div>
                        </Row>
                      )}
                    </Col>
                  </Row>
                  <div
                    style={
                      (this.state.filteredArray.length > 12 &&
                        this.state.filterValue !== "") ||
                      (this.state.userRoles.length > 12 &&
                        this.state.filterValue === "")
                        ? {
                            height: "325px",
                            overflowY: "scroll",
                            borderBottom: "1px solid #cdd4e0",
                          }
                        : {}
                    }
                    ref={this.divScrollRef}
                    className="scrollable-element"
                    onScroll={this.handleScroll}
                  >
                    <BootstrapTable
                      bootstrap4
                      {...props.baseProps}
                      striped
                      hover
                      condensed
                    />
                    <div className="col-md-10">
                      {((this.state.index <= this.state.userRoles.length &&
                        this.state.filterValue === "") ||
                        this.state.index <=
                          this.state.filteredArray.length) && (
                        <p>loading more rows, please scroll...</p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </ToolkitProvider>
            <Row>
              <Col md={11}>
                <Row>
                  <Col md={4}></Col>
                  <Col md={2} className="mg-t-10 mg-lg-t-0">
                    <button
                      id="Save"
                      onClick={this.saveUserRole}
                      tabIndex="3"
                      className="mg-t-10 mg-md-t-0 btn btn-gray-700 btn-block"
                    >
                      Save
                    </button>
                  </Col>
                  <Col md={1}></Col>
                  <Col md={2} className="mg-t-10 mg-lg-t-0">
                    <button
                      id="Reset"
                      onClick={this.reset}
                      tabIndex="4"
                      className="btn btn-gray-700 btn-block"
                    >
                      Reset
                    </button>
                  </Col>
                </Row>
              </Col>
              {this.state.position > 600 && this.state.filterValue === "" && (
                <div className="up-arrow">
                  <i
                    className="fa fa-angle-up pro-arrow"
                    onClick={this.scrollToTop}
                  ></i>
                </div>
              )}
            </Row>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default createUserRole;
