import React, { Component } from "react";
import "../../home.scss";
import helper from "../../helpers/helpers";
import helpers from "../../helpers/helpers";
import loginService from "../../services/login.service";
import accessControlService from "../../services/accessControl.service";
import dashboardService from "../../services/dashboard.service";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import projectService from "../../services/project.service";
import ReactApexChart from "react-apexcharts";
import Moment from "moment";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import "./style.scss";
import UserProfile from "../Profile/UserProfile";
toast.configure();

class DashboardDetails1 extends Component {
  constructor(props) {
    super(props); //reference to the parent constructor

    this.displayDurationDetails = this.displayDurationDetails.bind(this);
    this.displayActiveProjects = this.displayActiveProjects.bind(this);
    this.displayActiveResources = this.displayActiveResources.bind(this);
    this.displayActiveTasks = this.displayActiveTasks.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

    this.state = {
      index: 30,
      firstName: "",
      canAccessCustomerList: false,
      canAccessProjectList: false,
      canAccessViewProject: false,
      NoOfCustomers: null,
      NoOfProjects: null,
      NoOfOnGoingProjects: null,
      NoOfCompletedProjects: null,
      NoOfPendingProjects: null,
      NoOfCompletedProjectsPercentage: null,
      NoOfPendingProjectsPercentage: null,
      NoOfBatches: null,
      NoOfCompletedBatches: null,
      NoOfPendingBatches: null,
      NoOfCompletedBatchesPercentage: null,
      NoOfPendingBatchesPercentage: null,
      NoOfActiveTasks: null,
      loading: false,
      noOfProjects: [],
      status: [],
      spinnerMessage: "",
      activeTab: 1,
      defaultActiveKey: "activeProjects",
      NoOfActiveResources: 0,
      filteredArray: [],
      projects: [],
      openProfile: false,
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  // #region functions for UserProfile Modal
  openProfileModal = () => {
    this.setState({ openProfile: true })

  }
  closeProfileModal = () => {
    this.setState({ openProfile: false })

  }
  // #endregion

  /* Modal Show */
  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  showPopUp() {
    this.setState({ showModal: true });
  }
  /* Modal Show */

  //#region component mount
  componentDidMount() {
    if (!helpers.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }
    this.canUserAccessPage("View Project");
    this.fetchProjects();
    this.fetchUsername();
    this.fetchProjectsCompletionStatus();
  }
  //#endregion

  //#region Fetching selected User details
  fetchUsername() {
    this.setState({
      spinnerMessage: "Please wait while fetching logged in user details...",
      loading: true,
    });

    const user = helpers.getUser();
    //Service Call
    loginService
      .getUsername(user)
      .then((response) => {
        this.setState(
          {
            firstName: response.data.FirstName,
            loading: false,
          },
          () => this.fetchDashboardDetails()
        );
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Fetching Project details
  fetchProjects() {
    this.setState({
      spinnerMessage: "Please wait while loading Project List...",
      loading: true,
    });

    var toDate = new Date();

    toDate = Moment(toDate.setDate(toDate.getDate())).format("DD-MMM-yyyy");

    var data = {
      CustomerCode: "(All)",
      ProjectCode: "(All)",
      ProjectType: "(All)",
      FromDate: "01-Apr-2011",
      ToDate: toDate,
      UserID: helper.getUser(),
    };

    projectService
      .readOnGoingProjectsList(data)
      .then((response) => {
        let formattedArray = response.data;

        formattedArray = formattedArray.map((obj) => {
          delete obj.UserID;
          return obj;
        });

        this.setState({ projects: formattedArray, loading: false });
      })
      .catch((e) => {
        this.setState({ loading: false });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region fetching dashboard details from Web API
  fetchDashboardDetails() {
    this.setState({
      spinnerMessage: "Please wait while fetching dashboard details...",
      loading: true,
    });

    dashboardService
      .ReadDashboardDetails()
      .then((response) => {
        this.setState({
          NoOfCustomers: response.data.NoOfCustomers,
          NoOfProjects: response.data.NoOfProjects,
          NoOfOnGoingProjects: response.data.NoOfOnGoingProjects,
          NoOfCompletedProjects: response.data.NoOfCompletedProjects,
          NoOfPendingProjects: response.data.NoOfPendingProjects,
          NoOfCompletedProjectsPercentage:
            response.data.NoOfCompletedProjectsPercentage,
          NoOfPendingProjectsPercentage:
            response.data.NoOfPendingProjectsPercentage,
          NoOfBatches: response.data.NoOfBatches,
          NoOfCompletedBatches: response.data.NoOfCompletedBatches,
          NoOfPendingBatches: response.data.NoOfPendingBatches,
          NoOfCompletedBatchesPercentage:
            response.data.NoOfCompletedBatchesPercentage,
          NoOfPendingBatchesPercentage:
            response.data.NoOfPendingBatchesPercentage,
          NoOfActiveTasks: response.data.NoOfActiveTasks,
          NoOfActiveResources: response.data.NoOfActiveResources,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region fetch Projects Completion Status
  fetchProjectsCompletionStatus() {
    dashboardService
      .ReadProjectsCompletionStatus()
      .then((response) => {
        let status = response.data.map((a) => a.Status);
        let noOfProjects = response.data.map((a) => a.NoOfProjects);
        this.setState({
          status: status,
          noOfProjects: noOfProjects,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Display Duration Details
  displayDurationDetails() {
    this.props.history.push("/Dashboard/Duration");
  }
  //#endregion

  //#region Display Active Resources
  displayActiveResources() {
    this.props.history.push("/Dashboard/ActiveResources");
  }
  //#endregion

  //#region Display Active Tasks
  displayActiveTasks() {
    this.props.history.push("/Dashboard/ActiveTasks");
  }
  //#endregion

  //#region Display Active Projects
  displayActiveProjects() {
    this.props.history.push("/Projects");
  }
  //#endregion

  //#region Handle Scroll
  handleScroll(e) {
    var currentHeight = e.currentTarget.scrollTop;
    var maxScrollPosition =
      e.currentTarget.scrollHeight - e.currentTarget.clientHeight;

    this.setState({ position: currentHeight });

    if ((currentHeight / maxScrollPosition) * 100 > 90) {
      let curIndex = this.state.index + 20;
      this.setState({ index: curIndex });
    }
  }
  //#endregion

  //#region fetching Project page access
  canUserAccessPage(pageName) {
    accessControlService
      .CanUserAccessPage(helper.getUser(), pageName)
      .then((response) => {
        if (pageName === "View Project") {
          this.setState({
            canAccessViewProject: response.data,
          });
        }
      })
      .catch((e) => {
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  render() {
    const firstName = this.state.firstName;
    const projectsData = this.state.projects.slice(0, this.state.index);
    const canAccessCustomerList = this.state.canAccessCustomerList;
    const canAccessProjectList = this.state.canAccessProjectList;
    const canAccessViewProject = this.state.canAccessViewProject;

    const updateData = () => {
      this.setState({ show: false });
    };

    const projectListColumns = [
      {
        dataField: "SlNo",
        text: "Sl No.",
        headerStyle: {
          backgroundColor: "#f2f8fb !important",
        },
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "ProjectID",
        text: "Project ID",
        align: "center",
        hidden: true,
      },
      {
        dataField: "CustomerCode",
        text: "Cus Code",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "ProjectCode",
        text: "Proj Code",
        align: "center",
        classes: canAccessViewProject ? "demo-key-row1" : "",
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            canAccessViewProject &&
              this.props.history.push({
                pathname: "/Projects/ViewProject",
                state: {
                  ProjectID: row.ProjectID,
                  activeTab: 1,
                }, // passing Project ID to View Project Page
              });
          },
        },
      },
      {
        dataField: "InputCount",
        text: "Input Count",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "ProductionAllocatedCount",
        text: "Prod. Allocated",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "ProductionCompletedCount",
        text: "Prod. Completed",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "QCAllocatedCount",
        text: "QC Allocated",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "QCCompletedCount",
        text: "QC Completed",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "Status",
        text: "Status",
        headerStyle: {
          backgroundColor: "#f2f8fb",
        },
        headerAlign: "center",
        align: "center",
        style: {
          minWidth: "100px",
        },
      },
    ];

    // New Radial Bar Chart
    const RadialBarCharts = {
      series: this.state.noOfProjects,
      options: {
        chart: { height: 100, type: "radialBar" },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: "40%",
              background: "transparent",
              image: undefined,
            },
            dataLabels: {
              name: { show: false },
              value: { show: false },
            },
          },
        },
        colors: ["#1ab7ea", "#0084ff", "#39539E"],
        labels: this.state.status,
        legend: {
          show: true,
          floating: true,
          fontSize: "13px",
          position: "left",
          offsetX: 80,
          offsetY: 15,
          labels: { useSeriesColors: true },
          markers: { size: 0 },
          formatter: function (seriesName, opts) {
            return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
          },
          itemMargin: { vertical: 3 },
        },
        responsive: [{ breakpoint: 480, options: { legend: { show: false } } }],
      },
    };
    // New Radial Bar Chart

    return (
      <div className="main-data" style={{
        height: "100%", paddingBottom: "20px", overflow: "auto"
      }}>

        {/* User Profile Modal */}
        {this.state.openProfile && <UserProfile
          openProfile={this.state.openProfile}
          closeProfileModal={this.closeProfileModal}
        />}
        {/* User Profile Modal */}
        <LoadingOverlay
          active={this.state.loading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
          <div className="az-content az-content-dashboard" style={{
              height: "100%",
            }}>
            <div className="container" style={{
              height: "100%",
            }}>
              <div className="az-content-body" style={{
              height: "100%",
            }}>
                <Row className="row-sm" style={{
              minHeight: "4%",
            }}>
                  <Col lg={8}>
                    <h2 className="az-dashboard-title">
                      Hi <span className="user-name"
                      onClick={this.openProfileModal}
                      >{firstName}</span>, welcome back!
                    </h2>
                  </Col>
                  <Col lg={4}>
                  </Col>
                </Row>
                <div className="card-header" style={{
              minHeight: "5%", padding: "4px 0"
            }}>
                          <h6 className="card-title m-0">Projects Status</h6>
                      </div>
                <div className="row row-sm mg-b-20 card-row-section" >
                  <div className="col-lg-7 ht-lg-100p">
                    <div className="card card-dashboard-one" style={{height: "100%"}}>  
                      <div className="card-body project-cards p-0">
                        <div className="card-body-top">
                          <div className="card-div">
                            <label className="mg-b-0">Projects</label>
                            <h2 className="text-center">
                              {this.state.NoOfProjects}
                            </h2>
                          </div>
                          <div className="card-div">
                            <label className="mg-b-0">Completed</label>
                            <h2 className="text-center">
                              {this.state.NoOfCompletedProjectsPercentage !==
                                null && (
                                <>
                                  {this.state.NoOfCompletedProjectsPercentage}%
                                </>
                              )}
                            </h2>
                          </div>
                          <div className="card-div">
                            <label className="mg-b-0">Pending</label>
                            <h2 className="text-center">
                              {this.state.NoOfPendingProjectsPercentage !==
                                null && (
                                <>{this.state.NoOfPendingProjectsPercentage}%</>
                              )}
                            </h2>
                          </div>
                        </div>
                        <div className="card-body-top">
                          <div className="card-div">
                            <label className="mg-b-0">Batches</label>
                            <h2 className="text-center">
                              {this.state.NoOfBatches}
                            </h2>
                          </div>
                          <div className="card-div">
                            <label className="mg-b-0">Completed</label>
                            <h2 className="text-center">
                              {this.state.NoOfCompletedBatchesPercentage !==
                                null && (
                                <>
                                  {this.state.NoOfCompletedBatchesPercentage}%
                                </>
                              )}
                            </h2>
                          </div>
                          <div className="card-div">
                            <label className="mg-b-0">Pending</label>
                            <h2 className="text-center">
                              {this.state.NoOfPendingBatchesPercentage !==
                                null && (
                                <>{this.state.NoOfPendingBatchesPercentage}%</>
                              )}
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 mg-t-20 mg-lg-t-0">
                    <div className="row row-sm p-0" style={{height: "100%"}}>
                      <div className="col-sm-6 project-data">
                        <Link to="/Projects" className="card-link-div">
                          <div
                            className="card card-dashboard-two pointer"
                            style={{ height: "100%" }}
                          >
                            <div className="card-header">
                              <label >
                                Projects{" "}
                                {canAccessProjectList && (
                                  <i
                                    className="fas fa-list"
                                    style={{ color: "blue" }}
                                  ></i>
                                )}
                              </label>
                              <h5 className="data-count">
                                
                                  {this.state.NoOfOnGoingProjects !== null && (
                                    <>
                                      {this.state.NoOfOnGoingProjects}/{this.state.NoOfProjects}
                                    </>
                                  )}
                              </h5>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-sm-6 mg-t-20 mg-sm-t-0 costomer-data">
                        <Link to="/Masters/Customers" className="card-link-div">
                          <div
                            className="card card-dashboard-two pointer"
                            style={{ height: "100%" }}
                          >
                            <div className="card-header">
                              <label >
                                Customers{" "}
                                {canAccessCustomerList && (
                                  <i
                                    className="fas fa-list"
                                    style={{ color: "blue" }}
                                  ></i>
                                )}
                              </label>
                              <h5 className="data-count">
                                  {this.state.NoOfCustomers}
                              </h5>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row row-sm mg-b-20 main-table-div">
                  <div className="col-lg-7" style={{ height: "100%" }}>
                    <div
                      className="card card-dashboard-one table-header-card"
                    >
                      <div className="project-card-body" style={{ height: "100%" }}>
                            <span
                              className="demo-key-row1"
                              onClick={this.displayActiveProjects}
                            >
                              <b>
                                Active Projects (
                                {this.state.NoOfOnGoingProjects})
                              </b>
                            </span>
                            <span
                              className="demo-key-row1"
                              onClick={this.displayActiveTasks}
                            >
                              <b>Active Tasks ({this.state.NoOfActiveTasks})</b>
                            </span>
                            <span
                              className="demo-key-row1"
                              onClick={this.displayActiveResources}
                            >
                              <b>
                                Active Resources (
                                {this.state.NoOfActiveResources})
                              </b>
                            </span>
                            <span
                              className="demo-key-row1"
                              onClick={this.displayDurationDetails}
                            >
                              <b>Duration</b>
                            </span>
                      </div>
                    </div>
                    <div className="dashboard-table-section">
                    <div
                      className="card card-dashboard-one"
                      style={{ height: "100%" }}
                    >
                      <ToolkitProvider
                        keyField="ProjectID"
                        data={projectsData}
                        columns={projectListColumns}
                      >
                        {(props) => (
                          <div
                            style={
                              this.state.projects.length > 12
                                ? {
                                    overflowY: "scroll",
                                    borderBottom: "1px solid #cdd4e0",
                                  }
                                : {}
                            }
                            ref={this.divScrollRef}
                            className="table-display-data"
                            onScroll={this.handleScroll}
                          >
                            <BootstrapTable
                              bootstrap4
                              {...props.baseProps}
                              striped
                              hover
                              condensed
                              className="dashboard-table"
                            />
                            <div className="col-md-10">
                              {((this.state.index <=
                                this.state.projects.length &&
                                this.state.filterValue === "") ||
                                this.state.index <=
                                  this.state.filteredArray.length) && (
                                <p>loading more rows, please scroll...</p>
                              )}
                            </div>
                          </div>
                        )}
                      </ToolkitProvider>
                    </div>
                    </div>
                  </div>

                  <div className="col-lg-5 mg-t-20 mg-lg-t-0" style={{ height: "100%" }}>
                    <div
                      className="card card-dashboard-two"
                      style={{
                        padding: "2px",
                      }}
                    >
                      <h1
                        style={{
                          textAlign: "center",
                          padding: "1%",
                          fontSize: "13px",
                          fontWeight: "600",
                          color: "#666666",
                        }}
                      >
                        Projects Status
                      </h1>
                      <ReactApexChart
                        options={RadialBarCharts.options}
                        series={RadialBarCharts.series}
                        type="radialBar"
                        className="custom-radial"
                        height={"100%"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LoadingOverlay>
        <Modal
          show={this.state.show}
          onHide={this.hideModal}
          className="edit-data-modal"
        >
          <div className="update-data">
            <Row>
              <Col lg={11}>
                <h1 style={{ fontSize: "20px" }}>Edit Data</h1>
              </Col>
              <Col lg={1} align="right">
                <i
                  className="far fa-window-close close-btn"
                  onClick={this.hideModal}
                ></i>
              </Col>
            </Row>
            <Form>
              <Row>
                <Col lg={4}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.CustomerCode"
                  >
                    <Form.Label> Customer Code </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Customer Code"
                      value="AMD"
                      readOnly
                      name="customercode"
                      onChange={updateData}
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3" controlId="exampleForm.JobNo">
                    <Form.Label> Job No </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Job No"
                      value="010"
                      readOnly
                      name="jobno"
                      onChange={updateData}
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.Activities"
                  >
                    <Form.Label> Activities </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Activities"
                      value="Classification | Item Mapping | Data Extraction"
                      readOnly
                      name="activities"
                      onChange={updateData}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <Form.Group className="mb-3" controlId="exampleForm.RefSlNo">
                    <Form.Label> RefSlNo </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter RefSlNo"
                      value=""
                      readOnly
                      name="refslno"
                      onChange={updateData}
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.INV_MAST_SERVICE_ID"
                  >
                    <Form.Label> INV_MAST_SERVICE_ID </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter INV_MAST_SERVICE_ID"
                      value="18369"
                      readOnly
                      name="invmast"
                      onChange={updateData}
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3" controlId="exampleForm.NAME">
                    <Form.Label> NAME (DESCRIPTION) </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter NAME (DESCRIPTION)"
                      value="ASIAMODIN-20 mg -Tablets-B30-0415-02273-01"
                      readOnly
                      name="namedesc"
                      onChange={updateData}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <Form.Group className="mb-3" controlId="exampleForm.">
                    <Form.Label> MRO/HC/LAB/PHARMA </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter MRO/HC/LAB/PHARMA"
                      value="PHARMA"
                      readOnly
                      name="mhlp"
                      onChange={updateData}
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}></Col>
                <Col lg={4}></Col>
              </Row>
              <div className="border-data"></div>
              <Row>
                <Col lg={4}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ProductionUser"
                  >
                    <Form.Label> Production User </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Production User"
                      value="AAE"
                      name="productionname"
                      onChange={updateData}
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ProdComments"
                  >
                    <Form.Label> Prod Comments </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Prod Comments"
                      value="Completed"
                      name="prodcomments"
                      onChange={updateData}
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3" controlId="exampleForm.SortCode">
                    <Form.Label> Sort Code </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Sort Code"
                      value="ADG"
                      name="sortcode"
                      onChange={updateData}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.SortComment"
                  >
                    <Form.Label> Sort Comment </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Sort Comment"
                      value="OK"
                      name="sortcomment"
                      onChange={updateData}
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3" controlId="exampleForm.Team">
                    <Form.Label> Team </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Team"
                      value="ADG"
                      name="team"
                      onChange={updateData}
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.StartDate"
                  >
                    <Form.Label> Start Date </Form.Label>
                    <Form.Control
                      type="date"
                      value="2022-12-09"
                      name="startdate"
                      onChange={updateData}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <Form.Group className="mb-3" controlId="exampleForm.EndDate">
                    <Form.Label> End Date </Form.Label>
                    <Form.Control
                      type="date"
                      value="2023-02-19"
                      name="enddate"
                      onChange={updateData}
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3" controlId="exampleForm.Source">
                    <Form.Label> Source </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Source"
                      value="YASASII_INVENTORY MASTERS"
                      name="source"
                      onChange={updateData}
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3" controlId="exampleForm.SlNo">
                    <Form.Label> SlNo </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter SlNo"
                      value=""
                      name="slno"
                      onChange={updateData}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.SUBCATEGORY"
                  >
                    <Form.Label>
                      {" "}
                      SUBCATEGORY (Group) (ATTRIBUTE_CATEGORY){" "}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter SUBCATEGORY (Group) (ATTRIBUTE_CATEGORY)"
                      value=""
                      name="subcategory"
                      onChange={updateData}
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3" controlId="exampleForm.CPT_CODE">
                    <Form.Label> CPT_CODE </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter CPT_CODE"
                      value="B30-0415-02273-01"
                      name="cptcode"
                      onChange={updateData}
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.UNIT_NAME"
                  >
                    <Form.Label> UNIT_NAME </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter UNIT_NAME"
                      value="Tablet"
                      name="unitname"
                      onChange={updateData}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <Form.Group className="mb-3" controlId="exampleForm.QUANTITY">
                    <Form.Label> QUANTITY (PACKING) </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter QUANTITY (PACKING)"
                      value="1"
                      name="qty"
                      onChange={updateData}
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.QUANTITY_UNIT"
                  >
                    <Form.Label> QUANTITY_UNIT </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter QUANTITY_UNIT"
                      value="Tablet"
                      name="qtyunit"
                      onChange={updateData}
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3" controlId="exampleForm.OP_UNIT">
                    <Form.Label> OP_UNIT </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter OP_UNIT"
                      value="Tablet"
                      name="opunit"
                      onChange={updateData}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <Form.Group className="mb-3" controlId="exampleForm.IP_UNIT">
                    <Form.Label> IP_UNIT </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter IP_UNIT"
                      value="Tablet"
                      name="ipunit"
                      onChange={updateData}
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.PRESCRIBING_UNIT"
                  >
                    <Form.Label> PRESCRIBING_UNIT </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter PRESCRIBING_UNIT"
                      value="Tablet"
                      name="prescribingunit"
                      onChange={updateData}
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3" controlId="exampleForm.AGENCY">
                    <Form.Label> AGENCY </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter AGENCY"
                      value=""
                      name="agency"
                      onChange={updateData}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.MANUFACTURER_NAME"
                  >
                    <Form.Label> MANUFACTURER_NAME </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter MANUFACTURER_NAME"
                      value="Asia Pharmaceutical Industries, SYRIA"
                      name="manfacturername"
                      onChange={updateData}
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ALTERNATE_NAME"
                  >
                    <Form.Label> ALTERNATE_NAME </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter ALTERNATE_NAME"
                      value="ASIAMODIN-20 mg -Tablets-B30-0415-02273-01"
                      name="alternatename"
                      onChange={updateData}
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3" controlId="exampleForm.BRAND">
                    <Form.Label> BRAND </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter BRAND"
                      value=""
                      name="brand"
                      onChange={updateData}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ATTRIBUTE1"
                  >
                    <Form.Label> ATTRIBUTE1 </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter ATTRIBUTE1"
                      value=""
                      name="attribute1"
                      onChange={updateData}
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ATTRIBUTE2"
                  >
                    <Form.Label> ATTRIBUTE2 </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter ATTRIBUTE2"
                      value=""
                      name="attribute2"
                      onChange={updateData}
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}></Col>
              </Row>
              <Row>
                <Col lg={6} align="right">
                  <Button
                    variant="primary"
                    className="pro-act-btn upd-btn"
                    onClick={updateData}
                  >
                    {" "}
                    Update{" "}
                  </Button>
                </Col>
                <Col lg={6} align="left">
                  <Button
                    variant="primary"
                    className="pro-act-btn can-btn"
                    onClick={this.hideModal}
                  >
                    {" "}
                    Cancel{" "}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal>
      </div>
    );
  }
}

export default DashboardDetails1;
