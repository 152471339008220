import React, { Component } from "react";
import helpers from "../../helpers/helpers";
import tableFunctions from "../../helpers/tableFunctions";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import dashboardService from "../../services/dashboard.service";
import BarLoader from "react-spinners/BarLoader";
import ModernDatepicker from "react-modern-datepicker";
import LoadingOverlay from "react-loading-overlay";
import Moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class ActiveResources extends Component {
  constructor(props) {
    super(props); //reference to the parent constructor

    this.divScrollRef = React.createRef();

    this.displaySortingFields = this.displaySortingFields.bind(this);
    this.displayFilteringField = this.displayFilteringField.bind(this);
    this.onChangefilterValue = this.onChangefilterValue.bind(this);
    this.onChangeColumn = this.onChangeColumn.bind(this);
    this.onChangeSortOrder = this.onChangeSortOrder.bind(this);
    this.sortData = this.sortData.bind(this);
    this.clearSort = this.clearSort.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.exportActiveResourcesToExcel =
      this.exportActiveResourcesToExcel.bind(this);
    this.clearDates = this.clearDates.bind(this);

    this.state = {
      resources: [],
      spinnerMessage: "",
      loading: false,
      fromDate: null,
      toDate: null,
      formErrors: {},
      resourceProductivityDetails: [],
      resourceExpanded: [],
      index: 30,
      position: 0,
      columns: [],
      isToShowSortingFields: false,
      isToShowFilteringField: false,
      selectedColumn: "",
      selectedSort: "",
      filteredArray: [],
      filterValue: "",
    };
  }

  //#region Component Mount
  componentDidMount() {
    if (!helpers.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    this.fetchActiveResources();
  }
  //#endregion

  //#region Validating the From Date and To Date
  handleDateValidation() {
    var fromDate = this.state.fromDate;
    var toDate = this.state.toDate;

    let formErrors = {};
    let isValidForm = true;

    if (fromDate || toDate) {
      //From Date
      if (!fromDate) {
        isValidForm = false;
        formErrors["fromDateError"] = "From Date is required.";
      }

      //To Date
      if (!toDate) {
        isValidForm = false;
        formErrors["toDateError"] = "To Date is required.";
      }

      if (fromDate) {
        if (toDate) {
          if (new Date(fromDate) > new Date(toDate)) {
            isValidForm = false;
            formErrors["fromDateError"] =
              "From Date can't be later than To Date";
          }
        }
      }
    }

    this.setState({ formErrors: formErrors });
    return isValidForm;
  }
  //#endregion

  //#region Fetch Active Resources
  async fetchActiveResources() {
    if (this.handleDateValidation()) {
      this.setState({
        spinnerMessage: "Please wait while fetching Active Resources...",
        loading: true,
      });

      await dashboardService
        .ReadResources(this.state.fromDate, this.state.toDate)
        .then((response) => {
          if (response.data.length === 0) {
            this.setState({
              displayFilter: false,
              resources: [],
              loading: false,
            });
            toast.error("No Data Found!!");
          } else {
            let formattedArray = response.data.map((obj) => ({
              ...obj,
              StartDate: Moment(obj.StartDate).format("DD-MMM-yyyy"),
              EndDate: Moment(obj.EndDate).format("DD-MMM-yyyy"),
            }));

            this.setState({
              resources: formattedArray,
              loading: false,
              displayFilter: true,
            });
          }
        })
        .catch((e) => {
          this.setState({
            loading: false,
          });
          toast.error(e.response.data.Message, { autoClose: false });
        });
    }
  }
  //#endregion

  //#region  Get Selected From Date
  onChangeFromDate(date) {
    this.setState({
      fromDate: date,
      resources: [],
      isToShowSortingFields: false,
      isToShowFilteringField: false,
    });

    if (date !== "" && date !== null) {
      const formErrors = { ...this.state.formErrors, fromDateError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region  Get Selected To Date
  onChangeToDate(date) {
    this.setState({
      toDate: date,
      resources: [],
      isToShowSortingFields: false,
      isToShowFilteringField: false,
    });

    if (date !== "" && date !== null) {
      const formErrors = { ...this.state.formErrors, toDateError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Export Active Resources to Excel
  exportActiveResourcesToExcel() {
    this.setState({
      spinnerMessage:
        "Please wait while exporting active resources to excel...",
      loading: true,
    });

    let fileName = "Active Resources.xlsx";

    dashboardService
      .exportActiveResourcesToExcel(this.state.fromDate, this.state.toDate)
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);
        fileLink.click();

        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Fetch Resource Productivity Details
  fetchResourceProductivityDetails = (row, isExpand, rowIndex, e) => {
    if (!isExpand) {
      this.setState(() => ({
        resourceExpanded: [],
        loading: false,
      }));
      return;
    }

    this.setState({
      spinnerMessage:
        "Please wait while fetching Resource Productivity Details...",
      loading: true,
    });

    dashboardService
      .ReadResourceProductivityDetails(
        row.ResourceCode,
        this.state.fromDate,
        this.state.toDate
      )
      .then((response) => {
        this.setState({
          resourceProductivityDetails: response.data,
          resourceExpanded: [row.SlNo],
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  };
  //#endregion

  //#region Clear Date Fields
  clearDates() {
    this.setState(
      {
        fromDate: null,
        toDate: null,
        isToShowSortingFields: false,
        isToShowFilteringField: false,
        resources: [],
      },
      () => this.fetchActiveResources()
    );
  }
  //#endregion

  //#region Filter Functions
  //#region Display Filtering Field
  displayFilteringField() {
    this.setState((previousState) => ({
      isToShowFilteringField: !previousState.isToShowFilteringField,
      filterValue: "",
      isToShowSortingFields: false,
    }));
  }
  //#endregion

  //#region on change filter value
  onChangefilterValue(e) {
    this.setState({ filterValue: e.target.value }, () =>
      this.getFilteredRows()
    );
  }
  //#endregion

  //#region get filtered rows
  getFilteredRows() {
    const filteredArray = tableFunctions.filterArray(
      this.state.resources,
      this.state.filterValue
    );

    this.setState({ filteredArray: filteredArray });
  }
  //#endregion

  //#region Clear Search
  clearSearch() {
    this.setState({
      filterValue: "",
    });
  }
  //#endregion
  //#endregion

  //#region  Sort Functions
  //#region Display Sorting Fields
  displaySortingFields() {
    let columns = Object.keys(this.state.resources[0]);

    this.setState((previousState) => ({
      isToShowSortingFields: !previousState.isToShowSortingFields,
      selectedColumn: "",
      selectedSort: "",
      columns: columns,
      filterValue: "",
      isToShowFilteringField: false,
    }));
  }
  //#endregion

  //#region Selecting the sort column
  onChangeColumn(e) {
    this.setState({
      selectedColumn: e.target.value,
      selectedSort: "",
    });
  }
  //#endregion

  //#region On Change Sort
  onChangeSortOrder(e) {
    this.setState(
      {
        selectedSort: e.target.value,
      },
      () => this.sortData()
    );
  }
  //#endregion

  //#region Sort Data based on column and order
  sortData() {
    let sortedArray = [];
    let column =
      this.state.selectedColumn !== "" ? this.state.selectedColumn : "SlNo";
    const selectedSort =
      this.state.selectedSort !== "" ? this.state.selectedSort : "ascending";
    let numberColumns = [
      "SlNo",
      "ProductionCompletedCount",
      "QCCompletedCount",
      "HoursWorked",
    ];

    let dateColumns = ["StartDate", "EndDate"];

    sortedArray = tableFunctions.sortData(
      this.state.resources,
      column,
      selectedSort,
      numberColumns,
      dateColumns
    );

    this.setState({ resources: sortedArray });
  }
  //#endregion

  //#region  Clear Sort
  clearSort() {
    this.setState(
      {
        selectedColumn: "",
        selectedSort: "",
      },
      () => this.sortData()
    );
  }
  //#endregion
  //#endregion

  //#region Handle Scroll
  handleScroll(e) {
    var currentHeight = e.currentTarget.scrollTop;
    var maxScrollPosition =
      e.currentTarget.scrollHeight - e.currentTarget.clientHeight;

    this.setState({ position: currentHeight });

    if ((currentHeight / maxScrollPosition) * 100 > 90) {
      let curIndex = this.state.index + 20;
      this.setState({ index: curIndex });
    }
  }
  //#endregion

  //#region Scroll to Top
  scrollToTop = () => {
    this.divScrollRef.current.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  //#endregion

  render() {
    const data = this.state.resources.slice(0, this.state.index);
    const filteredData = this.state.filteredArray.slice(0, this.state.index);

    const activeResourcesColumns = [
      {
        dataField: "SlNo",
        text: "Sl No",
        align: "center",
        style: {
          width: "6%",
        },
      },
      {
        dataField: "ResourceCode",
        text: "ResourceCode",
        align: "center",
        style: {
          width: "8%",
        },
      },
      {
        dataField: "ResourceName",
        text: "ResourceName",
        style: {
          width: "15%",
        },
      },
      {
        dataField: "StartDate",
        align: "center",
        text: "StartDate",
        style: {
          width: "8%",
        },
      },
      {
        dataField: "EndDate",
        align: "center",
        text: "EndDate",
        style: {
          width: "8%",
        },
      },
      {
        dataField: "ProductionCompletedCount",
        align: "center",
        text: "ProductionCompletedCount",
        style: {
          width: "15%",
        },
      },
      {
        dataField: "QCCompletedCount",
        align: "center",
        text: "QCCompletedCount",
        style: {
          width: "8%",
        },
      },
      {
        dataField: "HoursWorked",
        align: "center",
        text: "HoursWorked",
        style: {
          width: "10%",
        },
      },
    ];

    const dateColumns = [
      {
        dataField: "DateWorked",
        text: "Date Worked",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          fontWeight: 800,
          width: "110px",
        },
        headerAlign: "center",
        align: "center",
        sort: true,
        formatter: (cell, row, rowIndex, extraData) =>
          `${Moment(row.DateWorked).format("DD-MMM-yyyy")}`,
        filterValue: (cell, row, rowIndex, extraData) =>
          `${Moment(row.DateWorked).format("DD-MMM-yyyy")}`,
      },
      {
        dataField: "CustomerCode",
        text: "Cus Code",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          width: "90px",
          fontWeight: 800,
        },
        headerAlign: "center",
        align: "center",
        sort: true,
      },
      {
        dataField: "ProjectCode",
        text: "Proj Code",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          width: "90px",
          fontWeight: 800,
        },
        headerAlign: "center",
        align: "center",
        sort: true,
      },
      {
        dataField: "Activity",
        text: "Activity",
        style: {
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        },
        title: true,
        headerStyle: {
          backgroundColor: "#f2f8fb",
          width: "150px",
          fontWeight: 800,
        },
        headerAlign: "center",
        sort: true,
      },
      {
        dataField: "ProductionCompletedCount",
        text: "Production Count",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          width: "130px",
          fontWeight: 800,
        },
        headerAlign: "center",
        align: "center",
        sort: true,
      },
      {
        dataField: "ProductionTarget",
        text: "Production Target",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          width: "130px",
          fontWeight: 800,
        },
        headerAlign: "center",
        align: "center",
        sort: true,
      },
      {
        dataField: "QCCompletedCount",
        text: "QC Count",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          width: "100px",
          fontWeight: 800,
        },
        headerAlign: "center",
        align: "center",
        sort: true,
      },
      {
        dataField: "QCTarget",
        text: "QC Target",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          width: "100px",
          fontWeight: 800,
        },
        headerAlign: "center",
        align: "center",
        sort: true,
      },
      {
        dataField: "HoursWorked",
        text: "Hours Worked",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          width: "110px",
          fontWeight: 800,
        },
        headerAlign: "center",
        align: "center",
        sort: true,
      },
    ];

    const expandActiveResourceRow = {
      onlyOneExpanding: true,
      parentClassName: "rowBackgroundColor",
      showExpandColumn: true,
      onExpand: this.fetchResourceProductivityDetails,
      expanded: this.state.resourceExpanded,
      expandHeaderColumnRenderer: (isAnyExpands) => (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        ></div>
      ),
      renderer: (row) => (
        <>
          <div className="table-responsive">
            <BootstrapTable
              keyField="SlNo"
              data={this.state.resourceProductivityDetails}
              columns={dateColumns}
            />
          </div>
        </>
      ),
    };

    return (
      <>
        <LoadingOverlay
          active={this.state.loading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helpers.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
          <div className="az-content-body pd-lg-l-40 d-flex flex-column mg-r-20 mg-t-20">
            <ToolkitProvider
              keyField="SlNo"
              data={this.state.filterValue === "" ? data : filteredData}
              columns={activeResourcesColumns}
            >
              {(props) => (
                <div>
                  <div style={{ textAlign: "end", marginBottom: "5px" }}>
                    <div className="row">
                      <div className="col-md-3" style={{ textAlign: "left" }}>
                        <div className="row">
                          <h5 style={{ marginLeft: "15px", marginTop: "7px" }}>
                            <b>Active Resources</b>
                          </h5>
                          <span className="icon-size mg-l-5">
                            <Link
                              to={{
                                pathname: "/Dashboard",
                              }}
                            >
                              <i
                                className="far fa-arrow-alt-circle-left"
                                title="Back to Dashboard"
                              ></i>
                            </Link>
                          </span>
                        </div>
                      </div>
                      <div className="row col-md-9">
                        <div className="col-md-4">
                          <div className="row row-sm">
                            <div className="col-md-4 mg-t-10">
                              <b>From Date</b>:
                            </div>
                            <div className="col-md-7">
                              <div className="form-control">
                                <ModernDatepicker
                                  date={this.state.fromDate}
                                  format={"DD-MMM-YYYY"}
                                  onChange={(date) =>
                                    this.onChangeFromDate(date)
                                  }
                                  placeholder={"Select a date"}
                                  className="color"
                                  minDate={new Date(1900, 1, 1)}
                                />
                              </div>
                              <div className="error-message">
                                {this.state.formErrors["fromDateError"]}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 mg-t-10 mg-lg-t-0">
                          <div className="row row-sm">
                            <div className="col-md-3 mg-t-10">
                              <b>To Date</b>:
                            </div>
                            <div className="col-md-7">
                              <div className="form-control">
                                <ModernDatepicker
                                  date={this.state.toDate}
                                  format={"DD-MMM-YYYY"}
                                  onChange={(date) => this.onChangeToDate(date)}
                                  placeholder={"Select a date"}
                                  className="color"
                                  minDate={new Date(1900, 1, 1)}
                                />
                              </div>
                              <div className="error-message">
                                {this.state.formErrors["toDateError"]}
                              </div>
                            </div>
                            <div className="col-md-2">
                              <span
                                className="btn btn-primary pd-b-5"
                                onClick={this.clearDates}
                                title="Clear Date Fields"
                              >
                                <i className="far fa-window-close"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 mg-t-10 mg-lg-t-0">
                          <button
                            onClick={() =>
                              this.fetchActiveResources(
                                this.state.fromDate,
                                this.state.toDate
                              )
                            }
                            className="btn btn-gray-700 btn-block mg-l-50"
                          >
                            View Report
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mg-b-10" style={{ marginRight: "15px" }}>
                    <div className="col-md-10" style={{ whiteSpace: "nowrap" }}>
                      <div className="row">
                        {this.state.isToShowSortingFields && (
                          <>
                            <div className="col-md-4">
                              <div className="row">
                                <div className="col-md-3 mg-t-7">
                                  <label htmlFor="sortColumn">Column:</label>
                                </div>
                                <div className="col-lg">
                                  <select
                                    className="form-control mg-l-5"
                                    value={this.state.selectedColumn}
                                    onChange={this.onChangeColumn}
                                  >
                                    <option value="">--Select--</option>
                                    {this.state.columns.map((col) => (
                                      <option key={col}>{col}</option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="row">
                                <div className="col-md-3 mg-t-7">
                                  <label htmlFor="sortOrder">Order:</label>
                                </div>
                                <div className="col-lg">
                                  <select
                                    className="form-control mg-l-5"
                                    value={this.state.selectedSort}
                                    onChange={this.onChangeSortOrder}
                                  >
                                    <option value="">--Select--</option>
                                    <option value="ascending">Ascending</option>
                                    <option value="descending">
                                      Descending
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div>
                                <span
                                  className="btn btn-primary pd-b-5"
                                  onClick={this.clearSort}
                                  title="Clear Sort Fields"
                                >
                                  <i className="far fa-window-close"></i>
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                        {this.state.isToShowFilteringField && (
                          <>
                            <div className="col-md-12">
                              <div
                                className="row"
                                style={{ flexWrap: "nowrap" }}
                              >
                                <div className="col-md-1 mg-t-7">
                                  <label htmlFor="search">Search:</label>
                                </div>
                                <div className="col-lg pd-r-10">
                                  <input
                                    type="text"
                                    className="form-control mg-l-10"
                                    maxLength="20"
                                    value={this.state.filterValue}
                                    onChange={this.onChangefilterValue}
                                  />
                                </div>
                                <div>
                                  <span
                                    className="btn btn-primary pd-b-5"
                                    onClick={this.clearSearch}
                                  >
                                    <i
                                      className="far fa-window-close"
                                      title="Clear Filter"
                                    ></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    {this.state.resources.length > 0 && (
                      <div
                        className="col-md-2"
                        style={{ textAlign: "end", marginTop: "10px" }}
                      >
                        <i
                          className="fas fa-exchange-alt fa-rotate-90 pointer"
                          title={
                            this.state.isToShowSortingFields
                              ? "Hide Sort"
                              : "Show Sort"
                          }
                          onClick={this.displaySortingFields}
                        ></i>
                        {!this.state.isToShowFilteringField ? (
                          <i
                            className="fas fa-filter pointer mg-l-10"
                            onClick={this.displayFilteringField}
                            title="Show Filter"
                          ></i>
                        ) : (
                          <i
                            className="fas fa-funnel-dollar pointer mg-l-10"
                            onClick={this.displayFilteringField}
                            title="Hide Filter"
                          ></i>
                        )}
                        <i
                          className="fas fa-file-excel mg-l-10 pointer"
                          style={{ color: "green" }}
                          onClick={this.exportActiveResourcesToExcel}
                          title="Export to Excel"
                        ></i>
                      </div>
                    )}
                  </div>
                  <div>
                    <table
                      style={{
                        width:
                          (this.state.filteredArray.length < 12 &&
                            this.state.filterValue !== "") ||
                          this.state.resources.length < 12
                            ? "100%"
                            : "99.1%",
                      }}
                    >
                      <thead>
                        <tr>
                          <td
                            className="custom-table-header"
                            style={{
                              width:
                                (this.state.filteredArray.length < 12 &&
                                  this.state.filterValue !== "") ||
                                this.state.resources.length < 12
                                  ? "3.35%"
                                  : "3.35%",
                            }}
                          ></td>
                          <td
                            className="custom-table-header"
                            style={{
                              width:
                                (this.state.filteredArray.length < 12 &&
                                  this.state.filterValue !== "") ||
                                this.state.resources.length < 12
                                  ? "6.7%"
                                  : "6.7%",
                            }}
                          >
                            Sl No
                          </td>
                          <td
                            className="custom-table-header"
                            style={{
                              width:
                                (this.state.filteredArray.length < 12 &&
                                  this.state.filterValue !== "") ||
                                this.state.resources.length < 12
                                  ? "8.95%"
                                  : "8.9%",
                            }}
                          >
                            Resource Code
                          </td>
                          <td
                            className="custom-table-header"
                            style={{
                              width:
                                (this.state.filteredArray.length < 12 &&
                                  this.state.filterValue !== "") ||
                                this.state.resources.length < 12
                                  ? "16.75%"
                                  : "16.8%",
                            }}
                          >
                            Resource Name
                          </td>
                          <td
                            className="custom-table-header"
                            style={{
                              width:
                                (this.state.filteredArray.length < 12 &&
                                  this.state.filterValue !== "") ||
                                this.state.resources.length < 12
                                  ? "8.9%"
                                  : "8.9%",
                            }}
                          >
                            Start Date
                          </td>
                          <td
                            className="custom-table-header"
                            style={{
                              width:
                                (this.state.filteredArray.length < 12 &&
                                  this.state.filterValue !== "") ||
                                this.state.resources.length < 12
                                  ? "8.9%"
                                  : "8.9%",
                            }}
                          >
                            End Date
                          </td>
                          <td
                            className="custom-table-header"
                            style={{
                              width:
                                (this.state.filteredArray.length < 12 &&
                                  this.state.filterValue !== "") ||
                                this.state.resources.length < 12
                                  ? "16.8%"
                                  : "16.9%",
                            }}
                          >
                            Production Completed Count
                          </td>
                          <td
                            className="custom-table-header"
                            style={{
                              width:
                                (this.state.filteredArray.length < 12 &&
                                  this.state.filterValue !== "") ||
                                this.state.resources.length < 12
                                  ? "8.9%"
                                  : "8.9%",
                            }}
                          >
                            QC Count
                          </td>
                          <td
                            className="custom-table-header"
                            style={{
                              width:
                                (this.state.filteredArray.length < 12 &&
                                  this.state.filterValue !== "") ||
                                this.state.resources.length < 12
                                  ? "11.2%"
                                  : "11.2%",
                            }}
                          >
                            Hours Worked
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <div
                    style={
                      (this.state.filteredArray.length > 12 &&
                        this.state.filterValue !== "") ||
                      (this.state.resources.length > 12 &&
                        this.state.filterValue === "")
                        ? {
                            overflowY: "scroll",
                            borderBottom: "1px solid #cdd4e0",
                          }
                        : {}
                    }
                    ref={this.divScrollRef}
                    className="active-resources-table-height"
                    onScroll={this.handleScroll}
                  >
                    <BootstrapTable
                      bootstrap4
                      {...props.baseProps}
                      striped
                      hover
                      condensed
                      headerClasses="header-class"
                      expandRow={expandActiveResourceRow}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
            {this.state.position > 600 && this.state.filterValue === "" && (
              <div style={{ textAlign: "end" }}>
                <button className="scroll-top" onClick={this.scrollToTop}>
                  <div className="arrow up"></div>
                </button>
              </div>
            )}
          </div>
        </LoadingOverlay>
      </>
    );
  }
}

export default ActiveResources;
