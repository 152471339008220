import React, { Component } from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import helper from "../../helpers/helpers";
import SuggestionToManagement from "./suggestiontomanagement";
import ViewSuggestions from "./viewsuggestions";
import "../reports/report.scss";
import "./grievancereach.scss"
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import accessControlService from "../../services/accessControl.service";
toast.configure();

class grievancereach extends Component {
  constructor(props) {
    super(props); 

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: 'suggestiontomanagement', 
      accessControl: [],
      spinnerMessage: "",
      loading: false,
      employeeTaskReportPageAccess: false,
      canAccessSuggestionToManagement: false,
      canAccessViewSuggestions: false,
      // defaultActiveKey: 'suggestiontomanagement'
    };
  }

  toggle = (tab) => {
    this.setState({ activeTab: tab });
  };

  componentDidMount() {
    this.canUserAccessPage();
  }


  // #region Access control
  canUserAccessPage() {
    accessControlService.CanUserAccessPage(helper.getUser(), 'Suggestion To Management')
      .then((response) => {
        const canAccessSuggestionToManagement = response?.data ?? false;
        this.setState({ canAccessSuggestionToManagement });

        if (canAccessSuggestionToManagement) {
          this.setState({ activeTab: 'suggestiontomanagement' });
        }

        return accessControlService.CanUserAccessPage(helper.getUser(), 'View Suggestions');
      })
      .then((response) => {
        const canAccessViewSuggestions = response?.data ?? false;
        this.setState({ canAccessViewSuggestions });

        if (canAccessViewSuggestions) {
          this.setState({ activeTab: 'viewsuggestions' });
        }
      })
      .catch((e) => {
        const errorMessage = e.response?.data?.Message ?? 'An error occurred';
        toast.error(errorMessage, { autoClose: false });
      });
  }
  // #end region

  render() {
    
    const { canAccessSuggestionToManagement, canAccessViewSuggestions, activeTab } = this.state;
    return (
      <div>
         <Tab.Container id="left-tabs-example" activeKey={activeTab} onSelect={this.toggle}>
          <div className="row" style={{ marginRight: "15px", marginTop: '15px' }}>
            <div>
              <Nav variant="pills" className="mg-l-50 mg-b-15 mg-t-10" style={{ cursor: "pointer" }}>
                {canAccessSuggestionToManagement && (
                  <Nav.Item>
                    <Nav.Link eventKey="suggestiontomanagement" style={{ border: "1px solid #5E41FC" }}>
                      Suggestion To Management
                    </Nav.Link>
                  </Nav.Item>
                )}
                {canAccessViewSuggestions && (
                  <Nav.Item>
                    <Nav.Link eventKey="viewsuggestions" style={{ border: "1px solid #5E41FC" }}>
                      View Suggestions
                    </Nav.Link>
                  </Nav.Item>
                )}
              </Nav>
            </div>
            <div className="d-flex justify-content-end"></div>
          </div>

          <Tab.Content>
            {canAccessSuggestionToManagement && (
              <Tab.Pane eventKey="suggestiontomanagement">
                <SuggestionToManagement {...this.props} />
              </Tab.Pane>
            )}
            {canAccessViewSuggestions && (
              <Tab.Pane eventKey="viewsuggestions">
                <ViewSuggestions />
              </Tab.Pane>
            )}
          </Tab.Content>
        </Tab.Container>
       
      </div>
    );
  }
}

export default grievancereach;
