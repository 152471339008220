import React, { Component } from "react";
import helper from "../../helpers/helpers";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import { TextField } from '@mui/material';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import grievanceService from "../../services/grievanceService";
toast.configure();

class suggestiontomanagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      spinnerMessage: "",
      filterValue: '',
      myfilterValue: '',
      isFocused: false,
      myisFocused: false,
      subject: '',
      details: '',
      subjectErr: '',
      detailsErr: ''
    };
  }
  // #region web api Create Suggestion form validation
  handleSubmit = (e) => {
    e.preventDefault();

    const { subject, details } = this.state;
    let isValid = true;
    if (!subject) {
      this.setState({ subjectErr: 'Subject is required' });
      isValid = false;
    } else if (subject.length > 100) {
      this.setState({ subjectErr: 'Subject must be less than 100 characters' });
      isValid = false;
    } else {
      this.setState({ subjectErr: '' });
    }
    if (!details) {
      this.setState({ detailsErr: 'Details are required' });
      isValid = false;
    } else if (details.length > 1000) {
      this.setState({ detailsErr: 'Details must be less than 1000 characters' });
      isValid = false;
    } else {
      this.setState({ detailsErr: '' });
    }
    if (!isValid) return;
    this.setState({ loading: true });
    grievanceService.CreateSuggestion({ subject, details })
      .then((response) => {
        toast.success("Suggestion submitted successfully.");
        this.setState({
          subject: '',
          details: '',
          loading: false
        });
      })
      .catch((error) => {
        toast.error("An error occurred while submitting suggestion.");
        this.setState({ loading: false });
      });
  };
  // #endregion

  // #region form handling
  onChangeSubjectValue = (e) => {
    this.setState({ subject: e.target.value });
  };
  onmyChangeDetailsValue = (e) => {
    this.setState({ details: e.target.value });
  };
  // #endregion

  // #region Focus Handling
  handleFocus = (input) => {
    this.setState({ [input]: true });
  };
  
  handleBlur = (input) => {
    this.setState({ [input]: false });
  };
  // #endregion
  
  
render() {
    const { filterValue, isFocused, subjectErr, detailsErr } = this.state;
    const shouldShowLabel = isFocused || filterValue !== ''; 
    const { myfilterValue, myisFocused } = this.state;
    const myshouldShowLabel = myisFocused || myfilterValue !== '';
    const { subject, details } = this.state;
    return (
      <LoadingOverlay active={this.state.loading} className="custom-loader" spinner={
        <div className="spinner-background">
          <BarLoader
            css={helper.getcss()}
            color={"#38D643"}
            width={"350px"}
            height={"10px"}
            speedMultiplier={0.3}
          />
          <p style={{ color: "black", marginTop: "5px" }}>
            {this.state.spinnerMessage}
          </p>
        </div>
        }
      >
        <div className="mg-l-50 mg-r-25" >
          <div className="row row-sm  mg-r-15 mg-l-5 mg-t-5 mygrvncecnt">
            <div className="col-lg-7 my-mncnt" style={{ border: "1px solid #cdd4e0"}}>
              <div className="row mb-2">
                <div className="col-md-12 mt-2" style={{textAlign:'center'}}>
                  <h4 className="grvn-text"><b >Suggestion To Management</b>{" "}</h4>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <textarea className="mygrvncetxtarea" rows="5" cols="100" readOnly
                    defaultValue = "Do you have a suggestion? Do you think there is a better way to do a particular activity? You may give a suggestion or opinion about anything related to operations, working environment, grievance, complaint, abuse or just about anything. Your message is sent to CEO, through this interface your personal information is not transmitted and not stored anywhere. You can be assured of anonymity and confidentiality.    " />
                </div>
              </div>
              <form onSubmit={this.handleSubmit}>
                <div className="row mt-2 mysgtdta">
                  <div className="col-md-12">
                    <div className="mydtlscnt">
                      <TextField
                        className="form-control custom-textfield"
                        id="CustomerCode"
                        label={shouldShowLabel ? "Subject (max.100 characters)" : ""}
                          variant="outlined"
                          size="small"
                          placeholder="Enter the subject of suggestion here (max.100 characters)"
                          value={subject}
                          onChange={this.onChangeSubjectValue}
                          onFocus={() => this.handleFocus('isFocused')}
                          onBlur={() => this.handleBlur('isFocused')}
                          inputProps={{ maxLength: 100 }}
                          InputLabelProps={{ shrink: shouldShowLabel }}           
                      />
                      <span className="text-danger asterisk-size ml-2">*</span> 
                    </div>
                    {subjectErr && (
                      <div className="error-message">{subjectErr}</div>
                    )}
                  </div>
                </div>
                            
                <div className="row mt-3 mb-3">
                  <div className="col-md-12">
                    <div className="mydtlssuggest mydtlscnt">
                      <TextField
                        className="resizable-textfield"
                        id="Details"
                        label={myshouldShowLabel ? "Details (max.1000 characters)" : ""}
                        placeholder="Enter the details of suggestion here (max.1000 characters)"
                        value={details}
                        onChange={this.onmyChangeDetailsValue}
                        onFocus={() => this.handleFocus('myisFocused')}
                        onBlur={() => this.handleBlur('myisFocused')}
                        inputProps={{ maxLength: 1000 }}
                        multiline
                        rows={7}
                        col={200}
                        variant="outlined"
                        size="small"
                                           
                      />
                      <span className="text-danger asterisk-size ml-2">*</span>   
                    </div>
                    {detailsErr && (
                      <div className="error-message">{detailsErr}</div>
                    )}
                  </div>
                </div>
                <button type="submit" className="sugst submit-button mb-3"><i class="fa fa-save"></i> Submit</button>
              </form>           
            </div>
          </div>
        </div>
      </LoadingOverlay>  
    );
  }
}

export default suggestiontomanagement;
