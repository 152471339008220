import React, { Component } from "react";
import projectService from "../../services/project.service";
import projectBatchService from "../../services/projectBatch.service";
import inputOutputFormatService from "../../services/inputOutputFormat.service";
import helper from "../../helpers/helpers";
import Moment from "moment";
import ModernDatepicker from "react-modern-datepicker";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class EditProjectBatch extends Component {
  constructor(props) {
    super(props);

    this.onChangeInputCount = this.onChangeInputCount.bind(this);
    this.onChangeInputCountType = this.onChangeInputCountType.bind(this);

    this.onChangeReceivedDate = this.onChangeReceivedDate.bind(this);
    this.onChangeReceivedFormat = this.onChangeReceivedFormat.bind(this);
    this.clearReceivedDate = this.clearReceivedDate.bind(this);

    this.onChangeOutputFormat = this.onChangeOutputFormat.bind(this);
    this.onChangePlannedStartDate = this.onChangePlannedStartDate.bind(this);
    this.clearPlannedStartDate = this.clearPlannedStartDate.bind(this);

    this.onChangepPlannedDeliveryDate =
      this.onChangePlannedDeliveryDate.bind(this);
    this.clearPlannedDeliveryDate = this.clearPlannedDeliveryDate.bind(this);
    this.onChangeRemarks = this.onChangeRemarks.bind(this);

    //#region Bind Customer File Upload controls
    this.uploadCustomerInputFile = this.uploadCustomerInputFile.bind(this);
    this.downloadCustomerInputFile = this.downloadCustomerInputFile.bind(this);
    this.deleteCustomerInputFile = this.deleteCustomerInputFile.bind(this);
    //#endregion

    this.reset = this.reset.bind(this);

    //#region Component State
    this.state = {
      //#region Project Batch
      loading: false,
      spinnerMessage: "",
      projectID: 0,
      projectBatchID: 0,
      customerCode: "",
      projectCode: "",
      batchNo: "",
      scope: "",
      inputCount: 0,
      selectedInputCountType: "",
      plannedStartDate: "",
      remarks: "",
      customerInputFile: "",
      customerInputFileUploadedName: "",
      messageForCustomerInputFile: false,
      customerInputFileKey: Date.now(),
      showCustomerInputFileLabel: true,
      //#endregion

      //#region Project Received Details
      receivedDate: "",
      InputOutputFormats: [],
      selectedReceivedFormat: "",
      //#endregion

      //#region Project Delivery Details
      selectedOutputFormat: "",
      plannedDeliveryDate: "",
      //#endregion

      formErrors: {},
    };
    //#endregion

    this.initialState = this.state;
  }

  //#region Component Mount
  componentDidMount() {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    this.fetchInputOutputFormats();
    this.fetchProjectBatchDetails();
  }
  //#endregion

  //#region fetching project Details from Web API
  fetchProjectBatchDetails() {
    const { state } = this.props.location; // Project Batch ID passed from View Project Batch Page
    if (state === 0 || state === null || state === undefined) {
      this.props.history.push("/Projects");
      return;
    }

    this.setState({
      spinnerMessage: "Please wait while fetching project batch details...",
      loading: true,
    });

    projectBatchService
      .getProjectBatchDetailsByID(state.ProjectBatchID, helper.getUser())
      .then((response) => {
        if (response.data.InputCountType === "Items / Lines")
          this.setState({ selectedInputCountType: "I" });
        else this.setState({ selectedInputCountType: "D" });

        if (response.data.ReceivedDate)
          this.setState({
            receivedDate: Moment(response.data.ReceivedDate).format(
              "DD-MMM-yyyy"
            ),
          });
        else
          this.setState({
            receivedDate: "",
          });

        if (response.data.PlannedStartDate)
          this.setState({
            plannedStartDate: Moment(response.data.PlannedStartDate).format(
              "DD-MMM-yyyy"
            ),
          });
        else
          this.setState({
            plannedStartDate: "",
          });

        if (response.data.PlannedDeliveryDate)
          this.setState({
            plannedDeliveryDate: Moment(
              response.data.PlannedDeliveryDate
            ).format("DD-MMM-yyyy"),
          });
        else
          this.setState({
            plannedDeliveryDate: "",
          });

        this.setState({
          projectID: response.data.ProjectID,
          projectBatchID: response.data.ProjectBatchID,
          customerCode: response.data.CustomerCode,
          projectCode: response.data.ProjectCode,
          batchNo: response.data.BatchNo,
          scope: response.data.Scope,
          inputCount: response.data.InputCount,
          selectedReceivedFormat: response.data.ReceivedFormat,
          selectedOutputFormat: response.data.OutputFormat,
          remarks: response.data.Remarks,
          customerInputFile: response.data.CustomerInputFileName,
          customerInputFileUploadedName: response.data.CustomerInputFileName,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({ loading: false });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region fetching Input Output Formats from Service
  fetchInputOutputFormats() {
    this.setState({
      spinnerMessage: "Please wait while loading input output formats...",
      loading: true,
    });

    inputOutputFormatService
      .getAllInputOutputFormats(helper.getUser(), true)
      .then((response) => {
        this.setState({
          InputOutputFormats: response.data,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Get Input Count value
  onChangeInputCount(e) {
    this.setState({
      inputCount: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = { ...this.state.formErrors, inputCountError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Get Selected Input Count Type
  onChangeInputCountType(e) {
    this.setState({
      selectedInputCountType: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = { ...this.state.formErrors, inputCountTypeError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region  Get Selected Received Date
  onChangeReceivedDate(date) {
    this.setState({
      receivedDate: date,
    });

    if (date !== "" && date !== null) {
      const formErrors = { ...this.state.formErrors, receivedDateError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Get Selected Received Format
  onChangeReceivedFormat(e) {
    this.setState({
      selectedReceivedFormat: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = { ...this.state.formErrors, receivedFormatError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Get Selected Output Format
  onChangeOutputFormat(e) {
    this.setState({
      selectedOutputFormat: e.target.value,
    });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = { ...this.state.formErrors, outputFormatError: "" };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Clearing Dates
  clearReceivedDate() {
    this.setState({ receivedDate: "" });
  }

  clearPlannedStartDate() {
    this.setState({ plannedStartDate: "" });
  }

  clearPlannedDeliveryDate() {
    this.setState({ plannedDeliveryDate: "" });
  }
  //#endregion

  //#region  Get Selected Planned Start Date
  onChangePlannedStartDate(date) {
    this.setState({
      plannedStartDate: date,
    });

    if (date !== "" && date !== null) {
      const formErrors = {
        ...this.state.formErrors,
        plannedStartDateError: "",
      };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region  Get Selected Planned Delivery Date
  onChangePlannedDeliveryDate(date) {
    this.setState({
      plannedDeliveryDate: date,
    });

    if (date !== "" && date !== null) {
      const formErrors = {
        ...this.state.formErrors,
        plannedDeliveryDateError: "",
      };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region get Remarks value
  onChangeRemarks(e) {
    this.setState({
      remarks: e.target.value,
    });
  }
  //#endregion

  //#region Uploading CustomerInput File
  uploadCustomerInputFile(e) {
    this.setState({
      messageForCustomerInputFile: true,
    });

    var files = e.target.files;

    let currentFile = files[0];
    let fileNameUploaded = files[0].name;
    this.setState({
      customerInputFileUploadedName: fileNameUploaded,
    });

    let formData = new FormData();
    formData.append("File", currentFile);

    this.setState({
      spinnerMessage: "Please wait while uploading customer file...",
      loading: true,
    });

    //Service call
    projectService
      .saveFileupload(formData)
      .then((response) => {
        this.setState({
          messageForCustomerInputFile: false,
          customerInputFile: response.data,
          showCustomerInputFileLabel: false,
          loading: false,
        });
      })
      .catch((error) => {
        toast.error(error.response.data.Message, { autoClose: false });
        this.setState({
          messageForCustomerInputFile: false,
          customerInputFile: "",
          loading: false,
        });
      });

    if (e.target.value !== "" && e.target.value !== null) {
      const formErrors = {
        ...this.state.formErrors,
        customerInputFileError: "",
      };
      this.setState({ formErrors: formErrors });
    }
  }
  //#endregion

  //#region Downloading customer Input File
  downloadCustomerInputFile(e) {
    this.setState({
      spinnerMessage: "Please wait while downloading customer file...",
      loading: true,
    });

    projectBatchService
      .downloadFile(this.state.customerInputFile)
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          this.state.customerInputFileUploadedName
        );
        document.body.appendChild(fileLink);

        fileLink.click();

        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Delete Customer Input File
  deleteCustomerInputFile() {
    this.setState({
      spinnerMessage: "Please wait while deleting customer file...",
      loading: true,
    });

    projectService
      .deleteFile(this.state.customerInputFile)
      .then(() => {
        this.setState({
          customerInputFileKey: Date.now(),
          customerInputFile: "",
          customerInputFileUploadedName: "",
          loading: false,
        });
      })
      .catch((error) => {
        toast.error(error.response.data.Message, { autoClose: false });
        this.setState({
          customerInputFile: "",
          loading: false,
        });
      });
  }
  //#endregion

  //#region Save Project Batch
  saveProjectBatch = (e) => {
    e.preventDefault();

    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    if (this.handleFormValidation()) {
      this.setState({
        spinnerMessage: "Please wait while Saving Project Batch...",
        loading: true,
      });

      var data = {
        ProjectID: this.state.projectID,
        ProjectBatchID: this.state.projectBatchID,
        CustomerCode: this.state.customerCode,
        ProjectCode: this.state.projectCode,
        BatchNo: this.state.batchNo,
        Scope: this.state.scope,
        InputCount: this.state.inputCount,
        InputCountType: this.state.selectedInputCountType,
        ReceivedDate: this.state.receivedDate,
        ReceivedFormat: this.state.selectedReceivedFormat,
        OutputFormat: this.state.selectedOutputFormat,
        PlannedStartDate: this.state.plannedStartDate,
        PlannedDeliveryDate: this.state.plannedDeliveryDate,
        Remarks: this.state.remarks,
        CustomerInputFileName: this.state.customerInputFile,
        Status: "",
        UserID: helper.getUser(),
      };

      //Service call
      projectBatchService
        .updateProjectBatch(this.state.projectBatchID, data)
        .then(() => {
          this.setState({
            loading: false,
          });
          toast.success("Project Batch Updated Successfully");
          this.setState(this.initialState);
          this.props.history.push({
            pathname: "/Projects/ProjectBatchList",
            state: {
              ProjectID: data.ProjectID,
              CustomerCode: data.CustomerCode,
              ProjectCode: data.ProjectCode,
              Scope: data.Scope,
              activeTab: 1,
            },
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          toast.error(error.response.data.Message, { autoClose: false });
        });
    }
  };
  //#endregion

  //#region Validating the input data
  handleFormValidation() {
    const inputCount = this.state.inputCount;
    const inputCountType = this.state.selectedInputCountType.trim();
    const receivedDate = this.state.receivedDate;
    const receivedFormat = this.state.selectedReceivedFormat.trim();
    const outputFormat = this.state.selectedOutputFormat.trim();
    const plannedStartDate = this.state.plannedStartDate;
    const plannedDeliveryDate = this.state.plannedDeliveryDate;
    const customerInputFile = this.state.customerInputFile;
    let formErrors = {};
    let isValidForm = true;

    //Input Count
    if (!inputCount) {
      isValidForm = false;
      formErrors["inputCountError"] = "Input Count is required";
    }

    //Input Count Type
    if (!inputCountType) {
      isValidForm = false;
      formErrors["inputCountTypeError"] = "Input Count Type is required";
    }

    //Received Date
    if (!receivedDate) {
      isValidForm = false;
      formErrors["receivedDateError"] = "Received Date is required";
    }

    //Received Format
    if (!receivedFormat) {
      isValidForm = false;
      formErrors["receivedFormatError"] = "Received Format is required";
    }

    //Output Format
    if (!outputFormat) {
      isValidForm = false;
      formErrors["outputFormatError"] = "Output Format is required";
    }

    //Planned Start Date
    if (!plannedStartDate) {
      isValidForm = false;
      formErrors["plannedStartDateError"] = "Planned Start Date is required";
    } else if (new Date(plannedStartDate) < new Date(receivedDate)) {
      isValidForm = false;
      formErrors["plannedStartDateError"] =
        "Planned Start Date can not be earlier than Received Date";
    }

    //Planned Delivery Date
    if (!plannedDeliveryDate) {
      isValidForm = false;
      formErrors["plannedDeliveryDateError"] =
        "Planned Delivery Date is required";
    } else if (new Date(plannedDeliveryDate) < new Date(plannedStartDate)) {
      isValidForm = false;
      formErrors["plannedDeliveryDateError"] =
        "Planned Delivery Date can not be earlier than Planned Start Date";
    }

    //Customer Input File
    if (!customerInputFile) {
      isValidForm = false;
      formErrors["customerInputFileError"] = "Customer Input File is required";
    }

    this.setState({ formErrors: formErrors });
    return isValidForm;
  }
  //#endregion

  //#region Reset the page
  reset() {
    this.setState({
      customerInputFileKey: Date.now(),
      showCustomerInputFileLabel: true,
      formErrors: {},
    });
    this.componentDidMount();
  }
  //#endregion

  render() {
    const messageForCustomerInputFile = this.state.messageForCustomerInputFile;
    const customerInputFile = this.state.customerInputFile;
    const showCustomerInputFileLabel = this.state.showCustomerInputFileLabel;

    return (
      <div className="create-project-page" style={{ height: "93%" }}>
        <LoadingOverlay
          active={this.state.loading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
          <div className="cp-main-div">
            <form onSubmit={this.saveProjectBatch} className="cp-form">
              <div className="cp-breadcome-header">
                <div className="az-content-breadcrumb mg-t-20">
                  <span>Projects</span>
                  <span>Edit Project Batch</span>
                </div>
                <h4>
                  Edit Project Batch{" "}
                  <span className="icon-size">
                    <i
                      onClick={() => {
                        this.props.history.push({
                          pathname: "/Projects/ProjectBatchList",
                          state: {
                            ProjectID: this.state.projectID,
                            CustomerCode: this.state.customerCode,
                            ProjectCode: this.state.projectCode,
                            Scope: this.state.scope,
                            activeTab: 1,
                          },
                        });
                      }}
                      className="far fa-arrow-alt-circle-left text-primary pointer"
                      title="Back to List"
                    ></i>
                  </span>
                </h4>
              </div>

              <div id="Edit_ProjectBatch" className="cpb-container">
                <div className="row row-sm read-project-data">
                  <div className="col-lg-2">
                    <div className="form-field-div-read">
                      <label htmlFor="CustomerCode">
                        <b>Customer Code</b>{" "}
                        <span className="text-danger asterisk-size">*</span>
                      </label>
                      <div>
                        <p id="CustomerCode" name="CustomerCode">
                          {this.state.customerCode}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-field-div-read">
                      <label htmlFor="CustomerCode">
                        <b>Project Code</b>{" "}
                        <span className="text-danger asterisk-size">*</span>
                      </label>
                      <div>
                        <p id="CustomerCode" name="CustomerCode">
                          {this.state.projectCode}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-field-div-read">
                      <label htmlFor="CustomerCode">
                        <b>Batch No.</b>{" "}
                        <span className="text-danger asterisk-size">*</span>
                      </label>
                      <div>
                        <p id="CustomerCode" name="CustomerCode">
                          {this.state.batchNo}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-field-div-read">
                      <label htmlFor="CustomerCode">
                        <b>Scope</b>{" "}
                        <span className="text-danger asterisk-size">*</span>
                      </label>
                      <div>
                        <p id="CustomerCode" name="CustomerCode">
                          {this.state.scope}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row row-sm">
                  <div className="col-lg-4">
                    <div className="form-field-div">
                      <label htmlFor="InputCount">
                        Input Count{" "}
                        <span className="text-danger asterisk-size">*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control flex-grow-1"
                        tabIndex="1"
                        id="InputCount"
                        name="InputCount"
                        value={this.state.inputCount}
                        onChange={this.onChangeInputCount}
                        max="99999"
                        min="1"
                      />
                    </div>
                    <div className="error-message">
                      {this.state.formErrors["inputCountError"]}
                    </div>
                  </div>
                  <div className="col-lg-4 mg-t-10 mg-lg-t-0">
                    <div className="form-field-div">
                      <label htmlFor="InputCountType">
                        Input Count Type{" "}
                        <span className="text-danger asterisk-size">*</span>
                      </label>
                      <select
                        className="form-control flex-grow-1"
                        tabIndex="2"
                        id="InputCountType"
                        name="InputCountType"
                        value={this.state.selectedInputCountType}
                        onChange={this.onChangeInputCountType}
                      >
                        <option value="">--Select--</option>
                        <option key="Items/Lines" value="I">
                          Items / Lines
                        </option>
                        <option key="Document" value="D">
                          Document
                        </option>
                      </select>
                    </div>
                    <div className="error-message">
                      {this.state.formErrors["inputCountTypeError"]}
                    </div>
                  </div>
                  <div className="col-lg-4 mg-t-10 mg-lg-t-0">
                    <div className="form-field-div">
                      <label htmlFor="ReceivedDate">
                        Received Date{" "}
                        <span className="text-danger asterisk-size">*</span>
                      </label>
                      <div className="form-field-div flex-grow-1">
                        <div className="form-control date-field-width">
                          <ModernDatepicker
                            tabIndex="4"
                            id="ReceivedDate"
                            name="ReceivedDate"
                            date={this.state.receivedDate}
                            format={"DD-MMM-YYYY"}
                            onChange={(date) => this.onChangeReceivedDate(date)}
                            placeholder={"Select a date"}
                            className="color"
                            minDate={new Date(1900, 1, 1)}
                          />
                        </div>
                        <span
                          className="btn btn-secondary"
                          onClick={this.clearReceivedDate}
                        >
                          <i
                            className="far fa-window-close"
                            title="Clear Received Date"
                          ></i>
                        </span>
                        <div className="error-message">
                          {this.state.formErrors["receivedDateError"]}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="row row-sm">
                  <div className="col-lg-4">
                    <div className="form-field-div">
                      <label htmlFor="ReceivedFormat">
                        Received Format{" "}
                        <span className="text-danger asterisk-size">*</span>
                      </label>
                      <select
                        className="form-control flex-grow-1"
                        tabIndex="5"
                        id="ReceivedFormat"
                        name="ReceivedFormat"
                        value={this.state.selectedReceivedFormat}
                        onChange={this.onChangeReceivedFormat}
                      >
                        <option value="">--Select--</option>
                        {this.state.InputOutputFormats.map((formats) => (
                          <option key={formats.Format}>{formats.Format}</option>
                        ))}
                      </select>
                    </div>
                    <div className="error-message">
                      {this.state.formErrors["receivedFormatError"]}
                    </div>
                  </div>
                  <div className="col-lg-4 mg-t-10 mg-lg-t-0">
                    <div className="form-field-div">
                      <label htmlFor="OutputFormat">
                        Output Format{" "}
                        <span className="text-danger asterisk-size">*</span>
                      </label>
                      <select
                        className="form-control flex-grow-1"
                        tabIndex="6"
                        id="OutputFormat"
                        name="OutputFormat"
                        value={this.state.selectedOutputFormat}
                        onChange={this.onChangeOutputFormat}
                      >
                        <option value="">--Select--</option>
                        {this.state.InputOutputFormats.map((formats) => (
                          <option key={formats.Format}>{formats.Format}</option>
                        ))}
                      </select>
                    </div>
                    <div className="error-message">
                      {this.state.formErrors["outputFormatError"]}
                    </div>
                  </div>
                  <div className="col-lg-4 mg-t-10 mg-lg-t-0">
                    <div className="form-field-div">
                      <label htmlFor="PlannedStartDate">
                        Planned Start Date{" "}
                        <span className="text-danger asterisk-size">*</span>
                      </label>
                      <div className="form-field-div flex-grow-1">
                        <div className="form-control date-field-width">
                          <ModernDatepicker
                            tabIndex="7"
                            id="PlannedStartDate"
                            name="PlannedStartDate"
                            date={this.state.plannedStartDate}
                            format={"DD-MMM-YYYY"}
                            onChange={(date) =>
                              this.onChangePlannedStartDate(date)
                            }
                            placeholder={"Select a date"}
                            className="color"
                            minDate={new Date(1900, 1, 1)}
                          />
                        </div>
                        <span
                          className="btn btn-secondary"
                          onClick={this.clearPlannedStartDate}
                        >
                          <i
                            className="far fa-window-close"
                            title="Clear Planned Start Date"
                          ></i>
                        </span>
                      </div>
                    </div>
                    <div className="error-message">
                      {this.state.formErrors["plannedStartDateError"]}
                    </div>
                  </div>
                </div>
                <br />
                <div className="row row-sm">
                  <div className="col-lg-4">
                    <div className="form-field-div">
                      <label htmlFor="plannedDeliveryDate">
                        Planned Delivery Date{" "}
                        <span className="text-danger asterisk-size">*</span>
                      </label>
                      <div className="form-field-div flex-grow-1">
                        <div className="form-control date-field-width">
                          <ModernDatepicker
                            tabIndex="8"
                            id="plannedDeliveryDate"
                            name="plannedDeliveryDate"
                            date={this.state.plannedDeliveryDate}
                            format={"DD-MMM-YYYY"}
                            onChange={(date) =>
                              this.onChangePlannedDeliveryDate(date)
                            }
                            placeholder={"Select a date"}
                            className="color"
                            minDate={new Date(1900, 1, 1)}
                          />
                        </div>
                        <span
                          className="btn btn-secondary"
                          onClick={this.clearPlannedDeliveryDate}
                        >
                          <i
                            className="far fa-window-close"
                            title="Clear Planned Delivery Date"
                          ></i>
                        </span>
                      </div>
                    </div>
                    <div className="error-message">
                      {this.state.formErrors["plannedDeliveryDateError"]}
                    </div>
                  </div>
                  <div className="col-lg-4 mg-t-10 mg-lg-t-0">
                    <div className="form-field-div">
                      <label htmlFor="Remarks">Remarks</label>
                      <textarea
                        className="form-control flex-grow-1"
                        rows="2"
                        tabIndex="9"
                        id="Remarks"
                        name="Remarks"
                        maxLength="500"
                        value={this.state.remarks}
                        onChange={this.onChangeRemarks}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-lg mg-t-10 mg-lg-t-0">
                    <div
                      className="form-field-div"
                      style={{ position: "relative" }}
                    >
                      <label htmlFor="CustomerInputFile">
                        Customer Input File{" "}
                        <span className="text-danger asterisk-size">*</span>
                      </label>
                      <div className="form-field-div flex-grow-1">
                        <div className="customer-file-width-a">
                          <input
                            type="file"
                            className="form-control"
                            tabIndex="9"
                            id="CustomerInputFile"
                            name="CustomerInputFile"
                            key={this.state.customerInputFileKey}
                            onChange={this.uploadCustomerInputFile}
                            accept=".xls, .xlsx,.doc,.docx,.pdf"
                          />
                        </div>
                        <div className="text-nowrap customer-file-width-b">
                          {customerInputFile && (
                            <>
                              <span
                                className="btn btn-secondary"
                                tabIndex="10"
                                onClick={this.downloadCustomerInputFile}
                              >
                                <i className="fas fa-download"></i>
                              </span>
                              <span
                                className="btn btn-secondary mg-l-5"
                                tabIndex="11"
                                onClick={this.deleteCustomerInputFile}
                              >
                                <i className="fas fa-trash-alt"></i>
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="error-message">
                        {this.state.formErrors["customerInputFileError"]}
                      </div>
                      {showCustomerInputFileLabel && (
                        <label
                          htmlFor="CustomerInputFile"
                          className="edited-file"
                        >
                          {this.state.customerInputFileUploadedName}
                        </label>
                      )}
                      {messageForCustomerInputFile && <p>Please Wait...</p>}
                    </div>
                  </div>
                </div>
                <br />
                <div className="row row-sm">
                  <div className="col-md-3"></div>
                  <div className="col-md-2 mg-t-10 mg-lg-t-0">
                    <input
                      type="submit"
                      id="Save"
                      className="mg-t-10 mg-md-t-0 btn btn-gray-700 btn-block"
                      tabIndex="12"
                      value="Save"
                    />
                  </div>
                  <div className="col-md-1"></div>
                  <div className="col-md-2  mg-t-10 mg-lg-t-0">
                    <span
                      className="btn btn-gray-700 btn-block d-flex align-items-center justify-content-center"
                      tabIndex="13"
                      onClick={this.reset}
                      id="Reset"
                    >
                      Reset
                    </span>
                  </div>
                </div>
                <div className="mg-b-10"></div>
              </div>
            </form>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default EditProjectBatch;
